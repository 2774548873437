import { Component, ErrorHandler, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {

    constructor(private errorHandler: ErrorHandler, private router: Router) { }

    ngOnInit() {
        this.errorHandler.handleError(`Unmatched route: ${this.router.url}`);
    }

}
