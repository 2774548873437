import * as Rollbar from 'rollbar';
import { ErrorHandler, Inject, Injectable, InjectionToken, Injector } from '@angular/core';

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

import { environment} from 'environments/environment';

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {

    constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

    handleError(err: any) {
        this.rollbar.error(err.originalError || err);
    }
}

export function rollbarFactory() {
    return new Rollbar(environment.rollbar);
}
