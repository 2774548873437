import {switchMap, map, catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {createEffect, Actions, ofType} from '@ngrx/effects';

import {Observable, of, from as fromPromise} from 'rxjs';

import * as global_search from '../actions/global-search.actions';

import {GlobalSearchService} from '../../core/services/global-search/global-search.service';

@Injectable()
export class GlobalSearchEffects {


    search: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(global_search.GLOBAL_SEARCH),
        map((action: global_search.GlobalSearchAction) => action.payload),
        switchMap((payload) =>
            fromPromise(this.globalSearchService.search(payload)).pipe(
                map((search) => new global_search.GlobalSearchActionSuccessful(search)),
                catchError((error) => of(new global_search.GlobalSearchActionFailed(error)))
            )
        )
    ));

    constructor(
        private $actions: Actions,
        private globalSearchService: GlobalSearchService,
    ) {
    }
}
