import * as invoiceItemCategories from '../actions/invoiceitemcategories.actions';

export interface State {
    loading: boolean;
    loadingSuccessful: boolean;
    loadingError: string;
    invoiceItemCategories: any;
}

const initialState: State = {

    loading: false,
    loadingSuccessful: null,
    loadingError: null,
    invoiceItemCategories: null
};

export function reducer(state = initialState, action): State {

    switch (action.type) {
        case invoiceItemCategories.LOAD_INVOICE_ITEM_CATEGORIES:
            return {...state, loading: true };
        case invoiceItemCategories.LOAD_INVOICE_ITEM_CATEGORIES_SUCCESSFUL:
            return {...state, loading: false, invoiceItemCategories: action.payload, loadingSuccessful: true };
        case invoiceItemCategories.LOADING_INVOICE_ITEM_CATEGORIES_FAILED:
            return {...state, loading: false, loadingSuccessful: false, loadingError: action.payload.error.message};
        default:
            return state;
    }

}

export const getInvoiceItemCategories = (state: State) => state.invoiceItemCategories;
