
import {switchMap, take, skipWhile} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';


import * as fromRoot from '../../store/reducers';
import * as form from '../../store/reducers/form.reducer';
import * as fromForms from '../../store/actions/form.actions';
import * as fromWorkorders from '../../store/actions/workorders.actions';
import * as fromJobs from '../../store/actions/job.actions';
import {VehicleService} from './vehicle.service';
import {NgxSmartModalService} from 'ngx-smart-modal';
import {WorkOrderProvider} from '../providers/work-order.provider';
import {SessionService} from './session.service';

@Injectable()
export class IframeMessengerService {


    private formState: form.State = null;
    private workorderState: any = null;

    constructor(private store: Store<fromRoot.State>, private router: Router, private vehicleService: VehicleService, private ngxSmartModalService: NgxSmartModalService,
        private workOrderProvider: WorkOrderProvider,
        private sessionService: SessionService, ) {

        this.store.select(fromRoot.getFormState).subscribe(formState => this.formState = formState);
        this.store.select(fromRoot.getWorkorderState).subscribe(workorderState => this.workorderState = workorderState);
    }

    public start() {
        window.addEventListener('message', (event) => {
            let message = '';
            try {
                message = JSON.parse(event.data);
            } catch (e) {
                // not valid json just return here
                return;
            }

            this.iframeMessageActionSwitcher(message);
        });
    }

    private iframeMessageActionSwitcher(message) {
        switch (message.action) {
            case 'Item Added':
                if (this.workorderState && this.workorderState.currentWorkorder) {
                if (this.formState && this.formState.addingItems) {
                    this.store.dispatch(new fromForms.FinishedAddingItemsAction({itemID: message.payload, form: this.formState, workorder: this.workorderState.currentWorkorder}));
                }
                this.store.dispatch(new fromWorkorders.LoadWorkOrderAction(this.workorderState.currentWorkorder.wor_workorderID));
                } else if (!this.workorderState || !this.workorderState.currentWorkorder) {
                    this.router.navigate(['search']);
                }
                break;
        case 'Quote':
            this.store.dispatch(new fromWorkorders.SetTicketTypeAction({type: 'quote'}));
            this.router.navigate(['search'], {queryParams: {type: 'quote'}});
            break;

            case 'Oil Item Added':

                if (this.workorderState && this.workorderState.currentWorkorder) {
                    this.store.dispatch(new fromWorkorders.LoadWorkOrderAction(this.workorderState.currentWorkorder.wor_workorderID));
                }

                if (this.formState && this.formState.addingItems) {
                    this.store.dispatch(new fromForms.FinishedAddingItemsAction({itemID: message.payload, form: this.formState, workorder: this.workorderState.currentWorkorder}));
                } else if (!this.workorderState || !this.workorderState.currentWorkorder) {
                    this.router.navigate(['search']);
                } else {
                this.router.navigate(['work-order-info']);
                }
                break;
            case 'Work Order Selected':
                this.store.dispatch(new fromWorkorders.SetWorkorderAction(null));
                this.store.dispatch(new fromWorkorders.LoadWorkOrderAction(message.payload.workorderId));

                setTimeout(() => {
                this.router.navigate(['work-order-info']);
                }, 1000);

                break;
            case 'Work Order Invoice':
            this.store.dispatch(new fromWorkorders.SetWorkorderAction(null));
            this.store.dispatch(new fromWorkorders.LoadWorkOrderAction(message.payload.workorderId));

            setTimeout(() => {
                this.router.navigate(['work-order-info'], {queryParams: {invoice: 1}});
            }, 1000);
            break;
            case 'Work Order Inspection Selected':
                this.store.dispatch(new fromWorkorders.LoadWorkOrderAction(message.payload.workOrderId));
                this.store.select((state) => state).pipe(skipWhile((state: fromRoot.State) => !(state.workOrders.currentWorkorder)), take(1), switchMap( result => {

                    this.store.select(fromRoot.getCurrentReasonForVisit).pipe(take(1)).subscribe((currentReasonForVisit: any) => {

                        if (currentReasonForVisit.action) {
                            const actions = currentReasonForVisit.action
                                .split(',')
                                .map((actionItem) => actionItem.trim())
                                .map((actionItem) => parseInt(actionItem, 10))
                                .filter(actionItem => !isNaN(actionItem));

                            this.store.dispatch(new fromJobs.LoadSubcategoriesAction(actions));
                        }

                    });

                    return this.store.select(fromRoot.getWorkorderState).pipe(skipWhile((state: any) => state.loading === true), take(1));

                })).subscribe(workorder => {
                    this.router.navigate(['workflow']);
                });

                break;
            case 'Home Item Add':
                this.router.navigate(['add-item/blank']);
                break;
            case 'credit_card_processing':
                this.router.navigate(['/credit-card-processing']);
                break;
            case 'Work Order Message':
            this.store.dispatch(new fromWorkorders.LoadWorkOrderAction(message.payload.workOrderId));
            setTimeout(() => {
                this.router.navigate(['/work-order-messages'], {queryParams: {woID: message.payload.workOrderId, returnUrl: (message.payload.returnUrl ? message.payload.returnUrl : '')}});
            });
            break;
            case 'Edit Salespeople':
            this.store.dispatch(new fromWorkorders.SetWorkorderAction(null));
            this.store.dispatch(new fromWorkorders.LoadWorkOrderAction(message.payload.workOrderId));
            setTimeout(() => {
                this.router.navigate(['work-order-info'], {queryParams: {popSales: true, returnUrl: (message.payload.returnUrl ? message.payload.returnUrl : '')}});
            }, 1000);
            break;
            case 'Edit Customer':
            this.store.dispatch(new fromWorkorders.SetWorkorderAction(null));
            this.store.dispatch(new fromWorkorders.LoadWorkOrderAction(message.payload.workOrderId));
            this.router.navigate(['/customers/' + message.payload.customerID + '/edit'], {queryParams: {returnUrl: (message.payload.returnUrl ? message.payload.returnUrl : '')}});
            break;
            case 'Edit Vehicle':
            this.store.dispatch(new fromWorkorders.SetWorkorderAction(null));
            this.store.dispatch(new fromWorkorders.LoadWorkOrderAction(message.payload.workOrderId));
            this.router.navigate(['/vehicles/' + message.payload.vehicleID + '/edit'], {queryParams: {returnUrl: (message.payload.returnUrl ? message.payload.returnUrl : '')}});
            break;
            case 'ARC Cart':
            this.router.navigate(['arc/cart/workOrderId/' + message.payload.workOrderId]);
            break;
            case 'Customer Messages':
            this.router.navigate(['/customer-messages/' + message.payload.customerID], {queryParams: {returnUrl: (message.payload.returnUrl ? message.payload.returnUrl : '')}});
            break;
            case 'Vehicle Specifications':
            this.router.navigate(['/vehicle-specifications']);
            break;
            case 'Go to Workflow':
            this.router.navigate(['workflow-iframe']);
            break;
            case 'Go to Inspections':
            this.store.dispatch(new fromWorkorders.SetWorkorderAction(null));
            this.store.dispatch(new fromWorkorders.LoadWorkOrderAction(message.payload.workOrderId));
            setTimeout(() => {
                this.router.navigate(['workflow'], {queryParams: {returnUrl: (message.payload.returnUrl ? message.payload.returnUrl : '')}});
            }, 500);
            break;
            case 'Open Notes':
            this.store.dispatch(new fromWorkorders.SetWorkorderAction(null));
            this.store.dispatch(new fromWorkorders.LoadWorkOrderAction(message.payload.workOrderId));
            setTimeout(() => {
                this.router.navigate(['other'], {queryParams: {returnUrl: (message.payload.returnUrl ? message.payload.returnUrl : '')}});
            }, 500);
            break;
            case 'Vehicle History':
            this.router.navigate(['vehicle-history'], {queryParams: {vehicleID: message.payload.vehicleID, custID: message.payload.custID, returnUrl: (message.payload.returnUrl ? message.payload.returnUrl : '')}});
            break;
            case 'bay_scheduler':
            this.router.navigate(['scheduler'], {queryParams: {returnUrl: (message.payload.returnUrl ? message.payload.returnUrl : ''), appointmentID: message.payload.scheduleID}});
            break;
            case 'Back To Inspection':
                if (this.workorderState && this.workorderState.currentWorkorder) {
                    if (this.formState && this.formState.addingItems) {
                        this.store.dispatch(new fromForms.BackToInspectionAction({itemID: message.payload, form: this.formState, workorder: this.workorderState.currentWorkorder}));
                    }
                } else if (!this.workorderState || !this.workorderState.currentWorkorder) {
                    this.router.navigate(['work-order-info']);
                }
                break;
            case 'Text to Pay':
            this.router.navigate(['financing/three-sixty-text-to-pay']);
            break;
            case 'Send Text to Pay':
            this.ngxSmartModalService.getModal('sendTextToPayModal').open();
            break;
            case 'Order Verification':
            this.ngxSmartModalService.getModal('orderVerificationModal').open();
            break;
        case 'Clear Store':
            this.sessionService.clear().then(() => {
                this.workOrderProvider.clear();
            });
            break;
        case 'Open Financing Modal':
            this.ngxSmartModalService.getModal('financing_modal').setData({
                workOrderId: message.payload.workOrderId,
                workOrderTotal: message.payload.workOrderTotal,
                paymentAmount: message.payload.paymentAmount
            });
            this.ngxSmartModalService.getModal('financing_modal').open();
            break;
        case 'Financing Closed':
            this.ngxSmartModalService.getModal('financing_modal').close();
            break;
        case 'Open Documents Modal':
            this.ngxSmartModalService.getModal('documents_modal').setData({
                workOrderId: message.payload.workOrderId,
            });
            this.ngxSmartModalService.getModal('documents_modal').open();
            break;
        case 'Close Documents Modal':
            this.ngxSmartModalService.getModal('documents_modal').close();
            break;
        case 'Open Time Tracker Modal':
            // console.log(message.payload.workOrderId,message.payload.userId);
            this.ngxSmartModalService.getModal('time_tracking_modal').setData({
              workOrderId: message.payload.workOrderId,
              userId: message.payload.userId
            });
            this.ngxSmartModalService.getModal('time_tracking_modal').open();
            break;
          case 'Close Time Tracker Modal':
            this.ngxSmartModalService.getModal('time_tracking_modal').close();
            break;
          case 'Open DOT Age Modal':
            this.ngxSmartModalService.getModal('dot_verification_modal').setData({
                workOrderId: message.payload.workOrderId,
            });
            this.ngxSmartModalService.getModal('dot_verification_modal').open();
            break;
        case 'Go To PO Info':
            this.router.navigate(['purchase-orders']);
            break;
        case 'Open Barcode For PO Verification':
            this.ngxSmartModalService.getModal('po_verification_barcode_modal').open();
            break;
        case 'Go To Mobile Bay Portal':
            this.router.navigate(['/mobile-bay-portal']);
            break;
        case 'Go to physical inventory':
            this.router.navigate(['physical-inventory']);
            break;
        case 'Work Order DOTs':
            this.store.dispatch(new fromWorkorders.SetWorkorderAction(null));
            this.store.dispatch(new fromWorkorders.LoadWorkOrderAction(message.payload.workOrderId));
            setTimeout(() => {
                this.router.navigate(['enter-mileage']);
            }, 500);
            break;
          case 'rewards-sign-up':
            this.ngxSmartModalService.getModal('customer_rewards_sign_up_modal').setData({
              work_order_id: message.payload.work_order_id,
              customer_id: message.payload.customer_id
            });
            this.ngxSmartModalService.getModal('customer_rewards_sign_up_modal').open();
            break;
          case 'Rewards Customer Profile':
            this.ngxSmartModalService.getModal('customer_rewards_profile_modal').setData({
              work_order_id: message.payload.work_order_id,
              customer_id: message.payload.customer_id
            });
            this.ngxSmartModalService.getModal('customer_rewards_profile_modal').open();
            break;
          case 'Go to mobile statistics dashboard':
              this.router.navigate(['statistics-dashboard']);
              break;
          case 'Time Clock Clock in/out':
              this.ngxSmartModalService.getModal('clock_in_out_modal').open();
              break;
          default:
              return;
        }
    }

}
