<div class="messages-alert__container no-print" *ngIf="showMessageAlert" #alertContainer>
  <div>
    <div class="messages-alert__head" (click)="toggleMessageDetails()">
      <h4 class="messages-alert__head-heading">
          <app-icon [name]="'message'" [fill]="'#ffffff'" class="messages-alert__head-icon"></app-icon>
        <span class="messages-alert__head-text">
          {{headingText}}
        </span>
        <span class="messages-alert__head-toggle"><app-icon [name]="iconName"></app-icon> </span>
      </h4>
    </div>

    <div class="messages-alert__body" *ngIf="expand">
      <div class="messages-alert__body-dismiss-all" (click)="dismissAll()">
        <span><app-icon [name]="'close'" class="messages-alert__body-dismiss-all-icon"></app-icon></span>
      </div>
      <div *ngFor="let message of newMessages; let i = index" class="message_alert__body-container">
        <div class="message-alert__body-info">
          <div class="messages-alert__head-clickable" (click)="goToMessage(message.wom_woID, message.wom_messageID)"></div>
          <span class="message-alert__body-info-from"><b>From {{message.wom_user}}  |  {{message.vehicle}}</b></span>
        </div>
        <div class="message-alert__row">
          <span class="message-alert__body-text">{{message.wom_message}}</span>
          <div class="message-alert__actions">
            <div class="message-alert__body-wo" (click)="goToWorkOrder(message.wom_woID, message.wom_messageID)">
              <app-navigation-icon [name]="'service'"></app-navigation-icon>
            </div>
            <div class="message-alert__body-read" (click)="markAsRead(message.wom_messageID)">
              <app-icon [name]="'checkmark'"></app-icon>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
