import {Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../store/reducers';
import {getProfile, getWorkorder} from '../../store/reducers';
import * as WorkOrderMessagesActions from '../../store/actions/workordermessages.actions';
import {skipWhile, take} from 'rxjs/operators';

@Component({
    selector: 'app-messages-center-new-message',
    templateUrl: './messages-center-new-message.component.html',
    styleUrls: ['./messages-center-new-message.component.scss']
})
export class MessagesCenterNewMessageComponent implements OnInit {
    @Output() messageSent: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('employeeList') employeeList: ElementRef;

    public workOrder;
    public employees: any;
    public userID: any;
    public userName: string;
    public vehicle: string;
    public assignedEmployees: any;
    public techHasBeenSelected = false;
    public employeesKeys: any;
    public sendTo: any = [];
    public recipientList: any = [];
    public messageText: any;
    public showCheckBoxes: any = false;
    public allEmployees: any = 0;


    constructor(private store: Store<fromRoot.State>,
    ) {
    }

    ngOnInit() {
        this.getWorkorder();
        this.getProfile();
    }

    //
    // @HostListener('document:mousedown', ['$event'])
    // onGlobalClick(event): void {
    //   if (!this.employeeList.nativeElement.contains(event.target)) {
    //     // clicked outside => close dropdown list
    //     this.showCheckBoxes = false;
    //   }
    // }

    closeCheckBoxes() {
        this.showCheckBoxes = false;
    }

    getUsersToMessage() {
        this.store.select(fromRoot.getUsersForMessage).pipe(
            skipWhile(users => users.length === 0),
            take(1)
        ).subscribe((e) => {
            if (e && e.data && e.data.length) {
                this.employeesKeys = Object.keys(e.data);
                this.employees = e.data;
            }
        });
    }

    getWorkorder() {
        this.store.select(getWorkorder).pipe(
            skipWhile(workorder => !workorder),
            take(1)
        ).subscribe((response: any) => {
            this.workOrder = response;
            this.getUsersToMessage();
            this.getSalesAndTechs();

            if (response != null && response.vehicle != null && typeof response.vehicle != 'undefined') {
                this.vehicle = response.vehicle.veh_color + ' ' + response.vehicle.veh_year + ' ' + response.vehicle.veh_make + ' ' + response.vehicle.veh_model;
            }
        });
    }

    getProfile() {
        this.store.select(getProfile).pipe(
            skipWhile(profile => !profile),
            take(1)
        ).subscribe((response) => {
            if (response) {
                this.userName = response.use_fname + ' ' + response.use_lname;
                this.userID = response.use_userID;
            }
        });
    }

    sendMessage() {
        const message = {
            "wom_message": this.messageText,
            "wom_selected_users": this.sendTo.length ? (this.sendTo.includes(0) ? '' : this.sendTo.toString()) : '',
            "wom_woID": this.workOrder.wor_workorderID,
            "wom_userID": this.userID,
            "wom_user": this.userName,
            "woNumber": this.workOrder.wor_wonumber,
            "wom_read": 1,
            "vehicle": this.vehicle
        };

        this.messageSent.emit(message);
        this.messageText = '';
        this.store.dispatch(new WorkOrderMessagesActions.SendMessage({'message': message}));
    }

    getSalesAndTechs() {
        const salesManOnWorkOrder = [];
        const techsOnWorkOrder = [];
        if (this.workOrder && this.workOrder.item_relations) {
            this.workOrder.item_relations.forEach((item) => {
                if (item.woi_sales1 != 0) {
                    salesManOnWorkOrder.push(item.woi_sales1);
                }
                if (item.woi_sales2 != 0) {
                    salesManOnWorkOrder.push(item.woi_sales2);
                }
                if (item.woi_tech1 != 0) {
                    techsOnWorkOrder.push(item.woi_tech1);
                }
                if (item.woi_tech2 != 0) {
                    techsOnWorkOrder.push(item.woi_tech2);
                }
            });
        }

        this.assignedEmployees = {'sales': salesManOnWorkOrder, 'techs': techsOnWorkOrder};
    }

    addEmployeeToSendList(userID, userArrayKey) {
        if (this.sendTo.includes(userID)) {
            if (userID === this.allEmployees) {
                this.recipientList = [];
                this.sendTo = [];
            } else {
                if (this.sendTo.includes(this.allEmployees)) {
                    this.recipientList.splice(this.recipientList.indexOf('All'), 1);
                    this.sendTo.splice(this.sendTo.indexOf(this.allEmployees), 1);
                }
                this.sendTo.splice(this.sendTo.indexOf(userID), 1);
                this.recipientList.splice(this.recipientList.indexOf(userArrayKey), 1);
            }
        } else {
            if (userID === this.allEmployees) {
                this.sendTo.push(userID);
                this.recipientList.push('All');
                this.addAllEmployeesToSendList();
            } else {
                this.recipientList.push(userArrayKey);
                this.sendTo.push(userID);
                if (this.sendTo.length === this.employeesKeys.length) {
                    this.recipientList.push('All');
                    this.sendTo.push(this.allEmployees);
                }
            }
        }
    }

    addAllEmployeesToSendList() {
        this.employeesKeys.forEach((employee) => {
            if (!this.sendTo.includes(this.employees[employee].use_userID)) {
                this.sendTo.push(this.employees[employee].use_userID);
                this.recipientList.push(employee);
            }
        });
    }

    isEmployeeSelected(userID) {
        if (this.sendTo.includes(userID)) {
            return 'selected';
        }
        return false;
    }
}
