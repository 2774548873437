import { Injectable } from '@angular/core';
import { Observable ,  BehaviorSubject } from 'rxjs';

@Injectable()
export class AlertProvider {

    private _alertSource = new BehaviorSubject<object>({});
    private alert;

    constructor() {
        this.alert = this._alertSource.asObservable();
    }

    addSuccess(data: string) {
        this._alertSource.next({ type: 'success', message: data });
    }

    addError(data: string) {
        this._alertSource.next({ type: 'error', message: data });
    }

    getAlerts() {
        return this.alert;
    }

}


