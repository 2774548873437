import * as profile from '../actions/profile.actions';

export interface State {
    loading: boolean;
    loadingSuccessful: boolean;
    loadingError: string;
    currentProfile: any;
    currentReasonForVisit: {
        text: string;
        action: string;
        quick_links: any;
    };
}

const initialState: State = {

    loading: false,
    loadingSuccessful: null,
    loadingError: null,
    currentProfile: null,
    currentReasonForVisit: null
};

export function reducer(state = initialState, action): State {

    switch (action.type) {
        case profile.SET_PROFILE:
            return {...state, loading: false, currentProfile: action.payload};
        case profile.LOAD_PROFILE:
            return {...state, loading: true };
        case profile.LOAD_PROFILE_SUCCESSFUL:
            return {...state, loading: false, currentProfile: action.payload, loadingSuccessful: true };
        case profile.LOADING_PROFILE_FAILED:
            return {...state, loading: false, loadingSuccessful: false, loadingError: action.payload.error.message};
        case profile.SET_REASON_FOR_VISIT:
            return {...state, currentReasonForVisit: action.payload};
        case profile.SET_REASON_FOR_VISIT_BY_TYPE:
            const currentReasonForVisit = getCurrentReasonForVisitByType(state, action.payload);
            return {...state, currentReasonForVisit};
        default:
            return state;
    }

}


const getCurrentReasonForVisitByType = (state: State, type: string) => {
    const settings = state.currentProfile.location.settings;

    for (let i = 0; i < 5; i++) {
        const baseKeyString = `loc_mobile_reason_for_visit_${i}`;
        const textString = `${baseKeyString}_text`;

        if (settings.hasOwnProperty(textString) && settings[textString].toUpperCase().trim() === type.toUpperCase().trim()) {
            return  {
                text: settings[textString],
                action: settings[`${baseKeyString}_action`],
                quick_links: settings[`${baseKeyString}_quick_links`]
            };
        }
    }
};

export const getCurrentProfile = (state: State) => state.currentProfile;

export const getProfileLoading = (state: State) => state.loading;

export const getServer = (state: State): string | false => {
    if (state.currentProfile && state.currentProfile.use_server) {
        return state.currentProfile.use_server;
    }

    return false;
};


export const getReasonsForVisit = (state: State) => {
    const reasonsForVisit = [];
    if (!state.currentProfile || !state.currentProfile.location) {
        return reasonsForVisit;
    }
    const settings = state.currentProfile.location.settings;
    for (let index = 0; index <= 5; index++) {
        const baseKeyString = `loc_mobile_reason_for_visit_${index}`;
        if (settings.hasOwnProperty(`${baseKeyString}_text`) && settings[`${baseKeyString}_text`].length !== 0) {
            reasonsForVisit.push({
                text: settings[`${baseKeyString}_text`],
                action: settings[`${baseKeyString}_action`],
                quick_links: settings[`${baseKeyString}_quick_links`]
            });
        }
    }

    return reasonsForVisit;
};

export const getCurrentReasonForVisit = (state: State) => state.currentReasonForVisit || false;

export const getQuickLinks = (state: State) => {
    if (!state.currentReasonForVisit || !state.currentReasonForVisit.quick_links) {
        return false;
    }

    const split = state.currentReasonForVisit.quick_links.split(',');

    const quickLinks = [];

    if (!split.length) {
        return false;
    }

    split.forEach((splitItem) => {
        let items = splitItem.split(':');

        items = items.map((item: string) => item.trim());

        quickLinks.push({
            key: items[0],
            value: items[1]
        });
    });

    return quickLinks;
};
