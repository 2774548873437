import {Component, Input, OnInit} from '@angular/core';
import { VariableHelper } from '../../../core/helpers/variable.helper';

@Component({
    selector: 'app-carfax-vehicle-info-general',
    templateUrl: './carfax-vehicle-info-general.component.html',
    styleUrls: ['./carfax-vehicle-info-general.component.scss']
})
export class CarfaxVehicleInfoGeneralComponent implements OnInit {

    @Input() veh_carfax;

    constructor() { }

    ngOnInit() {
    }

    getYear() {
        if (VariableHelper.isset(() => this.veh_carfax['data']['aaia-legacy-veh-year'])) {
            return this.veh_carfax['data']['aaia-legacy-veh-year'];
        }
        if (VariableHelper.isset(() => this.veh_carfax['data']['base-year-model'])) {
            return this.veh_carfax['data']['base-year-model'];
        }
        if (VariableHelper.isset(() => this.veh_carfax['data']['base-year-model'])) {
            return this.veh_carfax['data']['base-year-model'];
        }
    }

    getMake() {
        if (VariableHelper.isset(() => this.veh_carfax['data']['aaia-legacy-make'])) {
            return this.veh_carfax['data']['aaia-legacy-make'];
        }
        if (VariableHelper.isset(() => this.veh_carfax['data']['base-make-name'])) {
            return this.veh_carfax['data']['base-make-name'];
        }
        if (VariableHelper.isset(() => this.veh_carfax['data']['nonoem-make'])) {
            return this.veh_carfax['data']['nonoem-make'];
        }
    }

    getModel() {
        if (VariableHelper.isset(() => this.veh_carfax['data']['aaia-legacy-model'])) {
            return this.veh_carfax['data']['aaia-legacy-model'];
        }
        if (VariableHelper.isset(() => this.veh_carfax['data']['nonoem-base-model'])) {
            return this.veh_carfax['data']['nonoem-base-model'];
        }
        if (VariableHelper.isset(() => this.veh_carfax['data']['oem-base-model'])) {
            return this.veh_carfax['data']['oem-base-model'];
        }
        if (VariableHelper.isset(() => this.veh_carfax['data']['base-series-name'])) {
            return this.veh_carfax['data']['base-series-name'];
        }
    }

    getType() {
        if (VariableHelper.isset(() => this.veh_carfax['data']['aaia-legacy-submodel'])) {
            return this.veh_carfax['data']['aaia-legacy-submodel'];
        }
        if (VariableHelper.isset(() => this.veh_carfax['data']['submodel'])) {
            return this.veh_carfax['data']['submodel'];
        }
        if (VariableHelper.isset(() => this.veh_carfax['data']['@attributes']['submodel'])) {
            return this.veh_carfax['data']['@attributes']['submodel'];
        }
    }

}
