import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {createEffect, Actions, ofType} from '@ngrx/effects';

import {Observable, of, from as fromPromise} from 'rxjs';


import * as session from '../actions/session.actions';
import {SessionService} from '../../core/services/session.service';
import {Store} from '@ngrx/store';

import * as fromRoot from '../reducers';
import * as workorderActions from '../actions/workorders.actions';
import * as vehicleActions from '../actions/vehicle.actions';
import * as customerActions from '../actions/customer.actions';

@Injectable()
export class SessionEffects {


    loadWorkorder: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(session.LOAD_SESSION),
        switchMap(() =>
            fromPromise(this.sessionService.getState()).pipe(
                map((state: any) =>  new session.LoadSessionSuccessfulAction(state)),
                catchError((error) => of(new session.LoadSessionFailedAction(error))))
        )));


    loadWokrorderSuccessful: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(session.LOAD_SESSION_SUCCESSFUL),
        map((action: session.LoadSessionSuccessfulAction) => action.payload))
        .pipe(
            tap((state: any) => {
                if (state.data && state.data.workOrderId) {
                    this.store.dispatch(new workorderActions.LoadWorkOrderAction(state.data.workOrderId));
                }
                if (state.data && state.data.vehicleId) {
                    this.store.dispatch(new vehicleActions.LoadVehicleAction(state.data.vehicleId));
                }
                if (state.data && state.data.customerId) {
                    this.store.dispatch(new customerActions.LoadCustomerAction(state.data.customerId));
                }
            })
        ), {dispatch: false});

    constructor(private $actions: Actions, private sessionService: SessionService, private store: Store<fromRoot.State>) {
    }
}
