<div>
  <div>
    <label>
      <app-icon fill="currentColor" name="scanner" class="icon"></app-icon>

      <input type="text" class="input txtInput" id="search" name="search"
             inputmode="search"
             (ngModelChange)="onSearchValueChange($event)" [(ngModel)]="search_value"
             placeholder="Search"
      >
    </label>

  </div>

  <div #global_search_results_container>
    <app-global-search-results-container *ngIf="showResults"
                                         [results]="search_result" [profile]="profile"
                                         (clear_results_results_container)="clearResults()"
                                         (blur)="offClickHandler($event)"
    ></app-global-search-results-container>
  </div>
</div>
