<app-work-order-nav *ngIf="messageType === 'workorder'"></app-work-order-nav>
<app-navigation *ngIf="messageType === 'workorder'"></app-navigation>

<div class="panel__body">

  <button *ngIf="messageType === 'workorder'" class="btn is-primary is-sm newMessageButton" (click)="newMessagePopup.open()">
    <app-icon name="new-message"></app-icon>
  </button>

  <div class="columns flexNoWrap" *ngIf="messageType === 'internal'">
    <div class="column flexNoWrap">
      <button (click)="filterOutRead(false)"
              class="btn filterButton {{viewAll === 'true' ? 'is-unselected disabled' : 'is-primary'}}"><b>View All</b></button>
      <button (click)="filterOutRead(true)" class="btn filterButton {{viewAll === 'true' ? 'is-primary' : 'is-unselected'}}"><b>Unread</b>
      </button>
    </div>

  </div>

  <div *ngIf="!loading">
    <app-list-messages-center-messages [messages]="messages" [type]="messageType" (toWorkorder)="goToWorkOrder($event)"
                                       (toMessages)="goToMessage($event)" (markAsRead)="markAsRead($event)"
    >

    </app-list-messages-center-messages>
  </div>

  <div class="pageButtons" *ngIf="messages?.length">
    <button class="btn is-primary is-sm pageButton" *ngIf="numberOfPages > 1 && pageNum !== 1" (click)="backPage()">
      Prev
    </button>

    <div class="pageButton clickable" (click)="backPage()" *ngIf="numberOfPages > 1 && pageNum !== 1">
      <b>{{pageNum - 1}}</b></div>
    <div class="pageButton btn is-sm is-primary"><b class="selectedPage">{{pageNum}}</b></div>
    <div class="pageButton clickable" (click)="nextPage()" *ngIf="numberOfPages > 1 && pageNum < numberOfPages">
      <b>{{pageNum + 1}}</b></div>

    <button class="btn is-primary is-sm pageButton" *ngIf="numberOfPages > 1 && pageNum < numberOfPages"
            (click)="nextPage()">
      Next
    </button>
  </div>

  <div>
    <a class="btn is-primary is-sm" *ngIf="returnUrl" (click)="redirect()">
      Back
    </a>
  </div>

  <div class="panel__body centered-text" *ngIf="loading">
    <app-spinner-loading [isInverted]="true"></app-spinner-loading>
  </div>
</div>

<ngx-smart-modal #newMessagePopup [identifier]="'newMessagePopup'">
  <app-messages-center-new-message (messageSent)="messageSent($event)"></app-messages-center-new-message>
</ngx-smart-modal>
