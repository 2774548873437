import * as customer from '../actions/customer.actions';
import * as session from '../actions/session.actions';

export interface State {
    loading: boolean;
    loadingSuccessful: boolean;
    loadingError: string;

    creating: boolean;
    creatingSuccessful: boolean;
    creatingError: string;

    deleting: boolean;
    deletingSuccesful: boolean;
    deletingError: string;

    updating: boolean;
    updatingSuccessful: boolean;
    updatingError: string;

    customers: {[id: number]: any};
    currentCustomer: any;
}

const initialState: State = {

    loading: false,
    loadingSuccessful: null,
    loadingError: null,

    creating: false,
    creatingSuccessful: null,
    creatingError: null,

    deleting: false,
    deletingSuccesful: null,
    deletingError: null,

    updating: false,
    updatingSuccessful: null,
    updatingError: null,

    customers: {},
    currentCustomer: null
};

export function reducer(state = initialState, action): State {

    switch (action.type) {
        case customer.SET_CUSTOMER:
            return {...state, loading: false, currentCustomer: action.payload};
        case customer.LOAD_CUSTOMER:
        case session.LOAD_SESSION_SUCCESSFUL:
            return {...state, loading: true };
        case customer.LOAD_CUSTOMER_SUCCESSFUL:
            return {...state, loading: false, currentCustomer: action.payload, loadingSuccessful: true };
        case customer.LOADING_CUSTOMER_FAILED:
            return {...state, loading: false, loadingSuccessful: false, loadingError: action.payload.error.message};

        case customer.UPDATE_CUSTOMER:
            return {...state, updating: true};
        case customer.UPDATE_CUSTOMER_SUCCESSFUL:
            return {...state, updating: false, currentCustomer: action.payload};
        case customer.UPDATE_CUSTOMER_FAILED:
            return {...state, updating: false, updatingError: action.payload.error.message, updatingSuccessful: false};

        case customer.CREATE_CUSTOMER:
            return {...state, creating: true};
        case customer.CREATE_CUSTOMER_SUCCESSFUL:
            return {...state, creating: false, currentCustomer: action.payload};
        case customer.CREATE_CUSTOMER_FAILED:
            return {...state, creating: false, creatingSuccessful: false, creatingError: 'There as an error creating the customer. Please try again. If the error persists please contact support.'};
        default:
            return state;
    }

}

export const getCustomers = (state: State) => state.customers;
export const getCurrentCustomer = (state: State) => state.currentCustomer;
export const getCustomerLoading = (state: State) => state.loading;

export const getCustomerUpdating = (state: State) => state.updating;

export const getCustomerCreating = (state: State) => state.creating;
