
import {take} from 'rxjs/operators';
import {Directive, ElementRef} from '@angular/core';
import {Router, ActivatedRoute, ParamMap} from '@angular/router';
import {Store} from '@ngrx/store';


import { WorkOrderService } from '../../core/services/work-order.service';
import {WorkOrderProvider} from '../../core/providers/work-order.provider';

import { CustomerService } from '../../core/services/customer.service';

import * as fromRoot from '../../store/reducers';
import * as fromForms from '../../store/actions/form.actions';
import * as fromWorkorder from '../../store/actions/workorders.actions';
import * as form from '../../store/reducers/form.reducer';

@Directive({
    selector: '[appPathUpdate]'
})
export class PathUpdateDirective {

    private iframe;
    private formState: form.State = null;
    private workorderState: any = null;

    constructor(
        private router: Router,
        private workOrderService: WorkOrderService,
        private customerService: CustomerService,
        private workOrderProvider: WorkOrderProvider,
        private store: Store<fromRoot.State>,
        el: ElementRef
    ) {
        this.iframe = el.nativeElement;
        this.iframe.addEventListener('load', this.setupEventListener.bind(this));
        this.iframe.addEventListener('load', this.onLoad.bind(this));

        this.store.select(fromRoot.getFormState).subscribe(formState => this.formState = formState);
        this.store.select(fromRoot.getWorkorder).subscribe(workorderState => this.workorderState = workorderState);

    }

    setupEventListener() {
        const document = this.iframe.contentDocument;

        const links: Array<HTMLElement> = Array.from(document.querySelectorAll('a, button'));

        for (const link of links) {
            link.addEventListener('click', event => {

                const linkElement = this.findParent(event.target, 'a');
                const buttonElement = this.findParent(event.target, 'button');
                const formElement = this.findParent(event.target, 'form');

                let href = '';
                if (linkElement) {
                    href = linkElement.href;
                } else if (buttonElement) {
                    href = buttonElement.getAttribute('onclick');
                    href = href || '';
                }

                const customerUpdate = formElement ? formElement.getAttribute('action').match(/\/mobile\/customerUpdate\.php.*/) : false;

                if (customerUpdate) {
                    const hrefAttribute = this.iframe.getAttribute('src');
                    const hrefMatcher = hrefAttribute.match(/\/mobile\/customer_edit\.php\?custID=(\d+)/);
                    const customerID = hrefMatcher[1];
                    this.customerService.getCustomer(customerID).then((customerInfo: any) => {
                        this.workOrderProvider.setCustomer(customerInfo.data);
                    });
                }
            });
        }


    }

    iframeMessage(message) {
    }

    findParent(el, tagName) {
        tagName = tagName.toLowerCase();

        if (el.tagName && el.tagName.toLowerCase() === tagName) {
            return el;
        }

        while (el && el.parentNode) {
            el = el.parentNode;
            if (el.tagName && el.tagName.toLowerCase() === tagName) {
                return el;
            }
        }
        return null;
    }

    onLoad(event) {
        try {
            const iFrame = event.target;
            const iFrameLocation = iFrame.contentWindow.location.href;
            const fragment = this.getUrlFragment(iFrameLocation);
            const param = this.getUrlParams(iFrameLocation);
            const path = this.getUrlPath(iFrameLocation);
            this.mapPathUpdate(path, param);
            const route = encodeURI(`/inspect/frame/${path}`);
        } catch (error) {
            console.error('iFrame Load Event', error, event);
        }
    }

    mapPathUpdate(path: string, param: any) {
        switch (path) {
            case 'customer_search.php':
                this.router.navigateByUrl('/search').then(response => {
                }).catch(error => {
                });
                break;
            case 'order_items.php':
                this.store.select(fromRoot.getWorkorderState).pipe(take(1)).subscribe((state) => {
                    if (!state.currentWorkorder) {
                        this.store.dispatch(new fromWorkorder.SetAddingItemsAction());
                    }
                });
                break;
            case 'work_order_select.php':
                if (!param || !('only' in param)) {
                    break;
                }

                const only = param.only;
                if (only.includes('inspection')) {
                    this.router.navigateByUrl('/inspection-portal');
                }
                break;
            case 'order_details_print.php':
            case 'history_print.php':
                this.store.dispatch(new fromWorkorder.ShowWorkOrderBackButtonAction());
                break;
            case 'order_details.php':
                this.store.dispatch(new fromWorkorder.HideWorkOrderBackButtonAction());
        }

    }

    getUrlPath(src) {
        let url = src.replace(/^(http).*(\/mobile\/)/g, '');
        url = url.replace(/(\?).*/g, '');
        url = url.replace(/(#).*/g, '');
        return url;
    }

    getUrlFragment(src) {
        const url = src.split('#');
        return url.pop();
    }

    getUrlParams(url) {
        let splitUrl = url.split('?');
        splitUrl = splitUrl.pop().split('=');
        const matrixParamString = {};
        for (let index = 0; index < splitUrl.length; index += 2) {
            matrixParamString[splitUrl[index]] = splitUrl[index + 1];
        }

        return matrixParamString;
    }

}
