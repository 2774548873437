import {Component, OnInit} from '@angular/core';
import * as fromCustComm from '../../store/actions/customerCommunication.actions';
import * as fromRoot from '../../store/reducers';
import {WorkOrderService} from '../../core/services/work-order.service';
import {Router} from '@angular/router';
import {AlertProvider} from '../../core/providers/alert.provider';
import {Store} from '@ngrx/store';
import * as fromCustComms from '../../store/actions/customerCommunication.actions';
import {Subject} from 'rxjs';
import {skipWhile, take, takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-customer-messages-center-container',
    templateUrl: './customer-messages-center-container.component.html',
    styleUrls: ['./customer-messages-center-container.component.scss']
})
export class CustomerMessagesCenterContainerComponent implements OnInit {

    public messages: any;
    public userID: any;
    public allMessages: any;
    public showCustomer = false;
    public settingAllowingTexting = false;
    public permissionToViewTexting = false;
    public viewAll = 'true';
    public textMessages: any;
    public locID: any;
    public pageNum = 1;
    public search = '';
    public numberOfPages: any = 0;
    public searchTerm = '';
    public loading = true;
    public viewUnread = false;
    public page: any = 1;
    private unsubscribe: Subject<void> = new Subject();

    constructor(private workOrderService: WorkOrderService,
        private router: Router,
        private alertProvider: AlertProvider,
        private store: Store<fromRoot.State>,
    ) {
    }

    ngOnInit() {
        this.getProfile();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    getProfile() {
        this.store.select(fromRoot.getProfile).pipe(
            skipWhile(profile => !profile),
            take(1)
        ).subscribe((response) => {
            if (response != null) {
                this.userID = response.use_userID;
                this.settingAllowingTexting = response.location.settings.loc_use_two_way_texting;
                this.permissionToViewTexting = response.permissions.view_customer_texts;

                if (this.settingAllowingTexting && this.permissionToViewTexting) {
                    this.showCustomer = true;
                }

                this.locID = response.location.loc_locID;
                if (this.locID) {
                    this.dispatchGetTexts();
                    this.subscribeToAllMessages();
                    this.subscribeToTextsState();
                    this.getNumberOfTextPages();
                }
            }
        });
    }

    dispatchGetTexts() {
        this.store.dispatch(new fromCustComm.GetTextMessagesAction({'locID': this.locID, 'viewUnread': this.viewAll, 'page': this.pageNum, 'search': this.search}));
    }

    getNumberOfTextPages() {
        this.store.select(fromRoot.getNumberOfTextPages).pipe(takeUntil(this.unsubscribe)).subscribe((pages) => {
            this.numberOfPages = pages;
        });
    }

    nextPage() {
        this.pageNum += 1;
        this.dispatchGetTexts();
    }

    backPage() {
        this.pageNum -= 1;
        this.dispatchGetTexts();
    }

    goToMessage(customerID) {
        this.router.navigate(['/customer-messages/' + customerID]);
    }

    markAsRead(messageID) {
        this.store.dispatch(new fromCustComms.MarkIndividualTextAsReadAction({'textId': messageID, 'userId': this.userID}));
        this.store.dispatch(new fromCustComms.GetTextMessagesAction({'locID': this.locID, 'viewUnread': this.viewUnread, 'search': this.searchTerm, 'page': this.page}));
    }

    subscribeToTextsState() {
        this.store.select(fromRoot.getCustomerCommunicationsState).pipe(takeUntil(this.unsubscribe)).subscribe((state) => {
            if (state.markAsReadSuccessful) {
                this.alertProvider.addSuccess('Message marked as read');
                this.store.dispatch(new fromCustComm.ClearMarkedTextAsReadAction({}));
            }
        });
    }

    subscribeToAllMessages() {
        this.store.select(fromRoot.getTextMessages).pipe(skipWhile(texts => !texts), takeUntil(this.unsubscribe)).subscribe((texts) => {
            this.textMessages = texts;

            this.setMessagesData(this.textMessages);
        });
    }

    areThereTexts() {
        return this.textMessages && this.textMessages.length;
    }

    setMessagesData(messages) {
        this.messages = [];

        messages.forEach((message) => {
            this.messages[message.id] = {
                "message": message.content,
                "whoTo": (message.text_messagable.cus_fname || '') + ' ' + (message.text_messagable.cus_lname || ''),
                "read": !!message.viewedByUserID,
                "vehicle": message.vehicle,
                "custID": message.text_messagable.cus_customerID || message.text_messagable.from,
                "msgID": message.id
            };
        });

        this.loading = false;
    }

    filterOutRead(toFilter) {
        if (toFilter) {
            this.viewAll = 'true';
            this.dispatchGetTexts();
        } else {
            this.viewAll = 'false';
            this.dispatchGetTexts();
        }
    }
}
