import { createAction, Action as NgrxAction } from '@ngrx/store';

export const SET_CUSTOMER = '[Customer] Set customer';
export const LOAD_CUSTOMER = '[Customer] Load Customer';
export const LOAD_CUSTOMER_SUCCESSFUL = '[Customer] Load Customer successful';
export const LOADING_CUSTOMER_FAILED = '[Customer] Load Customer failed';
export const UPDATE_CUSTOMER = '[Customer] Update Customer';
export const UPDATE_CUSTOMER_SUCCESSFUL = '[Customer] Update Customer successful';
export const UPDATE_CUSTOMER_FAILED = '[Customer] Update Customer failed';
export const CREATE_CUSTOMER = '[Customer] Create Customer';
export const CREATE_CUSTOMER_SUCCESSFUL = '[Customer] create Customer successful';
export const CREATE_CUSTOMER_FAILED = '[Customer] Create Customer failed';

export class SetCustomerAction implements NgrxAction {
    readonly type = SET_CUSTOMER;

    constructor(public payload: any) {
    }
}

export class LoadCustomerAction implements NgrxAction {
    readonly type = LOAD_CUSTOMER;

    constructor(public payload: any) {
    }

}

export class LoadCustomerSuccessfulAction implements NgrxAction {
    readonly type = LOAD_CUSTOMER_SUCCESSFUL;

    constructor(public payload: any) {
    }

}

export class LoadCustomerFailedAction implements NgrxAction {
    readonly type = LOADING_CUSTOMER_FAILED;

    constructor(public payload: any) {
    }
}

export class UpdateCustomerAction implements NgrxAction {
    readonly type = UPDATE_CUSTOMER;

    constructor(public payload: any) {
    }

}

export class UpdateCustomerSuccessfulAction implements NgrxAction {
    readonly type = UPDATE_CUSTOMER_SUCCESSFUL;

    constructor(public payload: any) {
    }

}

export class UpdateCustomerFailedAction implements NgrxAction {
    readonly type = UPDATE_CUSTOMER_FAILED;

    constructor(public payload: any) {
    }
}

export class CreateCustomerAction implements NgrxAction {
    readonly type = CREATE_CUSTOMER;

    constructor(public payload: any) {
    }

}

export class CreateCustomerSuccessfulAction implements NgrxAction {
    readonly type = CREATE_CUSTOMER_SUCCESSFUL;

    constructor(public payload: any) {
    }

}

export class CreateCustomerFailedAction implements NgrxAction {
    readonly type = CREATE_CUSTOMER_FAILED;

    constructor(public payload: any) {
    }
}

export type Action = SetCustomerAction
| LoadCustomerAction
| LoadCustomerSuccessfulAction
| LoadCustomerFailedAction
| UpdateCustomerAction
| UpdateCustomerSuccessfulAction
| UpdateCustomerFailedAction
| CreateCustomerAction
| CreateCustomerFailedAction
| CreateCustomerSuccessfulAction;
