
import {take, skipWhile} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';
import * as fromRoot from '../store/reducers';
import {Store} from '@ngrx/store';
import {getProfile} from '../store/reducers';

@Component({
    selector: 'app-messages-center',
    templateUrl: './messages-center.component.html',
    styleUrls: ['./messages-center.component.scss']
})
export class MessagesCenterComponent implements OnInit {

    public showCustomer = false;
    public settingAllowingTexting = false;
    public permissionToViewTexting = false;

    constructor(
        private store: Store<fromRoot.State>,
    ) {
    }

    ngOnInit() {
        this.getProfile();
    }

    getProfile() {
        this.store.select(getProfile).pipe(skipWhile(profile => !profile), take(1), ).subscribe((response) => {
            if (response != null) {
                this.settingAllowingTexting = response.location.settings.loc_use_two_way_texting;
                this.permissionToViewTexting = response.permissions.view_customer_texts;

                if (this.settingAllowingTexting && this.permissionToViewTexting) {
                    this.showCustomer = true;
                }

            }
        });
    };

}
