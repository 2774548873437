import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

@Injectable()
export class ProfileService {

    public url: string;

    constructor(private http: HttpClient) {
        this.url = environment.tireguruBaseApi + '/me';
    }

    getProfile() {
        const url = `${this.url}`;
        return this.http.get(url).toPromise();
    }
}
