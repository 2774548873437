<app-panel [y_padding]="'has-no-padding-y'">
  <app-panel-header>
    <span *ngIf="type === 'workorder'">Work Orders</span>
    <span *ngIf="type === 'po'">Purchase Orders</span>
    <span *ngIf="type === 'invoice'">Invoices</span>
    <span *ngIf="type === 'quote'">Quotes</span>
  </app-panel-header>

  <app-panel-body *ngFor="let result of type_results" class="blackColor">
    <app-global-search-wo-result class="child_padding" *ngIf="type === 'workorder'"
                                 [result]="result"
                                 (set_work_order)="goToWorkOrder($event)"
                                 (show_alert)="doAlert($event)"
    ></app-global-search-wo-result>

    <app-global-search-po-result class="child_padding" *ngIf="type === 'po'"
                                 [result]="result"
                                 [profile]="profile"
                                 (show_alert)="doAlert($event)"
                                 (go_to_po_item_verification)="goToPurchaseOrderItemVerification($event)"
    ></app-global-search-po-result>

    <app-global-search-invoice-result class="child_padding" *ngIf="type === 'invoice'"
                                      [result]="result"
                                      (show_alert)="doAlert($event)"
    ></app-global-search-invoice-result>

    <app-global-search-quote-result class="child_padding" *ngIf="type === 'quote'"
                                    [result]="result"
                                    (set_work_order)="goToWorkOrder($event)"
    ></app-global-search-quote-result>
  </app-panel-body>
</app-panel>
