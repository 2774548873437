<div class="has-margin-y-2 panel__body" *ngIf="veh_carfax || vehicle">

  <div class="centered-text">
    <img src="assets/images/logos/carfax2.jpg">
  </div>

  <div class="columns">

    <div class="column is-6">
      <div class="has-padding-x">

        <div class="container table" *ngIf="vehicle">
          <app-carfax-vehicle-info-information [vehicle]="vehicle"></app-carfax-vehicle-info-information>
        </div>

        <div class="container table" *ngIf="veh_carfax">
          <app-carfax-vehicle-info-engine-specs [veh_carfax]="veh_carfax"></app-carfax-vehicle-info-engine-specs>
        </div>

      </div>
    </div>

    <div class="column is-6 has-divider-left">
      <div class="has-padding-x">

        <div class="container table" *ngIf="veh_carfax">
          <app-carfax-vehicle-info-general [veh_carfax]="veh_carfax"></app-carfax-vehicle-info-general>
        </div>

        <div class="container table" *ngIf="veh_carfax">
          <app-carfax-vehicle-info-optional [veh_carfax]="veh_carfax"></app-carfax-vehicle-info-optional>
        </div>

      </div>
    </div>

  </div>
  <p class="is-grey centered-text">
    *The information above was retrieved from CARFAX based on the vehicle's license plate number and/or VIN.<br/>
    ABMS Tire Guru is not responsible for inaccurate information or liable for any problems caused by inaccurate
    information.
  </p>
</div>

<div class="has-margin-y-2 panel__body" *ngIf="!veh_carfax">

  <div class="centered-text">
    <img src="assets/images/logos/carfax2.jpg">
  </div>

  <p class="is-grey centered-text">
    No data was found for this vehicle. To populate the CARFAX data, open the vehicle edit screen and check for license/state
    or VIN# accuracy.
  </p>
</div>
