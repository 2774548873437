import * as workorder from '../actions/workorders.actions';
import * as session from '../actions/session.actions';

export interface State {
    loading: boolean;
    loadingSuccessful: boolean;
    loadingError: string;

    creating: boolean;
    creatingSuccessful: boolean;
    creatingError: string;

    deleting: boolean;
    deletingSuccesful: boolean;
    deletingError: string;

    updating: boolean;
    updatingSuccessful: boolean;
    updatingError: string;

    workorders: {[id: number]: any};
    currentWorkorder: any;

    addingItems: boolean;
    addingItemSuccess: boolean;
    addingItemFailed: any;

    workOrderItemVerifications: any;
    getWorkOrderItemVerificationsLoading: boolean;
    getWorkOrderItemVerificationsSuccessful: boolean;
    getWorkOrderItemVerificationsFailed: string;

    updateWorkOrderItemVerificationLoading: boolean;
    updateWorkOrderItemVerificationSuccessful: boolean;
    updateWorkOrderItemVerificationFailed: string;

    showWorkOrderBackButton: boolean;

    workOrderStatuses: any;
    workOrderStatusesLoading: boolean;
    workOrderStatusesSuccessful: boolean;
    workOrderStatusesError: string;

    setWorkOrderStatusLoading: boolean;
    setWorkOrderStatusSuccessful: boolean;
    setWorkOrderStatusError: string;

    employeesForAssignment: any;
    employeesForAssignmentLoading: boolean;
    employeesForAssignmentSuccessful: boolean;
    employeesForAssignmentError: string;

    setWorkOrderEmployeesLoading: boolean;
    setWorkOrderEmployeesSuccessful: boolean;
    setWorkOrderEmployeesError: string;

    ticketType: string;
}

const initialState: State = {

    loading: false,
    loadingSuccessful: null,
    loadingError: null,

    creating: false,
    creatingSuccessful: null,
    creatingError: null,

    deleting: false,
    deletingSuccesful: null,
    deletingError: null,

    updating: false,
    updatingSuccessful: false,
    updatingError: null,

    workorders: {},
    currentWorkorder: null,

    addingItems: false,
    addingItemSuccess: null,
    addingItemFailed: null,
    showWorkOrderBackButton: false,

    workOrderItemVerifications: null,
    getWorkOrderItemVerificationsLoading: false,
    getWorkOrderItemVerificationsSuccessful: false,
    getWorkOrderItemVerificationsFailed: null,

    updateWorkOrderItemVerificationLoading: false,
    updateWorkOrderItemVerificationSuccessful: false,
    updateWorkOrderItemVerificationFailed: null,

    workOrderStatuses: false,
    workOrderStatusesLoading: false,
    workOrderStatusesSuccessful: null,
    workOrderStatusesError: null,

    setWorkOrderStatusLoading: false,
    setWorkOrderStatusSuccessful: null,
    setWorkOrderStatusError: null,

    employeesForAssignment: false,
    employeesForAssignmentLoading: false,
    employeesForAssignmentSuccessful: null,
    employeesForAssignmentError: null,

    setWorkOrderEmployeesLoading: false,
    setWorkOrderEmployeesSuccessful: null,
    setWorkOrderEmployeesError: null,

    ticketType: null
};

export function reducer(state = initialState, action): State {

    switch (action.type) {
        case workorder.SET_WORKORDER:
            return {...state, loading: false, currentWorkorder: action.payload};

        case workorder.LOAD_WORKORDER:
        case session.LOAD_SESSION_SUCCESSFUL:
            return {...state, loading: true };
        case workorder.LOAD_WORKORDER_SUCCESSFUL:
            return {...state, loading: false, currentWorkorder: action.payload, loadingSuccessful: true };
        case workorder.LOADING_WORKORDER_FAILED:
            return {...state, loading: false, loadingSuccessful: false, loadingError: action.payload.error.message};
    case workorder.RESET_LOAD_WORKORDER_SUCCESSFUL_STATUS:
            return {...state, loadingSuccessful: null};

        case workorder.DELETE_WORKORDER:
            return {...state, deleting: true};
        case workorder.DELETE_WORKORDER_SUCCESSFUL:
            return {...state, deleting: false, deletingSuccesful: true};
        case workorder.DELETE_WORKORDER_FAILED:
            return  {...state, deleting: false, deletingSuccesful: false, deletingError: action.payload.error.message};

        case workorder.UPDATE_WORKORDER:
            return {...state, updating:  true};
        case workorder.UPDATE_WORKORDER_SUCCESSFUL:
            return {...state, updating: false, currentWorkorder: action.payload};
        case workorder.UPDATE_WORKORDER_FAILED:
            return {...state, updating: false, updatingError: action.payload.error.message, updatingSuccessful: false};

        case workorder.CREATE_WORKORDER:
            return {...state, creating: true};
        case workorder.CREATE_WORKORDER_SUCCESSFUL:
            return {...state, creating: false, currentWorkorder: action.payload};
        case workorder.CREATE_WORKORDER_FAILED:
            return {...state, creating: false, creatingSuccessful: false, creatingError: action.payload.error.message};

        case workorder.SET_ADDING_ITEMS:
            return {...state, addingItems: true};
        case workorder.CLEAR_ADDING_ITEMS:
            return {...state, addingItems: false};

        case workorder.ADD_ITEM_TO_WORKORDER:
        return {...state, addingItems: true};
        case workorder.ADD_ITEM_TO_WORKORDER_SUCCESSFUL:
        return {...state, addingItems: false, addingItemSuccess: action.payload.success};
        case workorder.ADD_ITEM_TO_WORKORDER_FAILED:
        return {...state, addingItems: false, addingItemSuccess: false, addingItemFailed: action.payload.error.message};

        case workorder.SHOW_WORK_ORDER_BACK_BUTTON:
            return {...state, showWorkOrderBackButton: true };
        case workorder.HIDE_WORK_ORDER_BACK_BUTTON:
            return {...state, showWorkOrderBackButton: false };
        case workorder.GET_WORK_ORDER_STATUSES:
        return {...state, workOrderStatusesLoading: true};
        case workorder.GET_WORK_ORDER_STATUSES_SUCCESSFUL:
        return {...state, workOrderStatusesLoading: false, workOrderStatusesSuccessful: true, workOrderStatuses: action.payload};
        case workorder.GET_WORK_ORDER_STATUSES_FAILED:
        return {...state, workOrderStatusesSuccessful: false, workOrderStatusesLoading: false, workOrderStatusesError: action.payload};
        case workorder.SET_WORK_ORDER_STATUSES:
        return {...state, setWorkOrderStatusLoading: true};
        case workorder.SET_WORK_ORDER_STATUSES_SUCCESSFUL:
        return {...state, setWorkOrderStatusLoading: false, setWorkOrderStatusSuccessful: true};
        case workorder.SET_WORK_ORDER_STATUSES_FAILED:
        return {...state, setWorkOrderStatusSuccessful: false, setWorkOrderStatusLoading: false, setWorkOrderStatusError: action.payload};
        case workorder.GET_EMPLOYEES_FOR_ASSIGNMENT:
        return {...state, employeesForAssignmentLoading: true};
        case workorder.GET_EMPLOYEES_FOR_ASSIGNMENT_SUCCESSFUL:
        return {...state, employeesForAssignmentLoading: false, employeesForAssignmentSuccessful: true, employeesForAssignment: action.payload};
        case workorder.GET_EMPLOYEES_FOR_ASSIGNMENT_FAILED:
        return {...state, employeesForAssignmentSuccessful: false, employeesForAssignmentLoading: false, employeesForAssignmentError: action.payload};
        case workorder.SET_WORK_ORDER_EMPLOYEES:
        return {...state, setWorkOrderEmployeesLoading: true};
        case workorder.SET_WORK_ORDER_EMPLOYEES_SUCCESSFUL:
        return {...state, setWorkOrderEmployeesLoading: false, setWorkOrderEmployeesSuccessful: true};
        case workorder.SET_WORK_ORDER_EMPLOYEES_FAILED:
        return {...state, setWorkOrderEmployeesLoading: false, setWorkOrderEmployeesSuccessful: false, setWorkOrderEmployeesError: action.payload};
    case workorder.GET_WORKORDER_ITEMS_VERIFICATION:
        return {...state, getWorkOrderItemVerificationsLoading: true};
    case workorder.GET_WORKORDER_ITEMS_VERIFICATION_SUCCESSFUL:
        return {...state, getWorkOrderItemVerificationsLoading: false, getWorkOrderItemVerificationsSuccessful: true, workOrderItemVerifications: action.payload};
    case workorder.GET_WORKORDER_ITEMS_VERIFICATION_FAILED:
        return {...state, getWorkOrderItemVerificationsLoading: false, getWorkOrderItemVerificationsFailed: action.payload};
    case workorder.UPDATE_WORKORDER_ITEM_VERIFICATION:
        return {...state, updateWorkOrderItemVerificationLoading: true};
    case workorder.UPDATE_WORKORDER_ITEM_VERIFICATION_SUCCESSFUL:
        return {...state, updateWorkOrderItemVerificationLoading: false, updateWorkOrderItemVerificationSuccessful: true, workOrderItemVerifications: action.payload};
    case workorder.UPDATE_WORKORDER_ITEM_VERIFICATION_FAILED:
        return {...state, updateWorkOrderItemVerificationLoading: false, updateWorkOrderItemVerificationSuccessful: true, getWorkOrderItemVerificationsFailed: action.payload};
    case workorder.CLEAR_SALES_TECH_LOADING_VARIABLES_ACTION:
        return {...state, setWorkOrderEmployeesSuccessful: null};
        case workorder.SET_TICKET_TYPE:
        return {...state, ticketType: action.payload.type};
        case workorder.CLEAR_TICKET_TYPE:
        return {...state, ticketType: null};
        default:
            return state;
    }

}

export const getWorkorders = (state: State) => state.workorders;

export const getCurrentWorkorder = (state: State) => state.currentWorkorder;

export const getWorkorderLoading = (state: State) => state.loading;

export const getWorkorderUpdating = (state: State) => state.updating;

export const getWorkorderDeleting = (state: State) => state.deleting;

export const getWorkorderCreating = (state: State) => state.creating;

export const loadWorkOrderSuccess = (state: State) => state.loadingSuccessful;

export const getAddingItems = (state: State) => state.addingItems;

export const getAddingItemSuccess = (state: State) => state.addingItemSuccess;

export const getShowBackButton = (state: State) => state.showWorkOrderBackButton;

export const getWorkOrderStatuses = (state: State) => state.workOrderStatuses;

export const getTicketType = (state: State) => state.ticketType;

export const getSetWorkOrderStatus = (state: State) => [state.setWorkOrderStatusLoading, state.setWorkOrderStatusSuccessful];

export const getEmployeesForAssignment = (state: State) => state.employeesForAssignment;
export const getSetWorkOrderEmployees = (state: State) => [state.setWorkOrderEmployeesLoading, state.setWorkOrderEmployeesSuccessful];

export const getWorkOrderItemVerifications = (state: State) => [state.workOrderItemVerifications, state.getWorkOrderItemVerificationsLoading, state.getWorkOrderItemVerificationsSuccessful];

export const updateWorkOrderItemVerifications = (state: State) => [state.workOrderItemVerifications, state.updateWorkOrderItemVerificationLoading, state.updateWorkOrderItemVerificationSuccessful];
