import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';


@Injectable()
export class WorkOrderMessagesService {
    private urlMessageRead: string;
    private urlGetMessages: string;
    private urlGetUsers: string;
    private urlSendMessage: string;

    constructor(private http: HttpClient) {
        this.urlMessageRead = environment.tireguruBaseApi + '/mark-as-read';
        this.urlGetMessages = environment.tireguruBaseApi + '/get-wo-messages';
        this.urlGetUsers = environment.tireguruBaseApi + '/get-users';
        this.urlSendMessage = environment.tireguruBaseApi + '/send-message';
    }

    markMessageAsRead(messageID, userID) {
        let url = `${this.urlMessageRead}`;
        url += '/' + messageID;
        url += '/' + userID;
        const body = [];
        return this.http.post(url, body);
    }

    getWorkOrderMessages(userID) {
        let url = `${this.urlGetMessages}`;
        url += '/' + userID;

        return this.http.get(url).toPromise();
    }

    getUsersForMessage(locationID) {
        let url = `${this.urlGetUsers}`;
        url += '/' + locationID;

        return this.http.get(url).toPromise();
    }

    sendMessage(message) {
        const url = `${this.urlSendMessage}`;

        return this.http.post(url, [message]);
    }
}
