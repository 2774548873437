import { Action as NgrxAction } from '@ngrx/store';

export const GET_ARC_CART = '[ARC] Get ARC Cart';
export const GET_ARC_CART_SUCCESSFUL = '[ARC] Get ARC Cart Successful';
export const GET_ARC_CART_FAILED = '[ARC] Get ARC Cart Failed';
export const CLEAR_ARC_STATE = '[ARC] Clear ARC state';
export const UPDATE_ITEM_QTY = '[ARC] Update Item Quantity';
export const UPDATE_ITEM_QTY_SUCCESSFUL = '[ARC] Update Item Quantity Successful';
export const UPDATE_ITEM_QTY_FAILED = '[ARC] Update Item Quantity Failed';
export const DELETE_ITEM_FROM_ARC_CART = '[ARC] Delete Item From ARC Cart';
export const DELETE_ITEM_FROM_ARC_CART_SUCCESSFUL = '[ARC] Delete Item From ARC Cart Successful';
export const DELETE_ITEM_FROM_ARC_CART_FAILED = '[ARC] Delete Item From ARC Cart Failed';
export const GET_UNLINKED_ARC_PARTS_LABOR_CORE = '[ARC] Get Unlinked Arc Parts/Labor/Core';
export const GET_UNLINKED_ARC_PARTS_LABOR_CORE_SUCCESSFUL = '[ARC] Get Unlinked Arc Parts/Labor/Core successful';
export const GET_UNLINKED_ARC_PARTS_LABOR_CORE_FAILED = '[ARC] Get Unlinked Arc Parts/Labor/Core failed';
export const GET_UNLINKED_VENDORS = '[ARC] Get Unlinked Vendors';
export const GET_UNLINKED_VENDORS_SUCCESSFUL = '[ARC] Get Unlinked Vendors Successful';
export const GET_UNLINKED_VENDORS_FAILED = '[ARC] Get Unlinked Vendors Failed';
export const FINALIZE_ARC = '[ARC] Finalize Arc';
export const FINALIZE_ARC_SUCCESSFUL = '[ARC] Finalize Arc Successful';
export const FINALIZE_ARC_FAILED = '[ARC] Finalize Arc Failed';

export class GetArcCartAction implements NgrxAction {
    readonly type = GET_ARC_CART;

    constructor(public payload: any) {
    }
}

export class GetArcCartSuccessFulAction implements NgrxAction {
    readonly type = GET_ARC_CART_SUCCESSFUL;

    constructor(public payload: any) {
    }
}

export class GetArcCartFailedAction implements NgrxAction {
    readonly type = GET_ARC_CART_FAILED;

    constructor(public payload: any) {
    }
}

export class ClearArcCartStateAction implements NgrxAction {
    readonly type = CLEAR_ARC_STATE;

    constructor() {
    }
}

export class UpdateItemQtyAction implements NgrxAction {
    readonly type = UPDATE_ITEM_QTY;

    constructor(public payload: any) {
    }
}

export class UpdateItemQtySuccessFulAction implements NgrxAction {
    readonly type = UPDATE_ITEM_QTY_SUCCESSFUL;

    constructor(public payload: any) {
    }
}

export class UpdateItemQtyFailedAction implements NgrxAction {
    readonly type = UPDATE_ITEM_QTY_FAILED;

    constructor(public payload: any) {
    }
}

export class DeleteItemFromArcCartAction implements NgrxAction {
    readonly type = DELETE_ITEM_FROM_ARC_CART;

    constructor(public payload: any) {
    }
}

export class DeleteItemFromArcCartSuccessFulAction implements NgrxAction {
    readonly type = DELETE_ITEM_FROM_ARC_CART_SUCCESSFUL;

    constructor(public payload: any) {
    }
}

export class DeleteItemFromArcCartFailedAction implements NgrxAction {
    readonly type = DELETE_ITEM_FROM_ARC_CART_FAILED;

    constructor(public payload: any) {
    }
}

export class GetUnlinkedArcPartsLaborCoreAction implements NgrxAction {
    readonly type = GET_UNLINKED_ARC_PARTS_LABOR_CORE;

    constructor(public payload: any){
    }
}

export class GetUnlinkedArcPartsLaborCoreSuccessfulAction implements NgrxAction {
    readonly type = GET_UNLINKED_ARC_PARTS_LABOR_CORE_SUCCESSFUL;

    constructor(public payload: any){
    }
}

export class GetUnlinkedArcPartsLaborCoreFailedAction implements NgrxAction {
    readonly type = GET_UNLINKED_ARC_PARTS_LABOR_CORE_FAILED;

    constructor(public payload: any){
    }
}

export class GetUnlinkedVendorsAction implements NgrxAction {
    readonly type = GET_UNLINKED_VENDORS;

    constructor(public payload: any){
    }
}

export class GetUnlinkedVendorsSuccessfulAction implements NgrxAction {
    readonly type = GET_UNLINKED_VENDORS_SUCCESSFUL;

    constructor(public payload: any){
    }
}

export class GetUnlinkedVendorsFailedAction implements NgrxAction {
    readonly type = GET_UNLINKED_VENDORS_FAILED;

    constructor(public payload: any){
    }
}

export class FinalizeArcAction implements NgrxAction {
    readonly type = FINALIZE_ARC;

    constructor(public payload: any){
    }
}

export class FinalizeArcSuccessfulAction implements NgrxAction {
    readonly type = FINALIZE_ARC_SUCCESSFUL;

    constructor(public payload: any){
    }
}

export class FinalizeArcFailedAction implements NgrxAction {
    readonly type = FINALIZE_ARC_FAILED;

    constructor(public payload: any){
    }
}

export type Action = GetArcCartAction
| GetArcCartSuccessFulAction
| GetArcCartFailedAction
| ClearArcCartStateAction
| UpdateItemQtyAction
| UpdateItemQtySuccessFulAction
| UpdateItemQtyFailedAction
| DeleteItemFromArcCartAction
| DeleteItemFromArcCartSuccessFulAction
| DeleteItemFromArcCartFailedAction
| GetUnlinkedArcPartsLaborCoreAction
| GetUnlinkedArcPartsLaborCoreSuccessfulAction
| GetUnlinkedArcPartsLaborCoreFailedAction
| GetUnlinkedVendorsAction
| GetUnlinkedVendorsSuccessfulAction
| GetUnlinkedVendorsFailedAction
| FinalizeArcAction
| FinalizeArcSuccessfulAction
| FinalizeArcFailedAction;

