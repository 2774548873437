
import {take, skipWhile} from 'rxjs/operators';
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {AlertProvider} from "../../../../../core/providers/alert.provider";
import * as workorderActions from "../../../../../store/actions/workorders.actions";
import {Store} from "@ngrx/store";
import {Router} from "@angular/router";
import * as fromPurchaseOrders from "../../../../../store/actions/purchase-orders.actions";
import * as fromRoot from "../../../../../store/reducers/index";

@Component({
    selector: 'app-global-search-results-container',
    templateUrl: './global-search-results-container.component.html',
    styleUrls: ['./global-search-results-container.component.scss']
})
export class GlobalSearchResultsContainerComponent implements OnInit, OnChanges {
    @Input() results;
    @Input() profile;

    @Output() clear_results_results_container = new EventEmitter();

    public result_keys: any = false;
    public loading = true;

    constructor(private store: Store<any>,
        private router: Router,
        private alertProvider: AlertProvider
    ) {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.results.currentValue) {
            this.getResultResponseKeys();
            if (this.checkIfSingleResult()) {
                this.handleSingleResult();
            } else {
                this.loading = false;
            }
        }
    }

    setWorkOrder(woID) {
        this.store.dispatch(new workorderActions.LoadWorkOrderAction(woID));
    }



    goToPurchaseOrderItemVerification(poID) {
        this.store.dispatch(new fromPurchaseOrders.GetPurchaseOrderAction({poID: poID}));

    this.doSuccessAlert(`Loading Purchase Order`);

        this.store.select(fromRoot.getSetPurchaseOrder).pipe(
            skipWhile((result) => result.loading),
            take(1), )
            .subscribe((result) => {
                this.router.navigate(['/purchase-orders/purchase-order-verifications']);
                this.clear_results_results_container.emit();
            });
    }

    goToWorkOrder(woID) {
        this.setWorkOrder(woID);
        setTimeout(() => {
            this.navigate('/work-order-info');
            this.clear_results_results_container.emit();
        }, 2500);
        this.doSuccessAlert("Loading Work Order");
    }

    private navigate(route) {
        this.router.navigate([route]);
    }

    private getResultResponseKeys() {
        this.result_keys = Object.keys(this.results.response);
    }

    get showResults() {
        let showResults = false;

        if (this.result_keys.length > 0) {
            this.result_keys.forEach((key) => {
                if (this.results.response[key].length > 0) {
                    return showResults = true;
                }
            });
        }

        return showResults;
    }

    private checkIfSingleResult() {
        let count_results = 0;
        this.result_keys.forEach((key) => {
            count_results += this.results.response[key].length || 0;
        });
        return (count_results === 1);
    }

    private handleSingleResult() {
        const result = this.getSingleResult();

        if (result.type === 'workorder' || result.type === 'quote') {
            this.handleSingleWorkOrderResult(result);
        } else if (result.type === 'po') {
            this.handleSinglePoResult(result);
        }
        this.loading = false;
    }

    private handleSingleWorkOrderResult(result) {
        if (result.state === 'open') {
            this.goToWorkOrder(result.id);
        }
    }

  private handleSinglePoResult(result) {
    if (this.canOpenPO(result)) {
      this.goToPurchaseOrderItemVerification(result.id);
    }
  }

  private canOpenPO(result) {
    return (result.state !== 'completed'
      && this.profile['permissions']['verify_purchase_orders']
      && this.profile['location']['settings']['loc_verification_purchase_orders']
    );
  }

    private getSingleResult() {
        let result = null;
        this.result_keys.forEach((key) => {
            if (this.results.response[key].length > 0) {
                result = this.results.response[key][0];
            }
        });
        return result;
    }

    public doAlert(event) {
        if (event.type === 'error') {
            this.doErrorAlert(event.msg);
        } else {
            this.doSuccessAlert(event.msg);
        }
    }

    private doSuccessAlert(string) {
        this.alertProvider.addSuccess(string);
    }

    private doErrorAlert(string) {
        this.alertProvider.addError(string);
    }

}
