<app-panel>
  <app-panel-header>
    Scheduler
  </app-panel-header>
  <app-panel-body>
    <div>
      <div *ngIf="returnUrl">
        <a class="btn is-primary is-sm" (click)="redirect()">
          Back
        </a>
      </div>
      <app-iframe theme="light" [src]="getSource()" [minHeight]="800"></app-iframe>
    </div>
  </app-panel-body>
</app-panel>
