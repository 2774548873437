import { Action as NgrxAction } from '@ngrx/store';

export const CLEAR_PURCHASE_ORDER = '[Purchase Orders] Clear purchase order state';
export const CLEAR_PURCHASE_ORDERS = '[Purchase Orders] Clear purchase orders state';
export const CLEAR_ENTIRE_PURCHASE_ORDERS_STATE = '[Purchase Orders] Clear entire purchase orders state';
export const GET_LOCATION_PURCHASE_ORDERS = '[Purchase Orders] Get location purchase orders';
export const GET_LOCATION_PURCHASE_ORDERS_SUCCESSFUL = '[Purchase Orders] Get location purchase orders successful';
export const GET_LOCATION_PURCHASE_ORDERS_FAILED = '[Purchase Orders] Get location purchase orders failed';
export const GET_PURCHASE_ORDER = '[Purchase Order] Get Purchase Order';
export const GET_PURCHASE_ORDER_SUCCESSFUL = '[Purchase Order] Get Purchase Order successful';
export const GET_PURCHASE_ORDER_FAILED = '[Purchase Order] Get Purchase Order failed';
export const GET_PURCHASE_ORDER_VERIFICATIONS = '[Purchase Order] Get Purchase order verifications';
export const GET_PURCHASE_ORDER_VERIFICATIONS_SUCCESSFUL = '[Purchase Order] Get Purchase order verifications successful';
export const GET_PURCHASE_ORDER_VERIFICATIONS_FAILED = '[Purchase Order] Get Purchase order verifications failed';
export const UPDATE_PURCHASE_ORDER_ITEM_VERIFICATION = '[Purchase Order] Update Purchase Order item verification';
export const UPDATE_PURCHASE_ORDER_ITEM_VERIFICATION_SUCCESSFUL = '[Purchase Order] Update Purchase Order item verification successful';
export const UPDATE_PURCHASE_ORDER_ITEM_VERIFICATION_FAILED = '[Purchase Order] Update Purchase Order item verification failed';
export const UPDATE_PURCHASE_ORDER_ITEM_VERIFICATION_BARCODE = '[Purchase Order] Update Purchase Order item verification by barcode';
export const UPDATE_PURCHASE_ORDER_ITEM_VERIFICATION_BARCODE_SUCCESSFUL = '[Purchase Order] Update Purchase Order item verification by barcode successful';
export const UPDATE_PURCHASE_ORDER_ITEM_VERIFICATION_BARCODE_FAILED = '[Purchase Order] Update Purchase Order item verification by barcode failed';



export class ClearPurchaseOrderState implements NgrxAction {
    readonly type = CLEAR_PURCHASE_ORDER;

    constructor() {
    }
}

export class ClearPurchaseOrdersState implements NgrxAction {
    readonly type = CLEAR_PURCHASE_ORDERS;

    constructor() {
    }
}

export class ClearEntireFullPurchaseOrderState implements NgrxAction {
    readonly type = CLEAR_ENTIRE_PURCHASE_ORDERS_STATE;

    constructor() {
    }
}

export class GetLocationPurchaseOrdersAction implements NgrxAction {
    readonly type = GET_LOCATION_PURCHASE_ORDERS;

    constructor(public payload: any) {
    }
}

export class GetLocationPurchaseOrdersSuccessfulAction implements NgrxAction {
    readonly type = GET_LOCATION_PURCHASE_ORDERS_SUCCESSFUL;

    constructor(public payload: any) {
    }
}

export class GetLocationPurchaseOrdersFailedAction implements NgrxAction {
    readonly type = GET_LOCATION_PURCHASE_ORDERS_FAILED;

    constructor(public err: any) {
    }
}

export class GetPurchaseOrderAction implements NgrxAction {
    readonly type = GET_PURCHASE_ORDER;

    constructor(public payload: any) {
    }
}

export class GetPurchaseOrderSuccessfulAction implements NgrxAction {
    readonly type = GET_PURCHASE_ORDER_SUCCESSFUL;

    constructor(public payload: any) {
    }
}

export class GetPurchaseOrderFailedAction implements NgrxAction {
    readonly type = GET_PURCHASE_ORDER_FAILED;

    constructor(public err: any) {
    }
}

export class GetPurchaseOrderVerificationsAction implements NgrxAction {
    readonly type = GET_PURCHASE_ORDER_VERIFICATIONS;

    constructor(public payload: any) {
    }
}

export class GetPurchaseOrderVerificationsSuccessfulAction implements NgrxAction {
    readonly type = GET_PURCHASE_ORDER_VERIFICATIONS_SUCCESSFUL;

    constructor(public payload: any) {
    }
}

export class GetPurchaseOrderVerificationsFailedAction implements NgrxAction {
    readonly type = GET_PURCHASE_ORDER_VERIFICATIONS_FAILED;

    constructor(public err: any) {
    }
}

export class UpdatePurchaseOrderItemVerificationAction implements NgrxAction {
    readonly type = UPDATE_PURCHASE_ORDER_ITEM_VERIFICATION;

    constructor(public payload: any) {
    }
}

export class UpdatePurchaseOrderItemVerificationSuccessfulAction implements NgrxAction {
    readonly type = UPDATE_PURCHASE_ORDER_ITEM_VERIFICATION_SUCCESSFUL;

    constructor(public payload: any) {
    }
}

export class UpdatePurchaseOrderItemVerificationFailedAction implements NgrxAction {
    readonly type = UPDATE_PURCHASE_ORDER_ITEM_VERIFICATION_FAILED;

    constructor(public err: any) {
    }
}

export class UpdatePurchaseOrderItemVerificationByBarcodeAction implements NgrxAction {
    readonly type = UPDATE_PURCHASE_ORDER_ITEM_VERIFICATION_BARCODE;

    constructor(public payload: any) {
    }
}

export class UpdatePurchaseOrderItemVerificationByBarcodeSuccessfulAction implements NgrxAction {
    readonly type = UPDATE_PURCHASE_ORDER_ITEM_VERIFICATION_BARCODE_SUCCESSFUL;

    constructor(public payload: any) {
    }
}

export class UpdatePurchaseOrderItemVerificationByBarcodeFailedAction implements NgrxAction {
    readonly type = UPDATE_PURCHASE_ORDER_ITEM_VERIFICATION_BARCODE_FAILED;

    constructor(public err: any) {
    }
}
