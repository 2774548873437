import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';
import { Router } from '@angular/router';

@Injectable()
export class UserService {

    private urlApi: string;
    private urlOld: string;

    constructor(
        private http: HttpClient,
        private router: Router,
    ) {
        this.urlApi = environment.tireguruBaseApi;
        this.urlOld = environment.tireguruBaseUrl;
    }

    login({ username, password, server }) {
        const url = `${this.urlOld}/login.php`;

        const body = new URLSearchParams();
        body.append('_L', '');
        body.append('_S', server);
        body.append('logID', username);
        body.append('pass', password);
        body.append('server', server);

        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        const options = { headers };
        return this.http.post(url, body.toString(), options).toPromise();
    }

    logout() {
        const url = `${this.urlOld}/logout.php`;

        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        const options = { headers };
        return this.http.post(url, {}, options).toPromise();
    }

}
