
import {switchMap, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';

import { Observable ,  of , from as fromPromise} from 'rxjs';





import * as workordermessage from '../actions/workordermessages.actions';

import { WorkOrderMessagesService } from '../../core/services/work-order-messages.service';

@Injectable()
export class WorkordermessagesEffects {


    getWorkOrders: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(workordermessage.GET_WORK_ORDER_MESSAGES),
        map((action: workordermessage.GetWorkOrderMessages) => action.payload),
        switchMap((payload) => fromPromise(this.WorkOrderMessagesService.getWorkOrderMessages(payload.userID))),
        map((messages) => new workordermessage.GetMessagesSuccessful(messages))));


    markWorkOrderMessageAsRead: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(workordermessage.MARK_WORK_ORDER_MESSAGE_AS_READ),
        map((action: workordermessage.MarkWorkOrderMessageAsRead) => action.payload),
        map(payload => {
            this.WorkOrderMessagesService.markMessageAsRead(payload.messageID, payload.userID).subscribe(response => {
            });
            return false;
        })), {dispatch: false});


    getUsersForMessage: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(workordermessage.GET_USERS),
        map((action: workordermessage.GetUsersForMessage) => action.payload),
        switchMap((payload) => fromPromise(this.WorkOrderMessagesService.getUsersForMessage(payload.locationID))),
        map((employees) => new workordermessage.GetUsersSuccessful(employees))));


    sendNewMessage: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(workordermessage.SEND_MESSAGE),
        map((action: workordermessage.SendMessage) => action.payload),
        map(payload => {
            this.WorkOrderMessagesService.sendMessage(payload.message).subscribe(response => {
            });
            return false;
        })), {dispatch: false});

    constructor(private $actions: Actions, private WorkOrderMessagesService: WorkOrderMessagesService) {}
}
