import * as search from '../actions/search.actions';

export interface State {
    searching: boolean;
    searchTerm: string;
    searchType: string;
    searchResults: any;
    searchFailed: any;
}

const initialState = {
    searching: false,
    searchTerm: '',
    searchType: '',
    searchResults: null,
    searchFailed: null
};

export function reducer(state = initialState, action): State {
    switch (action.type) {
        case search.SEARCH:
            return {...state, searchTerm: action.payload, searching: true};
        case search.SEARCH_TYPE:
            return {...state, searchType: action.payload, searching: true};
        case search.SEARCH_SUCCESSFUL:
            return {...state, searching: false, searchResults: action.payload, searchFailed: null};
        case search.SEARCH_FAILED:
            return {...state, searching: false, searchResults: null, searchFailed: action.payload};
        case search.SEARCH_CLEAR:
            return {...state, searchTerm: '', searchType: '', searching: false, searchResults: null, searchFailed: null};
        default:
            return state;
    }
}

export const getSearchTerm = (state: State) => state.searchTerm;
export const getSearchInfo = (state: State) => ({"searchTerm": state.searchTerm, "searchType": state.searchType});
export const getSearchResults = (state: State) => !state.searching && !state.searchFailed ? state.searchResults : false;
export const getSearchFailed = (state: State) => state.searchFailed;
