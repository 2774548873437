
import {take, skipWhile} from 'rxjs/operators';
import {ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {WorkOrderService} from '../../../core/services/work-order.service';
import {Router} from '@angular/router';
import {AlertProvider} from '../../../core/providers/alert.provider';
import * as WorkOrderMessagesActions from '../../../store/actions/workordermessages.actions';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../store/reducers';
import {getProfile} from '../../../store/reducers';
import * as workorderActions from '../../../store/actions/workorders.actions';


@Component({
    selector: 'app-message-alert',
    templateUrl: './message-alert.component.html',
    styleUrls: ['./message-alert.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class MessageAlertComponent implements OnInit {
    public newMessages: any;
    public showMessageAlert: boolean;
    public woID: number;
    public userID: any;
    public iconName: string;
    public expand: boolean;
    public headingText: string;

    @ViewChild('alertContainer') messageContainer: ElementRef;

    constructor(
        private workOrderService: WorkOrderService,
        private router: Router,
        private alertProvider: AlertProvider,
        private store: Store<fromRoot.State>,
    ) {
        this.store.select(getProfile).pipe(skipWhile(result => !result), take(1), ).subscribe((response) => {
            this.userID = response.use_userID;
        });
    }

    ngOnInit() {
        this.showMessageAlert = false;
        this.expand = false;
        this.iconName = 'angle-down';
        this.headingText = 'New Message';
        this.newMessages = [];
        this.store.select(fromRoot.getUnreadWorkOrderMessages(this.userID)).subscribe( (e) => {
            this.newMessages = e;
            this.showMessageAlert = this.newMessages.length > 0;
            this.headingText = this.newMessages.length > 1 ? this.newMessages.length + ' New Messages' : 'New Message'
        });
    }

    updateHeader() {
        if (this.newMessages.length == 0) {
            this.showMessageAlert = false;
            this.expand = false;
        }
    }

    goToMessage(woID= this.woID, messageID) {
        this.setWorkOrder(woID, ['/messages/']);
        this.removeMessageFromArray(messageID);
        this.alertProvider.addSuccess("Loading Work Order Messages");
        this.toggleMessageDetails();
        this.updateHeader();
    }

    setWorkOrder(woID, navigateTo) {
        this.workOrderService.getWorkOrder(woID).then(response => {
            const workorder: any = response;
            this.store.dispatch(new workorderActions.LoadWorkOrderAction(workorder.data.wor_workorderID));
            setTimeout(() => {
                this.router.navigate(navigateTo);
            }, 800);
        });
    }

    goToWorkOrder(woID, messageID) {
        this.setWorkOrder(woID, ['/work-order-info']);
        this.removeMessageFromArray(messageID);
        this.alertProvider.addSuccess("Loading Work Order");
        this.toggleMessageDetails();
        this.updateHeader();
    }

    toggleMessageDetails() {
        this.expand = !this.expand == true;
        this.messageContainer.nativeElement.style.height = this.messageContainer.nativeElement.style.height == 'auto' ? '50px' : 'auto';
        this.iconName = this.iconName == 'angle-up' ? 'angle-down' : 'angle-up';
    }

    removeMessageFromArray(messageID) {
        this.newMessages = this.newMessages.filter((item) => item["workOrderMessageID"] != messageID);
    }

    dismissAll() {
        this.newMessages = [];
        this.updateHeader();
    }

    dismissMessage(messageID) {
        this.removeMessageFromArray(messageID);
        this.updateHeader();
    }

    markAsRead(messageID) {
        this.store.dispatch(new WorkOrderMessagesActions.MarkWorkOrderMessageAsRead({'messageID': messageID, 'userID': this.userID}));
        this.dismissMessage(messageID);
        this.alertProvider.addSuccess("Message marked as read.");
    }

}
