import { Component, OnInit } from '@angular/core';
import { AlertProvider } from '../../../core/providers/alert.provider';
import { Constants } from '../../../core/constants/constants';

@Component({
    selector: 'app-alerts',
    templateUrl: './alerts.component.html',
    styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit {

    public alert: any;
    public isAlertVisible: boolean;

    constructor(
        private alertProvider: AlertProvider,
    ) {
        this.isAlertVisible = false;
    }

    ngOnInit() {
        this.alertProvider.getAlerts().subscribe(alert => {
            this.isAlertVisible = true;
            this.alert = alert;
            if (alert && alert.type === Constants.STATUS.SUCCESS) {
                setTimeout(() => {
                    this.isAlertVisible = false;
                }, 3000);
            }
        });
    }

    clearAlert() {
        this.isAlertVisible = false;
    }

    get hasAlerts() {
        return Object.keys(this.alert).length !== 0;
    }

    get alertClass() {
        return {
            "is-success": this.hasAlerts && this.alert.type === Constants.STATUS.SUCCESS,
            "is-error": this.hasAlerts && this.alert.type === Constants.STATUS.ERROR };
    }

}
