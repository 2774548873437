import * as purchase_orders from '../actions/purchase-orders.actions';

export interface State {
    loading: boolean;
    updating: boolean;
    purchaseOrders: any;
    purchaseOrder: any;
    message: any;

    getPurchaseOrdersSuccessful: boolean;
    getPurchaseOrdersFailed: any;

    getPurchaseOrderSuccessful: boolean;
    getPurchaseOrderFailed: any;

    purchaseOrderVerifications: any;
    getPurchaseOrderVerificationsSuccessful: boolean;
    getPurchaseOrderVerificationsFailed: any;

    updatePurchaseOrderItemVerificationSuccessful: boolean;
    updatePurchaseOrderItemVerificationFailed: any;
}

const initialState: State = {
    loading: false,
    updating: false,
    purchaseOrders: null,
    purchaseOrder: null,
    message: null,

    getPurchaseOrdersSuccessful: null,
    getPurchaseOrdersFailed: null,

    getPurchaseOrderSuccessful: null,
    getPurchaseOrderFailed: null,

    purchaseOrderVerifications: null,
    getPurchaseOrderVerificationsSuccessful: null,
    getPurchaseOrderVerificationsFailed: null,

    updatePurchaseOrderItemVerificationSuccessful: null,
    updatePurchaseOrderItemVerificationFailed: null
};

export function reducer(state = initialState, action): State {
    switch (action.type) {
    case purchase_orders.CLEAR_PURCHASE_ORDER:
        return {...state, purchaseOrder: null, purchaseOrderVerifications: null};
    case purchase_orders.CLEAR_PURCHASE_ORDERS:
        return {...state, purchaseOrders: null};
    case purchase_orders.CLEAR_ENTIRE_PURCHASE_ORDERS_STATE:
        return {...state = initialState};
    case purchase_orders.GET_LOCATION_PURCHASE_ORDERS:
        return {...state, loading: true, purchaseOrders: null, getPurchaseOrdersSuccessful: null, getPurchaseOrdersFailed: null};
    case purchase_orders.GET_LOCATION_PURCHASE_ORDERS_SUCCESSFUL:
        return {...state, purchaseOrders: action.payload, getPurchaseOrdersSuccessful: true, loading: false};
    case purchase_orders.GET_LOCATION_PURCHASE_ORDERS_FAILED:
        return {...state, purchaseOrders: false, getPurchaseOrdersSuccessful: false, getPurchaseOrdersFailed: action.err, loading: false};

    case purchase_orders.GET_PURCHASE_ORDER:
        return {...state, loading: true, purchaseOrder: null, getPurchaseOrderSuccessful: null, getPurchaseOrderFailed: null};
    case purchase_orders.GET_PURCHASE_ORDER_SUCCESSFUL:
        return {...state, purchaseOrder: action.payload, getPurchaseOrderSuccessful: true, loading: false};
    case purchase_orders.GET_PURCHASE_ORDER_FAILED:
        return {...state, purchaseOrder: null, getPurchaseOrderSuccessful: false, getPurchaseOrderFailed: action.err, loading: false};

    case purchase_orders.GET_PURCHASE_ORDER_VERIFICATIONS:
        return {...state, loading: true, purchaseOrderVerifications: null, getPurchaseOrderVerificationsSuccessful: null, getPurchaseOrderVerificationsFailed: null};
    case purchase_orders.GET_PURCHASE_ORDER_VERIFICATIONS_SUCCESSFUL:
        return {...state, purchaseOrderVerifications: action.payload, getPurchaseOrderVerificationsSuccessful: true, loading: false};
    case purchase_orders.GET_PURCHASE_ORDER_VERIFICATIONS_FAILED:
        return {...state, purchaseOrderVerifications: false, getPurchaseOrderVerificationsSuccessful: false, getPurchaseOrderVerificationsFailed: action.err, loading: false};

    case purchase_orders.UPDATE_PURCHASE_ORDER_ITEM_VERIFICATION:
        return {...state, updating: true, updatePurchaseOrderItemVerificationSuccessful: null, updatePurchaseOrderItemVerificationFailed: null, message: null};
    case purchase_orders.UPDATE_PURCHASE_ORDER_ITEM_VERIFICATION_SUCCESSFUL:
        return {...state, updatePurchaseOrderItemVerificationSuccessful: action.payload.success, message: action.payload.response.message || null, purchaseOrderVerifications: null, updating: false};
    case purchase_orders.UPDATE_PURCHASE_ORDER_ITEM_VERIFICATION_FAILED:
        return {...state, updatePurchaseOrderItemVerificationSuccessful: false, updatePurchaseOrderItemVerificationFailed: action.err, updating: false};

    case purchase_orders.UPDATE_PURCHASE_ORDER_ITEM_VERIFICATION_BARCODE:
        return {...state, updating: true, updatePurchaseOrderItemVerificationSuccessful: null, updatePurchaseOrderItemVerificationFailed: null,  message: null};
    case purchase_orders.UPDATE_PURCHASE_ORDER_ITEM_VERIFICATION_BARCODE_SUCCESSFUL:
        return {...state, updatePurchaseOrderItemVerificationSuccessful: action.payload.success, message: action.payload.response.message || null, purchaseOrderVerifications: null, updating: false};
    case purchase_orders.UPDATE_PURCHASE_ORDER_ITEM_VERIFICATION_BARCODE_FAILED:
        return {...state, updatePurchaseOrderItemVerificationSuccessful: false, updatePurchaseOrderItemVerificationFailed: action.err, updating: false};

    default:
        return state;
    }
}

export const getLocationPurchaseOrders = (state: State) => ({
    response: state.purchaseOrders,
    success: state.getPurchaseOrdersSuccessful,
    failed: state.getPurchaseOrdersFailed,
    loading: state.loading
});

export const getPurchaseOrder = (state: State) => ({
    response: state.purchaseOrder,
    success: state.getPurchaseOrderSuccessful,
    failed: state.getPurchaseOrderFailed,
    loading: state.loading
});

export const getPurchaseOrderVerifications = (state: State) => ({
    response: state.purchaseOrderVerifications,
    success: state.getPurchaseOrderVerificationsSuccessful,
    failed: state.getPurchaseOrderVerificationsFailed,
    loading: state.loading
});

export const getUpdatePurchaseOrderItemVerification = (state: State) => ({
    success: state.updatePurchaseOrderItemVerificationSuccessful,
    message: state.message,
    failed: state.updatePurchaseOrderItemVerificationFailed,
    updating: state.updating
});
