import {throwError as observableThrowError, Observable, EMPTY as empty, of, from as fromPromise} from 'rxjs';

import {catchError, switchMap, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {createEffect, Actions, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';


import * as profile from '../actions/profile.actions';

import {ProfileService} from '../../core/services/profile.service';

@Injectable()
export class ProfileEffects {


    lodCustomer: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(profile.LOAD_PROFILE),
        map((action: profile.LoadProfileAction) => action.payload),
        switchMap((profileID) => {
            if (!profileID) {
                return observableThrowError({payload: {error: {message: 'Could not load profile'}}});
            }

            return fromPromise(this.profileService.getProfile()).pipe(
                map((profileInfo) => new profile.LoadProfileSuccessfulAction(profileInfo)),
                catchError((error) => of(new profile.LoadProfileFailedAction({error: {message: 'Profile could not be loaded, please try again'}}))));
        }
        )));

    constructor(private $actions: Actions, private profileService: ProfileService) {
    }
}
