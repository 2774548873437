
import {take, catchError, map, switchMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {createEffect, Actions, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';

import {Observable, EMPTY as empty, of, from as fromPromise} from 'rxjs';




import {Store} from '@ngrx/store';
import * as fromRoot from '../reducers';

import * as vehicle from '../actions/vehicle.actions';

import {VehicleService} from '../../core/services/vehicle.service';
import {CarfaxService} from '../../core/services/carfax.service';

@Injectable()
export class VehicleEffects {


    loadVehicle: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(vehicle.LOAD_VEHICLE),
        map((action: vehicle.LoadVehicleAction) => action.payload),
        switchMap((vehicleId) =>
            fromPromise(this.vehicleService.getVehicle(vehicleId)).pipe(
                map((vehicleInfo) => new vehicle.LoadVehicleSuccessfulAction(vehicleInfo)),
                catchError((error) => of(new vehicle.LoadVehicleFailedAction({error: {message: 'Vehicle could not be loaded, please try again'}}))))
        )));


    createVehicle: Observable<vehicle.Action> = createEffect(() => this.$actions.pipe(
        ofType(vehicle.CREATE_VEHICLE),
        map((action: vehicle.CreateVehicleAction) => action.payload),
        switchMap((vehicleCreationInfo) =>
            fromPromise(this.vehicleService.createVehicle(vehicleCreationInfo)).pipe(
                map((vehicleInfo) => new vehicle.CreateVehicleSuccessfulAction(vehicleInfo)),
                catchError(() => of(new vehicle.CreateVehicleFailedAction({error: {message: 'Vehicle creat work order, please try again later'}}))))
        )));


    updateVehicle: Observable<vehicle.Action> = createEffect(() => this.$actions.pipe(
        ofType(vehicle.UPDATE_VEHICLE),
        map((action: vehicle.UpdateVehicleAction) => action.payload),
        switchMap((vehicleUpdateInfo) =>
            fromPromise(this.vehicleService.updateVehicle(vehicleUpdateInfo)).pipe(
                map((vehicleInfo) => new vehicle.UpdateVehicleSuccessfulAction(vehicleInfo)),
                catchError(() => of(new vehicle.UpdateVehicleFailedAction({error: {message: 'Could not update vehicle'}}))))
        )));


    uploadVehicleImage: Observable<vehicle.Action> = createEffect(() => this.$actions.pipe(
        ofType(vehicle.UPLOAD_VEHICLE_IMAGE),
        map((action: vehicle.UpdateVehicleAction) => action.payload),
        switchMap((vehicleImageInfo) =>
            fromPromise(this.vehicleService.saveVehicleImage(vehicleImageInfo.imageData, vehicleImageInfo.vehicleId)).pipe(
                map((response: any) => new vehicle.UploadVehicleImageSuccessfulAction(response.data)),
                catchError((error) => of(new vehicle.UploadVehicleImageFailedAction(error))))
        )));


    getVehicleCarfax: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(vehicle.GET_VEHICLE_CARFAX),
        map((action: vehicle.GetVehicleCarfax) => action.payload),
        switchMap((payload) =>
            this.store.select(fromRoot.getCurrentVehicleCarfax).pipe(
                take(1),
                switchMap((carfax: any) => {
                    if (carfax) {
                        return of(carfax);
                    }
                    return fromPromise(this.carfaxService.getCarFaxWithLookupCodes(payload.license, payload.state, payload.vin));
                }),
                map((carfax: any) => new vehicle.GetVehicleCarfaxSuccessful(carfax)),
                catchError((error) => of(new vehicle.GetVehicleCarfaxFailed({error: error}))))
        )));


    duplicateVinLookup$: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(vehicle.DUPLICATE_VIN_LOOKUP),
        map((action: vehicle.DuplicateVinLookup) => action.vin),
        switchMap((vin) => fromPromise(this.vehicleService.duplicateVinLookup(vin)).pipe(
            map((response: any) => new vehicle.DuplicateVinLookupSuccessful(response.response)),
            catchError((error) => of(new vehicle.DuplicateVinLookupFailed(error)))
        ))));

  constructor(
    private $actions: Actions,
    private vehicleService: VehicleService,
    private store: Store<fromRoot.State>,
    private carfaxService: CarfaxService,
  ) {
  }
}
