import {switchMap, map, catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {createEffect, Actions, ofType} from '@ngrx/effects';

import {Observable, of, from as fromPromise} from 'rxjs';

import * as barcodes from '../actions/barcodes.actions';
import {ItemBarcodesService} from '../../core/services/barcodes/item-barcodes.service';

@Injectable()
export class BarcodesEffects {

    getSingleItemBarcode$: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(barcodes.GET_ITEM_BARCODE),
        map((action: barcodes.GetItemBarcode) => action.payload),
        switchMap((payload) =>
            fromPromise(this.itemBarcodesService.getSingleItemBarcode(payload.itemID, payload.qty)).pipe(
                map((response) => new barcodes.GetItemBarcodeSuccessful(response)),
                catchError((error) => of(new barcodes.GetItemBarcodeFailed(error)))
            )
        )
    ));


    searchForItemByCode: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(barcodes.SEARCH_ITEM_BY_BARCODE),
        map((action: barcodes.SearchForItemByBarcode) => action.payload),
        switchMap((payload) =>
            fromPromise(this.itemBarcodesService.searchForItemByCode(payload.code)).pipe(
                map((response) => new barcodes.SearchForItemByBarcodeSuccessful(response)),
                catchError((error) => of(new barcodes.SearchForItemByBarcodeFailed(error)))
            )
        )
    ));

    constructor(
        private $actions: Actions,
        private itemBarcodesService: ItemBarcodesService
    ) {
    }

}
