import * as vehicle from '../actions/vehicle.actions';
import * as session from '../actions/session.actions';

export interface State {
    loading: boolean;
    loadingSuccessful: boolean;
    loadingError: string;

    creating: boolean;
    creatingSuccessful: boolean;
    creatingError: string;

    deleting: boolean;
    deletingSuccesful: boolean;
    deletingError: string;

    updating: boolean;
    updatingSuccessful: boolean;
    updatingError: string;

    uploadingImage: boolean;
    uploadImageSuccessful: boolean;
    uploadImageFailed: boolean;

    getVehicleCarfaxLoading: boolean;
    getVehicleCarfaxSuccessful: boolean;
    getVehicleCarfaxFailed: string;

  duplicateVinLookupResults: any;
  duplicateVinLookupSuccessful: boolean;
  duplicateVinLookupError: any;

  vehicles: { [id: number]: any };
  currentVehicle: any;
  carfax: any;
}

const initialState: State = {

    loading: false,
    loadingSuccessful: null,
    loadingError: null,

    creating: false,
    creatingSuccessful: null,
    creatingError: null,

    deleting: false,
    deletingSuccesful: null,
    deletingError: null,

    updating: false,
    updatingSuccessful: null,
    updatingError: null,

    uploadingImage: null,
    uploadImageSuccessful: null,
    uploadImageFailed: null,

    getVehicleCarfaxLoading: false,
    getVehicleCarfaxSuccessful: null,
    getVehicleCarfaxFailed: null,

  duplicateVinLookupResults: null,
  duplicateVinLookupSuccessful: null,
  duplicateVinLookupError: null,

  vehicles: {},
  currentVehicle: null,
  carfax: false,
};

export function reducer(state = initialState, action): State {

    switch (action.type) {
    case vehicle.SET_VEHICLE:
        let toRet = {...state, loading: false, currentVehicle: {...action.payload}};
        if (action.payload && action.payload.engine && action.payload.engine.length) {
            toRet.currentVehicle.engine = action.payload.engine.filter(engine => engine.FG_OptionID === action.payload.veh_packageID).pop();
        }
        return toRet;

    case vehicle.LOAD_VEHICLE:
    case session.LOAD_SESSION_SUCCESSFUL:
        return {...state, loading: true};
    case vehicle.LOAD_VEHICLE_SUCCESSFUL:
        let toReturn = {...state, loading: false, currentVehicle: {...action.payload}, loadingSuccessful: true};
        if (action.payload.engine && action.payload.engine.length) {
            toReturn.currentVehicle.engine = action.payload.engine.filter(engine => engine.FG_OptionID === action.payload.veh_packageID).pop();
        }
        return toReturn;
    case vehicle.LOADING_VEHICLE_FAILED:
        return {...state, loading: false, loadingSuccessful: false, loadingError: action.payload.error.message};

    case vehicle.DELETE_VEHICLE:
        return {...state, deleting: true};
    case vehicle.DELETE_VEHICLE_SUCCESSFUL:
        return {...state, deleting: false, deletingSuccesful: true};
    case vehicle.DELETE_VEHICLE_FAILED:
        return {...state, deleting: false, deletingSuccesful: false, deletingError: action.payload.error.message};

    case vehicle.UPDATE_VEHICLE:
        return {...state, updating: true};
    case vehicle.UPDATE_VEHICLE_SUCCESSFUL:
        return {...state, updating: false, currentVehicle: action.payload};
    case vehicle.UPDATE_VEHICLE_FAILED:
        return {...state, updating: false, updatingError: action.payload.error.message, updatingSuccessful: false};

    case vehicle.CREATE_VEHICLE:
        return {...state, creating: true};
    case vehicle.CREATE_VEHICLE_SUCCESSFUL:
        return {...state, creating: false, currentVehicle: action.payload};
    case vehicle.CREATE_VEHICLE_FAILED:
        return {...state, creating: false, creatingSuccessful: false, creatingError: action.payload.error.message};

    case vehicle.UPLOAD_VEHICLE_IMAGE:
        return {...state, uploadingImage: true, uploadImageSuccessful: null, uploadImageFailed: null};
    case vehicle.UPLOAD_VEHICLE_IMAGE_SUCCESSFUL:
        return {...state, uploadingImage: null, uploadImageSuccessful: true, uploadImageFailed: false, currentVehicle: action.payload.vehicle};
    case vehicle.UPLOAD_VEHICLE_IMAGE_FAILED:
        return {...state, uploadingImage: null, uploadImageSuccessful: false, uploadImageFailed: true};
    case vehicle.CLEAR_VEHICLE_UPLOAD_STATE:
        return {...state, uploadImageSuccessful: null, uploadImageFailed: null};

    case vehicle.SET_VEHICLE_CARFAX:
        return {...state, carfax: action.payload, getVehicleCarfaxLoading: false};
    case vehicle.GET_VEHICLE_CARFAX:
        return {...state, getVehicleCarfaxLoading: true};
    case vehicle.GET_VEHICLE_CARFAX_SUCCESSFUL:
        return {...state, getVehicleCarfaxLoading: false, getVehicleCarfaxSuccessful: true, carfax: action.payload};
    case vehicle.GET_VEHICLE_CARFAX_FAILED:
        return {...state, getVehicleCarfaxLoading: false, getVehicleCarfaxSuccessful: false, getVehicleCarfaxFailed: action.payload};

    case vehicle.DUPLICATE_VIN_LOOKUP:
      return {...state, loading: true, duplicateVinLookupSuccessful: null, duplicateVinLookupResults: null, duplicateVinLookupError: null};
    case vehicle.DUPLICATE_VIN_LOOKUP_SUCCESSFUL:
      return {...state, duplicateVinLookupSuccessful: true, duplicateVinLookupResults: action.response, loading: false};
    case vehicle.DUPLICATE_VIN_LOOKUP_FAILED:
      return {...state, duplicateVinLookupSuccessful: false, duplicateVinLookupError: action.payload, loading: false};

    default:
        return state;
    }

}

export const getVehicles = (state: State) => state.vehicles;

export const getCurrentVehicle = (state: State) => state.currentVehicle;

export const getVehicleLoading = (state: State) => state.loading;

export const getVehicleUpdating = (state: State) => state.updating;

export const getVehicleDeleting = (state: State) => state.deleting;

export const getVehicleCreating = (state: State) => state.creating;

export const getCurrentVehicleCarfax = (state: State) => state.carfax;

export const getCurrentVehicleCarfaxState = (state: State) => ({
    carfax: state.carfax,
    loading: state.getVehicleCarfaxLoading
});

export const getDuplicateVinLookupResults = (state: State) => {
  return {
    results: state.duplicateVinLookupResults,
    loading: state.loading,
    error: state.duplicateVinLookupError,
    success: state.duplicateVinLookupSuccessful
  };
};

export const getVehicleImageUploadState = (state: State) => {
    const {uploadingImage, uploadImageSuccessful, uploadImageFailed} = state;
    return {uploadingImage, uploadImageSuccessful, uploadImageFailed};
};
