import { Action as NgrxAction } from '@ngrx/store';

export const LOAD_SESSION = '[Session] load session';
export const LOAD_SESSION_SUCCESSFUL = '[Session] load session successful';
export const LOAD_SESSION_FAILED = '[Session[ Load session failed';

export class LoadSessionAction implements NgrxAction {
    readonly type = LOAD_SESSION;
}

export class LoadSessionSuccessfulAction implements NgrxAction {
    readonly type = LOAD_SESSION_SUCCESSFUL;

    constructor(public payload: any) {
    }
}

export class LoadSessionFailedAction implements NgrxAction {
    readonly type = LOAD_SESSION_FAILED;

    constructor(public payload: any) {
    }
}

export type Action = LoadSessionAction
| LoadSessionSuccessfulAction
| LoadSessionFailedAction;
