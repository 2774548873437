import {catchError, switchMap, map, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {createEffect, Actions, ofType} from '@ngrx/effects';

import {Observable, of, from as fromPromise} from 'rxjs';


import * as form from '../actions/form.actions';
import * as fromRoot from '../reducers';
import {Store} from '@ngrx/store';
import {FormService} from '../../core/services/form.service';

@Injectable()
export class FormEffects {


    finishAddingItem: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(form.FINISHED_ADDING_ITEMS),
        map((action: form.FinishedAddingItemsAction) => action.payload),
        map(payload => {
            const formData = new FormData();
            formData.append(`form${payload.form.addingItemsFrom}`, payload.itemID);

            this.formService.saveFormValue(formData, payload.form.form.frm_formID, payload.workorder.wor_workorderID).subscribe(resonse => {
                this.router.navigateByUrl(`/dynamic/${payload.form.form.frm_formID}`);
            });
            return false;
        })), {dispatch: false});


    backToInspection: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(form.BACK_TO_INSPECTION),
        map((action: form.BackToInspectionAction) => action.payload),
        map(payload => {
            this.router.navigateByUrl(`/dynamic/${payload.form.form.frm_formID}`);
            return false;
        })), {dispatch: false});


    loadCustomerSubmissions: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(form.LOAD_CUSTOMER_FORM_SUBMISSIONS),
        map((action: form.LoadCustomerFormSubmissionsAction) => action.payload),
        switchMap((info: any) => this.formService.getFormSubmissionsForCustomerAndVehicle(info.customerId, info.vehicleId, info.page)),
        map((submissions: any) => new form.LoadCustomerFormSubmissionsSucessfulAction(submissions))));


    loadsSlugJobCategoryRelations: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(form.LOAD_SLUG_JOB_CATEGORY_RELATIONS),
        map((action: form.LoadSlugJobCategoryRelationsAction) => action.payload),
        switchMap(companyId => this.formService.getSlugJobCategoryRelations(companyId)),
        map((results: any) => new form.LoadSlugCategoryRelationsSuccessfulAction(results)),
        catchError((errors) => of(new form.LoadSlugCategoryRelationsFailedAction(errors)))));


    getInspectionFormInfo: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(form.GET_INSPECTION_INFO),
        map((action: form.GetInspectionInfoAction) => action.payload),
        switchMap((payload) =>
            fromPromise(this.formService.getInspectionInfo(payload)).pipe(
                map((inspectionInfo) => new form.GetInspectionInfoSuccessfulAction(inspectionInfo)),
                catchError((error) => of(new form.GetInspectionInfoFailedAction(error))))
        )));


    deleteImageAttachment$: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(form.DELETE_IMAGE_ATTACHMENT),
        map((action: form.DeleteImageAttachmentAction) => action.payload),
        switchMap(payload => this.formService.deleteImageAttachment(payload.comment, payload.slug)),
        map((results: any) => new form.DeleteImageAttachmentActionSuccessful(results)),
        catchError((errors) => of(new form.DeleteImageAttachmentActionFailed(errors)))));

    constructor(private $actions: Actions, private router: Router, private formService: FormService) {
    }
}
