import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-global-search-po-result',
    templateUrl: './global-search-po-result.component.html',
    styleUrls: ['./global-search-po-result.component.scss', '../global-search-result-type-container/global-search-result-type-container.component.scss']
})
export class GlobalSearchPoResultComponent implements OnInit {
    @Input() result;
    @Input() profile;

    @Output() show_alert = new EventEmitter();
    @Output() go_to_po_item_verification = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
    }

    public poSelected() {
        if (this.result['state'] !== 'completed') {
            this.goToPoItemVerification();
        } else {
            this.show_alert.emit({type: 'error', 'msg': 'Cannot open ' + this.result['state'] + ' purchase orders.'});
        }
    }

  public goToPoItemVerification() {
    if (!this.profile['permissions']['verify_purchase_orders']) {
      this.show_alert.emit({type: 'error', 'msg': 'User does not have permission to use PO item verification.'})
    } else if(!this.profile['location']['settings']['loc_verification_purchase_orders']) {
      this.show_alert.emit({type: 'error', 'msg': 'Location does not use PO verifications.'})
    } else {
      this.show_alert.emit({type: 'success', 'msg': 'Loading po item verification.'});
      this.go_to_po_item_verification.emit(this.result['id']);
    }
  }
}
