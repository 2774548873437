import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

@Injectable()
export class NoteService {

    private urlApi: string;
    private urlOld: string;

    constructor(private http: HttpClient) {
        this.urlApi = environment.tireguruBaseApi;
        this.urlOld = environment.tireguruBaseUrl;
    }

    addNote({ action = 'add_note', note }) {
        const url = `${this.urlOld}/mobile/mobileUpdate.php`;
        const params = {
            act: action,
            note,
        };
        return this.http.get(url, { params }).toPromise();
    }

}
