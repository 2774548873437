import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-global-search-result-type-container',
    templateUrl: './global-search-result-type-container.component.html',
    styleUrls: ['./global-search-result-type-container.component.scss']
})
export class GlobalSearchResultTypeContainerComponent implements OnInit {

    @Input() type_results;
    @Input() type;
    @Input() profile;

    @Output() clear_results_type_container = new EventEmitter();
    @Output() go_to_purchase_order_item_verification = new EventEmitter();
    @Output() go_to_work_order = new EventEmitter();
    @Output() handle_alert = new EventEmitter();


    constructor() {
    }

    ngOnInit() {
    }

    goToWorkOrder(woID) {
        this.go_to_work_order.emit(woID);
    }

    goToPurchaseOrderItemVerification(poID) {
        this.go_to_purchase_order_item_verification.emit(poID);
    }

    public doAlert(event) {
        this.handle_alert.emit(event);
    }
}
