import * as note from '../actions/workordernote.actions';

export interface State {
    loading: boolean;
    loadingSuccessful: boolean;
    loadingError: string;

    creating: boolean;
    creatingSuccessful: boolean;
    creatingError: string;

    deleting: boolean;
    deletingSuccesful: boolean;
    deletingError: string;

    updating: boolean;
    updatingSuccessful: boolean;
    updatingError: string;

    notes: any;
    currentNote: any;
}

const initialState: State = {

    loading: false,
    loadingSuccessful: null,
    loadingError: null,

    creating: false,
    creatingSuccessful: null,
    creatingError: null,

    deleting: false,
    deletingSuccesful: null,
    deletingError: null,

    updating: false,
    updatingSuccessful: null,
    updatingError: null,

    notes: false,
    currentNote: null
};

export function reducer(state = initialState, action): State {

    switch (action.type) {
    case note.SET_NOTE:
        return {...state, loading: false, notes: action.payload};

    case note.LOAD_NOTES:
        return {...state, loading: true};
    case note.LOAD_NOTES_SUCCESSFUL:
        return {...state, loading: false, notes: action.payload, loadingSuccessful: true };
    case note.LOAD_NOTES_FAILED:
        return {...state, loading: false, loadingSuccessful: false, loadingError: action.payload.error.message};

    case note.CREATE_NOTE:
        return {...state, creating: true};
    case note.CREATE_NOTE_SUCCESSFUL:
        return {...state, creating: false, notes: action.payload};
    case note.CREATE_NOTE_FAILED:
        return {...state, creating: false, creatingSuccessful: false, creatingError: action.payload.error.message};

    case note.DELETE_NOTE:
        return {...state, deleting: true};
    case note.DELETE_NOTE_SUCCESSFUL:
        return {...state, deleting: false, deletingSuccesful: true, notes: action.payload};
    case note.DELETE_NOTE_FAILED:
        return {...state, deleting: false, deletingSuccesful: false, deletingError: action.payload.error.message};

    case note.UPDATE_NOTE:
        return {...state, updating: true};
    case note.UPDATE_NOTE_SUCCESSFUL:
        return {...state, updating: false, notes: action.payload};
    case note.UPDATE_NOTE_FAILED:
        return {...state, updating: false, updatingError: action.payload.error.message, updatingSuccessful: false};
    default:
        return state;
    }
}

export const getNotes = (state: State) =>  state.notes;

export const getCurrentNote = (state: State) => state.currentNote;

export const getNoteLoading = (state: State) => state.loading;

export const getNoteUpdating = (state: State) => state.updating;

export const getNoteDeleting = (state: State) => state.deleting;

export const getNoteCreating = (state: State) => state.creating;

