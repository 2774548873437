import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-global-search-wo-result',
    templateUrl: './global-search-wo-result.component.html',
    styleUrls: ['./global-search-wo-result.component.scss', '../global-search-result-type-container/global-search-result-type-container.component.scss']
})
export class GlobalSearchWoResultComponent implements OnInit {
    @Input() result;

    @Output() set_work_order = new EventEmitter();
    @Output() show_alert = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
    }

    public goToWorkOrder() {
        if (this.result['state'] === 'open') {
            this.set_work_order.emit(this.result['id']);
        } else {
            this.show_alert.emit({type: 'error', msg: this.buildErrAlertMessage()});
        }
    }

    private buildErrAlertMessage() {
        return 'Cannot open ' + this.result['state'] + ' work order.'
    }
}
