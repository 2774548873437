
import {take, skipWhile} from 'rxjs/operators';
import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Store} from '@ngrx/store';

import { WorkOrderProvider } from '../../../core/providers/work-order.provider';
import {SessionService} from '../../../core/services/session.service';
import * as fromWorkorders from '../../../store/actions/workorders.actions';

import * as fromRoot from '../../../store/reducers';
import {AlertProvider} from '../../../core/providers/alert.provider';
import { NgxSmartModalComponent } from 'ngx-smart-modal';


@Component({
    selector: 'app-work-order-nav',
    templateUrl: './work-order-nav.component.html',
    styleUrls: ['./work-order-nav.component.scss']
})
export class WorkOrderNavComponent implements OnInit {

    public workOrder: any;
    public customer: any;
    public vehicle: any = false;
    public salesTech: any;
    public statusID: string;
    public locID: any;
    public statuses: any;
    public selectedStatusID: any;
    public selectedStatus: any = false;
    public showPopup = false;
    public employees: any = false;
    public workOrderTypeQuote = 1;
    public showCarfaxPopup = false;
    public profile: any;
    public send_text_confirmed: any = false;
    public show_work_order_status_change_modal = false;

    @ViewChild('carfaxPopup') carfaxPopup: NgxSmartModalComponent;
    @ViewChild('work_order_status_change_modal') work_order_status_change_modal: NgxSmartModalComponent;

    constructor(
        private router: Router,
        private workOrderProvider: WorkOrderProvider,
        private sessionService: SessionService,
        private alertProvider: AlertProvider,
        private store: Store<fromRoot.State>,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.salesTech = {
            "sales_name": 'Not Assigned',
            "tech_name": 'Not Assigned',
        };
        this.statusID = '';
        this.setupSubscriptions();

        if (this.route.snapshot.queryParamMap.has('popSales')) {
            this.showPopup = !!this.route.snapshot.queryParamMap.get('popSales');
        }
    }

    setupSubscriptions() {
        this.store.select(fromRoot.getWorkorder).subscribe(result => {
            this.workOrder = result;
            this.vehicle = this.workOrderProvider.getSelectedVehicle();
            if (result != null && typeof result != 'undefined') {
                if (result.status != null && result.status.wos_name != null && typeof result.status.wos_name != 'undefined') {
                    this.statusID = result.status.wos_statusID;
                }
            }
            this.store.select(fromRoot.getProfile).pipe(skipWhile(profile => (profile && typeof profile === 'object' && !Object.keys(profile).length)), take(1), ).subscribe(profile => {
                if (profile && profile.location) {
                    this.profile = profile;
                    this.locID = profile.location.loc_locID;

                    this.getWorkOrderStatuses();

                    if (!this.employees) {
                        this.getEmployeesForAssignment();
                    }
                }

                this.workOrderProvider.getCustomer().pipe(skipWhile((cust) => !cust)).subscribe(custResult => {
                    if (custResult && custResult.cus_customerID && profile && profile.default_customer && profile.default_customer.cus_customerID === custResult.cus_customerID) {

                        if (this.workOrder.temp_customer_data) {
                            this.customer = this.workOrder.temp_customer_data.data;
                        }
                    } else {
                        this.customer = custResult;
                    }
                });
            });
        });

    }

    openMessages() {
        setTimeout(() => {
            this.router.navigate(['/work-order-messages'], {queryParams: {woID: this.workOrder.wor_workorderID}});
        }, 800);
        this.alertProvider.addSuccess("Loading Work Order Messages");
    }

    addNote() {
        this.router.navigate(['/other']);
    }

    clearSession() {
        this.sessionService.clear().then(() => this.router.navigate([""])).then(() => {
            this.workOrderProvider.clear();
        });
    }

    get vehicleEditLink(): Array<String> {
        return [`/vehicles/${this.vehicle.veh_vehicleID}/edit`];
    }

    get customerEditLink(): Array<String> {
        if (this.customer && this.customer.cus_customerID) {
            return [`/customers/${this.customer.cus_customerID}/edit`];
        }
        return ['#'];
    }

    get hasWorkOrder(): boolean {
        return this.workOrder && Object.keys(this.workOrder).length !== 0;
    }

    getWorkOrderStatuses() {
        this.store.dispatch(new fromWorkorders.GetWorkOrderStatusesAction({'locID': this.locID}));
        this.store.select(fromRoot.getWorkOrderStatuses).pipe(skipWhile(statuses => !statuses), take(1), ).subscribe((statuses) => {
            this.statuses = statuses;
            this.selectedStatusID = this.statusID;
        });
    }

    setWorkOrderStatus() {
        if (this.statusTextMessageAble) {
            this.openStatusChangeConfirmMessageModal();
        } else {
            this.sendStatusChange();
        }
    }

    sendStatusChange() {
        this.store.dispatch(new fromWorkorders.SetWorkOrderStatusAction({
            "locID": this.locID,
            "woID": this.workOrder.wor_workorderID,
            "statusID": this.selectedStatusID,
            "sendText": this.sendText
        }));

        this.store.select(fromRoot.getSetWorkOrderStatus).pipe(skipWhile(status => status[0]), take(1), ).subscribe((statusOfSet) => {
            if (statusOfSet[0] == false && statusOfSet[1] == true) {
                this.alertProvider.addSuccess("Work Order Status Updated Successfully");
                this.store.dispatch(new fromWorkorders.ClearSalesTechVariablesAction());
            }
        });
        this.store.dispatch(new fromWorkorders.LoadWorkOrderAction(this.workOrder.wor_workorderID));
    }

    get sendText() {
        return !!(this.statusTextMessageAble && this.send_text_confirmed);
    }

    get statusTextMessageAble() {
        return !!(this.profile.permissions.send_customer_texts && this.statusAutoSendText);
    }

    get statusAutoSendText() {
        if (!this.selectedStatus || this.selectedStatus.wos_statusID != this.selectedStatusID) {
            this.statuses.forEach((status) => {
                if (status.wos_statusID == this.selectedStatusID) {
                    this.selectedStatus = status;
                    return;
                }
            });
        }
        return !!(this.selectedStatus.wos_autosend);
    }

    closedPopup(event) {
        this.showPopup = false;
        this.employees = false;

        if (this.route.snapshot.queryParamMap.has('returnUrl')) {
            this.router.navigate([this.route.snapshot.queryParamMap.get('returnUrl')]);
        }
    }

    closeCarfaxPopup(event) {
        this.showCarfaxPopup = false;
    }

    getSalesAndTechs() {
        const salesManOnWorkOrder = [];
        const techsOnWorkOrder = [];
        if (this.workOrder && this.workOrder.item_relations) {
            this.workOrder.item_relations.forEach((item) => {
                salesManOnWorkOrder.push(item.woi_sales1);
                salesManOnWorkOrder.push(item.woi_sales2);
                techsOnWorkOrder.push(item.woi_tech1);
                techsOnWorkOrder.push(item.woi_tech2);
            });
        }
        this.getEmployeeNamesForSalesAndTech(salesManOnWorkOrder, techsOnWorkOrder);
    }

    getEmployeesForAssignment() {
        this.store.dispatch(new fromWorkorders.GetEmployeesForAssignmentAction({'locID': this.locID}));
        this.store.select(fromRoot.getEmployeesForAssignment).pipe(skipWhile(employees => !employees), take(1), ).subscribe((employees) => {
            this.employees = employees;
            this.getSalesAndTechs();
        });
    }

    getEmployeeNamesForSalesAndTech(salesMen, technicians) {
        let salesNames = '';
        let techNames = '';
        const alreadyListed = [];
        this.employees.forEach((employee) => {
            if (!alreadyListed.includes(employee.emp_employeeID)) {
                if (salesMen.includes(employee.emp_employeeID)) {
                    const beginning = salesNames ? ', ' : ' ';
                    alreadyListed.push(employee.emp_employeeID);
                    salesNames += beginning + employee.emp_fname + ' ' + employee.emp_lname;
                } else if (technicians.includes(employee.emp_employeeID)) {
                    const beginning = techNames ? ', ' : ' ';
                    alreadyListed.push(employee.emp_employeeID);
                    techNames += beginning + employee.emp_fname + ' ' + employee.emp_lname;
                }
            }
        });

        this.salesTech.sales_name = salesNames || 'Not Assigned';
        this.salesTech.tech_name = techNames || 'Not Assigned';
    }

    dispatchForFreshWorkOrder() {
        this.store.dispatch(new fromWorkorders.LoadWorkOrderAction(this.workOrder.wor_workorderID));
    }

    checkIfLocationUsesCarfax() {
        if (this.profile && this.profile.location && this.profile.location.settings) {
            return this.profile.location.settings.loc_carfax_agreement_signed;
        }
    }

    openStatusChangeConfirmMessageModal() {
        this.work_order_status_change_modal.open();
    }

    closeStatusChangeConfirmMessageModal() {
        this.work_order_status_change_modal.close();
    }
}
