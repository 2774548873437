import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Route, Router} from '@angular/router';

@Component({
    selector: 'app-bay-scheduler-iframe',
    templateUrl: './bay-scheduler-iframe.component.html',
    styleUrls: ['./bay-scheduler-iframe.component.scss']
})
export class BaySchedulerIframeComponent implements OnInit {
    public returnUrl: any = false;
    public apptID: any = false;
    constructor(private router: Router, private route: ActivatedRoute) { }

    ngOnInit() {
        if (this.route.snapshot.queryParamMap.has('returnUrl')) {
            this.returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
        }
        if (this.route.snapshot.queryParamMap.has('appointmentID')) {
            this.apptID = this.route.snapshot.queryParamMap.get('appointmentID');
        }
    }

    getSource() {
        let src = '/bc/scheduler?template=body';

        if (this.apptID) {
            src += '&appointmentID=' + this.apptID;
        }

        return src;
    }

    redirect() {
        this.router.navigate([this.returnUrl]);
    }

}
