
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Store} from "@ngrx/store";

import * as fromPurchaseOrders from '../../../store/actions/purchase-orders.actions';

@Injectable()
export class PurchaseOrdersService {

    private readonly urlOld: string;
    private readonly purchaseOrdersUrl: string;

    constructor(private http: HttpClient, private store: Store<any>) {
        this.urlOld = environment.tireguruBaseUrl;
        this.purchaseOrdersUrl = environment.tireguruBaseUrl + '/app/purchase-orders'
    }


    getLocationPurchaseOrders(payload) {
        return this.http.get(this.purchaseOrdersUrl).toPromise();
    }

    getPurchaseOrder(poID) {
        const url = `${this.purchaseOrdersUrl}/purchase-order/${poID}`;
        return this.http.get(url).pipe(map((response: any) => response.response)).toPromise();
    }

    getPurchaseOrderVerifications(poID) {
        const url = `${this.purchaseOrdersUrl}/purchase-order/${poID}/verifications`;
        return this.http.get(url).pipe(map((response: any) => response.response)).toPromise();
    }

    updatePurchaseOrderItemVerification(poID, poiID, qty, itemID) {
        const url = `${this.purchaseOrdersUrl}/purchase-order/${poID}/verifications/item`;

        return this.http.patch(url, {
            "purchaseItemID": poiID,
            qty,
            itemID
        }).toPromise();
    }

    updatePurchaseOrderItemVerificationByBarcode(poID, barcode, qty) {
        const url = `${this.purchaseOrdersUrl}/purchase-order/${poID}/verifications/item/barcode`;

        return this.http.patch(url, {
            "barcode": barcode,
            qty
        }).toPromise();
    }

    canDeactivate(component, currentRoute, currentState, nextState) {
        if (!(nextState.url.match('/purchase-orders*'))) {
            this.store.dispatch(new fromPurchaseOrders.ClearEntireFullPurchaseOrderState());
        }
        return true;
    }
}
