
import {catchError,  switchMap, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {createEffect, Actions, ofType} from '@ngrx/effects';

import {Observable, of, from as fromPromise} from 'rxjs';





import * as note from '../actions/workordernote.actions';

import {WorkorderNoteService} from '../../core/services/workorder-note.service';
import {Store} from '@ngrx/store';
import {Router} from '@angular/router';

@Injectable()
export class WorkorderNoteEffects {


    loadNotes: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(note.LOAD_NOTES),
        map((action: note.LoadNotesAction) => action.payload),
        switchMap((payload) => fromPromise(this.workorderNoteService.getNotes(payload.compID, payload.wor_workorderID)).pipe(
            map((notes) => new note.LoadNotesSuccessfulAction(notes)),
            catchError((error) => of(new note.LoadNotesFailedAction(error)))
        ))
    ));


    CreateNote: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(note.CREATE_NOTE),
        map((action: note.CreateNoteAction) => action.payload),
        switchMap((payload) => fromPromise(this.workorderNoteService.createNote(payload.body, payload.wor_workorderID)).pipe(
            map((notes) => new note.CreateNoteSuccessfulAction(notes)),
            catchError((error) => of(new note.CreateNoteFailedAction(error)))
        ))
    ));


    EditNote: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(note.UPDATE_NOTE),
        map((action: note.UpdateNoteAction) => action.payload),
        switchMap((payload) => fromPromise(this.workorderNoteService.updateNote(payload.body, payload.woi_workitemID)).pipe(
            map((notes) =>  new note.UpdateNoteSuccessfulAction(notes)),
            catchError((error) => of(new note.CreateNoteFailedAction(error)))
        ))
    ));


    DeleteNote: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(note.DELETE_NOTE),
        map((action: note.DeleteNoteAction) => action.payload),
        switchMap((payload) => fromPromise(this.workorderNoteService.deleteNote(payload.woi_workitemID)).pipe(
            map((notes) =>  new note.DeleteNoteSuccessfulAction(notes)),
            catchError((error) =>  of(new note.DeleteNoteFailedAction(error)))
        ))
    ));

    constructor(
        private $actions: Actions,
        private workorderNoteService: WorkorderNoteService,
        private store: Store<any>,
        private router: Router
    ) {
    }
}
