
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class ItemBarcodesService {
    private readonly urlOld: string;

    constructor(private http: HttpClient) {
        this.urlOld = environment.tireguruBaseUrl + '/app';
    }

    getSingleItemBarcode(itemID, qty) {
        let url = `${this.urlOld}/barcode/${itemID}/print`;

        if (qty) {
            url += `?qty=${qty}`;
        }

        return this.http.get(url).toPromise();
    }

    searchForItemByCode(code) {
        const url = `${this.urlOld}/items/search/${code}/code`;

        return this.http.get(url).pipe(
            map((response: any) => response.response))
            .toPromise();
    }
}
