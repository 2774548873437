import { Component, OnInit, Input } from '@angular/core';
import { Constants } from '../../../core/constants/constants';

@Component({
    selector: 'app-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {

    @Input() name: string;
    @Input() fill: string;

    constructor() {}

    ngOnInit() {
        // Default fill if none set
        this.fill = this.fill || '#232323';
    }

}
