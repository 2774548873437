
import {map} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';


@Injectable()
export class ArcService {
    private arcCartUrl: string;
    private arcCartItemsUrl: string;
    private deleteFromArcCartUrl: string;
    private updateQtyUrl: string;
    private unlinkedUrl: string;
    private finalizeUrl: string;

    constructor(private http: HttpClient) {
        this.arcCartUrl = environment.tireguruBaseUrl + '/app/ajax/workorder/';
        this.arcCartItemsUrl = environment.tireguruBaseUrl + '/app/ajax/arc/cart/';
        this.deleteFromArcCartUrl = environment.tireguruBaseUrl + '/app/ajax/arc/cart/items/';
        this.updateQtyUrl = environment.tireguruBaseUrl + '/app/ajax/arc/cart/items/';
        this.unlinkedUrl = environment.tireguruBaseUrl + '/app/ajax/arc/cart/';
        this.finalizeUrl = environment.tireguruBaseUrl + '/app/arc/cart/';
    }

    getArcCart(workOrderId) {
        let url = `${this.arcCartUrl}` + workOrderId;
        url += '/arc/cart';
        return this.http.get(url).pipe(map((response: any) => response.data)).toPromise();
    }

    getArcCartItems(cartID) {
        const url = `${this.arcCartItemsUrl}` + cartID;

        return this.http.get(url).pipe(map((response: any) => response.data)).toPromise();
    }

    deleteItemFromCart(item) {
        const url = `${this.deleteFromArcCartUrl}` + item.id;

        return this.http.delete(url).toPromise();
    }

    updateItemQty(itemId, body) {
        const url = `${this.updateQtyUrl}` + itemId;

        return this.http.put(url, body).toPromise();
    }

    getArcUnlinkedPartsLaborCore(workOrderId) {
        const url = `${this.unlinkedUrl}` + workOrderId + "/missing-item-categories";

        return this.http.get(url).pipe(map((response: any) => response)).toPromise();
    }

    getUnlinkedVendors(workOrderId) {
        const url = `${this.unlinkedUrl}` + workOrderId + "/missing-vendors";

        return this.http.get(url).pipe(map((response: any) => response)).toPromise();
    }

    finalizeArc(cartId) {
        const url = `${this.finalizeUrl}` + cartId + "/finalize";
        const body = [];

        return this.http.post(url, body).toPromise();
    }

}
