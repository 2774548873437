import { Action as NgrxAction } from '@ngrx/store';

export const SET_RESPONSES = '[Forms] Set responses';
export const SET_FORM = '[Forms] Set Form';
export const SET_RESPONSE = '[Forms] Set Response';
export const DELETE_RESPONSE = '[Forms] Delete Response';
export const DELETE_IMAGE_ATTACHMENT = '[Forms] Delete attachment';
export const DELETE_IMAGE_ATTACHMENT_SUCCESSFUL = '[Forms] Delete attachment successful';
export const DELETE_IMAGE_ATTACHMENT_FAILED = '[Forms] Delete attachment failed';
export const SET_ATTACHMENTS = '[Forms] Set Attachments';
export const SET_ATTACHMENT = '[Forms] Set attachment';
export const SET_ADDING_ITEMS = '[Forms] Set adding items';
export const FINISHED_ADDING_ITEMS = '[Forms] Finsihed adding items';
export const CLEAR_ADDING_ITEMS = '[Forms] clear adding items';
export const LOAD_CUSTOMER_FORM_SUBMISSIONS = '[Forms] Load customer form submissions';
export const LOAD_CUSTOMER_FORM_SUBMISSIONS_SUCCESSFUL = LOAD_CUSTOMER_FORM_SUBMISSIONS + ' successful';
export const LOAD_SLUG_JOB_CATEGORY_RELATIONS = '[forms] Load job category relations';
export const LOAD_SLUG_JOB_CATEGORY_RELATIONS_SUCCESSFUL = '[forms] Load job category relations successful';
export const LOAD_SLUG_JOB_CATEGORY_RELATIONS_FAILED = '[forms] Load job category relations failed';
export const BACK_TO_INSPECTION = '[forms] back to inspection';
export const GET_INSPECTION_INFO = '[forms] get inspection info';
export const GET_INSPECTION_INFO_SUCCESSFUL = '[forms] get inspection info successful';
export const GET_INSPECTION_INFO_FAILED = '[forms] get inspection info failed';
export const CLEAR_STATE = '[form] clear state';

export class SetResponsesAction implements NgrxAction {
    readonly type = SET_RESPONSES;

    constructor(public payload: any) {
    }
}

export class SetFormAction implements NgrxAction {
    readonly type = SET_FORM;

    constructor(public payload: any) {
    }
}

export class SetResponseAction implements NgrxAction {
    readonly type = SET_RESPONSE;

    constructor(public payload: any) {
    }
}

export class DeleteResponseAction implements NgrxAction {
    readonly type = DELETE_RESPONSE;

    constructor(public payload: any) {
    }
}

export class SetAttachmentsAction implements NgrxAction {
    readonly type = SET_ATTACHMENTS;

    constructor(public payload: any) {
    }
}

export class SetAttachmentAction implements NgrxAction {
    readonly type = SET_ATTACHMENT;

    constructor(public payload: any) {
    }
}

export class DeleteImageAttachmentAction implements NgrxAction {
    readonly type = DELETE_IMAGE_ATTACHMENT;

    constructor(public payload: any) {
    }
}

export class DeleteImageAttachmentActionSuccessful implements NgrxAction {
    readonly type = DELETE_IMAGE_ATTACHMENT_SUCCESSFUL;

    constructor(public payload: any) {
    }
}

export class DeleteImageAttachmentActionFailed implements NgrxAction {
    readonly type = DELETE_IMAGE_ATTACHMENT_FAILED;

    constructor(public payload: any) {
    }
}

export class SetAddingItemsAction implements NgrxAction {
    readonly type = SET_ADDING_ITEMS;

    constructor(public payload: any) {
    }
}

export class FinishedAddingItemsAction implements NgrxAction {
    readonly type = FINISHED_ADDING_ITEMS;

    constructor(public payload: any) {
    }
}

export class ClearAddingItemsAction implements NgrxAction {
    readonly type = CLEAR_ADDING_ITEMS;
}

export class LoadCustomerFormSubmissionsAction implements NgrxAction {
    readonly type = LOAD_CUSTOMER_FORM_SUBMISSIONS;

    constructor(public payload: {customerId: number; vehicleId: number; page?: number}) {
    }
}

export class LoadCustomerFormSubmissionsSucessfulAction implements NgrxAction {
    readonly type = LOAD_CUSTOMER_FORM_SUBMISSIONS_SUCCESSFUL;

    constructor(public payload: any) {
    }
}

export class LoadSlugJobCategoryRelationsAction implements NgrxAction {
    readonly type = LOAD_SLUG_JOB_CATEGORY_RELATIONS;

    constructor(public payload: number) {
    }
}

export class LoadSlugCategoryRelationsSuccessfulAction implements NgrxAction {
    readonly type = LOAD_SLUG_JOB_CATEGORY_RELATIONS_SUCCESSFUL;

    constructor(public payload: any) {}
}

export class LoadSlugCategoryRelationsFailedAction implements NgrxAction {
    readonly type = LOAD_SLUG_JOB_CATEGORY_RELATIONS_FAILED;

    constructor(public payload: any) {}
}

export class BackToInspectionAction implements NgrxAction {
    readonly type = BACK_TO_INSPECTION;

    constructor(public payload: any) {}
}

export class GetInspectionInfoAction implements NgrxAction {
    readonly type = GET_INSPECTION_INFO;

    constructor(public payload: any) {}
}

export class GetInspectionInfoSuccessfulAction implements NgrxAction {
    readonly type = GET_INSPECTION_INFO_SUCCESSFUL;

    constructor(public payload: any) {}
}

export class GetInspectionInfoFailedAction implements NgrxAction {
    readonly type = GET_INSPECTION_INFO_FAILED;

    constructor(public payload: any) {}
}

export class ClearStateAction implements NgrxAction {
    readonly type = CLEAR_STATE;

    constructor() {
    }
}

export type Action = SetResponsesAction
| SetFormAction
| SetResponseAction
| DeleteResponseAction
| SetAttachmentsAction
| SetAttachmentAction
| DeleteImageAttachmentAction
| DeleteImageAttachmentActionSuccessful
| DeleteImageAttachmentActionFailed
| SetAddingItemsAction
| FinishedAddingItemsAction
| LoadCustomerFormSubmissionsAction
| LoadCustomerFormSubmissionsSucessfulAction
| LoadSlugJobCategoryRelationsAction
| LoadSlugCategoryRelationsSuccessfulAction
| LoadSlugCategoryRelationsFailedAction
| BackToInspectionAction
| GetInspectionInfoAction
| GetInspectionInfoSuccessfulAction
| GetInspectionInfoFailedAction
| ClearStateAction;
