import { Action as NgrxAction } from '@ngrx/store';

export const SEARCH = '[Search] search';
export const SEARCH_TYPE = '[Search] search info';
export const SEARCH_SUCCESSFUL = '[Search] search successful';
export const SEARCH_FAILED = '[Search] search failed';
export const SEARCH_CLEAR = '[Search] clear';

export class SearchAction implements NgrxAction {
    readonly type = SEARCH;

    constructor(public payload: string) {
    }
}

export class SearchTypeAction implements NgrxAction {
    readonly type = SEARCH_TYPE;

    constructor(public payload: string) {
    }
}

export class SearchSuccessfulAction implements NgrxAction {
    readonly type = SEARCH_SUCCESSFUL;

    constructor(public payload: Array<any>) {
    }
}

export class SearchFailedAction implements NgrxAction {
    readonly type = SEARCH_FAILED;

    constructor(public payload: any) {
    }
}

export class SearchClearAction implements NgrxAction {
    readonly type = SEARCH_CLEAR;

    constructor() {
    }
}

export type Action = SearchAction
| SearchFailedAction
| SearchSuccessfulAction
| SearchClearAction
| SearchTypeAction;
