import { Component, OnInit } from '@angular/core';


import { ProfileProvider } from './core/providers/profile.provider';
import { Router, NavigationEnd } from '@angular/router';
import { ProfileService } from './core/services/profile.service';
import * as fromWorkorders from './store/actions/workorders.actions';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    title = 'app';
    public profile?: any;

    constructor(
        private profileService: ProfileService,
        private profileProvider: ProfileProvider,
        private router: Router
    ) {
        this.profileProvider.getProfile().subscribe(response => {
            this.profile = response;
            if (this.profile && this.profile.location && this.profile.location.settings) {
                const themeName = this.profile.location.settings.loc_mobile_theme;
                const themePrimaryColor = this.profile.location.settings.loc_mobile_primary_color;
                this.setTheme(themeName, themePrimaryColor);
            } else {
                this.resetTheme();
            }
        });
    }

    ngOnInit() {
        this.getProfileFromAPI();

        this.router.routeReuseStrategy.shouldReuseRoute = () => false;

        this.router.events.subscribe((evt) => {
            if (evt instanceof NavigationEnd) {
                this.router.navigated = false;
                window.scrollTo(0, 0);
            }
        });
    }

    get hasProfile() {
        return typeof this.profile === 'object' && Object.keys(this.profile).length && this.profile.use_userID;
    }


    getProfileFromAPI() {
    // Get profile from API
        return this.profileService.getProfile().then((response: any) => {
            this.profileProvider.setProfile(response.data);
        }).catch(error => {
        });
    }

    setTheme(themeName, themePrimaryColor) {
        const body = window.document.querySelector('body');
        if (themePrimaryColor && themePrimaryColor.length !== 0) {
            let primaryColorHexCode = themePrimaryColor.trim();
            if (primaryColorHexCode.charAt(0) !== '#') {
                primaryColorHexCode = `#${themePrimaryColor.trim()}`;
            }
            const primaryDarker = this.profileProvider.lightenDarkenColor(primaryColorHexCode, -12);
            const primaryLighter = this.profileProvider.lightenDarkenColor(primaryColorHexCode, 12);
            body.style.setProperty('--theme-primary-color', primaryColorHexCode);
            body.style.setProperty('--theme-primary-darker-color', primaryDarker);
            body.style.setProperty('--theme-primary-lighter-color', primaryLighter);
        }
        body.classList.add(themeName);
    }

    resetTheme() {
        const body = window.document.querySelector('body');
        body.style.removeProperty('--theme-primary-color');
        body.style.removeProperty('--theme-primary-darker-color');
        body.style.removeProperty('--theme-primary-lighter-color');
        if (body.classList.contains('light')) {
            body.classList.remove('light');
        }
    }


}
