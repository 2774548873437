
import {take, catchError, map, switchMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {createEffect, Actions, ofType} from '@ngrx/effects';

import {Observable, of, from as fromPromise} from 'rxjs';




import {Store} from '@ngrx/store';
import * as fromRoot from '../reducers';

import * as vehicleMotorData from '../actions/vehiclemotordata.actions';
import {VehicleMotorDataService} from '../../core/services/vehicle-motor-data.service';

@Injectable()
export class VehiclemotordataEffects {


    getVehicleMotorData: Observable<vehicleMotorData.Action> = createEffect(() => this.$actions.pipe(
        ofType(vehicleMotorData.LOAD_VEHICLE_MOTOR_DATA),
        map((action: vehicleMotorData.LoadVehicleMotorDataAction) => action.payload),
        switchMap((payload) =>
            this.store.select(fromRoot.getCurrentVehicleMotorData).pipe(take(1)).pipe(
                switchMap((motor_data: any) => {
                    if (motor_data) {
                        return of(motor_data);
                    }
                    return fromPromise(this.vehicleMotorDataService.getMotorData(payload.veh_id));
                })
            ).pipe(
                map((motor_data: any) => new vehicleMotorData.LoadVehicleMotorDataSuccessfulAction(motor_data)),
                catchError((error) => of(new vehicleMotorData.LoadVehicleMotorDataFailedAction({error: error})))
            ))));


    getVehicleMotorDataSpecs: Observable<vehicleMotorData.Action> = createEffect(() => this.$actions.pipe(
        ofType(vehicleMotorData.LOAD_VEHICLE_MOTOR_DATA_SPECS),
        map((action: vehicleMotorData.LoadVehicleMotorDataSpecsAction) => action.payload),
        switchMap((payload) =>
            fromPromise(this.vehicleMotorDataService.getVehicleSpecs(payload.veh_id)).pipe(
                map((motor_data_specs: any) => new vehicleMotorData.LoadVehicleMotorDataSpecsSuccessfulAction(motor_data_specs)),
                catchError((error) => of(new vehicleMotorData.LoadVehicleMotorDataSpecsFailedAction({error: error}))))
        )));


    getVehicleMotorDataItemsAvailable: Observable<vehicleMotorData.Action> = createEffect(() => this.$actions.pipe(
        ofType(vehicleMotorData.LOAD_VEHICLE_MOTOR_DATA_ITEMS_AVAILABLE),
        map((action: vehicleMotorData.LoadVehicleMotorDataItemsAvailableAction) => action.payload),
        switchMap((payload) =>
            fromPromise(this.vehicleMotorDataService.getItemsAvailable(payload.type, payload.vehicleID, payload.work_orderID)).pipe(
                map((motor_data_items_available: any) => new vehicleMotorData.LoadVehicleMotorDataItemsAvailableSuccessfulAction(motor_data_items_available)),
                catchError((error) => of(new vehicleMotorData.LoadVehicleMotorDataItemsAvailableFailedAction({error: error}))))
        )));


    getVehicleMotorDataEngineTypes: Observable<vehicleMotorData.Action> = createEffect(() => this.$actions.pipe(
        ofType(vehicleMotorData.LOAD_VEHICLE_MOTOR_DATA_ENGINE_TYPES),
        map((action: vehicleMotorData.LoadVehicleMotorDataEngineTypes) => action.payload),
        switchMap((payload) =>
            fromPromise(this.vehicleMotorDataService.getEngineTypes(payload.veh_id)).pipe(
                map((engine_types: any) =>  new vehicleMotorData.LoadVehicleMotorDataEngineTypesSuccessful(engine_types)),
                catchError((error) => of(new vehicleMotorData.LoadVehicleMotorDataEngineTypesFailed({error: error}))))
        )));


    constructor(
        private $actions: Actions,
        private store: Store<fromRoot.State>,
        private vehicleMotorDataService: VehicleMotorDataService
    ) {
    }
}
