import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';
import { Router } from '@angular/router';

@Injectable()
export class CreditCardProcessingService {

    private urlApi: string;
    private urlOld: string;

    constructor(
        private http: HttpClient,
        private router: Router,
    ) {
        this.urlApi = environment.tireguruBaseApi;
        this.urlOld = environment.tireguruBaseUrl;
    }

    get360Terminals() {
        const url = `${this.urlApi}/360/terminals`;


        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        const options = { headers };
        return this.http.get(url, options).toPromise();
    }

    get360TerminalByID(terminal_id) {
        const url = `${this.urlApi}/360/terminal/${terminal_id}`;


        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        const options = { headers };
        return this.http.get(url, options).toPromise();
    }

    pairTerminal(terminal) {
        const url = `${this.urlApi}/360/pair-terminal`;
        const { name, serial_number, contact_name, reseller_name, default_users, require_signature } = terminal;

        const payload = {
            'Name': name,
            "TerminalSerialNumber": serial_number,
            "ContactName": contact_name,
            "ResellerName": reseller_name,
            "UserDefaults": default_users,
            "AllowSignature": require_signature
        };

        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        const options = { headers };
        return this.http.post(url, payload, options).toPromise();
    }


    unPairTerminal(terminal) {
        const url = `${this.urlApi}/360/unpair-terminal/${terminal.terminal_id}`;

        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        const options = { headers };
        return this.http.post(url, options).toPromise();
    }

}
