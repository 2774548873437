import {switchMap, map, catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {createEffect, Actions, ofType} from '@ngrx/effects';

import {Observable, of, from as fromPromise} from 'rxjs';

import * as purchase_orders from '../actions/purchase-orders.actions';

import {PurchaseOrdersService} from '../../core/services/purchase-orders/purchase-orders.service';

@Injectable()
export class PurchaseOrdersEffects {

    getPurchaseOrdersWithVerifications$: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(purchase_orders.GET_LOCATION_PURCHASE_ORDERS),
        map((action: purchase_orders.GetLocationPurchaseOrdersAction) => action.payload),
        switchMap((payload) =>
            fromPromise(this.purchaseOrdersService.getLocationPurchaseOrders(payload)).pipe(
                map((response) => new purchase_orders.GetLocationPurchaseOrdersSuccessfulAction(response)),
                catchError((error) => of(new purchase_orders.GetLocationPurchaseOrdersFailedAction(error)))
            )
        )
    ));


    getPurchaseOrder$: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(purchase_orders.GET_PURCHASE_ORDER),
        map((action: purchase_orders.GetPurchaseOrderAction) => action.payload),
        switchMap((payload) =>
            fromPromise(this.purchaseOrdersService.getPurchaseOrder(payload.poID)).pipe(
                map((response) => new purchase_orders.GetPurchaseOrderSuccessfulAction(response)),
                catchError((error) => of(new purchase_orders.GetPurchaseOrderFailedAction(error)))
            )
        )
    ));


    getPurchaseOrderVerifications$: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(purchase_orders.GET_PURCHASE_ORDER_VERIFICATIONS),
        map((action: purchase_orders.GetPurchaseOrderVerificationsAction) => action.payload),
        switchMap((payload) =>
            fromPromise(this.purchaseOrdersService.getPurchaseOrderVerifications(payload.poID)).pipe(
                map((response) => new purchase_orders.GetPurchaseOrderVerificationsSuccessfulAction(response)),
                catchError((error) => of(new purchase_orders.GetPurchaseOrderVerificationsFailedAction(error)))
            )
        )
    ));


    updatePurchaseOrderItemVerification$: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(purchase_orders.UPDATE_PURCHASE_ORDER_ITEM_VERIFICATION),
        map((action: purchase_orders.UpdatePurchaseOrderItemVerificationAction) => action.payload),
        switchMap((payload) =>
            fromPromise(this.purchaseOrdersService.updatePurchaseOrderItemVerification(payload.poID, payload.poiID, payload.qty, payload.itmID)).pipe(
                map((response) => new purchase_orders.UpdatePurchaseOrderItemVerificationSuccessfulAction(response)),
                catchError((error) => of(new purchase_orders.UpdatePurchaseOrderItemVerificationFailedAction(error)))
            )
        )
    ));


    updatePurchaseOrderItemVerificationByBarcode$: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(purchase_orders.UPDATE_PURCHASE_ORDER_ITEM_VERIFICATION_BARCODE),
        map((action: purchase_orders.UpdatePurchaseOrderItemVerificationByBarcodeAction) => action.payload),
        switchMap((payload) =>
            fromPromise(this.purchaseOrdersService.updatePurchaseOrderItemVerificationByBarcode(payload.poID, payload.barcode, payload.qty)).pipe(
                map((response) => new purchase_orders.UpdatePurchaseOrderItemVerificationByBarcodeSuccessfulAction(response)),
                catchError((error) => of(new purchase_orders.UpdatePurchaseOrderItemVerificationByBarcodeFailedAction(error)))
            )
        )
    ));

    constructor(
        private $actions: Actions,
        private purchaseOrdersService: PurchaseOrdersService
    ) {
    }

}


