<header class="app-header justify-space-between">
  <a [routerLink]="['']" class="nav-item nav-brand has-text-left" *ngIf="profile?.location?.settings">
    <img *ngIf="logoUrl.length > 0" class="nav-brand__image" src="{{ logoUrl }}" alt="{{profile.location.loc_name}}">
  </a>
  <div class="app-header-messages-container" [routerLink]="['/messaging-center']">
    <div class="app-header-messages-badge" *ngIf="numberOfUnreadMessages && numberOfUnreadMessages > 0">{{numberOfUnreadMessages}}</div>
    <a class="btn is-primary app-header-messages-button">Messages</a>
  </div>
  <div *ngIf="!isProfileEmpty" class="nav-item has-text-right">
    <div class="nav-item__user-info">
      {{ firstName }} {{ lastName }}
    </div>

    <div>
      <a (click)="logout()">Logout</a>
    </div>

    <div class="global-search-position" *ngIf="permissionToUseGlobalSearch">
      <div class="global-search-div">
        <div>
          <app-global-search></app-global-search>
        </div>
      </div>
    </div>

  </div>
</header>
