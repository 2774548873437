import { Injectable } from '@angular/core';
import { Observable ,  BehaviorSubject } from 'rxjs';
import { Constants } from '../constants/constants';
import {State} from '../../store/reducers';
import {Store} from '@ngrx/store';

import * as profileActions from '../../store/actions/profile.actions';

@Injectable()
export class ProfileProvider {

    private _profileSource = new BehaviorSubject<object>({});
    private profile : Observable<any>;

    constructor(private store: Store<State>) {
        this.profile = this._profileSource.asObservable();
    }

    setProfile(data) {
        data.iconColor = this.getIconColor(data);
        this.store.dispatch(new profileActions.SetProfileAction(data));
        this._profileSource.next(data);
    }

    getProfile() {
        return this.profile;
    }

    getIconColor(profile) {
        // If no profile is set, default to white on black
        if (!profile || Object.keys(profile).length === 0) {
            return '#333333';
        }
        const theme = profile.location.settings.loc_mobile_theme;
        // Get theme and set colors for icons appropriately
        if (theme === 'dark') {
            return '#FFFFFF';
        }
        return '#333333';
    }

    lightenDarkenColor(col, amt) {

        let usePound = false;

        if (col[0] === '#') {
            col = col.slice(1);
            usePound = true;
        }

        const num = parseInt(col, 16);

        let r = (num >> 16) + amt;

        if (r > 255) {
            r = 255;
        } else if (r < 0) {
            r = 0;
        }

        let b = ((num >> 8) & 0x00FF) + amt;

        if (b > 255) {
            b = 255;
        } else if (b < 0) {
            b = 0;
        }

        let g = (num & 0x0000FF) + amt;

        if (g > 255) {
            g = 255;
        } else if (g < 0) {
            g = 0;
        }

        // return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
        return (usePound ? '#' : '') + String('000000' + (g | (b << 8) | (r << 16)).toString(16)).slice(-6);


    }

}
