<div class="panel__body">

  <div class="columns flexNoWrap">
    <div class="column flexNoWrap">
      <button (click)="filterOutRead(false)" class="btn {{viewAll === 'true' ? 'is-unselected' : 'is-primary'}}"><b>View All</b></button>
      <button (click)="filterOutRead(true)" class="btn {{viewAll === 'true' ? 'is-primary' : 'is-unselected'}}"><b>Unread</b></button>
    </div>

    <div class="column is-4 flexNoWrap">
      <label>
        <input class="btn search" placeholder="Search" [(ngModel)]="searchTerm" type="search">
      </label>

      <button class="btn is-sm is-primary">
        <app-icon class="icon" [name]="'search'" fill="#ffffff"></app-icon>
      </button>
    </div>

  </div>

  <div>
    <app-list-messages-center-messages *ngIf="messages && !loading" [messages]="messages" [type]="'customer-text'"
                                       [numberOfPages]="numberOfPages" (markAsRead)="markAsRead($event)"
                                       (toMessages)="goToMessage($event)"
    >

    </app-list-messages-center-messages>
  </div>

  <div class="pageButtons" *ngIf="messages?.length">
    <button class="btn is-primary is-sm pageButton" *ngIf="numberOfPages > 1 && pageNum !== 1" (click)="backPage()">Prev</button>

    <div class="pageButton clickable" (click)="backPage()" *ngIf="numberOfPages > 1 && pageNum !== 1"><b>{{pageNum -1}}</b></div>
    <div class="pageButton btn is-sm is-primary"><b class="selectedPage">{{pageNum}}</b></div>
    <div class="pageButton clickable" (click)="nextPage()" *ngIf="numberOfPages > 1 && pageNum < numberOfPages"><b>{{pageNum +1}}</b></div>

    <button class="btn is-primary is-sm pageButton" *ngIf="numberOfPages > 1 && pageNum < numberOfPages" (click)="nextPage()">
      Next
    </button>
  </div>

</div>
