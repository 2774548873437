import {Component, OnInit, Input, ViewChild, ElementRef} from '@angular/core';

@Component({
    selector: 'app-spinner-loading',
    templateUrl: './spinner-loading.component.html',
    styleUrls: ['./spinner-loading.component.scss']
})
export class SpinnerLoadingComponent implements OnInit {

    @Input() isInverted: boolean;
    @Input() height: any = false;

    @ViewChild('sk_circle_loader') sk_circle_loader: ElementRef;

    constructor() {
    }

    ngOnInit() {
        if (this.height) {
            this.sk_circle_loader.nativeElement.style.height = this.height + 'px';
        }
    }

}
