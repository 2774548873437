<app-panel>
  <app-panel-header>
    Mobile Bay Portal
  </app-panel-header>
  <app-panel-body>
    <div class="panel__body">
      <app-iframe [src]="'/app/scheduler/mobile-bays/technician-portal'" [theme]="'light'" [minHeight]="1000" [maxHeight]="1000" ></app-iframe>
    </div>
  </app-panel-body>
</app-panel>




