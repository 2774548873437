import { Injectable } from '@angular/core';

export const PROFILE_DEFAULT_PAGE_HOME = 'home';
export const PROFILE_DEFAULT_PAGE_WORK_ORDER = 'work_order';

@Injectable()
export class Constants {

    static GENERAL = {
        VEHICLE: 'vehicle',
    };

    static FORM = {
        TYPES: {
            TEXT: 'text',
            NUMBER: 'number',
            RADIO: 'radio',
            CHECKBOX: 'checkbox',
            SELECT: 'select',
            TEXTAREA: 'textarea',
            DATE: 'date',
        },
        SECTION: {
            TEST: 'test',
            TALL: 'test',
        }
    };

    static STATUS = {
        SUCCESS:  'success',
        ERROR: 'error',
    };

    static JOBS = {
        TIRE: 1
    };

    static COLORS: {
        WHITE: '#FFFFFF',
        BLACK: '#000000',
        BLACK_BIS: '#111111',
        BLACK_TER: '#232323',
        GREY: '#7A7A7A',
        GREY_DARKEST: '#333333',
    };

    static EMPLOYEES: {
        SALESPERSON: 24;
        TECHNICIAN: 25;
        MANAGEMENT: 26;
        CLERICAL: 27;
        DRIVER: 28;
        WAREHOUSE: 29;
        LUBETECH: 30;
        TIRETECH: 31;
    };

    getSectionClassName(slug) {
        switch (slug) {
            case Constants.FORM.SECTION.TEST:
            return 'test';
            case Constants.FORM.SECTION.TALL:
            return 'test';
            default:
            return '';
        }
    }

    getQuestionClassName(slug) {
        switch (slug) {
            case Constants.FORM.SECTION.TEST:
            return 'test';
            case Constants.FORM.SECTION.TALL:
            return 'test';
            default:
            return '';
        }
    }
}
