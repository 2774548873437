import { Injectable } from '@angular/core';
import * as io from 'socket.io-client';
import Echo from 'laravel-echo';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../store/reducers';
import * as WorkOrderMessagesActions from '../../store/actions/workordermessages.actions';
import {getUnreadWorkOrderMessages} from '../../store/reducers';
import {getProfile} from '../../store/reducers';
import {State} from '../../store/reducers';
import {environment} from '../../../environments/environment';
import * as fromCustComms from '../../store/actions/customerCommunication.actions';
import {AlertProvider} from '../providers/alert.provider';
import {skipWhile, take} from 'rxjs/operators';

@Injectable()
export class EchoServiceService {

    private userID: number;
    private server: string;
    private locID: string;

    constructor(
        private store: Store<fromRoot.State>,
        private alertProvider: AlertProvider,
    ) {
        this.store.select(getProfile).pipe(skipWhile(result => !result), take(1)).subscribe((response) => {
            this.userID = response.use_userID;
            this.server = response.use_server;

            if (typeof response.location !== 'undefined') {
                this.locID = response.location.loc_locID;
            }

            if (!isNaN(this.userID) && this.server != null && typeof this.server != 'undefined') {
                this.connectToSocket();
                this.store.dispatch(new WorkOrderMessagesActions.GetWorkOrderMessages({'userID': this.userID}));
                this.store.dispatch(new WorkOrderMessagesActions.GetUsersForMessage({'locationID': this.locID}));
            }
        });
    }

    connectToSocket() {
        window['io'] = io;

        window['Echo'] = new Echo({
            broadcaster: 'socket.io',
            host: environment.echoUrl,
            transports: ['polling'],
            namespace: 'TireGuru.LaravelUtilities.Models.Pro.Events'
        });

        window['Echo'].channel('WorkOrderMessageCreated.' + this.server + '.' + this.userID)
            .listen('WorkOrderMessageCreated', (message) => {
                this.store.dispatch(new WorkOrderMessagesActions.MessageReceived({message}));
                this.playAudio();
            });

        window['Echo'].channel('TextMessageReceived.' + this.server + '.' + this.locID)
            .listen('TextMessageReceivedEvent', (message) => {
                this.store.dispatch(new fromCustComms.GetTextMessagesAction({'locID': this.locID}));
                this.alertProvider.addSuccess("You have a new text message!");
                this.playAudio();
            });
    }

    playAudio() {
        const audio = new Audio();
        audio.src = '/m/assets/audio/message_alert.mp3';
        audio.load();
        audio.play();
    }


}
