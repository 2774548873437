import { Component, OnInit, Input } from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../store/reducers';
import {ProfileProvider} from '../../../core/providers/profile.provider';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
    @Input() selected;
    @Input() orderDetailsSrc;

    public profile: any;

    public STATE = {
        ADD_ITEM: 'add-item',
        ADD_SERVICE: 'add-service',
        INSPECTION: 'inspection',
        SIGNATURE: 'signature',
        MILEAGE: 'mileage',
        PORTAL: 'portal',
    };

    constructor(
        private store: Store<fromRoot.State>,
        private profileProvider: ProfileProvider,
    ) {
        this.profileProvider.getProfile().subscribe(result => {
            this.profile = result;
        });
    }

    ngOnInit() {
    }
}
