import { Action as NgrxAction } from '@ngrx/store';

export const SET_VEHICLE = '[Vehicle] set vehicle';
export const LOAD_VEHICLE = '[Vehicle] Load Vehicle';
export const LOAD_VEHICLE_SUCCESSFUL = '[Vehicle] Load Vehicle successful';
export const LOADING_VEHICLE_FAILED = '[Vehicle] Load Vehicle failed';

export const LOAD_VEHICLES = '[Vehicle] Load Vehicles';
export const LOAD_VEHICLES_SUCCESSFUL = '[Vehicle] Load Vehicles successful';
export const LOADING_VEHICLES_FAILED = '[Vehicle] Load Vehicles failed';

export const DELETE_VEHICLE = '[Vehicle] Delete Vehicle';
export const DELETE_VEHICLE_SUCCESSFUL = '[Vehicle] Delete Vehicle successful';
export const DELETE_VEHICLE_FAILED = '[Vehicle] Delete Vehicle failed';

export const UPDATE_VEHICLE = '[Vehicle] Update Vehicle';
export const UPDATE_VEHICLE_SUCCESSFUL = '[Vehicle] Update Vehicle successful';
export const UPDATE_VEHICLE_FAILED = '[Vehicle] Update Vehicle failed';

export const CREATE_VEHICLE = '[Vehicle] Create Vehicle';
export const CREATE_VEHICLE_SUCCESSFUL = '[Vehicle] create Vehicle successful';
export const CREATE_VEHICLE_FAILED = '[Vehicle] Create Vehicle failed';

export const UPLOAD_VEHICLE_IMAGE = '[Vehicle] Upload vehicle image';
export const UPLOAD_VEHICLE_IMAGE_SUCCESSFUL = '[Vehicle} Upload vehicle image successful';
export const UPLOAD_VEHICLE_IMAGE_FAILED = '[Vehcile] Upload vehicle image failed';

export const CLEAR_VEHICLE_UPLOAD_STATE = '[Vehicle] clear upload state';

export const SET_VEHICLE_CARFAX = '[Vehicle] Set vehicle carfax';
export const GET_VEHICLE_CARFAX = '[Vehicle] Get vehicle carfax';
export const GET_VEHICLE_CARFAX_SUCCESSFUL = '[Vehicle] Get vehicle carfax successful';
export const GET_VEHICLE_CARFAX_FAILED = '[Vehicle] Get vehicle carfax failed';

export const DUPLICATE_VIN_LOOKUP = '[Vehicle] Duplicate VIN lookup';
export const DUPLICATE_VIN_LOOKUP_SUCCESSFUL = '[Vehicle] Duplicate VIN lookup successful';
export const DUPLICATE_VIN_LOOKUP_FAILED = '[Vehicle] Duplicate VIN lookup failed';

export class SetVehicleAction implements NgrxAction {
    readonly type = SET_VEHICLE;

    constructor(public payload: any) {
    }
}

export class LoadVehicleAction implements NgrxAction {
    readonly type = LOAD_VEHICLE;

    constructor(public payload: any) {
    }

}

export class LoadVehicleSuccessfulAction implements NgrxAction {
    readonly type = LOAD_VEHICLE_SUCCESSFUL;

    constructor(public payload: any) {
    }

}

export class LoadVehicleFailedAction implements NgrxAction {
    readonly type = LOADING_VEHICLE_FAILED;

    constructor(public payload: any) {
    }
}

export class LoadVehiclesAction implements NgrxAction {
    readonly type = LOAD_VEHICLES;

    constructor(public payload: any) {
    }

}

export class LoadVehiclesSuccessfulAction implements NgrxAction {
    readonly type = LOAD_VEHICLES_SUCCESSFUL;

    constructor(public payload: any) {
    }

}

export class LoadVehiclesFailedAction implements NgrxAction {
    readonly type = LOADING_VEHICLES_FAILED;

    constructor(public payload: any) {
    }
}

export class DeleteVehicleAction implements NgrxAction {
    readonly type = DELETE_VEHICLE;

    constructor(public payload: any) {
    }

}

export class DeleteVehicleSuccessfulAction implements NgrxAction {
    readonly type = DELETE_VEHICLE_SUCCESSFUL;

    constructor(public payload: any) {
    }

}

export class DeleteVehicleFailedAction implements NgrxAction {
    readonly type = DELETE_VEHICLE_FAILED;

    constructor(public payload: any) {
    }
}

export class UpdateVehicleAction implements NgrxAction {
    readonly type = UPDATE_VEHICLE;

    constructor(public payload: any) {
    }

}

export class UpdateVehicleSuccessfulAction implements NgrxAction {
    readonly type = UPDATE_VEHICLE_SUCCESSFUL;

    constructor(public payload: any) {
    }

}

export class UpdateVehicleFailedAction implements NgrxAction {
    readonly type = UPDATE_VEHICLE_FAILED;

    constructor(public payload: any) {
    }
}

export class CreateVehicleAction implements NgrxAction {
    readonly type = CREATE_VEHICLE;

    constructor(public payload: any) {
    }

}

export class CreateVehicleSuccessfulAction implements NgrxAction {
    readonly type = CREATE_VEHICLE_SUCCESSFUL;

    constructor(public payload: any) {
    }

}

export class CreateVehicleFailedAction implements NgrxAction {
    readonly type = CREATE_VEHICLE_FAILED;

    constructor(public payload: any) {
    }
}

export class UploadVehicleImageAction implements NgrxAction {
    readonly type = UPLOAD_VEHICLE_IMAGE;

    constructor(public payload: {imageData: FormData; vehicleId: number}) {
    }
}

export class UploadVehicleImageSuccessfulAction implements NgrxAction {
    readonly type = UPLOAD_VEHICLE_IMAGE_SUCCESSFUL;

    constructor(public payload: any) {
    }
}

export class UploadVehicleImageFailedAction implements NgrxAction {
    readonly type = UPLOAD_VEHICLE_IMAGE_FAILED;

    constructor(public payload: any) {
    }
}

export class ClearUploadStateAction implements NgrxAction {
    readonly type = CLEAR_VEHICLE_UPLOAD_STATE;
    constructor(public payload: any) {
    }
}

export class SetVehicleCarfax implements NgrxAction {
    readonly type = SET_VEHICLE_CARFAX;

    constructor(public payload: any) {
    }
}

export class GetVehicleCarfax implements NgrxAction {
    readonly type = GET_VEHICLE_CARFAX;

    constructor(public payload: any) {
    }
}

export class GetVehicleCarfaxSuccessful implements NgrxAction {
    readonly type = GET_VEHICLE_CARFAX_SUCCESSFUL;

    constructor(public payload: any) {
    }
}

export class GetVehicleCarfaxFailed implements NgrxAction {
    readonly type = GET_VEHICLE_CARFAX_FAILED;

    constructor(public payload: any) {
    }
}

export class DuplicateVinLookup implements NgrxAction {
  readonly type = DUPLICATE_VIN_LOOKUP;

  constructor(public vin: string) {
  }
}

export class DuplicateVinLookupSuccessful implements NgrxAction {
  readonly type = DUPLICATE_VIN_LOOKUP_SUCCESSFUL;

  constructor(public response: any) {
  }
}

export class DuplicateVinLookupFailed implements NgrxAction {
  readonly type = DUPLICATE_VIN_LOOKUP_FAILED;

  constructor(public error: any) {
  }
}

export type Action = SetVehicleAction
    | LoadVehicleAction
    | LoadVehicleSuccessfulAction
    | LoadVehicleFailedAction
    | LoadVehiclesAction
    | LoadVehiclesSuccessfulAction
    | LoadVehiclesFailedAction
    | DeleteVehicleAction
    | DeleteVehicleSuccessfulAction
    | DeleteVehicleFailedAction
    | UpdateVehicleAction
    | UpdateVehicleSuccessfulAction
    | UpdateVehicleFailedAction
    | CreateVehicleAction
    | CreateVehicleFailedAction
    | CreateVehicleSuccessfulAction
    | UploadVehicleImageAction
    | UploadVehicleImageSuccessfulAction
    | UploadVehicleImageFailedAction
    | ClearUploadStateAction
    | GetVehicleCarfax
    | GetVehicleCarfaxSuccessful
    | GetVehicleCarfaxFailed
    | SetVehicleCarfax
    | DuplicateVinLookup
    | DuplicateVinLookupSuccessful
    | DuplicateVinLookupFailed;
