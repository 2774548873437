<div class="panel__body messaging-center__messages">

  <div *ngIf="reverseMessages.length">
    <div class="messaging-center__message" *ngFor="let message of reverseMessages">
      <app-messages-center-message [message]="message" [type]="type" (toMessages)="goToMessages($event)"
                   (toWorkorder)="goToWorkorder($event)" (markAsRead)="goMarkAsRead($event)">
      </app-messages-center-message>
    </div>
  </div>

  <div *ngIf="!reverseMessages.length" class="centered-text">
    <div class="messaging-center__message">
      <b>No Messages To Show</b>
    </div>
  </div>
</div>
