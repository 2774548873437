
import {take, skipWhile, takeUntil} from 'rxjs/operators';
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import * as WorkOrderMessagesActions from '../../store/actions/workordermessages.actions';
import {WorkOrderService} from '../../core/services/work-order.service';
import {Router} from '@angular/router';
import {WorkOrderProvider} from '../../core/providers/work-order.provider';
import {AlertProvider} from '../../core/providers/alert.provider';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../store/reducers';
import {getProfile} from '../../store/reducers';
import * as workorderActions from '../../store/actions/workorders.actions';
import {NgxSmartModalComponent} from 'ngx-smart-modal';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-internal-messages-center-container',
    templateUrl: './internal-messages-center-container.component.html',
    styleUrls: ['./internal-messages-center-container.component.scss']
})
export class InternalMessagesCenterContainerComponent implements OnInit {

    public unreadMessages: any;
    public workOrder: any;
    public workOrderNumber: any;
    public anyMessages: boolean;
    public userID: any;
    public employees: any;
    public messagesResponse: any;
    public woID: any;
    public messageType: any;
    public loading: any = true;
    public messages: any;
    public pageNum = 1;
    public search = '';
    public numberOfPages = 0;
    public searchTerm = '';
    public viewUnread = false;
    public page: any = 1;
    public viewAll = 'true';
    private unsubscribe: Subject<void> = new Subject();
    public returnUrl: any = false;

    @ViewChild('newMessagePopup') newMessagePopup: NgxSmartModalComponent;

    constructor(private store: Store<any>,
        private workOrderService: WorkOrderService,
        private router: Router,
        private workOrderProvider: WorkOrderProvider,
        private alertProvider: AlertProvider,
    ) {


    }

    ngOnInit() {
        if (this.getQueryParams()) {
            this.messageType = 'workorder';
            this.getMessagesForWorkorder();
        } else {
            this.messageType = 'internal';
            this.getUnreadAllWorkOrderMessages();
        }

        this.getProfile();
        this.getEmployees();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }


    getQueryParams() {
        const queryString = window.location.search;
        const urlParamOne = new URLSearchParams(queryString);
        this.woID = urlParamOne.get('woID');

        if (urlParamOne.has('returnUrl')) {
            this.returnUrl = urlParamOne.get('returnUrl');
        }

        return this.woID;
    }

    getAllWorkOrderMessages() {
        this.store.select(fromRoot.getAllWorkOrderMessages).pipe(skipWhile((response) => !response)).pipe(takeUntil(this.unsubscribe)).subscribe((e) => {
            this.messagesResponse = e;
            if (e.length > 0) {
                this.setMessagesData(this.messagesResponse);
            } else {
                this.loading = false;
            }
        });
    }

    getUnreadAllWorkOrderMessages() {
        this.store.select(fromRoot.getUnreadWorkOrderMessages('')).pipe(takeUntil(this.unsubscribe)).subscribe((e) => {
            this.messagesResponse = e;
            if (e.length > 0) {
                this.setMessagesData(this.messagesResponse);
            } else {
                this.loading = false;
            }
        });
    }

    getMessagesForWorkorder() {
        this.store.select(fromRoot.getMessagesForWorkOrder(this.woID)).pipe(takeUntil(this.unsubscribe)).subscribe((e) => {
            if (e && e.length > 0) {
                this.setMessagesData(e);
            } else {
                this.loading = false;
            }
        });
    }

    getProfile() {
        this.store.select(getProfile).pipe(take(1)).subscribe((response) => {
            if (response != null) {
                this.userID = response.use_userID;
            }
        });
    }

    getEmployees() {
        this.store.select(fromRoot.getUsersForMessage).pipe(take(1)).subscribe((e) => {
            this.employees = e.data;
            this.rekeyEmployees();
        });
    }

    rekeyEmployees() {
        const newEmployeeArray = [];
        if (typeof this.employees != 'undefined') {
            this.employees.forEach((employee) => {
                newEmployeeArray[employee.use_userID] = employee;
            });
            this.employees = newEmployeeArray;
        }
    }

    markAsRead(messageID) {
        this.store.dispatch(new WorkOrderMessagesActions.MarkWorkOrderMessageAsRead({'messageID': messageID, 'userID': this.userID}));
        this.alertProvider.addSuccess("Message marked as read.");
    }

    filterOutRead(toFilter) {
        this.loading = true;
        this.messages = [];

        if (toFilter) {
            this.viewAll = 'true';
            this.getUnreadAllWorkOrderMessages();
        } else {
            this.viewAll = 'false';
            this.getAllWorkOrderMessages();
        }
    }


    goToMessage(woID) {
        this.setWorkOrder(woID);
        setTimeout(() => {
            this.router.navigate(['/work-order-messages'], {queryParams: {woID: woID}});
        }, 800);
        this.alertProvider.addSuccess("Loading Work Order Messages");
    }

    setWorkOrder(woID) {
        this.store.dispatch(new workorderActions.LoadWorkOrderAction(woID));
    }

    goToWorkOrder(woID) {
        this.setWorkOrder(woID);
        setTimeout(() => {
            this.router.navigate(['/work-order-info']);
        }, 800);
        this.alertProvider.addSuccess("Loading Work Order");
    }

    getTo(message) {
        if (message.wom_selected_users == '') {
            return 'All';
        } else {
            if (typeof this.employees != 'undefined' && this.employees != null) {
                if (typeof this.employees[message.wom_selected_users] != 'undefined' && this.employees[message.wom_selected_users] != null) {
                    return this.employees[message.wom_selected_users].use_fname + ' ' + this.employees[message.wom_selected_users].use_lname;
                } else {
                    return '';
                }
            } else {
                return '';
            }

        }
    }

    setMessagesData(messages) {
        this.messages = [];

        messages.forEach((message) => {
            this.messages[message.wom_messageID] = {
                "message": message.wom_message,
                "user": message.wom_user,
                "sentTo": this.getTo(message),
                "read": message.wom_read,
                "vehicle": message.vehicle,
                "woID": message.wom_woID,
                "woNumber": message.woNumber,
                "msgID": message.wom_messageID
            };
        });
        this.loading = false;
    }

    nextPage() {
        this.pageNum += 1;
    }

    backPage() {
        this.pageNum -= 1;
    }

    messageSent(event) {
        this.newMessagePopup.close();
    }

    redirect() {
        this.router.navigate([this.returnUrl]);
    }
}
