import {catchError, map, switchMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {createEffect, Actions, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';

import {Observable, EMPTY as empty, of, from as fromPromise} from 'rxjs';


import * as invoiceItemCategories from '../actions/invoiceitemcategories.actions';

import {InvoiceItemCategoryService} from '../../core/services/invoice-item-category.service';

@Injectable()
export class InvoiceItemCategoriesEffects {


    lodInvoiceItemCategory: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(invoiceItemCategories.LOAD_INVOICE_ITEM_CATEGORIES),
        switchMap(() =>
            fromPromise(this.invoiceItemCategoriesService.getInvoiceItemCategories()).pipe(
                map((invoiceItemCategoriesInfo: any) => new invoiceItemCategories.LoadInvoiceItemCategoriesSuccessfulAction(invoiceItemCategoriesInfo.data)),
                catchError((error) => of(new invoiceItemCategories.LoadInvoiceItemCategoriesFailedAction({error: {message: 'Invoice Item Categories could not be loaded, please try again'}}))))
        )));

    constructor(private $actions: Actions, private invoiceItemCategoriesService: InvoiceItemCategoryService) {
    }
}
