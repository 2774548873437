
import {map} from 'rxjs/operators';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';

import { environment } from 'environments/environment';

import * as fromRoot from '../../store/reducers';
import * as customerActions from '../../store/actions/customer.actions';
import * as vehicleActions from '../../store/actions/vehicle.actions';
import * as workorderActions from '../../store/actions/workorders.actions';
import * as workOrderNoteActions from '../../store/actions/workordernote.actions';
import * as vehicleMotorDataActions from'../../store/actions/vehiclemotordata.actions';
import * as fromFormActions from '../../store/actions/form.actions';

@Injectable()
export class SessionService {

    private urlApi: string;
    private urlOld: string;

    constructor(
        private http: HttpClient,
        private store: Store<fromRoot.State>
    ) {
        this.urlApi = environment.tireguruBaseApi;
        this.urlOld = environment.tireguruBaseUrl;
    }

    setCustomer(customerId) {
        const url = `${this.urlApi}/session/customer`;
        return this.http.post(url, {
            customerId
        }).toPromise();
    }

    setWorkOrder(workOrderId) {
        const url = `${this.urlApi}/session/work-order`;
        return this.http.post(url, {
            workOrderId
        }).toPromise();
    }

    setVehicle(vehicleId) {
        const url = `${this.urlApi}/session/vehicle`;
        return this.http.post(url, {
            vehicleId
        }).toPromise();
    }

    getState() {
        const url = `${this.urlApi}/state`;
        return this.http.get(url, {}).toPromise();
    }

    clear() {
        const url = `${this.urlOld}/mobile/mobileUpdate.php`;

        let body = new HttpParams();
        body = body.set('act', 'clear_cust');
        body = body.set('nogo', '1');

        return this.http.post(url, body).pipe(map((response)  => {
            this.clearStore();
            return response;
        })).toPromise();
    }

    private clearStore() {
        this.store.dispatch(new customerActions.SetCustomerAction(null));
        this.store.dispatch(new vehicleActions.SetVehicleAction(null));
        this.store.dispatch(new vehicleActions.SetVehicleCarfax(null));
        this.store.dispatch(new vehicleMotorDataActions.ClearVehicleMotorDataAction);
        this.store.dispatch(new vehicleMotorDataActions.ClearVehicleMotorDataSpecsAction);
        this.store.dispatch(new vehicleMotorDataActions.ClearVehicleMotorDataItemsAvailableAction);
        this.store.dispatch(new workorderActions.SetWorkorderAction(null));
        this.store.dispatch(new workOrderNoteActions.SetNotesAction(null));
        this.store.dispatch(new fromFormActions.ClearStateAction());
    }

}
