import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {RouterModule, RouteReuseStrategy, Router} from '@angular/router';
import { ErrorHandler, NgModule } from '@angular/core';
import {HttpClientModule, HTTP_INTERCEPTORS, HttpClientXsrfModule} from '@angular/common/http';

import {Store, StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {EffectsModule} from '@ngrx/effects';

import {AppComponent} from './app.component';
import {appRouting} from './app.routing';
import {CommonComponentsModule} from './common-components/common-components.module';

import * as fromRoot from './store/reducers';
import {CustomerEffects} from './store/effects/customer.effects';
import {ProfileEffects} from './store/effects/profile.effects';
import {VehicleEffects} from './store/effects/vehicle.effects';
import {WorkOrderEffects} from './store/effects/workorders.effects';
import {JobEffects} from './store/effects/job.effects';
import {InvoiceItemCategoriesEffects} from './store/effects/invoiceitemcategories.effects';
import {VehiclemotordataEffects} from './store/effects/vehiclemotordata.effects';

// Providers
import {ProfileProvider} from './core/providers/profile.provider';
import {WorkOrderProvider} from './core/providers/work-order.provider';

// HTTP Interceptor
import {LegacyHttpInterceptor} from './legacy-http-interceptor';
import {CustomReuseStrategy} from './core/router/custom-reuse-strategy';


// Services
import {SearchService} from './core/services/search.service';
import {AppointmentService} from './core/services/appointment.service';
import {CustomerService} from './core/services/customer.service';
import {VehicleService} from './core/services/vehicle.service';
import {JobService} from './core/services/job.service';
import {TireService} from './core/services/tire.service';
import {ServiceService} from './core/services/service.service';
import {ProfileService} from './core/services/profile.service';
import {WorkOrderService} from './core/services/work-order.service';
import {UserService} from './core/services/user.service';
import {NoteService} from './core/services/note.service';
import {FormService} from './core/services/form.service';
import {InspectionPortalService} from './core/services/inspection-portal.service';
import {InvoiceItemCategoryService} from './core/services/invoice-item-category.service';
import {GrooveGloveService} from './core/services/grooveGlove.service';
import {WorkOrderMessagesService} from './core/services/work-order-messages.service';
import { CreditCardProcessingService } from './core/services/credit-card-processing.service';
import {CustomerCommunicationsService} from './core/services/customerCommunications.service';

import {Constants} from './core/constants/constants';

import {SessionService} from './core/services/session.service';

import {AlertProvider} from './core/providers/alert.provider';

// Helpers
import {FormHelper} from './core/helpers/form-helper';

import {environment} from 'environments/environment';
import {DeclinedServicesService} from './core/services/declined-services.service';
import {SearchEffects} from './store/effects/search.effects';
import {FormEffects} from './store/effects/form.effects';
import { SessionEffects } from './store/effects/session.effects';
import {IframeMessengerService} from './core/services/iframe-messenger.service';
import * as workorderActions from './store/actions/workorders.actions';
import * as vehicleActions from './store/actions/vehicle.actions';
import * as customerActions from './store/actions/customer.actions';
import * as sessionActions from './store/actions/session.actions';
import { RollbarErrorHandler, RollbarService, rollbarFactory } from './errorhandler';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PremiumDisabledComponent } from './premium-disabled/premium-disabled.component';
import {GroovegloveEffects} from './store/effects/grooveglove.effects';
import {WorkordermessagesEffects} from './store/effects/workordermessages.effects';
import {EchoServiceService} from './core/services/echo-service.service';
import {MessagesCenterComponent} from './messages-center/messages-center.component';
import {FormsModule} from '@angular/forms';
import {ArcService} from './core/services/arc.service';
import {ArcEffects} from './store/effects/arc.effects';
import {ArcCartModule} from './arc-cart/arc-cart.module';
import { CreditCardProcessingEffects } from './store/effects/credit-card-processing.effects';
import {CustomerCommunicationEffects} from './store/effects/customerCommunication.effects';
import {WorkorderNoteService} from './core/services/workorder-note.service';
import {WorkorderNoteEffects} from './store/effects/workordernote.effects';
import {CarfaxService} from './core/services/carfax.service';
import {VehicleMotorDataService} from './core/services/vehicle-motor-data.service';

import { BaySchedulerIframeComponent } from './bay-scheduler-iframe/bay-scheduler-iframe.component';
import { InternalMessagesCenterContainerComponent } from './messages-center/internal-messages-center-container/internal-messages-center-container.component';
import { CustomerMessagesCenterContainerComponent } from './messages-center/customer-messages-center-container/customer-messages-center-container.component';
import { ListMessagesCenterMessagesComponent } from './messages-center/list-messages-center-messages/list-messages-center-messages.component';
import { MessagesCenterMessageComponent } from './messages-center/messages-center-message/messages-center-message.component';
import { MessagesCenterNewMessageComponent } from './messages-center/messages-center-new-message/messages-center-new-message.component';
import {NgxSmartModalModule, NgxSmartModalService} from 'ngx-smart-modal';
import { WorkflowIframeComponent } from './workflow-iframe/workflow-iframe.component';
import { VehicleHistoryIframeComponent } from './vehicle-history-iframe/vehicle-history-iframe.component';
import {ZXingScannerModule} from '@zxing/ngx-scanner';
import {GlobalSearchEffects} from './store/effects/global-search.effects';
import {GlobalSearchService} from './core/services/global-search/global-search.service';
import {PurchaseOrdersEffects} from './store/effects/purchase-orders.effects';
import {PurchaseOrderVerificationsService} from './core/services/purchase-orders/purchase-order-verifications.service';
import {PurchaseOrdersService} from './core/services/purchase-orders/purchase-orders.service';
import {BarcodesEffects} from './store/effects/barcodes.effects';
import {ItemBarcodesService} from './core/services/barcodes/item-barcodes.service';
import {PhysicalInventoryService} from './core/services/physical-inventory/physical-inventory.service';
import {PhysicalInventoryCountService} from './core/services/physical-inventory/physical-inventory-count.service';
import {PhysicalInventoryEffects} from './store/effects/physical-inventory.effects';
import {MobileBayPortalIframeComponent} from './mobile-bay-portal-iframe/mobile-bay-portal-iframe.component';
import { StatisticsDashboardIframeComponent } from './statistics-dashboard-iframe/statistics-dashboard-iframe.component';

const rollbarProviders = [
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory }
];
const conditionalProviders = environment.production ? rollbarProviders : [];

@NgModule({
    declarations: [
        AppComponent,
        PageNotFoundComponent,
        PremiumDisabledComponent,
        MessagesCenterComponent,
        BaySchedulerIframeComponent,
        InternalMessagesCenterContainerComponent,
        CustomerMessagesCenterContainerComponent,
        ListMessagesCenterMessagesComponent,
        MessagesCenterMessageComponent,
        MessagesCenterNewMessageComponent,
        WorkflowIframeComponent,
        VehicleHistoryIframeComponent,
      MobileBayPortalIframeComponent,
    StatisticsDashboardIframeComponent,],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        NgxSmartModalModule.forRoot(),
        RouterModule.forRoot(appRouting),
        HttpClientModule,
        StoreModule.forRoot(fromRoot.reducer, {
            metaReducers: fromRoot.metaReducers,
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false
            }
        }),
        StoreDevtoolsModule.instrument({
            maxAge: 25, // Retains last 25 states
            logOnly: environment.production, // Restrict extension to log-only mode
        }),
        EffectsModule.forRoot([
            CustomerEffects,
            ProfileEffects,
            VehicleEffects,
            WorkOrderEffects,
            InvoiceItemCategoriesEffects,
            JobEffects,
            SearchEffects,
            FormEffects,
            SessionEffects,
            GroovegloveEffects,
            WorkordermessagesEffects,
            ArcEffects,
            CreditCardProcessingEffects,
            CustomerCommunicationEffects,
            WorkorderNoteEffects,
            VehiclemotordataEffects,
            GlobalSearchEffects,
            PurchaseOrdersEffects,
            BarcodesEffects,
            PhysicalInventoryEffects
        ]),
        CommonComponentsModule,
        FormsModule,
        ZXingScannerModule,
    ],
    providers: [
        ProfileProvider,
        WorkOrderProvider,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LegacyHttpInterceptor,
            multi: true
        },
        {
            provide: RouteReuseStrategy,
            useClass: CustomReuseStrategy
        },
        SearchService,
        AppointmentService,
        CustomerService,
        VehicleService,
        FormHelper,
        JobService,
        TireService,
        ServiceService,
        ProfileService,
        WorkOrderService,
        UserService,
        AlertProvider,
        SessionService,
        NoteService,
        FormService,
        InspectionPortalService,
        Constants,
        InvoiceItemCategoryService,
        GrooveGloveService,
        WorkOrderMessagesService,
        EchoServiceService,
        CustomerCommunicationsService,
        DeclinedServicesService,
        IframeMessengerService,
        ArcService,
        CreditCardProcessingService,
        WorkorderNoteService,
        CarfaxService,
        VehicleMotorDataService,
        NgxSmartModalService,
        GlobalSearchService,
        PurchaseOrderVerificationsService,
        PurchaseOrdersService,
        ItemBarcodesService,
        PhysicalInventoryService,
        PhysicalInventoryCountService,
        ...conditionalProviders
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

    public constructor(
        private sessionService: SessionService,
        private store: Store<fromRoot.State>,
        private iframeMessengerService: IframeMessengerService,
        private echoService: EchoServiceService
    ) {
        this.checkSession();
        this.startIframeMessagingService();
    }

    private startIframeMessagingService() {
        this.iframeMessengerService.start();
    }

    private checkSession() {
        this.store.dispatch(new sessionActions.LoadSessionAction());
    }
}
