import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment';


@Injectable()
export class DeclinedServicesService {

    private apiUrl: string;

    constructor(private http: HttpClient) {
        this.apiUrl = `${environment.tireguruBaseApi}/declined-services`;
    }

    declineServices(workOrderId, itemIds) {

        const url = `${this.apiUrl}/${workOrderId}/decline`;
        let params = new HttpParams();

        itemIds.forEach((id) => {
            params = params.append('itemIds[]', `${id}`);
        });

        return this.http.post(url, params)
            .toPromise();
    }

    approveServices(workOrderId, itemIds, fromDeclined) {

        const url = `${this.apiUrl}/${workOrderId}/approve`;
        let params = new HttpParams();

        itemIds.forEach((id) => {
            params = params.append('itemIds[]', `${id}`);
        });

        params = params.append('from-declined', fromDeclined);

        return this.http.post(url, params)
            .toPromise();
    }

    pendingServices(workOrderId, itemIds, fromDeclined) {
        const url = `${this.apiUrl}/${workOrderId}/pending`;
        let params = new HttpParams();

        itemIds.forEach((id) => {
            params = params.append('itemIds[]', `${id}`);
        });

        params = params.append('from-declined', fromDeclined);

        return this.http.post(url, params)
            .toPromise();
    }

}
