import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {NgxSmartModalComponent} from 'ngx-smart-modal';

@Component({
    selector: 'app-list-messages-center-messages',
    templateUrl: './list-messages-center-messages.component.html',
    styleUrls: ['./list-messages-center-messages.component.scss']
})
export class ListMessagesCenterMessagesComponent implements OnInit {
    @Input() messages;
    @Input() type;
    @Input() numberOfPages;

    @Output() toWorkorder: EventEmitter<any> = new EventEmitter<any>();
    @Output() toMessages: EventEmitter<any> = new EventEmitter<any>();
    @Output() markAsRead: EventEmitter<any> = new EventEmitter<any>();

    public keys: any;
    public pageNum: any = 1;

    constructor() {
    }

    ngOnInit() {
    }

    goToWorkorder(woID) {
        this.toWorkorder.emit(woID);
    }

    goToMessages(ID) {
        this.toMessages.emit(ID);
    }

    goMarkAsRead(msgID) {
        this.markAsRead.emit(msgID);
    }

    get reverseMessages() {
        if (this.messages) {
            return Object.keys(this.messages).map(key => this.messages[key]).reverse();
        }

        return [];
    }
}
