<table *ngIf="vehicle">
  <tr>
    <td class="headerVerticalAlign textAlignLeft">
      <span class="title is-size-3 is-bold">
        Vehicle Information
      </span><br>

      <span *ngIf="vehicle.veh_license"><strong class="subtitle">License:</strong>
            <span>{{vehicle.veh_license}}<br></span>
          </span>

      <span *ngIf="vehicle['veh_state']"><strong class="subtitle">State:</strong>
            <span>{{vehicle['veh_state']}}<br></span>
          </span>

      <span *ngIf="vehicle['veh_vin']"><strong class="subtitle">VIN:</strong>
            <span>{{vehicle['veh_vin']}}<br></span>
          </span>

      <span *ngIf="vehicle['veh_color']"><strong class="subtitle">Color:</strong>
            <span>{{vehicle['veh_color']}}<br></span>
          </span>

      <span *ngIf="vehicle['veh_torque']"><strong class="subtitle">Torque:</strong>
            <span>{{vehicle['veh_torque']}}<br></span>
          </span>

      <span *ngIf="vehicle['veh_air_pressure_front']"><strong class="subtitle">Air Pressure Front:</strong>
            <span>{{vehicle['veh_air_pressure_front']}}<br></span>
          </span>

      <span *ngIf="vehicle['veh_air_pressure_rear']"><strong class="subtitle">Air Pressure Rear:</strong>
            <span>{{vehicle['veh_air_pressure_rear']}}<br></span>
          </span>

      <span *ngIf="vehicle['veh_rawsize']"><strong class="subtitle">Vehicle Raw Size:</strong>
            <span>{{vehicle['veh_rawsize']}}<br></span>
          </span>

    </td>
  </tr>
</table>
