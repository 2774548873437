import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-carfax-vehicle-info-optional',
    templateUrl: './carfax-vehicle-info-optional.component.html',
    styleUrls: ['./carfax-vehicle-info-optional.component.scss']
})
export class CarfaxVehicleInfoOptionalComponent implements OnInit {

    @Input() veh_carfax;

    constructor() { }

    ngOnInit() {
    }

}
