import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';


import { environment } from 'environments/environment';

@Injectable()
export class WorkOrderService {

    private urlApi: string;
    private urlOld: string;


    constructor(private http: HttpClient) {
        this.urlApi = environment.tireguruBaseApi;
        this.urlOld = environment.tireguruBaseUrl;

    }

    createWorkOrder(customerId: number, vehicleId: number = null, type: string = null) {
        let url = `${this.urlApi}/work-order`;

        if (type != null) {
            url += '/' + type;
        }

        return this.http.post(url, {
            vehicleId,
            customerId
        }).toPromise();
    }

    getWorkOrder(workerId: number) {
        const url = `${this.urlApi}/work-order/${workerId}`;
        return this.http.get(url, {}).toPromise();
    }

    getWorkOrderFromCustomer(id) {
        return new Promise((resolve, reject) => {
            resolve('Working');
        });
        // const url = `${this.url}`;
        // return new Promise((resolve, reject) => {
        //     this.http.get(url, {
        //         params: {
        //             per_page: '10'
        //         }
        //     }).subscribe(response => {
        //         resolve(response);
        //     }, error => {
        //         reject(error);
        //     });
        // });
    }

    updateWorkOrder(workOrderId, customerId, data) {
        const url = `${this.urlApi}/update-work-order/${workOrderId}`;
        return this.http.post(url, data).pipe(
            map((response: any) => response)).toPromise();
    }

    addDelincedServiceToWorkOrder(workOrderId: number, services: Array<number>) {
        const url = `${this.urlOld}/invoicing/declined_services/add_declined_service_to_wo.php`;

        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

        const body = new URLSearchParams();
        // Default Params
        body.append('woID', workOrderId.toString());
        // body.append('ds_items[]', JSON.stringify(services));
        body.append('ds_items[]', services[0].toString());

        const options = { headers };
        return this.http.post(url.toString(), body.toString(), options).toPromise();

    }

    getWorkOrderStatuses(locID) {
        const url = `${this.urlOld}/app/ajax/location/${locID}/work-order-statuses`;

        return this.http.get(url).toPromise();
    }

    setWorkOrderStatus(locID, woID, statusID, send_text= false) {
        const url = `${this.urlOld}/app/ajax/work-order/status/${statusID}/${woID}`;

        return this.http.post(url, {
            send_text: send_text + ""
        }).toPromise();
    }

    getEmployeesForAssignment(locID) {
        const url = `${this.urlOld}/app/ajax/location/${locID}/get-employees`;

        return this.http.get(url).toPromise();
    }

    setWorkOrderEmployees(locID, woID, assignments) {
        const url = `${this.urlOld}/app/ajax/location/${locID}/workOrder/${woID}/save-assignments`;

        return this.http.post(url, assignments).toPromise();
    }

    addItemToWorkorder(itemID, qty, action = 'add_item') {
        const url = `${this.urlOld}/mobile/mobileUpdate.php`;
        const params = {
            act: action,
            itemID,
            qty
        };

        return this.http.get(url, { params }).toPromise();
    }

    getWorkOrderItemsVerification(woID) {
        const url = `${this.urlOld}/app/invoicing/workorders/${woID}/verifications?from=mobile_invoicing`;

        return this.http.get(url).toPromise();
    }

  updateWorkOrderItemVerification({qty, woItemID, barcode, itemID, workorderID, returnTo}) {
    const url = `${this.urlOld}/app/invoicing/workorders/${workorderID}/update`;

    return this.http.get(url, {
      params: {
        workItemID: woItemID || '',
        barcode: encodeURIComponent(barcode || ''),
        qty: qty || '',
        itemID: itemID || '',
        returnTo: returnTo || '',
        from: 'mobile_invoicing'
      }
    }).toPromise();
  }
}
