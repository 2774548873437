<div class="sales-tech-container" *ngIf="showPopup" (click)="valueChange.emit(false)">
  <app-panel class="sales-tech-popup-panel" (click)="$event.stopPropagation()">
    <app-panel-header>
      <div>
        <span>Assign Salespeople and Technicians</span>
        <div class="sales-tech-close">
          <app-icon [name]="'close'" (click)="valueChange.emit(false)"></app-icon>
        </div>
      </div>
    </app-panel-header>
    <app-panel-body>
      <div class="scrollable-body" *ngIf="workOrderItems">
        <app-assign-sales-and-tech-per-line-item [employees]="employees"
                                                 [workOrderItem]="{woi_workitemID: 'all', woi_description: 'Assign All'}"
                                                 [profile]="profile"
                                                 (updateAssignment)="createAllAssignments($event)"
        ></app-assign-sales-and-tech-per-line-item>
        <div class="body-container" *ngFor="let item of getWorkOrderItems()">
          <app-assign-sales-and-tech-per-line-item [employees]="employees"
                                                   [workOrderItem]="item"
                                                   [events]="assignAllEvent.asObservable()"
                                                   [profile]="profile"
                                                   (updateAssignment)="createNewAssignments($event)"
          ></app-assign-sales-and-tech-per-line-item>
        </div>
        <div class="save-button">
          <div style="display: inline-block">
            <button class="is-primary btn" [disabled]="!profile['permissions']['Change_Salesperson'] && !profile['permissions']['change_wo_technician']"
                    (click)="saveAssignedTechs(); valueChange.emit(true)">Save</button>
          </div>
        </div>
      </div>
    </app-panel-body>

  </app-panel>

</div>
