
import {take, skipWhile} from 'rxjs/operators';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as fromRoot from '../../store/reducers';
import {Store} from '@ngrx/store';
import * as fromVehicleActions from '../../store/actions/vehicle.actions';
import * as fromVehicleMotorDataActions from '../../store/actions/vehiclemotordata.actions';
import * as vehicleActions from "../../store/actions/vehicle.actions";

@Component({
    selector: 'app-carfax-vehicle-info',
    templateUrl: './carfax-vehicle-info.component.html',
    styleUrls: ['./carfax-vehicle-info.component.scss']
})
export class CarfaxVehicleInfoComponent implements OnInit {
    @Input() custVehicle;

    public veh_carfax: any = false;
    public vehicle: any = false;

    constructor(
        private store: Store<fromRoot.State>,
    ) {
    }

    ngOnInit() {
        this.searchCarFax();
        this.getMotorData();
    }

    searchCarFax() {
        if (this.custVehicle) {
            const state = this.custVehicle.state ? this.custVehicle.state : this.custVehicle.veh_state;
            const license = this.custVehicle.license ? this.custVehicle.license : this.custVehicle.veh_license;
            const vin = this.custVehicle.vin ? this.custVehicle.vin : this.custVehicle.veh_vin;

            this.store.dispatch(new fromVehicleActions.GetVehicleCarfax({'state': state ? state : '', 'license': license ? license : '', 'vin': vin ? vin : ''}));

            this.store.select(fromRoot.getCurrentVehicleCarfaxState).pipe(
                skipWhile((results) => results['loading'] === true), take(1), ).subscribe(response => {
                this.veh_carfax = response['carfax'] ? response['carfax']['data'][0] : false;
                this.store.dispatch(new vehicleActions.SetVehicleCarfax(null));
            });
        }
    }

    async getMotorData() {
        if (this.custVehicle && this.custVehicle.veh_vehicleID && false) {
            this.store.dispatch(new fromVehicleMotorDataActions.LoadVehicleMotorDataAction({'veh_id': this.custVehicle.veh_vehicleID}));

            this.store.select(fromRoot.getCurrentVehicleMotorData).pipe(
                skipWhile(motor_data => !motor_data), take(1), ).subscribe(motor_data => {
                this.vehicle = motor_data['data'];
            });
        }
    }
}
