import { Action as NgrxAction } from '@ngrx/store';

export const GET_UNREAD_TEXT_COUNT = '[Customer Communications] Get unread text count';
export const GET_UNREAD_TEXT_COUNT_SUCCESSFUL = '[Customer Communications] Get unread text count successful';
export const GET_UNREAD_TEXT_COUNT_FAILED = '[Customer Communications] Get unread text count failed';
export const GET_TEXT_MESSAGES = '[Customer Communications] Get text messages for location';
export const GET_TEXT_MESSAGES_SUCCESSFUL = '[Customer Communications] Get text messages for location successful';
export const GET_TEXT_MESSAGES_FAILED = '[Customer Communications] Get text messages for location failed';
export const GET_CUSTOMER_COMMUNICATIONS = '[Customer Communications] Get customer communications';
export const GET_CUSTOMER_COMMUNICATIONS_SUCCESSFUL = '[Customer Communications] Get customer communications successful';
export const GET_CUSTOMER_COMMUNICATIONS_FAILED = '[Customer Communications] Get customer communications failed';
export const SEND_TEXT_MESSAGE = '[Customer Communications] Send Text Message';
export const SEND_TEXT_MESSAGE_SUCCESSFUL = '[Customer Communications] Send Text Message Successful';
export const SEND_TEXT_MESSAGE_FAILED = '[Customer Communications] Send Text Message Failed';
export const MARK_TEXTS_AS_READ = '[Customer Communications] Mark Texts as read';
export const MARK_TEXTS_AS_READ_SUCCESSFUL = '[Customer Communications] Mark Texts as read successful';
export const MARK_TEXTS_AS_READ_FAILED = '[Customer Communications] Mark Texts as read failed';
export const MARK_INDIVIDUAL_TEXT_AS_READ = '[Customer Communications] mark individual text as read';
export const MARK_INDIVIDUAL_TEXT_AS_READ_SUCCESSFUL = '[Customer Communications] mark individual text as read successful';
export const MARK_INDIVIDUAL_TEXT_AS_READ_FAILED = '[Customer Communications] mark individual text as read failed';
export const CLEAR_MARK_TEXT_STATE = '[Customer Communications] clear mark text state';

export class GetUnreadTextCountAction implements NgrxAction {
    readonly type = GET_UNREAD_TEXT_COUNT;
    constructor(public payload: any) {
    }
}

export class GetUnreadTextCountSuccessfulAction implements NgrxAction {
    readonly type = GET_UNREAD_TEXT_COUNT_SUCCESSFUL;
    constructor(public payload: any) {
    }
}

export class GetUnreadTextCountFailedAction implements NgrxAction {
    readonly type = GET_UNREAD_TEXT_COUNT_FAILED;
    constructor(public payload: any) {
    }
}

export class GetTextMessagesAction implements NgrxAction {
    readonly type = GET_TEXT_MESSAGES;

    constructor(public payload: any) {
    }
}

export class GetTextMessagesSuccessfulAction implements NgrxAction {
    readonly type = GET_TEXT_MESSAGES_SUCCESSFUL;

    constructor(public payload: any) {
    }
}

export class GetTextMessagesFailedAction implements NgrxAction {
    readonly type = GET_TEXT_MESSAGES_FAILED;

    constructor(public payload: any) {
    }
}

export class GetCustomerCommunicationsAction implements NgrxAction {
    readonly type = GET_CUSTOMER_COMMUNICATIONS;

    constructor(public payload: any) {
    }
}

export class GetCustomerCommunicationsSuccessfulAction implements NgrxAction {
    readonly type = GET_CUSTOMER_COMMUNICATIONS_SUCCESSFUL;

    constructor(public payload: any) {
    }
}

export class GetCustomerCommunicationsFailedAction implements NgrxAction {
    readonly type = GET_CUSTOMER_COMMUNICATIONS_FAILED;

    constructor(public payload: any) {
    }
}

export class SendTextMessageAction implements NgrxAction {
    readonly type = SEND_TEXT_MESSAGE;

    constructor(public payload: any){
    }
}

export class SendTextMessageSuccessfulAction implements NgrxAction {
    readonly type = SEND_TEXT_MESSAGE_SUCCESSFUL;

    constructor(public payload: any){
    }
}

export class SendTextMessageFailedAction implements NgrxAction {
    readonly type = SEND_TEXT_MESSAGE_FAILED;

    constructor(public payload: any){
    }
}

export class MarkTextsAsReadAction implements NgrxAction {
    readonly type = MARK_TEXTS_AS_READ;

    constructor(public payload: any){
    }
}

export class MarkTextsAsReadSuccessfulAction implements NgrxAction {
    readonly type = MARK_TEXTS_AS_READ_SUCCESSFUL;

    constructor(public payload: any){
    }
}

export class MarkTextsAsReadFailedAction implements NgrxAction {
    readonly type = MARK_TEXTS_AS_READ_FAILED;

    constructor(public payload: any){
    }
}

export class MarkIndividualTextAsReadAction implements NgrxAction {
    readonly type = MARK_INDIVIDUAL_TEXT_AS_READ;

    constructor(public payload: any){
    }
}

export class MarkIndividualTextAsReadSuccessfulAction implements NgrxAction {
    readonly type = MARK_INDIVIDUAL_TEXT_AS_READ_SUCCESSFUL;

    constructor(public payload: any){
    }
}

export class MarkIndividualTextAsReadFailedAction implements NgrxAction {
    readonly type = MARK_INDIVIDUAL_TEXT_AS_READ_FAILED;

    constructor(public payload: any){
    }
}

export class ClearMarkedTextAsReadAction implements NgrxAction {
    readonly type = CLEAR_MARK_TEXT_STATE;

    constructor(public payload: any){
    }
}

export type Action = GetUnreadTextCountAction
| GetUnreadTextCountSuccessfulAction
| GetUnreadTextCountFailedAction
| GetTextMessagesAction
| GetTextMessagesSuccessfulAction
| GetTextMessagesFailedAction
| GetCustomerCommunicationsAction
| GetCustomerCommunicationsSuccessfulAction
| GetCustomerCommunicationsFailedAction
| SendTextMessageAction
| SendTextMessageSuccessfulAction
| SendTextMessageFailedAction
| MarkTextsAsReadAction
| MarkTextsAsReadSuccessfulAction
| MarkTextsAsReadFailedAction
| MarkIndividualTextAsReadAction
| MarkIndividualTextAsReadSuccessfulAction
| MarkIndividualTextAsReadFailedAction
| ClearMarkedTextAsReadAction;
