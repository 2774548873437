<div class="navigation">

  <a [routerLink]="['/add-item']" class="navigation__item" [ngClass]="{ 'selected' : selected === STATE.ADD_ITEM }"
     *ngIf="profile?.permissions?.add_items_to_wo">
    <div class="icon">
      <app-navigation-icon name="tire" fill="{{ selected === STATE.ADD_ITEM ? '#ffffff' : '#222222' }}"></app-navigation-icon>
    </div>
    <div class="heading is-uppercase">
      Add
      <strong>Item</strong>
    </div>
  </a>

  <a [routerLink]="['/add-service']" class="navigation__item" [ngClass]="{ 'selected' : selected === STATE.ADD_SERVICE }"
     *ngIf="profile?.permissions?.add_items_to_wo">
    <div class="icon">
      <app-navigation-icon name="service" fill="{{ selected === STATE.ADD_SERVICE ? '#ffffff' : '#222222' }}"></app-navigation-icon>
    </div>
    <div class="heading is-uppercase">
      Add
      <strong>Service</strong>
    </div>
  </a>

  <a [routerLink]="['/vehicle-specifications']" class="navigation__item" [ngClass]="{ 'selected' : selected === STATE.ADD_SERVICE }"
     *ngIf="!profile?.permissions?.add_items_to_wo">
    <div class="icon">
      <app-navigation-icon name="service" fill="{{ selected === STATE.ADD_SERVICE ? '#222222' : '#ffffff' }}"></app-navigation-icon>
    </div>
    <div class="heading is-uppercase">
      Motor
      <strong>Data</strong>
    </div>
  </a>

  <a [routerLink]="['/workflow']" class="navigation__item" [ngClass]="{ 'selected' : selected === STATE.INSPECTION }">
    <div class="icon">
      <app-navigation-icon name="inspection" fill="{{ selected === STATE.INSPECTION ? '#ffffff' : '#222222' }}"></app-navigation-icon>
    </div>
    <div *ngIf="profile && profile?.location?.settings?.loc_use_auto_serve_one_dvis; else digitalInspection">
      <div class="heading is-uppercase">
        Vehicle
        <strong>Overview</strong>
      </div>
    </div>
    <ng-template #digitalInspection>
      <div class="heading is-uppercase">
        Digital
        <strong>Inspection</strong>
      </div>
    </ng-template>
  </a>

  <a [routerLink]="['/collect-signature']" class="navigation__item" [ngClass]="{ 'selected' : selected === STATE.SIGNATURE }">
    <div class="icon">
      <app-navigation-icon name="edit" fill="{{ selected === STATE.SIGNATURE ? '#ffffff' : '#222222' }}"></app-navigation-icon>
    </div>
    <div class="heading is-uppercase">
      Collect
      <strong>Signature</strong>
    </div>
  </a>

  <a [routerLink]="['/enter-mileage']" class="navigation__item" [ngClass]="{ 'selected' : selected === STATE.MILEAGE }">
    <div class="icon">
      <app-navigation-icon name="speedometer" fill="{{ selected === STATE.MILEAGE ? '#ffffff' : '#222222' }}"></app-navigation-icon>
    </div>
    <div class="heading is-uppercase">
      Enter
      <strong>Mileage/Dots</strong>
    </div>
  </a>

  <a [routerLink]="['/technician-portal']" class="navigation__item" [ngClass]="{ 'selected' : selected === STATE.PORTAL }">
    <div class="icon">
      <app-navigation-icon name="technician" fill="{{ selected === STATE.PORTAL ? '#ffffff' : '#222222' }}"></app-navigation-icon>
    </div>
    <div class="heading is-uppercase">
      Technician
      <strong>Portal</strong>
    </div>
  </a>

</div>
