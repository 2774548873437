import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PremiumDisabledComponent } from './premium-disabled/premium-disabled.component';
import {MessagesCenterComponent} from './messages-center/messages-center.component';
import {ArcCartComponent} from './arc-cart/arc-cart.component';
import {VehicleMotorDataService} from './core/services/vehicle-motor-data.service';
import {BaySchedulerIframeComponent} from './bay-scheduler-iframe/bay-scheduler-iframe.component';
import {InternalMessagesCenterContainerComponent} from './messages-center/internal-messages-center-container/internal-messages-center-container.component';
import {WorkflowIframeComponent} from './workflow-iframe/workflow-iframe.component';
import {VehicleHistoryIframeComponent} from './vehicle-history-iframe/vehicle-history-iframe.component';
import {MobileBayPortalIframeComponent} from "./mobile-bay-portal-iframe/mobile-bay-portal-iframe.component";
import {StatisticsDashboardIframeComponent} from "./statistics-dashboard-iframe/statistics-dashboard-iframe.component";

export const appRouting = [
    {
        path: '',
        loadChildren: () => import('app/home/home.module').then(m => m.HomeModule),
    },
    {
        path: 'search',
        loadChildren: () => import('app/dashboard/dashboard.module').then(m => m.DashboardModule),
    },
    {
        path: 'advanced-search',
        loadChildren: () => import('app/advanced-search/advanced-search.module').then(m => m.AdvancedSearchModule),
    },
    {
        path: 'inspect',
        loadChildren: () => import('app/inspection-forms/inspection-forms.module').then(m => m.InspectionFormsModule),
    },
    {
        path: 'customers',
        loadChildren: () => import('app/customers/customers.module').then(m => m.CustomersModule),
    },
    {
        path: 'vehicles',
        loadChildren: () => import('app/vehicles/vehicles.module').then(m => m.VehiclesModule),
    },
    {
        path: 'workflow',
        loadChildren: () => import('app/workflow/workflow.module').then(m => m.WorkflowModule),
    },
    {
        path: 'inspection',
        loadChildren: () => import('app/inspection-service/inspection-service.module').then(m => m.InspectionServiceModule),
    },
    {
        path: 'inspection-report',
        loadChildren: () => import('app/inspection-report/inspection-report.module').then(m => m.InspectionReportModule),
    },
    {
        path: 'inspection-portal',
        loadChildren: () => import('app/inspection-portal/inspection-portal.module').then(m => m.InspectionPortalModule),
    },
    {
        path: 'other',
        loadChildren: () => import('app/other-reason-for-visit/other-reason-for-visit.module').then(m => m.OtherReasonForVisitModule),
    },
    {
        path: 'collect-signature',
        loadChildren: () => import('app/collect-signature/collect-signature.module').then(m => m.CollectSignatureModule),
    },
    {
        path: 'login',
        loadChildren: () => import('app/login/login.module').then(m => m.LoginModule),
    },
    {
        path: 'technician-portal',
        loadChildren: () => import('app/technician-portal/technician-portal.module').then(m => m.TechnicianPortalModule),
    },
    {
        path: 'enter-mileage',
        loadChildren: () => import('app/enter-mileage/enter-mileage.module').then(m => m.EnterMileageModule),
    },
    {
        path: 'add-item',
        loadChildren: () => import('app/add-item/add-item.module').then(m => m.AddItemModule),
    },
    {
        path: 'add-service',
        loadChildren: () => import('app/add-service/add-service.module').then(m => m.AddServiceModule),
    },
    {
        path: 'work-order-info',
        loadChildren: () => import('app/work-order-info/work-order-info.module').then(m => m.WorkOrderInfoModule),
    },
    {
        path: 'work-orders',
        loadChildren: () => import('app/work-orders/work-orders.module').then(m => m.WorkOrdersModule),
    },
    {
        path: 'messages',
        loadChildren: () => import('app/messages/messages.module').then(m => m.MessagesModule),
    },
    {
        path: 'customer-messages',
        loadChildren: () => import('app/customer-messages/customer-messages.module').then(m => m.CustomerMessagesModule),
    },
    {
        path: 'messaging-center',
        component: MessagesCenterComponent
    },
    {
        path: 'work-order-messages',
        component: InternalMessagesCenterContainerComponent
    },
    {
        path: 'arc',
        loadChildren: () => import('app/arc-cart/arc-cart.module').then(m => m.ArcCartModule),
    },
    {
        path: 'dynamic',
        loadChildren: () => import('app/dynamic-forms/dynamic-forms.module').then(m => m.DynamicFormsModule),
    },
    {
        path: 'inspection-print',
        loadChildren: () => import('app/inspection-print-report/inspection-print-report.module').then(m => m.InspectionPrintReportModule),
    },
    {
        path: 'premium-disabled',
        component: PremiumDisabledComponent
    },
    {
        path: 'credit-card-processing',
        loadChildren: () => import('app/credit-card-processing/credit-card-processing.module').then(m => m.CreditCardProcessingModule),
    },
    {
        path: 'terminal-info/:terminal_id',
        loadChildren: () => import('app/terminal-info/terminal-info.module').then(m => m.TerminalInfoModule),
    },
    {
        path: 'vehicle-specifications',
        loadChildren: () => import('app/vehicle-motor-data-specs/vehicle-motor-data-specs.module').then(m => m.VehicleMotorDataSpecsModule),
        canActivate: [VehicleMotorDataService]
    },
    {
        path: 'items-available',
        loadChildren: () => import('app/items-available/items-available.module').then(m => m.ItemsAvailableModule),
    },
    {
        path: 'scheduler',
        component: BaySchedulerIframeComponent
    },
    {
        path: 'statistics-dashboard',
        component: StatisticsDashboardIframeComponent
    },
    {path: 'workflow-iframe',
        component: WorkflowIframeComponent
    },
    {
        path: 'financing',
        loadChildren: () => import('app/financing/financing.module').then(m => m.FinancingModule),
    },
    {
        path: 'vehicle-history',
        component: VehicleHistoryIframeComponent
    },
    {
        path: 'purchase-orders',
        loadChildren: () => import('app/purchase-orders/purchase-orders.module').then(m => m.PurchaseOrdersModule)
    },
    {
        path: 'physical-inventory',
        loadChildren: () => import('app/physical-inventory/physical-inventory.module').then(m => m.PhysicalInventoryModule)
    },
    {
        path: 'barcodes',
        loadChildren: () => import('app/barcodes/barcodes.module').then(m => m.BarcodesModule)
    },
    {
        path: 'mobile-bay-portal',
        component: MobileBayPortalIframeComponent
    },
    {
        path: 'payroll',
        loadChildren: () => import('app/payroll/payroll.module').then(m => m.PayrollModule)
    },
    {
        path: '**',
        component: PageNotFoundComponent
    }
];
