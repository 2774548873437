import * as finance from '../actions/finance.actions';

export interface State {
    loading: boolean;
    loadingSuccessful: boolean;
    loadingError: string;

    showSendTextToPayModal: boolean;
}

const initialState: State = {
    loading: false,
    loadingSuccessful: null,
    loadingError: null,

    showSendTextToPayModal: false,
};

export function reducer(state = initialState, action): State {
    switch (action.type) {
    case finance.SET_SHOW_TEXT_TO_PAY_MODAL:
        return {...state, showSendTextToPayModal: action.payload};
    }
}

export const getShowSendTextToPayModal = (state: State) => state.showSendTextToPayModal;
