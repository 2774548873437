import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// Components
import { SpinnerComponent } from './components/spinner/spinner.component';
import { AppHeaderComponent } from './components/app-header/app-header.component';
import { ControlMessagesComponent } from './components/form/control-messages/control-messages.component';

import { PathUpdateDirective } from './directives/path-update.directive';
import { AlertsComponent } from './components/alerts/alerts.component';
import { CloseIconComponent } from './icons/close-icon/close-icon.component';
import { IconComponent } from './icons/icon/icon.component';
import { AlignmentIconComponent } from './icons/alignment-icon/alignment-icon.component';
import { ButtonIconComponent } from './icons/button-icon/button-icon.component';
import { NavigationIconComponent } from './icons/navigation-icon/navigation-icon.component';
import { SpinnerLoadingComponent } from './components/spinner-loading/spinner-loading.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { WorkOrderNavComponent } from './components/work-order-nav/work-order-nav.component';
import { IframeComponent } from './components/iframe/iframe.component';
import { CollapsableComponent } from './components/collapsable/collapsable.component';
import { DashboardIndicatorsComponent } from './icons/dashboard-indicators/dashboard-indicators.component';
import { FormSectionComponent } from './components/form/form-section/form-section.component';
import { FormSubSectionComponent } from './components/form/form-sub-section/form-sub-section.component';
import { FormSectionQuestionComponent } from './components/form/form-section-question/form-section-question.component';
import { PanelComponent } from './components/panel/panel/panel.component';
import { PanelHeaderComponent } from './components/panel/panel-header/panel-header.component';
import { PanelBodyComponent } from './components/panel/panel-body/panel-body.component';
import { FormQuestionComponent } from './components/form/form-question/form-question.component';
import { ImageUploaderComponent } from './components/image-uploader/image-uploader.component';
import { ItemPickerComponent } from './components/item-picker/item-picker.component';
import { ItemComponent } from './components/item-picker/item/item.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { StoppingDistanceComponent } from './components/stopping-distance/stopping-distance.component';
import { VehicleImageUploadComponent } from './components/vehicle-image-upload/vehicle-image-upload.component';
import { LinkGrooveGloveComponent } from './components/link-groove-glove/link-groove-glove.component';
import { MessageAlertComponent } from './components/message-alert/message-alert.component';
import { MessageListComponent } from './components/message-list/message-list.component';
import { TextMessagesListComponent } from './components/text-messages-list/text-messages-list.component';
import { SalesmanTechnicianPopupComponent } from './components/salesman-technician-popup/salesman-technician-popup.component';
import { AssignSalesAndTechPerLineItemComponent } from './components/salesman-technician-popup/assign-sales-and-tech-per-line-item/assign-sales-and-tech-per-line-item.component';
import {CarfaxModule} from '../carfax/carfax.module';
import {NgxSmartModalModule} from 'ngx-smart-modal';
import { BarcodeScannerComponent } from './components/barcode-scanner/barcode-scanner.component';
import {ZXingScannerModule} from '@zxing/ngx-scanner';
import { OrderItemVerificationComponent } from './components/order-item-verification/order-item-verification.component';
import { PoItemVerificationBarcodeComponent } from './components/po-item-verification-barcode/po-item-verification-barcode.component';
import {GlobalSearchComponent} from './components/global-search/global-search/global-search.component';
import {GlobalSearchResultsContainerComponent} from './components/global-search/global-search-results/global-search-results-container/global-search-results-container.component';
import {GlobalSearchQuoteResultComponent} from './components/global-search/global-search-results/global-search-results-container/global-search-quote-result/global-search-quote-result.component';
import {GlobalSearchWoResultComponent} from './components/global-search/global-search-results/global-search-results-container/global-search-wo-result/global-search-wo-result.component';
import {GlobalSearchInvoiceResultComponent} from './components/global-search/global-search-results/global-search-results-container/global-search-invoice-result/global-search-invoice-result.component';
import {GlobalSearchPoResultComponent} from './components/global-search/global-search-results/global-search-results-container/global-search-po-result/global-search-po-result.component';
import { GlobalSearchResultTypeContainerComponent } from './components/global-search/global-search-results/global-search-results-container/global-search-result-type-container/global-search-result-type-container.component';
import { PanelIconHeaderComponent } from './components/panel/panel-icon-header/panel-icon-header.component';
import { BarcodeScannerFooterInputsComponent } from './components/barcode-scanner/barcode-scanner-footer-inputs/barcode-scanner-footer-inputs.component';
import { FormSubmissionTreadTrackerReportComponent } from './components/form-submission-tread-tracker-report/form-submission-tread-tracker-report.component';
import { SpinnerLoadingOverlayComponent } from './components/spinner-loading-overlay/spinner-loading-overlay.component';
import { BarcodeScannerHistoryComponent } from './components/barcode-scanner/barcode-scanner-history/barcode-scanner-history.component';
import { WorkOrderStatusTextStatusConfirmComponent } from './components/work-order-status-text-status-confirm/work-order-status-text-status-confirm.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RouterModule,
        FormsModule,
        CarfaxModule,
        NgxSmartModalModule.forChild(),
        ZXingScannerModule
    ],
    declarations: [
        AppHeaderComponent,
        SpinnerComponent,
        ControlMessagesComponent,
        AlertsComponent,
        PathUpdateDirective,
        IconComponent,
        AlignmentIconComponent,
        CloseIconComponent,
        ButtonIconComponent,
        NavigationIconComponent,
        SpinnerLoadingComponent,
        NavigationComponent,
        WorkOrderNavComponent,
        IframeComponent,
        CollapsableComponent,
        DashboardIndicatorsComponent,
        FormSectionComponent,
        FormSubSectionComponent,
        FormSectionQuestionComponent,
        PanelComponent,
        PanelHeaderComponent,
        PanelBodyComponent,
        FormQuestionComponent,
        ImageUploaderComponent,
        ItemPickerComponent,
        ItemComponent,
        StoppingDistanceComponent,
        VehicleImageUploadComponent,
        LinkGrooveGloveComponent,
        MessageAlertComponent,
        MessageListComponent,
        TextMessagesListComponent,
        SalesmanTechnicianPopupComponent,
        AssignSalesAndTechPerLineItemComponent,
        BarcodeScannerComponent,
        OrderItemVerificationComponent,
        PoItemVerificationBarcodeComponent,
        GlobalSearchComponent,
        GlobalSearchResultsContainerComponent,
        GlobalSearchQuoteResultComponent,
        GlobalSearchWoResultComponent,
        GlobalSearchInvoiceResultComponent,
        GlobalSearchPoResultComponent,
        GlobalSearchResultTypeContainerComponent,
        PanelIconHeaderComponent,
        BarcodeScannerFooterInputsComponent,
        FormSubmissionTreadTrackerReportComponent,
        SpinnerLoadingOverlayComponent,
        BarcodeScannerHistoryComponent,
        WorkOrderStatusTextStatusConfirmComponent
    ],
    exports: [
        AppHeaderComponent,
        SpinnerComponent,
        ControlMessagesComponent,
        AlertsComponent,
        PathUpdateDirective,
        IconComponent,
        AlignmentIconComponent,
        CloseIconComponent,
        ButtonIconComponent,
        NavigationIconComponent,
        SpinnerLoadingComponent,
        NavigationComponent,
        WorkOrderNavComponent,
        IframeComponent,
        CollapsableComponent,
        DashboardIndicatorsComponent,
        FormSectionComponent,
        FormSubSectionComponent,
        FormSectionQuestionComponent,
        PanelComponent,
        PanelHeaderComponent,
        PanelBodyComponent,
        FormQuestionComponent,
        ImageUploaderComponent,
        ItemPickerComponent,
        StoppingDistanceComponent,
        VehicleImageUploadComponent,
        LinkGrooveGloveComponent,
        MessageAlertComponent,
        MessageListComponent,
        TextMessagesListComponent,
        BarcodeScannerComponent,
        OrderItemVerificationComponent,
        PoItemVerificationBarcodeComponent,
        PanelIconHeaderComponent,
        BarcodeScannerFooterInputsComponent,
        FormSubmissionTreadTrackerReportComponent,
        SpinnerLoadingOverlayComponent
    ]
})
export class CommonComponentsModule { }
