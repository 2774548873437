import {switchMap, map, catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {createEffect, Actions, ofType} from '@ngrx/effects';

import {Observable, of, from as fromPromise} from 'rxjs';

import * as physical_inventory from '../actions/physical-inventory.actions';
import {PhysicalInventoryService} from '../../core/services/physical-inventory/physical-inventory.service';
import {PhysicalInventoryCountService} from '../../core/services/physical-inventory/physical-inventory-count.service';
import {error} from 'protractor';
import {ManualUpdateWriteInItemCountSuccessful} from '../actions/physical-inventory.actions';

@Injectable()
export class PhysicalInventoryEffects {


    getLocationPhysicalInventoryMenuSetups$: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(physical_inventory.GET_LOCATION_PHYSICAL_INVENTORY_MENU_SETUPS),
        map((action: physical_inventory.GetLocationPhysicalInventorySetups) => action.payload),
        switchMap((payload) =>
            fromPromise(this.physicalInventoryCountService.getLocationPhysicalInventoryMenuSetups()).pipe(
                map((response) => new physical_inventory.GetLocationPhysicalInventorySetupsSuccessful(response)),
                catchError((error) => of(new physical_inventory.GetLocationPhysicalInventorySetupsFailed(error)))
            )
        )
    ));


    updateLocationPhysicalInventorySetup$: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(physical_inventory.UPDATE_LOCATION_PHYSICAL_INVENTORY_SETUP),
        map((action: physical_inventory.UpdatePhysicalInventorySetup) => action.payload),
        switchMap((payload) =>
            fromPromise(this.physicalInventoryCountService.updateLocationPhysicalInventorySetup(payload)).pipe(
                map((response) => new physical_inventory.UpdatePhysicalInventorySetupSuccessFul(response)),
                catchError((error) => of(new physical_inventory.UpdatePhysicalInventorySetupFailed(error)))
            )
        )
    ));


    getPhysicalInventorySetupItems$: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(physical_inventory.GET_PHYSICAL_INVENTORY_SETUP_ITEMS),
        map((action: physical_inventory.GetPhysicalInventorySetupItems) => action.payload),
        switchMap((payload) =>
            fromPromise(this.physicalInventoryCountService.getPhysicalInventorySetupItems(payload.setupID, payload.input_number, payload.page)).pipe(
                map((response) => new physical_inventory.GetPhysicalInventorySetupItemsSuccessFul(response)),
                catchError((error) => of(new physical_inventory.GetPhysicalInventorySetupItemsFailed(error)))
            )
        )
    ));


    getPhysicalInventorySetupWriteInItems$: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(physical_inventory.GET_PHYSICAL_INVENTORY_SETUP_WRITE_IN_ITEMS),
        map((action: physical_inventory.GetPhysicalInventorySetupWriteInItems) => action.payload),
        switchMap((payload) =>
            fromPromise(this.physicalInventoryCountService.getPhysicalInventorySetupWriteInItems(payload.setupID, payload.input_number, payload.page)).pipe(
                map((response) => new physical_inventory.GetPhysicalInventorySetupWriteInItemsSuccessful(response)),
                catchError((error) => of(new physical_inventory.GetPhysicalInventorySetupWriteInItemsFailed(error)))
            )
        )
    ));


    createPhysicalInventorySetupWriteInItem$: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(physical_inventory.CREATE_PHYSICAL_INVENTORY_SETUP_WRITE_IN_ITEM),
        map((action: physical_inventory.CreatePhysicalInventorySetupWriteInItem) => action.payload),
        switchMap((payload) =>
            fromPromise(this.physicalInventoryCountService.createPhysicalInventorySetupWriteInItem(payload.setupID, payload.input_num, payload.itemID)).pipe(
                map((response) => new physical_inventory.CreatePhysicalInventorySetupWriteInItemSuccessful(response)),
                catchError((err) => of(new physical_inventory.CreatePhysicalInventorySetupWriteInItemFailed(err)))
            )
        )
    ));


    deletePhysicalInventorySetupWriteInItem$: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(physical_inventory.DELETE_PHYSICAL_INVENTORY_SETUP_WRITE_IN_ITEM),
        map((action: physical_inventory.DeletePhysicalInventorySetupWriteInItem) => action.payload),
        switchMap((payload) =>
            fromPromise(this.physicalInventoryCountService.deletePhysicalInventorySetupWriteInItem(payload.setupID, payload.input_num, payload.itemID)).pipe(
                map((response) => new physical_inventory.DeletePhysicalInventorySetupWriteInItemSuccessful(response)),
                catchError((error) => of(new physical_inventory.DeletePhysicalInventorySetupWriteInItemFailed(error)))
            )
        )
    ));



    manualUpdatePhysicalInventoryItemCount$: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(physical_inventory.MANUAL_UPDATE_ITEM_COUNT),
        map((action: physical_inventory.UpdatePhysicalInventorySetup) => action.payload),
        switchMap((payload) =>
            fromPromise(this.physicalInventoryCountService.manualUpdateItemCount(payload)).pipe(
                map((response) => new physical_inventory.ManualUpdateItemCountSuccessful(response)),
                catchError((error) => of(new physical_inventory.ManualUpdateItemCountFailed(error)))
            )
        )
    ));


    barcodeUpdatePhysicalInventoryItemCount$: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(physical_inventory.BARCODE_UPDATE_ITEM_COUNT),
        map((action: physical_inventory.BarcodeUpdateItemCount) => action.payload),
        switchMap((payload) =>
            fromPromise(this.physicalInventoryCountService.barcodeUpdateItemCount(payload)).pipe(
                map((response) => new physical_inventory.BarcodeUpdateItemCountSuccessful(response)),
                catchError((error) => of(new physical_inventory.BarcodeUpdateItemCountFailed(error)))
            )
        )
    ));


    barcodeUpdatePhysicalInventoryWriteInItemCount$: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(physical_inventory.BARCODE_UPDATE_WRITE_IN_ITEM_COUNT),
        map((action: physical_inventory.BarcodeUpdateWriteInItemCount) => action.payload),
        switchMap((payload) =>
            fromPromise(this.physicalInventoryCountService.barcodeUpdateWriteInItemCount(payload)).pipe(
                map((response) => new physical_inventory.BarcodeUpdateWriteInItemCountSuccessful(response)),
                catchError((error) => of(new physical_inventory.BarcodeUpdateWriteInItemCountFailed(error)))
            )
        )
    ));


    manualUpdatePhysicalInventoryWriteInItemCount$: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(physical_inventory.MANUAL_UPDATE_WRITE_IN_ITEM_COUNT),
        map((action: physical_inventory.ManualUpdateWriteInItemCount) => action.payload),
        switchMap((payload) =>
            fromPromise(this.physicalInventoryCountService.manualUpdateWriteInItemCount(payload)).pipe(
                map((response) => new physical_inventory.ManualUpdateWriteInItemCountSuccessful(response)),
                catchError((error) => of(new physical_inventory.ManualUpdateWriteInItemCountFailed(error)))
            )
        )
    ));


    clearPhysicalInventorySetupItemsCache$: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(physical_inventory.CLEAR_PHYSICAL_INVENTORY_SETUP_ITEMS_CACHE),
        map((action: physical_inventory.ClearPhysicalInventorySetupItemsCache) => action.payload),
        switchMap((payload) =>
            fromPromise(this.physicalInventoryCountService.clearPhysicalInventorySetupItemsCache(payload.setupID, payload.input_num)).pipe(
                map((response) => new physical_inventory.ClearPhysicalInventorySetupItemsCacheSuccessful(response)),
                catchError((error) => of(new physical_inventory.ClearPhysicalInventorySetupItemsCacheFailed(error)))
            )
        )
    ));


    clearPhysicalInventorySetupWriteInItemsCache$: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(physical_inventory.CLEAR_PHYSICAL_INVENTORY_SETUP_WRITE_IN_ITEMS_CACHE),
        map((action: physical_inventory.ClearPhysicalInventorySetupWriteInItemsCache) => action.payload),
        switchMap((payload) =>
            fromPromise(this.physicalInventoryCountService.clearPhysicalInventorySetupWriteInItemsCache(payload.setupID, payload.input_num)).pipe(
                map((response) => new physical_inventory.ClearPhysicalInventorySetupWriteInItemsCacheSuccessful(response)),
                catchError((error) => of(new physical_inventory.ClearPhysicalInventorySetupWriteInItemsCacheFailed(error)))
            )
        )
    ));

    constructor(
        private $actions: Actions,
        private physicalInventoryService: PhysicalInventoryService,
        private physicalInventoryCountService: PhysicalInventoryCountService
    ) {
    }
}
