import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'app-iframe',
    templateUrl: './iframe.component.html',
    styleUrls: ['./iframe.component.scss']
})
export class IframeComponent implements OnInit {

    @Input() src;
    @Input() theme;
    @Input() fitToWindow = false;
    @Input() maxHeight: false|number = false;
    @Input() minHeight: false|number = false;

    public sanitizedSrc;

    constructor(
        public sanitizer: DomSanitizer
    ) { }

    ngOnInit() {
        this.sanitizedSrc = this.iframeSrc();
    }

    iframeSrc(): SafeResourceUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl(this.src);
    }

    get themeClass(): string {
        return `${this.theme}`;
    }

    @Input()
    set resetLocation(resetLocation) {
        if (resetLocation) {
            this.sanitizedSrc = this.iframeSrc();
        }
    }

    // hacks for mobile integration on pieces we aren't able to spend time rewriting right now
    onIframeLoad(myIframe) {
        if (myIframe.contentDocument && myIframe.contentWindow.$$) {
            // this.setTheme(myIframe);
            myIframe.onError = function(error) {
                console.error(error);
            };
            const $$ = myIframe.contentWindow.$$;
            const footerWrapper = $$('#mobile-footer-wrapper')[0];
            const footer = $$('#mobile-footer')[0];
            if (footerWrapper) {
                footerWrapper.removeChild(footer);
            }

            const headerWrapper = $$('#mobile-header-wrapper')[0];
            const header = $$('#mobile-header')[0];
            if (headerWrapper) {
                headerWrapper.removeChild(header);
            }
            this.resizeIframe(myIframe);

            this.onElementHeightChange(myIframe.contentWindow, myIframe.contentDocument.body, () => {
                this.resizeIframe(myIframe);
            });

            const parentContainer = $$('#parent-container')[0];

            if (parentContainer) {
                parentContainer.addEventListener('click', event => {
                });
            }
        } else {
            myIframe.style.height = this.minHeight + 'px';
        }
    }

    setTheme(myIframe) {
        const head = myIframe.contentWindow.$$('head')[0];
        head.innerHTML += '<style>body { background: transparent; color: #222222; }</style>';
    }

    resizeIframe(myIframe) {
        const body = myIframe.contentDocument.body;
        let height;
        try {
            if (this.fitToWindow) {
                height = 600;
            } else if (this.minHeight) {
                height = Math.max(body.getHeight(), this.minHeight);
            } else {
                height = body.getHeight();
            }
        } catch (e) {
            height = body.scrollHeight;
        }

        const whitespaceFix = this.fitToWindow ? 0 : 30;

        if (this.maxHeight && (height + whitespaceFix > this.maxHeight)) {
            height = this.maxHeight;
        }
        myIframe.style.height = height + whitespaceFix + 'px';
    }

    onElementHeightChange(contextWindow, elm, callback) {
        let lastHeight = elm.clientHeight;
        let newHeight;
        (function run() {
            newHeight = elm.clientHeight;
            if (lastHeight !== newHeight) {
                callback();
            }
            lastHeight = newHeight;

            if (elm.onElementHeightChangeTimer) {
                contextWindow.clearTimeout(elm.onElementHeightChangeTimer);
            }

            elm.onElementHeightChangeTimer = contextWindow.setTimeout(run, 200);
        })();
    }

}
