import {throwError as observableThrowError, Observable, EMPTY as empty, of, from as fromPromise} from 'rxjs';

import {catchError, switchMap, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {createEffect, Actions, ofType} from '@ngrx/effects';


import * as customer from '../actions/customer.actions';

import {WorkOrderProvider} from '../../core/providers/work-order.provider';
import {CustomerService} from '../../core/services/customer.service';

@Injectable()
export class CustomerEffects {


    lodCustomer: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(customer.LOAD_CUSTOMER),
        map((action: customer.LoadCustomerAction) => action.payload),
        switchMap((customerID) => {
            if (!customerID) {
                return observableThrowError({payload: {error: {message: 'Could not load customer'}}});
            }

            return fromPromise(this.customerService.getCustomer(customerID)).pipe(
                map((customerInfo: any) => new customer.LoadCustomerSuccessfulAction(customerInfo.data)), catchError((error) => of(new customer.LoadCustomerFailedAction({error: {message: "Customer could not be loaded, please try again"}}))));
        })));


    createCustomer: Observable<customer.Action> = createEffect(() => this.$actions.pipe(
        ofType(customer.CREATE_CUSTOMER),
        map((action: customer.CreateCustomerAction) => action.payload),
        switchMap((creationInfo) =>
            this.customerService.createCustomer(creationInfo).pipe(
                switchMap((customerInfo) => fromPromise(this.workOrderProvider.setCustomer(customerInfo, false)).pipe(map(response => customerInfo))),
                map(customerInfo => new customer.CreateCustomerSuccessfulAction(customerInfo)),
                catchError(() => of(new customer.CreateCustomerFailedAction({error: {message: 'Customer creat work order, please try again later'}}))))
        )));


    updateCustomer: Observable<customer.Action> = createEffect(() => this.$actions.pipe(
        ofType(customer.UPDATE_CUSTOMER),
        map((action: customer.UpdateCustomerAction) => action.payload),
        switchMap((customerUpdateInfo) =>
            fromPromise(this.customerService.updateCustomer(customerUpdateInfo, customerUpdateInfo.customerId)).pipe(
                map((customerInfo) => new customer.UpdateCustomerSuccessfulAction(customerInfo)),
                catchError(() => of(new customer.UpdateCustomerFailedAction({error: {message: 'Could not update customer'}}))))
        )));

    constructor(private customerService: CustomerService, private $actions: Actions, private workOrderProvider: WorkOrderProvider) {
    }
}
