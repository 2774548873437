export const environment = {
    production: true,
    tireguruBaseApi: '/api-proxy',
    tireguruBaseUrl: 'https://mobile.tireguru.net',
    tireguruLegacyBaseURL: 'https://businesscenter.tireguru.net/',
    rollbar: {
        accessToken: 'f3ae94226f744d8fa068b0f7038a01c6',
        captureUncaught: true,
        captureUnhandledRejections: true
    },
    echoUrl: 'https://echo.tireguru.net'
};

