<table *ngIf="veh_carfax">
  <tr>
    <td class="headerVerticalAlign textAlignLeft">
      <span class="title is-size-3 is-bold">
        Optional*
      </span><br>

      <span *ngIf="veh_carfax['data']['oem-air-conditioning']"><strong class="subtitle">Air Conditioning:</strong>
            <span>{{veh_carfax['data']['oem-air-conditioning']}}<br></span>
          </span>

      <span *ngIf="veh_carfax['data']['oem-antilock-breaks']"><strong class="subtitle">Anti-lock Brakes:</strong>
            <span>{{veh_carfax['data']['oem-antilock-breaks']}}<br></span>
          </span>

      <span *ngIf="veh_carfax['data']['oem-daytime-running-lights']"><strong
        class="subtitle">Daytime Running Lights:</strong>
            <span>{{veh_carfax['data']['oem-daytime-running-lights']}}<br></span>
      </span>

      <span *ngIf="veh_carfax['data']['oem-power-breaks']"><strong class="subtitle">Power Breaks:</strong>
            <span>{{veh_carfax['data']['oem-power-breaks']}}<br></span>
          </span>

      <span *ngIf="veh_carfax['data']['oem-power-windows']"><strong class="subtitle">Power Windows:</strong>
            <span>{{veh_carfax['data']['oem-power-windows']}}<br></span>
          </span>

      <span *ngIf="veh_carfax['data']['oem-opt-radio']"><strong class="subtitle">Radio:</strong>
            <span>{{veh_carfax['data']['oem-radio']}}<br></span>
          </span>

      <span *ngIf="veh_carfax['data']['oem-restraint-type']"><strong class="subtitle">Restraint Type:</strong>
            <span>{{veh_carfax['data']['oem-restraint-type']}}<br></span>
          </span>

      <span *ngIf="veh_carfax['data']['oem-roof']"><strong class="subtitle">Roof:</strong>
            <span>{{veh_carfax['data']['oem-roof']}}<br></span>
          </span>

      <span *ngIf="veh_carfax['data']['oem-tilt-wheel']"><strong class="subtitle">Tilt Wheel:</strong>
            <span>{{veh_carfax['data']['oem-tilt-wheel']}}<br></span>
          </span>

      <span *ngIf="veh_carfax['data']['oem-security-system']"><strong class="subtitle">Security System:</strong>
            <span>{{veh_carfax['data']['oem-security-system']}}<br></span>
          </span>

      <span *ngIf="veh_carfax['data']['oem-segmentation-code']"><strong class="subtitle">Segmentation:</strong>
            <span>{{veh_carfax['data']['oem-segmentation-code']}}<br></span>
          </span>

    </td>
  </tr>
</table>
