
import {map, switchMap} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';
import { Router } from '@angular/router';
import { WorkOrderProvider } from '../providers/work-order.provider';
import {getServer, State} from '../../store/reducers';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';

@Injectable()
export class CustomerService {

    public url: string;
    private legacyUrl: string;

    constructor(
        private http: HttpClient,
        private router: Router,
        private workOrderProvider: WorkOrderProvider,
        private store: Store<State>
    ) {
        this.url = environment.tireguruBaseApi + '/customer';
        this.legacyUrl = environment.tireguruLegacyBaseURL;
    }

    static isCustomerMissingInfo(customer) {
        // TODO: Fix for now
        return false;
        // No customer
        // if (!customer) {
        //     return true;
        // }
        // // Check if property exists and that it is not empty
        // if (customer.cus_fname === 'undefined' || customer.cus_fname.length === 0) {
        //     return true;
        // }
        // if (customer.cus_lname === 'undefined' || customer.cus_lname.length === 0) {
        //     return true;
        // }

        // // Customer exists and has all the appropritate info
        // return false;
    }

    static customerHasVehicle(customer) {
        // TODO: fix for now
        return true;
        // const vehicles = customer.vehicles;
        // if (vehicles && vehicles.length > 0) {
        //     console.log('Customer has vehicle');
        //     return true;
        // }
        // console.log('Customer has NO vehicle');
        // return false;
    }

    // HELPER METHOD. TODO: move to better place
    checkForMissingCustomerOrVehicleInfo(customer) {
        // Check if customer has a vehicle - add car
        if (!CustomerService.customerHasVehicle(customer)) {
            this.router.navigate(['workflow']);
            return false;
            // this.router.navigate(['vehicles/create']);
        }
        // Check if Customer is missing info - redirect
        if (CustomerService.isCustomerMissingInfo(customer)) {
            this.workOrderProvider.setCustomer(customer);
            this.router.navigate(['customers/create']);
            return false;
        }

        return true;
    }

    createCustomer(customer: any): Observable<any> {
        return this.store.select(getServer).pipe(
            switchMap((server) => this.http.post(this.url, customer)),
            map((response: any) => response.data), );
    }

    updateCustomer(customer, customerId) {

        return this.store.select(getServer).pipe(switchMap((server) => {
            const url = `${this.legacyUrl}/${customerId}`;
            return this.http.put(url, customer);
        })).toPromise();
    }

    getCustomer(customerId) {
        const url = `${this.url}/${customerId}`;
        return this.http.get(url, {}).toPromise();
    }


    getRecentServicesForCustomerAndVehicle(customerId, vehicleId) {
        const url = `${this.url}/${customerId}/recent`;
        return this.http.get(url, {
            params: {
                vehicleId
            }
        }).toPromise();
    }

    getAppointmentsForCustomer(customerId) {
        const url = `${this.url}/${customerId}/appointments`;

        return this.http.get(url, {}).toPromise();
    }

    getDeclinedServicesForCustomerVehicle(customerId, vehicleId) {
        const type = 'declined-service';
        return this.getWorkOrderForCustomerVehicle(customerId, vehicleId, type);
    }

    getWorkOrderForCustomerVehicle(customerId, vehicleId, type = null) {
        const url = `${this.url}/${customerId}/vehicle/${vehicleId}/work-orders`;

        return this.http.get(url, {
            params: {
                type
            }
        }).toPromise();
    }

    _mapDeclinedServices(workOrders) {

        const declinedServices = [];

        for (const workOrder of workOrders) {

            const declinedService = {
                id: workOrder.wor_workorderID,
                header: {},
                items: [],
                workOrder
            };
            for (const item of workOrder.item_relations) {
                // If it is a header
                if (!item.woi_parent_woitemID || item.woi_parent_woitemID === 0) {
                    declinedService.header = item;
                } else {
                    declinedService.items.push(item);
                }
            }
            declinedServices.push(declinedService);
        }

        return declinedServices;
    }

}
