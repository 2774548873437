import {catchError, switchMap, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {createEffect, Actions, ofType} from '@ngrx/effects';
import {SearchService} from '../../core/services/search.service';

import * as search from '../actions/search.actions';
import {Observable, of, from as fromPromise} from 'rxjs';

@Injectable()
export class SearchEffects {

    search: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(search.SEARCH),
        map((action: search.SearchAction) => action.payload),
        switchMap(searchTerm =>
            this.searchService.search(searchTerm).pipe(
                map((response: any) => new search.SearchSuccessfulAction(response)),
                catchError(error => of(new search.SearchFailedAction(error))))
        )));


    public constructor(private $actions: Actions, private searchService: SearchService) {
    }
}
