import { Component, OnInit, Input } from '@angular/core';
import { Constants } from '../../../core/constants/constants';

@Component({
    selector: 'app-close-icon',
    templateUrl: './close-icon.component.html',
    styleUrls: ['./close-icon.component.scss']
})
export class CloseIconComponent implements OnInit {
    @Input() fill: string;

    constructor() {}

    ngOnInit() { }

    get color(): string {
        return this.fill || '#FFFFFF';
    }

}
