<div class="assign-container">
  <div>
    <p><b>{{workOrderItem.woi_description}}</b></p>
  </div>
  <div class="drop-down">
    <label>Salesman: </label><br>
    <select class="select" [disabled]="!profile['permissions']['Change_Salesperson']" (change)="updateAssignment.emit({'itemID': workOrderItem.woi_workitemID, 'employeeID': sales1.value, 'assignment': 'sales1'})" #sales1>
      <option value="">Default</option>
      <option *ngFor="let salesman of salesmen" value="{{salesman.emp_employeeID}}" [selected]="salesman.emp_employeeID == workOrderItem.woi_sales1">{{salesman.emp_fname}} {{salesman.emp_lname}}</option>
    </select><br>
    <select class="select" [disabled]="!profile['permissions']['Change_Salesperson']" #sales2 (change)="updateAssignment.emit({'itemID': workOrderItem.woi_workitemID, 'employeeID': sales2.value, 'assignment': 'sales2'})">
      <option>--secondary--</option>
      <option *ngFor="let salesman of salesmen" value="{{salesman.emp_employeeID}}" [selected]="salesman.emp_employeeID == workOrderItem.woi_sales2">{{salesman.emp_fname}} {{salesman.emp_lname}}</option>
    </select>

  </div>
  <div class="drop-down">
    <label>Technician: </label><br>
    <select class="select" [disabled]="!profile['permissions']['change_wo_technician']" #tech1 (change)="updateAssignment.emit({'itemID': workOrderItem.woi_workitemID, 'employeeID': tech1.value, 'assignment': 'tech1'})">
      <option value="">Not Assigned</option>
      <option *ngFor="let salesman of techs" value="{{salesman.emp_employeeID}}" [selected]="salesman.emp_employeeID == workOrderItem.woi_tech1">{{salesman.emp_fname}} {{salesman.emp_lname}}</option>
    </select><br>
    <select class="select" [disabled]="!profile['permissions']['change_wo_technician']" #tech2 (change)="updateAssignment.emit({'itemID': workOrderItem.woi_workitemID, 'employeeID': tech2.value, 'assignment': 'tech2'})">
      <option>--secondary--</option>
      <option *ngFor="let salesman of techs" value="{{salesman.emp_employeeID}}" [selected]="salesman.emp_employeeID == workOrderItem.woi_tech2">{{salesman.emp_fname}} {{salesman.emp_lname}}</option>
    </select>
  </div>
</div>

