import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

@Injectable()
export class CustomerCommunicationsService {
    private communicationsUrl: string;

    constructor(private http: HttpClient) {
        this.communicationsUrl = environment.tireguruBaseUrl + '/app/ajax/customers/';
    }

    public getUnreadTextsCount(locID) {
        const url = `${this.communicationsUrl}` + 'messages/unread/count/' + locID;

        return this.http.get(url).toPromise();
    }

    public getTextMessages(locID, viewUnread, page, search) {
        const url = `${this.communicationsUrl}` + 'messages/all/' + locID + '?page=' + page + '&viewUnread=' + viewUnread + '&search=' + search;

        return this.http.get(url).toPromise();
    }

    public getAllCommunications(customerId) {
        const url = `${this.communicationsUrl}` + customerId + '/messages';

        return this.http.get(url).toPromise();
    }

    public sendTextMessage(customerId, content) {
        const url = `${this.communicationsUrl}` + customerId + '/messages/send';
        const body = {'message': content};
        return this.http.post(url, body).toPromise();
    }

    public markTextsAsRead(customerId, userId) {
        if (customerId != 'undefined') {
            const url = `${this.communicationsUrl}` + 'messages/mark-as-read/all/' + customerId + '/' + userId;
            const body = [];
            return this.http.post(url, body).toPromise();
        }
    }

    public markIndividualTextAsRead(textID, userID) {
        const url = `${this.communicationsUrl}` + 'messages/mark-as-read/individual/' + textID + '/' + userID;
        const body = [];
        return this.http.post(url, body).toPromise();
    }
}
