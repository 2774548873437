import { Action as NgrxAction } from '@ngrx/store';

export const CLEAR_VEHICLE_MOTOR_DATA = '[Vehicle] Set vehicle motor data';
export const LOAD_VEHICLE_MOTOR_DATA = '[Vehicle] Get vehicle motor data';
export const LOAD_VEHICLE_MOTOR_DATA_SUCCESSFUL = '[Vehicle] Get vehicle motor data successful';
export const LOAD_VEHICLE_MOTOR_DATA_FAILED = '[Vehicle] Get vehicle motor data failed';

export const CLEAR_VEHICLE_MOTOR_DATA_SPECS = '[Vehicle] Set vehicle motor data specs';
export const LOAD_VEHICLE_MOTOR_DATA_SPECS = '[Vehicle] Get vehicle motor data specs';
export const LOAD_VEHICLE_MOTOR_DATA_SPECS_SUCCESSFUL = '[Vehicle] Get vehicle motor data specs successful';
export const LOAD_VEHICLE_MOTOR_DATA_SPECS_FAILED = '[Vehicle] Get vehicle motor data specs failed';

export const CLEAR_VEHICLE_MOTOR_DATA_ITEMS_AVAILABLE = '[Vehicle] Set vehicle motor data items available';
export const LOAD_VEHICLE_MOTOR_DATA_ITEMS_AVAILABLE = '[Vehicle] Get vehicle motor data items available';
export const LOAD_VEHICLE_MOTOR_DATA_ITEMS_AVAILABLE_SUCCESSFUL = '[Vehicle] Get vehicle motor data items available successful';
export const LOAD_VEHICLE_MOTOR_DATA_ITEMS_AVAILABLE_FAILED = '[Vehicle] Get vehicle motor data items available failed';

export const CLEAR_VEHICLE_MOTOR_DATA_ENGINE_TYPES = '[Vehicle] Set vehicle motor data engine types';
export const LOAD_VEHICLE_MOTOR_DATA_ENGINE_TYPES = '[Vehicle] Get vehicle motor data engine types';
export const LOAD_VEHICLE_MOTOR_DATA_ENGINE_TYPES_SUCCESSFUL = '[Vehicle] Get vehicle motor data engine types successful';
export const LOAD_VEHICLE_MOTOR_DATA_ENGINE_TYPES_FAILED = '[Vehicle] Get vehicle motor data engine types failed';

export class ClearVehicleMotorDataAction implements NgrxAction {
    readonly type = CLEAR_VEHICLE_MOTOR_DATA;

    constructor() {
    }
}

export class LoadVehicleMotorDataAction implements NgrxAction {
    readonly type = LOAD_VEHICLE_MOTOR_DATA;

    constructor(public payload: any) {
    }
}

export class LoadVehicleMotorDataSuccessfulAction implements NgrxAction {
    readonly type = LOAD_VEHICLE_MOTOR_DATA_SUCCESSFUL;

    constructor(public payload: any) {
    }
}

export class LoadVehicleMotorDataFailedAction implements NgrxAction {
    readonly type = LOAD_VEHICLE_MOTOR_DATA_FAILED;

    constructor(public payload: any) {
    }
}

export class ClearVehicleMotorDataSpecsAction implements NgrxAction {
    readonly type = CLEAR_VEHICLE_MOTOR_DATA_SPECS;

    constructor() {
    }
}

export class LoadVehicleMotorDataSpecsAction implements NgrxAction {
    readonly type = LOAD_VEHICLE_MOTOR_DATA_SPECS;

    constructor(public payload: any) {
    }
}

export class LoadVehicleMotorDataSpecsSuccessfulAction implements NgrxAction {
    readonly type = LOAD_VEHICLE_MOTOR_DATA_SPECS_SUCCESSFUL;

    constructor(public payload: any) {
    }
}

export class LoadVehicleMotorDataSpecsFailedAction implements NgrxAction {
    readonly type = LOAD_VEHICLE_MOTOR_DATA_SPECS_FAILED;

    constructor(public payload: any) {
    }
}

export class ClearVehicleMotorDataItemsAvailableAction implements NgrxAction {
    readonly type = CLEAR_VEHICLE_MOTOR_DATA_ITEMS_AVAILABLE;

    constructor() {
    }
}

export class LoadVehicleMotorDataItemsAvailableAction implements NgrxAction {
    readonly type = LOAD_VEHICLE_MOTOR_DATA_ITEMS_AVAILABLE;

    constructor(public payload: any) {
    }
}

export class LoadVehicleMotorDataItemsAvailableSuccessfulAction implements NgrxAction {
    readonly type = LOAD_VEHICLE_MOTOR_DATA_ITEMS_AVAILABLE_SUCCESSFUL;

    constructor(public payload: any) {
    }
}

export class LoadVehicleMotorDataItemsAvailableFailedAction implements NgrxAction {
    readonly type = LOAD_VEHICLE_MOTOR_DATA_ITEMS_AVAILABLE_FAILED;

    constructor(public payload: any) {
    }
}

export class ClearVehicleMotorDataEngineTypes implements NgrxAction {
    readonly type = CLEAR_VEHICLE_MOTOR_DATA_ENGINE_TYPES;

    constructor() {
    }
}

export class LoadVehicleMotorDataEngineTypes implements NgrxAction {
    readonly type = LOAD_VEHICLE_MOTOR_DATA_ENGINE_TYPES;

    constructor(public payload: any) {
    }
}

export class LoadVehicleMotorDataEngineTypesSuccessful implements NgrxAction {
    readonly type = LOAD_VEHICLE_MOTOR_DATA_ENGINE_TYPES_SUCCESSFUL;

    constructor(public payload: any) {
    }
}

export class LoadVehicleMotorDataEngineTypesFailed implements NgrxAction {
    readonly type = LOAD_VEHICLE_MOTOR_DATA_ENGINE_TYPES_FAILED;

    constructor(public payload: any) {
    }
}

export type Action = ClearVehicleMotorDataAction
| LoadVehicleMotorDataAction
| LoadVehicleMotorDataSuccessfulAction
| LoadVehicleMotorDataFailedAction
| ClearVehicleMotorDataSpecsAction
| LoadVehicleMotorDataSpecsAction
| LoadVehicleMotorDataSpecsSuccessfulAction
| LoadVehicleMotorDataSpecsFailedAction
| ClearVehicleMotorDataItemsAvailableAction
| LoadVehicleMotorDataItemsAvailableAction
| LoadVehicleMotorDataItemsAvailableSuccessfulAction
| LoadVehicleMotorDataItemsAvailableFailedAction
| ClearVehicleMotorDataEngineTypes
| LoadVehicleMotorDataEngineTypes
| LoadVehicleMotorDataEngineTypesSuccessful
| LoadVehicleMotorDataEngineTypesFailed;
