import { Action as NgrxAction } from '@ngrx/store';

export const LOAD_JOBS = '[Jobs] load jobs';
export const LOAD_JOBS_SUCCESSFUL = '[Jobs] Load jobs successful';
export const LOAD_JOBS_FAILED = '[Jobs] Load jobs failed';
export const LOAD_JOB_SUBCATEGORY = '[Jobs] Load job subcategory';
export const LOAD_JOB_SUBCATEGORY_SUCCESSFUL = '[Jobs] Load job subcategory successful';
export const LOAD_JOB_SUBCATEGORY_FAILED = '[Jobs] Load job subcategory failed';
export const LOAD_SUBCATEGORIES = '[Jobs] load subcategories';
export const LOAD_JOB_FORMS = '[Jobs] Load job forms';
export const LOAD_JOB_FORMS_SUCCESSFUL = '[Jobs] Load job forms successful';
export const LOAD_JOB_FORMS_FAILED = '[Jobs] Load job forms failed';

export class LoadJobsAction implements NgrxAction {
    readonly type = LOAD_JOBS;

    constructor(public payload: any) {
    }
}

export class LoadJobsSuccessfulAction implements NgrxAction {
    readonly type = LOAD_JOBS_SUCCESSFUL;

    constructor(public payload: any) {
    }
}

export class LoadJobsFailedAction implements NgrxAction {
    readonly type = LOAD_JOBS_FAILED;

    constructor(public payload: any) {
    }
}

export class LoadJobSubcategoryAction implements NgrxAction {
    readonly type = LOAD_JOB_SUBCATEGORY;

    constructor(public payload: any) {
    }
}

export class LoadJobSubcategorySuccessfulAction implements NgrxAction {
    readonly type = LOAD_JOB_SUBCATEGORY_SUCCESSFUL;

    constructor(public payload: any) {
    }
}

export class LoadJobsSubcategoryFailedAction implements NgrxAction {
    readonly type = LOAD_JOB_SUBCATEGORY_FAILED;

    constructor(public payload: any) {
    }
}

export class LoadSubcategoriesAction implements NgrxAction {
    readonly type = LOAD_SUBCATEGORIES;

    constructor(public payload: Array<number>) {
    }
}

export class LoadJobFormsAction implements NgrxAction {
    readonly type = LOAD_JOB_FORMS;

    constructor(public payload: any) {

    }
}

export class LoadJobFormsSuccessfulAction implements NgrxAction {
    readonly type = LOAD_JOB_FORMS_SUCCESSFUL;

    constructor(public payload: any) {

    }
}

export class LoadJobFormsFailedAction implements NgrxAction {
    readonly type = LOAD_JOB_FORMS_FAILED;

    constructor(public payload: any) {

    }
}
export type Action = LoadJobsAction
| LoadJobsSuccessfulAction
| LoadJobsFailedAction
| LoadJobSubcategoryAction
| LoadJobSubcategorySuccessfulAction
| LoadJobsSubcategoryFailedAction
| LoadSubcategoriesAction
| LoadJobFormsAction
| LoadJobFormsSuccessfulAction
| LoadJobFormsFailedAction;

