
import {map} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';


@Injectable()
export class GrooveGloveService {
    private grooveGloveUrl: string;
    private linkGrooveGloveUrl: string;

    constructor(private http: HttpClient) {
        this.grooveGloveUrl = environment.tireguruBaseApi + '/groove-glove';
        this.linkGrooveGloveUrl = environment.tireguruBaseApi + '/link-scan';
    }

    getGrooveGlove(locationId) {
        let url = `${this.grooveGloveUrl}`;
        url += '/' + locationId;
        return this.http.get(url).pipe(map((response: any) => response.data)).toPromise();
    }

    linkGrooveGlove(scanId, workOrderId, customerId, formId) {
        let url = `${this.linkGrooveGloveUrl}`;
        url += '/' + scanId + '/' + customerId + '/' + workOrderId + '/' + formId;
        const body = [];
        return this.http.post(url, body);
    }

    deleteGrooveGlove(scanId) {
        let url = `${this.linkGrooveGloveUrl}`;
        url += '/' + scanId;
        return this.http.delete(url);
    }

    getFormLinkedGrooveGloveScans(workOrderId, formId, locID) {
        const url = `${this.grooveGloveUrl}/linked/${workOrderId}/${formId}/${locID}`;
        return this.http.get(url).pipe(map((response: any) => response.data)).toPromise();
    }

}
