<app-panel *ngIf="!loading; else global_search_loading" [y_padding]="'has-no-padding-y'">
  <app-panel-body *ngIf="showResults; else no_result_found">
    <div class="global-search-result-container">
      <div *ngFor="let result_type of result_keys">
        <app-global-search-result-type-container class="has-text-left" *ngIf="results.response[result_type].length"
                                                 [profile]="profile"
                                                 [type_results]="results.response[result_type]"
                                                 [type]="result_type"
                                                 (go_to_purchase_order_item_verification)="goToPurchaseOrderItemVerification($event)"
                                                 (go_to_work_order)="goToWorkOrder($event)"
                                                 (handle_alert)="doAlert($event)"
        ></app-global-search-result-type-container>
      </div>
    </div>
  </app-panel-body>

  <ng-template #no_result_found>
    <app-panel-body class="is-error has-text-centered">
      No Results Found
    </app-panel-body>
  </ng-template>
</app-panel>

<ng-template #global_search_loading>
  <app-panel [y_padding]="'has-no-padding-y'">
    <app-panel-body class="centered-text">
      <div class="is-inline-block">
        <app-spinner-loading class="has-padding-1"></app-spinner-loading>
      </div>
    </app-panel-body>
  </app-panel>
</ng-template>
