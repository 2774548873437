import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-work-order-status-text-status-confirm',
    templateUrl: './work-order-status-text-status-confirm.component.html',
    styleUrls: ['./work-order-status-text-status-confirm.component.scss']
})
export class WorkOrderStatusTextStatusConfirmComponent implements OnInit {
    @Input() profile;
    @Input() customer;
    @Input() work_order;
    @Input() selected_status;

    public send_text: any = false;

    @Output() submit_text_status = new EventEmitter();
    @Output() change_status = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
        this.send_text = this.canSendText;
    }

    get canSendText() {
        return (
            this.profile.permissions.send_customer_texts
      && this.profile.location.settings.loc_marketing_enable_send
      && this.customer.cus_cellphone
      && this.selected_status
      && this.selected_status.wos_text
      && this.selected_status.wos_text.length > 0
        );
    }

    get customerName() {
        if (this.customer && this.customer.cus_businessname && this.customer.cus_businessname.length > 0) {
            return this.customer.businessname;
        }
        return `${this.customer.cus_fname || ''} ${this.customer.cus_lname || ''}`;
    }

    submit() {
        this.submit_text_status.emit({send_text: this.send_text});
    }
}
