import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-carfax-vehicle-info-information',
    templateUrl: './carfax-vehicle-info-information.component.html',
    styleUrls: ['./carfax-vehicle-info-information.component.scss']
})
export class CarfaxVehicleInfoInformationComponent implements OnInit {

    @Input() vehicle;

    constructor() { }

    ngOnInit() {
    }

}
