import * as workOrderMessages from '../actions/workordermessages.actions';

export interface State {
    messages: any;
    getSuccessful: boolean;
    users: any;
}

const initialState: State = {
    messages: [],
    getSuccessful: null,
    users: [],
};

export function reducer(state = initialState, action): State {
    switch (action.type) {
    case workOrderMessages.GET_WORK_ORDER_MESSAGES:
        return {...state};
    case workOrderMessages.GET_MESSAGES_SUCCESSFUL:
        return {...state, getSuccessful: true, messages: action.payload};
    case workOrderMessages.GET_MESSAGES_UNSUCCESSFUL:
        return {...state, getSuccessful: false};
    case workOrderMessages.MARK_WORK_ORDER_MESSAGE_AS_READ:
        let messages = state.messages.data.map(message => {
            if (message.wom_messageID == action.payload.messageID) {
                message.wom_read = 1;
            }
            return message;
        });
        return {...state, messages: {...state.messages, data: messages}};
    case workOrderMessages.MESSAGE_RECEIVED:
        return {...state, messages: {...state.messages, data: [action.payload.message, ...state.messages.data]}};
    case workOrderMessages.GET_USERS:
        return {...state};
    case workOrderMessages.GET_USERS_SUCCESSFUL:
        return {...state, users: action.payload, getSuccessful: true};
    case workOrderMessages.GET_USERS_UNSUCCESSFUL:
        return {...state, getSuccessful: false};
    case workOrderMessages.SEND_MESSAGE:
        return {...state, messages: {...state.messages, data: [action.payload.message, ...state.messages.data]}};
    default:
        return state;
    }
}

export const getAllWorkOrderMessages = (state: State) => {
    if (state.getSuccessful == true) {
        return state.messages.data;
    } else {
        return false;
    }
};

export const getUnreadWorkOrderMessages = (userID, state: State) => {
    if (state.getSuccessful == true) {
        if (state.messages.data != null && typeof state.messages.data != 'undefined') {
            return state.messages.data.reduce((carry, message) => {
                if (!message['wom_read'] && message['wom_userID'] != userID) {
                    carry.push(message);
                }
                return carry;
            }, []);
        } else {
            return false;
        }
    } else {
        return false;
    }
};

export const getUnreadWorkOrderMessagesCount = (userID, state: State) => {
    if (state.getSuccessful == true) {
        if (state.messages.data != null && typeof state.messages.data != 'undefined') {
            return state.messages.data.reduce((carry, message) => {
                if (!message['wom_read'] && message['wom_userID'] != userID) {
                    ++carry;
                }
                return carry;
            }, 0);
        } else {
            return false;
        }
    } else {
        return false;
    }

};

export const getMessagesForWorkOrder = (workOrderID, state: State) => {
    if (state.getSuccessful == true) {
        if (state.messages.data != null && typeof state.messages.data != 'undefined') {
            return state.messages.data.reduce((carry, message) => {
                if (message['wom_woID'] == workOrderID) {
                    carry.push(message);
                }
                return carry;
            }, []);
        } else {
            return false;
        }
    } else {
        return false;
    }
};

export const getUsersToMessage = (state: State) => state.users;




