import { Injectable } from '@angular/core';
import { Observable ,  BehaviorSubject } from 'rxjs';

import { SessionService } from '../services/session.service';
import { HttpClient } from '@angular/common/http';
import {State} from '../../store/reducers';
import {Store} from '@ngrx/store';

import * as workorderActions from '../../store/actions/workorders.actions';
import * as vehicleActions from '../../store/actions/vehicle.actions';
import * as customerActions from '../../store/actions/customer.actions';

@Injectable()
export class WorkOrderProvider {

    private _workOrderSource = new BehaviorSubject<object>({});
    private workorder;

    private _customerSource = new BehaviorSubject<object>({});
    private customer;
    private selectedVehicle;

    constructor(
        private http: HttpClient,
        private store: Store<State>,
        private sessionService: SessionService
    ) {
        this.workorder = this._workOrderSource.asObservable();
        this.customer = this._customerSource.asObservable();
    }

    setWorkOrder(data, shouldNotifyStore = false): Promise<boolean> {
        return new Promise((resolve, reject) => {

            // empty object
            if (!data || Object.keys(data).length === 0) {
                // reset data
                this.setCustomer(null)
                    .then(() => this.setSelectedVehicle(null))
                    .then(() => {
                        this._workOrderSource.next(null);
                        if (shouldNotifyStore) {
                            this.store.dispatch(new workorderActions.SetWorkorderAction(data));
                        }
                        resolve(true);
                    });
            } else {

                this.syncWorkOrderSession(data.wor_workorderID)
                    .then(() => this.setCustomer(data.customer))
                    .then(() =>  this.setSelectedVehicle(data.vehicle))
                    .then(() => {
                        this._workOrderSource.next(data);
                        if (shouldNotifyStore) {
                            this.store.dispatch(new workorderActions.SetWorkorderAction(data));
                        }
                        resolve(true);
                    });
            }
        });
    }

    getWorkOrder() {
        return this.workorder;
    }

    setCustomer(customer, shouldNotifyStore = true): Promise<any> {
        let promise = Promise.resolve(true);
        if (customer && customer.cus_customerID) {
            promise = this.syncCustomerSession(customer.cus_customerID);
        }

        this._customerSource.next(customer);

        if (shouldNotifyStore) {
            this.store.dispatch(new customerActions.SetCustomerAction(customer));
        }

        return promise;
    }

    getCustomer() {
        return this.customer;
    }

    getSelectedVehicle(): object {
        return this.selectedVehicle;
    }

    setSelectedVehicle(vehicle, shouldNotifyStore = true): Promise<any> {
        this.selectedVehicle = vehicle;
        let promise = Promise.resolve(true);
        if (vehicle && vehicle.veh_vehicleID) {
            promise = this.syncVehicleSession(vehicle.veh_vehicleID);
            if (shouldNotifyStore) {
                this.store.dispatch(new vehicleActions.SetVehicleAction(vehicle));
            }
        }

        return promise;
    }


    // Sync Seession
    syncWorkOrderSession(workOrderId): Promise<any> {
        return this.sessionService.setWorkOrder(workOrderId);
    }

    syncVehicleSession(vehicleId): Promise<any> {
        return this.sessionService.setVehicle(vehicleId);
    }

    syncCustomerSession(customerId): Promise<any> {
        return this.sessionService.setCustomer(customerId);
    }

    clear() {
        this.setWorkOrder(null);
        this.setCustomer(null);
        this.setSelectedVehicle(null);
    }

}
