import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

@Injectable()
export class SearchService {

    private url: string;

    constructor(private http: HttpClient) {
        this.url = environment.tireguruBaseApi;
    }

    search(text) {
        const url = `${this.url}/search`;
        return this.http.get(url, {
            params: {
                search: text
            }
        });
    }

    searchAdvancedSearchAdvanced(form, companyMatches = false) {
        const customerType = companyMatches ? 'company' : '';
        const params = {
            ...form,
            customerType
        };

        const url = `${this.url}/customers/advanced-search`;
        return new Promise((resolve, reject) => {
            this.http.get(url, {
                params
            }).subscribe(response => {
                resolve(response);
            });
        });
    }

}
