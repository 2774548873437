
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError} from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';



import { Router } from '@angular/router';

@Injectable()
export class LegacyHttpInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Clone the request to add the new header.
        const authReq = req.clone({
            headers: req.headers.set('X-TireGuruApi', 'legacy') });

        // send the newly created request
        return next.handle(authReq).pipe(
            catchError((error, caught) => {
                // intercept the respons error and displace it to the console
                // console.log(error);
                if (window.location.href.includes('inspection-print')) {
                    return next.handle(authReq);
                }

                if (!error.ok && error.status === 401 && error.error.error === 'unauthorized') {
                    this.router.navigate(['login']);
                } else if (error.status && error.status === 403) {
                    this.router.navigate(['premium-disabled']);
                }
                // return the error to the method that called it
                return observableThrowError(error);
            })) as any;
    }
}
