import {Injectable} from '@angular/core';
import {Store} from "@ngrx/store";

import * as fromPhysicalInventory from '../../../store/actions/physical-inventory.actions';

@Injectable()
export class PhysicalInventoryService {

    constructor(private store: Store<any>) { }


    canDeactivate(component, currentRoute, currentState, nextState) {
        if (!(nextState.url.match('/physical-inventory*'))) {
            this.store.dispatch(new fromPhysicalInventory.ClearEntirePhysicalInventoryState());
        }
        return true;
    }
}
