import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-vehicle-history-iframe',
    templateUrl: './vehicle-history-iframe.component.html',
    styleUrls: ['./vehicle-history-iframe.component.scss']
})
export class VehicleHistoryIframeComponent implements OnInit {

    public vehicleID: any = false;
    public custID: any = false;
    public loaded = false;
    public returnUrl: any = false;

    constructor(private route: ActivatedRoute) { }

    ngOnInit() {
        if (this.route.snapshot.queryParamMap.has('custID')) {
            this.custID = this.route.snapshot.queryParamMap.get('custID');
        }

        if (this.route.snapshot.queryParamMap.has('vehicleID')) {
            this.vehicleID = this.route.snapshot.queryParamMap.get('vehicleID');
        }

        if (this.route.snapshot.queryParamMap.has('returnUrl')) {
            this.returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
        }

        this.loaded = true;
    }

    get url() {
        return `/mobile/history_print.php?custID=${this.custID}&vehicleID=${this.vehicleID}`;
    }

}
