import { Action as NgrxAction } from '@ngrx/store';

export const GET_360_TERMINALS = 'GET_360_TERMINALS_ACTION';
export const GET_360_TERMINALS_SUCCESSFUL = 'GET_360_TERMINALS_SUCCESSFUL_ACTION';
export const GET_360_TERMINALS_FAILED = 'GET_360_TERMINALS_FAILED_ACTION';
export const PAIR_360_TERMINAL_ACTION = 'PAIR_360_TERMINAL_ACTION';
export const PAIR_360_TERMINAL_SUCCESSFUL = 'PAIR_360_TERMINAL_SUCCESSFUL_ACTION';
export const PAIR_360_TERMINAL_FAILED = 'PAIR_360_TERMINAL_FAILED_ACTION';
export const UN_PAIR_360_TERMINAL_ACTION = 'UN_PAIR_360_TERMINAL_ACTION';
export const UN_PAIR_360_TERMINAL_SUCCESSFUL = 'UN_PAIR_360_TERMINAL_SUCCESSFUL_ACTION';
export const UN_PAIR_360_TERMINAL_FAILED = 'UN_PAIR_360_TERMINAL_FAILED_ACTION';
export const GET_360_TERMINAL_BY_ID = 'GET_360_TERMINAL_BY_ID';
export const GET_360_TERMINAL_BY_ID_SUCCESSFUL = 'GET_360_TERMINAL_BY_ID_SUCCESSFUL';
export const GET_360_TERMINAL_BY_ID_FAILED = 'GET_360_TERMINAL_BY_ID_FAILED';


export class Get360Terminals implements NgrxAction {
    readonly type = GET_360_TERMINALS;

    constructor(public payload: any) {
    }
}

export class Get360TerminalsSuccess implements NgrxAction {
    readonly type = GET_360_TERMINALS_SUCCESSFUL;

    constructor(public payload: any) {
    }
}

export class Get360TerminalsFailed implements NgrxAction {
    readonly type = GET_360_TERMINALS_FAILED;

    constructor(public payload: any) {
    }
}

export class Pair360TerminalAction implements NgrxAction {
    readonly type = PAIR_360_TERMINAL_ACTION;

    constructor(public payload: any) {
    }
}

export class Pair360TerminalSuccess implements NgrxAction {
    readonly type = PAIR_360_TERMINAL_SUCCESSFUL;

    constructor(public payload: any) {
    }
}

export class Pair360TerminalFailed implements NgrxAction {
    readonly type = PAIR_360_TERMINAL_FAILED;

    constructor(public payload: any) {
    }
}


export class UnPair360TerminalAction implements NgrxAction {
    readonly type = UN_PAIR_360_TERMINAL_ACTION;

    constructor(public payload: any) {
    }
}

export class UnPair360TerminalSuccess implements NgrxAction {
    readonly type = UN_PAIR_360_TERMINAL_SUCCESSFUL;

    constructor(public payload: any) {
    }
}

export class UnPair360TerminalFailed implements NgrxAction {
    readonly type = UN_PAIR_360_TERMINAL_FAILED;

    constructor(public payload: any) {
    }
}

export class get360TerminalByIDAction implements NgrxAction {
    readonly type = GET_360_TERMINAL_BY_ID;

    constructor(public payload: any) {
    }
}

export class get360TerminalByIDActionSuccess implements NgrxAction {
    readonly type = GET_360_TERMINAL_BY_ID_SUCCESSFUL;

    constructor(public payload: any) {
    }
}

export class get360TerminalByIDActionFailed implements NgrxAction {
    readonly type = GET_360_TERMINAL_BY_ID_FAILED;

    constructor(public payload: any) {
    }
}



export type Action = Get360Terminals
| Get360TerminalsSuccess
| Get360TerminalsFailed
| Pair360TerminalAction
| Pair360TerminalSuccess
| Pair360TerminalFailed
| UnPair360TerminalAction
| UnPair360TerminalSuccess
| UnPair360TerminalFailed
| get360TerminalByIDAction
| get360TerminalByIDActionSuccess
| get360TerminalByIDActionFailed;

