import * as arcActions from '../actions/arc.actions';

export interface State {
    cart: any;
    loading: boolean;
    loadingSuccessful: boolean;
    loadingError: string;
    loadingItems: boolean;
    loadingItemsSuccessful: boolean;
    loadingItemsError: boolean;
    updating: boolean;
    updatingSuccessful: boolean;
    updatingError: string;
    deleting: boolean;
    deletingSuccessful: boolean;
    deletingError: string;
    unlinkedPartsLaborCore: any;
    unlinkedPartsLaborCoreLoading: boolean;
    unlinkedPartsLaborCoreSuccessful: boolean;
    unlinkedPartsLaborCoreError: string;
    unlinkedVendors: any;
    unlinkedVendorsLoading: boolean;
    unlinkedVendorsSuccessful: boolean;
    unlinkedVendorsError: string;
    finalizeArcLoading: boolean;
    finalizingArcSuccessful: boolean;
    finalizeArcError: string;
}

const initialState: State = {
    cart: {},
    loading: false,
    loadingSuccessful: null,
    loadingError: null,
    loadingItems: false,
    loadingItemsSuccessful: null,
    loadingItemsError: null,
    updating: false,
    updatingSuccessful: null,
    updatingError: null,
    deleting: false,
    deletingSuccessful: null,
    deletingError: null,
    unlinkedPartsLaborCore: false,
    unlinkedPartsLaborCoreLoading: false,
    unlinkedPartsLaborCoreSuccessful: null,
    unlinkedPartsLaborCoreError: null,
    unlinkedVendors: false,
    unlinkedVendorsLoading: false,
    unlinkedVendorsSuccessful: null,
    unlinkedVendorsError: null,
    finalizeArcLoading: false,
    finalizingArcSuccessful: null,
    finalizeArcError: null,
};

export function reducer(state = initialState, action): State {
    switch (action.type) {
    case arcActions.GET_ARC_CART:
        return {...state, loading: true};
    case arcActions.GET_ARC_CART_SUCCESSFUL:
        return {...state, cart: action.payload, loading: false, loadingSuccessful: true};
    case arcActions.GET_ARC_CART_FAILED:
        return {...state, loading: false, loadingSuccessful: false, loadingError: action.payload};
    case arcActions.CLEAR_ARC_STATE:
        return {...state, cart: {}, loading: false, loadingSuccessful: null, loadingItems: false, loadingItemsSuccessful: null, loadingItemsError: null, loadingError: null, updating: false, updatingSuccessful: null, updatingError: null, deleting: false, deletingSuccessful: null, deletingError: null};
    case arcActions.UPDATE_ITEM_QTY:
        return {...state, updating: true};
    case arcActions.UPDATE_ITEM_QTY_SUCCESSFUL:
        return {...state, updating: false, updatingSuccessful: true, cart: action.payload.cart};
    case arcActions.UPDATE_ITEM_QTY_FAILED:
        return {...state, updating: false, updatingSuccessful: false, updatingError: action.payload};
    case arcActions.DELETE_ITEM_FROM_ARC_CART:
        return {...state, loading: true};
    case arcActions.DELETE_ITEM_FROM_ARC_CART_SUCCESSFUL:
        return {...state, deleting: false, deletingSuccessful: true, cart: action.payload};
    case arcActions.DELETE_ITEM_FROM_ARC_CART_FAILED:
        return {...state, deleting: false, deletingSuccessful: false, deletingError: action.payload};
    case arcActions.GET_UNLINKED_ARC_PARTS_LABOR_CORE:
        return {...state, unlinkedPartsLaborCoreLoading: true};
    case arcActions.GET_UNLINKED_ARC_PARTS_LABOR_CORE_SUCCESSFUL:
        return {...state, unlinkedPartsLaborCore: action.payload, unlinkedPartsLaborCoreLoading: false, unlinkedPartsLaborCoreSuccessful: true};
    case arcActions.GET_UNLINKED_ARC_PARTS_LABOR_CORE_FAILED:
        return {...state, unlinkedPartsLaborCoreSuccessful: false, unlinkedPartsLaborCoreLoading: false, unlinkedPartsLaborCoreError: action.payload};
    case arcActions.GET_UNLINKED_VENDORS:
        return {...state, unlinkedVendorsLoading: true};
    case arcActions.GET_UNLINKED_VENDORS_SUCCESSFUL:
        return {...state, unlinkedVendorsLoading: false, unlinkedVendorsSuccessful: true, unlinkedVendors: action.payload};
    case arcActions.GET_UNLINKED_VENDORS_FAILED:
        return {...state, unlinkedVendorsLoading: false, unlinkedVendorsSuccessful: false, unlinkedVendorsError: action.payload};
    case arcActions.FINALIZE_ARC:
        return {...state, finalizeArcLoading: true};
    case arcActions.FINALIZE_ARC_SUCCESSFUL:
        return {...state, finalizeArcLoading: false, finalizingArcSuccessful: true};
    case arcActions.FINALIZE_ARC_FAILED:
        return {...state, finalizingArcSuccessful: false, finalizeArcLoading: false, finalizeArcError: action.payload};
    default:
        return state;
    }
}

export const getCart = (state: State) => state.cart;
export const getUnlinkedPartsLaborCore = (state: State) => state.unlinkedPartsLaborCore;
export const getUnlinkedVendor = (state: State) => state.unlinkedVendors;
