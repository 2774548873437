import { Action as NgrxAction } from '@ngrx/store';

export const CLEAR_ENTIRE_PHYSICAL_INVENTORY_STATE = '[Physical Inventory] Clear entire physical inventory state';

export const SET_PHYSICAL_INVENTORY_INPUT_SETUP = '[Physical Inventory] Set location physical inventory input setup';

export const SET_PHYSICAL_INVENTORY_INPUT_NUMBER = '[Physical Inventory] Set physical inventory input number';

export const GET_LOCATION_PHYSICAL_INVENTORY_MENU_SETUPS = '[Physical Inventory] Get location physical inventory setups';
export const GET_LOCATION_PHYSICAL_INVENTORY_MENU_SETUPS_SUCCESSFUL = '[Physical Inventory] Get location physical inventory setups successful';
export const GET_LOCATION_PHYSICAL_INVENTORY_MENU_SETUPS_FAILED = '[Physical Inventory] Get location physical inventory setups failed';

export const UPDATE_LOCATION_PHYSICAL_INVENTORY_SETUP = '[Physical Inventory] Update location physical inventory setup';
export const UPDATE_LOCATION_PHYSICAL_INVENTORY_SETUP_SUCCESSFUL = '[Physical Inventory] Update location physical inventory setup successful';
export const UPDATE_LOCATION_PHYSICAL_INVENTORY_SETUP_FAILED = '[Physical Inventory] Update location physical inventory setup failed';

export const GET_PHYSICAL_INVENTORY_SETUP_ITEMS = '[Physical Inventory] Get physical inventory setup items';
export const GET_PHYSICAL_INVENTORY_SETUP_ITEMS_SUCCESSFUL = '[Physical Inventory] Get physical inventory setup items successful';
export const GET_PHYSICAL_INVENTORY_SETUP_ITEMS_FAILED = '[Physical Inventory] Get physical inventory setup items failed';

export const MANUAL_UPDATE_ITEM_COUNT = '[Physical Inventory Item] Manual update item count';
export const MANUAL_UPDATE_ITEM_COUNT_SUCCESSFUL = '[Physical Inventory Item] Manual update item count successful';
export const MANUAL_UPDATE_ITEM_COUNT_FAILED = '[Physical Inventory Item] Manual update item count failed';

export const MANUAL_UPDATE_WRITE_IN_ITEM_COUNT = '[Physical Inventory Item] Manual update write in item count';
export const MANUAL_UPDATE_WRITE_IN_ITEM_COUNT_SUCCESSFUL = '[Physical Inventory Item] Manual update write in item count successful';
export const MANUAL_UPDATE_WRITE_IN_ITEM_COUNT_FAILED = '[Physical Inventory Item] Manual update write in item count failed';

export const BARCODE_UPDATE_ITEM_COUNT = '[Physical Inventory Item] Barcode update item count ';
export const BARCODE_UPDATE_ITEM_COUNT_SUCCESSFUL = '[Physical Inventory Item] Barcode update item count successful';
export const BARCODE_UPDATE_ITEM_COUNT_FAILED = '[Physical Inventory Item] Barcode update item count failed';

export const BARCODE_UPDATE_WRITE_IN_ITEM_COUNT = '[Physical Inventory Item] Barcode update write in item count ';
export const BARCODE_UPDATE_WRITE_IN_ITEM_COUNT_SUCCESSFUL = '[Physical Inventory Item] Barcode update write in item count successful';
export const BARCODE_UPDATE_WRITE_IN_ITEM_COUNT_FAILED = '[Physical Inventory Item] Barcode update write in item count failed';

export const GET_PHYSICAL_INVENTORY_SETUP_WRITE_IN_ITEMS = '[Physical Inventory write in items] Get Physical Inventory setup write-in items';
export const GET_PHYSICAL_INVENTORY_SETUP_WRITE_IN_ITEMS_SUCCESSFUL = '[Physical Inventory write in items] Get Physical Inventory setup write-in items successful';
export const GET_PHYSICAL_INVENTORY_SETUP_WRITE_IN_ITEMS_FAILED = '[Physical Inventory write in items] Get Physical Inventory setup write-in items failed';

export const CREATE_PHYSICAL_INVENTORY_SETUP_WRITE_IN_ITEM = '[Physical Inventory write in items] Create Physical Inventory setup write-in item';
export const CREATE_PHYSICAL_INVENTORY_SETUP_WRITE_IN_ITEM_SUCCESSFUL = '[Physical Inventory write in items] Create Physical Inventory setup write-in item successful';
export const CREATE_PHYSICAL_INVENTORY_SETUP_WRITE_IN_ITEM_FAILED = '[Physical Inventory write in items] Create Physical Inventory setup write-in item failed';

export const DELETE_PHYSICAL_INVENTORY_SETUP_WRITE_IN_ITEM = '[Physical Inventory write in items] Delete Physical Inventory setup write-in item';
export const DELETE_PHYSICAL_INVENTORY_SETUP_WRITE_IN_ITEM_SUCCESSFUL = '[Physical Inventory write in items] Delete Physical Inventory setup write-in item successful';
export const DELETE_PHYSICAL_INVENTORY_SETUP_WRITE_IN_ITEM_FAILED = '[Physical Inventory write in items] Delete Physical Inventory setup write-in item failed';

export const CLEAR_PHYSICAL_INVENTORY_SETUP_ITEMS_CACHE = '[Physical Inventory setup items] Clear Physical Inventory setup items cache';
export const CLEAR_PHYSICAL_INVENTORY_SETUP_ITEMS_CACHE_SUCCESSFUL = '[Physical Inventory setup items] Clear Physical Inventory setup items cache successful';
export const CLEAR_PHYSICAL_INVENTORY_SETUP_ITEMS_CACHE_FAILED = '[Physical Inventory setup items] Clear Physical Inventory setup items cache failed';

export const CLEAR_PHYSICAL_INVENTORY_SETUP_WRITE_IN_ITEMS_CACHE = '[Physical Inventory setup write in items] Clear Physical Inventory write in items cache';
export const CLEAR_PHYSICAL_INVENTORY_SETUP_WRITE_IN_ITEMS_CACHE_SUCCESSFUL = '[Physical Inventory setup write in items] Clear Physical Inventory write in items cache successful';
export const CLEAR_PHYSICAL_INVENTORY_SETUP_WRITE_IN_ITEMS_CACHE_FAILED = '[Physical Inventory setup write in items] Clear Physical Inventory write in items cache failed';


export class ClearEntirePhysicalInventoryState implements NgrxAction {
    readonly type = CLEAR_ENTIRE_PHYSICAL_INVENTORY_STATE;

    constructor() {
    }
}

export class GetLocationPhysicalInventorySetups implements NgrxAction {
    readonly type = GET_LOCATION_PHYSICAL_INVENTORY_MENU_SETUPS;

    constructor(public payload: any) {
    }
}

export class SetPhysicalInventoryInputNumber implements NgrxAction {
    readonly type = SET_PHYSICAL_INVENTORY_INPUT_NUMBER;

    constructor(public inputNumber: number) {
    }
}

export class GetLocationPhysicalInventorySetupsSuccessful implements NgrxAction {
    readonly type = GET_LOCATION_PHYSICAL_INVENTORY_MENU_SETUPS_SUCCESSFUL;

    constructor(public payload: any) {
    }
}

export class GetLocationPhysicalInventorySetupsFailed implements NgrxAction {
    readonly type = GET_LOCATION_PHYSICAL_INVENTORY_MENU_SETUPS_FAILED;

    constructor(public err: any) {
    }
}

export class SetPhysicalInventoryInput implements NgrxAction {
    readonly type = SET_PHYSICAL_INVENTORY_INPUT_SETUP;

    constructor(public payload: any) {
    }
}

export class UpdatePhysicalInventorySetup implements NgrxAction {
    readonly type = UPDATE_LOCATION_PHYSICAL_INVENTORY_SETUP;

    constructor(public payload: any) {
    }
}

export class UpdatePhysicalInventorySetupSuccessFul implements NgrxAction {
    readonly type = UPDATE_LOCATION_PHYSICAL_INVENTORY_SETUP_SUCCESSFUL;

    constructor(public payload: any) {
    }
}

export class UpdatePhysicalInventorySetupFailed implements NgrxAction {
    readonly type = UPDATE_LOCATION_PHYSICAL_INVENTORY_SETUP_FAILED;

    constructor(public err: any) {
    }
}

export class GetPhysicalInventorySetupItems implements NgrxAction {
    readonly type = GET_PHYSICAL_INVENTORY_SETUP_ITEMS;

    constructor(public payload: any) {
    }
}

export class GetPhysicalInventorySetupItemsSuccessFul implements NgrxAction {
    readonly type = GET_PHYSICAL_INVENTORY_SETUP_ITEMS_SUCCESSFUL;

    constructor(public payload: any) {
    }
}

export class GetPhysicalInventorySetupItemsFailed implements NgrxAction {
    readonly type = GET_PHYSICAL_INVENTORY_SETUP_ITEMS_FAILED;

    constructor(public err: any) {
    }
}

export class ManualUpdateItemCount implements NgrxAction {
    readonly type = MANUAL_UPDATE_ITEM_COUNT;

    constructor(public payload: {
        setupID: any; itemID: any; count: any; input_num: any; prev_count: any; bin: any; entered: any;
    }) {
    }
}

export class ManualUpdateItemCountSuccessful implements NgrxAction {
    readonly type = MANUAL_UPDATE_ITEM_COUNT_SUCCESSFUL;

    constructor(public payload: any) {
    }
}

export class ManualUpdateItemCountFailed implements NgrxAction {
    readonly type = MANUAL_UPDATE_ITEM_COUNT_FAILED;

    constructor(public err: any) {
    }
}

export class ManualUpdateWriteInItemCount implements NgrxAction {
    readonly type = MANUAL_UPDATE_WRITE_IN_ITEM_COUNT;

    constructor(public payload: {
        setupID: any; itemID: any; count: any; input_num: any; prev_count: any;
    }) {
    }
}

export class ManualUpdateWriteInItemCountSuccessful implements NgrxAction {
    readonly type = MANUAL_UPDATE_WRITE_IN_ITEM_COUNT_SUCCESSFUL;

    constructor(public payload: any) {
    }
}

export class ManualUpdateWriteInItemCountFailed implements NgrxAction {
    readonly type = MANUAL_UPDATE_WRITE_IN_ITEM_COUNT_FAILED;

    constructor(public err: any) {
    }
}

export class BarcodeUpdateItemCount implements NgrxAction {
    readonly type = BARCODE_UPDATE_ITEM_COUNT;

    constructor(public payload: {
        setupID: any; barcode: any; count: any; input_num: any; prev_count: any; bin: any; entered: any;
    }) {
    }
}

export class BarcodeUpdateItemCountSuccessful implements NgrxAction {
    readonly type = BARCODE_UPDATE_ITEM_COUNT_SUCCESSFUL;

    constructor(public payload: any) {
    }
}

export class BarcodeUpdateItemCountFailed implements NgrxAction {
    readonly type = BARCODE_UPDATE_ITEM_COUNT_FAILED;

    constructor(public err: any) {
    }
}

export class BarcodeUpdateWriteInItemCount implements NgrxAction {
    readonly type = BARCODE_UPDATE_WRITE_IN_ITEM_COUNT;

    constructor(public payload: {
        setupID: any; barcode: any; count: any; input_num: any; prev_count: any;
    }) {
    }
}

export class BarcodeUpdateWriteInItemCountSuccessful implements NgrxAction {
    readonly type = BARCODE_UPDATE_WRITE_IN_ITEM_COUNT_SUCCESSFUL;

    constructor(public payload: any) {
    }
}

export class BarcodeUpdateWriteInItemCountFailed implements NgrxAction {
    readonly type = BARCODE_UPDATE_WRITE_IN_ITEM_COUNT_FAILED;

    constructor(public err: any) {
    }
}

export class GetPhysicalInventorySetupWriteInItems implements NgrxAction {
    readonly type = GET_PHYSICAL_INVENTORY_SETUP_WRITE_IN_ITEMS;

    constructor(public payload: any) {
    }
}

export class GetPhysicalInventorySetupWriteInItemsSuccessful implements NgrxAction {
    readonly type = GET_PHYSICAL_INVENTORY_SETUP_WRITE_IN_ITEMS_SUCCESSFUL;

    constructor(public payload: any) {
    }
}

export class GetPhysicalInventorySetupWriteInItemsFailed implements NgrxAction {
    readonly type = GET_PHYSICAL_INVENTORY_SETUP_WRITE_IN_ITEMS_FAILED;

    constructor(public err: any) {
    }
}

export class CreatePhysicalInventorySetupWriteInItem implements NgrxAction {
    readonly type = CREATE_PHYSICAL_INVENTORY_SETUP_WRITE_IN_ITEM;

    constructor(public payload: {setupID: any; input_num: any; itemID: any}) {
    }
}

export class CreatePhysicalInventorySetupWriteInItemSuccessful implements NgrxAction {
    readonly type = CREATE_PHYSICAL_INVENTORY_SETUP_WRITE_IN_ITEM_SUCCESSFUL;

    constructor(public payload: any) {
    }
}

export class CreatePhysicalInventorySetupWriteInItemFailed implements NgrxAction {
    readonly type = CREATE_PHYSICAL_INVENTORY_SETUP_WRITE_IN_ITEM_FAILED;

    constructor(public err: any) {
    }
}

export class DeletePhysicalInventorySetupWriteInItem implements NgrxAction {
    readonly type = DELETE_PHYSICAL_INVENTORY_SETUP_WRITE_IN_ITEM;

    constructor(public payload: {setupID: any; input_num: any; itemID: any}) {
    }
}

export class DeletePhysicalInventorySetupWriteInItemSuccessful implements NgrxAction {
    readonly type = DELETE_PHYSICAL_INVENTORY_SETUP_WRITE_IN_ITEM_SUCCESSFUL;

    constructor(public payload: any) {
    }
}

export class DeletePhysicalInventorySetupWriteInItemFailed implements NgrxAction {
    readonly type = DELETE_PHYSICAL_INVENTORY_SETUP_WRITE_IN_ITEM_FAILED;

    constructor(public err: any) {
    }
}

export class ClearPhysicalInventorySetupItemsCache implements NgrxAction {
    readonly type = CLEAR_PHYSICAL_INVENTORY_SETUP_ITEMS_CACHE;

    constructor(public payload: {setupID: any; input_num: any}) {
    }
}

export class ClearPhysicalInventorySetupItemsCacheSuccessful implements NgrxAction {
    readonly type = CLEAR_PHYSICAL_INVENTORY_SETUP_ITEMS_CACHE_SUCCESSFUL;

    constructor(public payload: any) {
    }
}

export class ClearPhysicalInventorySetupItemsCacheFailed implements NgrxAction {
    readonly type = CLEAR_PHYSICAL_INVENTORY_SETUP_ITEMS_CACHE_FAILED;

    constructor(public err: any) {
    }
}

export class ClearPhysicalInventorySetupWriteInItemsCache implements NgrxAction {
    readonly type = CLEAR_PHYSICAL_INVENTORY_SETUP_WRITE_IN_ITEMS_CACHE;

    constructor(public payload: {setupID: any; input_num: any}) {
    }
}

export class ClearPhysicalInventorySetupWriteInItemsCacheSuccessful implements NgrxAction {
    readonly type = CLEAR_PHYSICAL_INVENTORY_SETUP_WRITE_IN_ITEMS_CACHE_SUCCESSFUL;

    constructor(public payload: any) {
    }
}

export class ClearPhysicalInventorySetupWriteInItemsCacheFailed implements NgrxAction {
    readonly type = CLEAR_PHYSICAL_INVENTORY_SETUP_WRITE_IN_ITEMS_CACHE_FAILED;

    constructor(public err: any) {
    }
}
