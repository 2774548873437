import {catchError, switchMap, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {createEffect, Actions, ofType} from '@ngrx/effects';
import {Observable, from as fromPromise, of} from 'rxjs';
import * as custComm from '../actions/customerCommunication.actions';
import {CustomerCommunicationsService} from '../../core/services/customerCommunications.service';

@Injectable()
export class CustomerCommunicationEffects {

    getUnreadTextCount: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(custComm.GET_UNREAD_TEXT_COUNT),
        map((action: custComm.GetUnreadTextCountAction) => action.payload),
        switchMap((payload) =>
            fromPromise(this.customerCommunicationsService.getUnreadTextsCount(payload.locID)).pipe(
                map((messages) => new custComm.GetUnreadTextCountSuccessfulAction(messages)),
                catchError((error) => of(new custComm.GetUnreadTextCountFailedAction(error))))
        )));


    getTextMessages: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(custComm.GET_TEXT_MESSAGES),
        map((action: custComm.GetTextMessagesAction) => action.payload),
        switchMap((payload) =>
            fromPromise(this.customerCommunicationsService.getTextMessages(payload.locID, payload.viewUnread, payload.page, payload.search)).pipe(
                map((messages) => new custComm.GetTextMessagesSuccessfulAction(messages)),
                catchError((error) => of(new custComm.GetTextMessagesFailedAction(error))))
        )));


    getCustomerCommunications: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(custComm.GET_CUSTOMER_COMMUNICATIONS),
        map((action: custComm.GetCustomerCommunicationsAction) => action.payload),
        switchMap((payload) =>
            fromPromise(this.customerCommunicationsService.getAllCommunications(payload.custID)).pipe(
                map((messages) => new custComm.GetCustomerCommunicationsSuccessfulAction(messages)),
                catchError((error) => of(new custComm.GetCustomerCommunicationsFailedAction(error))))
        )));


    sendText: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(custComm.SEND_TEXT_MESSAGE),
        map((action: custComm.SendTextMessageAction) => action.payload),
        switchMap((payload) =>
            fromPromise(this.customerCommunicationsService.sendTextMessage(payload.custId, payload.content)).pipe(
                map((messages) => new custComm.SendTextMessageSuccessfulAction(messages)),
                catchError((error) => of(new custComm.SendTextMessageFailedAction(error))))
        )));


    markAsRead: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(custComm.MARK_TEXTS_AS_READ),
        map((action: custComm.MarkTextsAsReadAction) => action.payload),
        switchMap((payload) =>
            fromPromise(this.customerCommunicationsService.markTextsAsRead(payload.custId, payload.userId)).pipe(
                map((messages) => new custComm.MarkTextsAsReadSuccessfulAction(messages)),
                catchError((error) => of(new custComm.MarkTextsAsReadFailedAction(error))))
        )));


    markIndividualAsRead: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(custComm.MARK_INDIVIDUAL_TEXT_AS_READ),
        map((action: custComm.MarkIndividualTextAsReadAction) => action.payload),
        switchMap((payload) =>
            fromPromise(this.customerCommunicationsService.markIndividualTextAsRead(payload.textId, payload.userId)).pipe(
                map((messages) => new custComm.MarkIndividualTextAsReadSuccessfulAction(messages)),
                catchError((error) => of(new custComm.MarkIndividualTextAsReadFailedAction(error))))
        )));

    constructor(private $actions: Actions, private customerCommunicationsService: CustomerCommunicationsService) {
    }
}
