import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';
import { from } from 'rxjs';
import { map, groupBy, mergeMap, toArray, switchMap } from 'rxjs/operators';

@Injectable()
export class InspectionPortalService {

    public url: string;
    public urlOld: string;
    public submissionurl: string;

    constructor(private http: HttpClient) {
        this.url = environment.tireguruBaseApi + '/inspection-portal';
        this.urlOld = environment.tireguruBaseUrl;
    }

    getVehicleImage(workOrderId) {
        const url = `${this.urlOld}/mobile/vehicle_image.php`;
        const params = {
            workorder_id: workOrderId,
        };
        return this.http.get(url, { params }).toPromise();
    }

    getTireDepths(id) {
        const url = `${this.url}/${id}/tire-depths`;


        return this.http.get(url)
            .pipe(
                map(response => response['data']),
                switchMap(items => from(items.map(item => {
                    item.section = item.key.match(/[\w]*-[\w]*-(tire)/i)[0];
                    return item;
                }))),
                groupBy((item: any) => item.section),
                mergeMap(group => group.pipe(toArray())),
            );
    }

    getInspectionReport(id) {
        const url = `${this.url}/${id}/report`;


        return this.http.get(url)
            .pipe(
                map((response: any) => {
                    if (response.success) {
                        return response;
                    }
                    throw new Error(response.data.message);
                }),
                map(response => response['data']),
            );
    }

    getAlignmentStatus(id) {
        const url = `${this.url}/${id}/alignment`;


        return this.http.get(url)
            .pipe(
                map(response => response['data']),
            );
    }

    getRequiredChecks(id) {
        const url = `${this.url}/${id}/required`;


        return this.http.get(url)
            .pipe(
                map(response => response['data']),
            );
    }

    getWorkOrders(locationId, page, filter) {
        const url = `${this.url}/work-orders?location=${locationId}&filter=${filter}&page=${page}`;

        return this.http.get(url)
            .pipe(
                map(response => response['data']),
            );
    }
}
