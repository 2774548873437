import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-global-search-invoice-result',
    templateUrl: './global-search-invoice-result.component.html',
    styleUrls: ['./global-search-invoice-result.component.scss', '../global-search-result-type-container/global-search-result-type-container.component.scss']
})
export class GlobalSearchInvoiceResultComponent implements OnInit {

    @Input() result;

    @Output() show_alert = new EventEmitter();

    constructor() {
    }

    ngOnInit() {
    }

    public openInvoice() {
        this.show_alert.emit({type: 'error', 'msg': 'Cannot open invoices.'});
    }

}
