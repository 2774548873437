import {Injectable} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class PurchaseOrderVerificationsService {

    private readonly urlOld: string;
    private readonly purchaseOrdersUrl: string;

    constructor(private http: HttpClient) {
        this.urlOld = environment.tireguruBaseUrl;
        this.purchaseOrdersUrl = environment.tireguruBaseUrl + '/app/purchase-orders'
    }


    getPurchaseOrdersWithVerifications(payload) {
        const url = `${this.purchaseOrdersUrl}/verifications`;

        return this.http.get(url).toPromise();
    }
}
