import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-statistics-dashboard-iframe',
  templateUrl: './statistics-dashboard-iframe.component.html',
  styleUrls: ['./statistics-dashboard-iframe.component.scss']
})
export class StatisticsDashboardIframeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
