import { Action as NgrxAction } from '@ngrx/store';

export const LOAD_INVOICE_ITEM_CATEGORIES = '[Invoice Item Categories] Load InvoiceItemCategories';
export const LOAD_INVOICE_ITEM_CATEGORIES_SUCCESSFUL = '[Invoice Item Categories] Load InvoiceItemCategories successful';
export const LOADING_INVOICE_ITEM_CATEGORIES_FAILED = '[Invoice Item Categories] Load InvoiceItemCategories failed';


export class LoadInvoiceItemCategoriesAction implements NgrxAction {
    readonly type = LOAD_INVOICE_ITEM_CATEGORIES;

    constructor() {}

}

export class LoadInvoiceItemCategoriesSuccessfulAction implements NgrxAction {
    readonly type = LOAD_INVOICE_ITEM_CATEGORIES_SUCCESSFUL;

    constructor(public payload: any) {
    }

}

export class LoadInvoiceItemCategoriesFailedAction implements NgrxAction {
    readonly type = LOADING_INVOICE_ITEM_CATEGORIES_FAILED;

    constructor(public payload: any) {
    }
}

export type Action = LoadInvoiceItemCategoriesAction
| LoadInvoiceItemCategoriesSuccessfulAction
| LoadInvoiceItemCategoriesFailedAction;
