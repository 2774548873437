import * as globalSearch from '../actions/global-search.actions';

export interface State {
    search: any;

    searching: boolean;
    searchSuccessful: boolean;
    searchFailed: any;
    searchResponse: any;
}

const initialState: State = {
    search: null,

    searching: false,
    searchSuccessful: false,
    searchFailed: null,
    searchResponse: null,
};

export function reducer(state = initialState, action): State {
    switch (action.type) {
    case globalSearch.GLOBAL_SEARCH:
        return {...state, searching: true, searchResponse: null, search: action.payload};
    case globalSearch.GLOBAL_SEARCH_SUCCESSFUL:
        return {...state, searchSuccessful: true, searchResponse: action.payload, searching: false};
    case globalSearch.GLOBAL_SEARCH_FAILED:
        return {...state, searchFailed: action.payload, searchSuccessful: false, searching: false};
    default:
        return state;
    }
}

export const getGlobalSearchResponse = (state: State) => ({response: state.searchResponse, success: state.searchSuccessful, searching: state.searching, failed: state.searchFailed});

export const getGlobalSearchCurrentSearch = (state: State) => state.search;

export const getGlobalSearchSearching = (state: State) => state.searching;

