
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";

@Injectable()
export class PhysicalInventoryCountService {

    private readonly urlOld: string;

    constructor(private http: HttpClient) {
        this.urlOld = environment.tireguruBaseUrl + '/app/inventory/physical-inventory';
    }

    getLocationPhysicalInventoryMenuSetups() {
        const url = `${this.urlOld}/setups`;

        return this.http.get(url).pipe(map((response: any) => response.response)).toPromise();
    }

    updateLocationPhysicalInventorySetup(payload) {
        const url = `${this.urlOld}/setups`;

        return this.http.patch(url, {
            data: payload
        }).pipe(map((response: any) => response.response)).toPromise();
    }

    getPhysicalInventorySetupItems(setupID, input_number, page = '0') {
        const url = `${this.urlOld}/${setupID}/items`;

        return this.http.get(url, {
            params: {
                page,
                input_number
            }
        }).pipe(map((response: any) => response.response)).toPromise();
    }

    manualUpdateItemCount(payload) {
        const url = `${this.urlOld}/${payload.setupID}/items`;

        return this.http.patch(url, {
            phc_itemID: payload.itemID,
            phc_quantity: payload.count,
            phc_orig_quantity: payload.prev_count,
            phc_input_num: payload.input_num,
            phc_binID: payload.bin,
            phc_entered: payload.entered
        }).pipe(map((response: any) => response.response)).toPromise();
    }

    barcodeUpdateItemCount(payload) {
        const url = `${this.urlOld}/${payload.setupID}/items/barcode`;

        return this.http.patch(url, {
            barcode: payload.barcode,
            phc_quantity: payload.count,
            phc_orig_quantity: payload.prev_count,
            phc_input_num: payload.input_num,
            phc_binID: payload.bin,
            phc_entered: payload.entered
        }).pipe(map((response: any) => response.response)).toPromise();
    }

    getPhysicalInventorySetupWriteInItems(setupID, input_number, page = '0') {
        const url = `${this.urlOld}/${setupID}/items/write-in`;

        return this.http.get(url, {
            params: {
                page,
                input_number
            }
        }).pipe(map((response: any) => response.response)).toPromise();
    }

    createPhysicalInventorySetupWriteInItem(setupID, input_number, item_id) {
        const url = `${this.urlOld}/${setupID}/items/write-in`;

        return this.http.post(url, {
            item_id,
            input_number
        }).pipe(map((response: any) => response.response)).toPromise();
    }

    deletePhysicalInventorySetupWriteInItem(setupID, input_number, item_id) {
        const url = `${this.urlOld}/${setupID}/items/write-in`;

        return this.http.delete(url, {
            params: {
                item_id,
                input_number
            }
        }).pipe(map((response: any) => response.success)).toPromise();
    }

    manualUpdateWriteInItemCount(payload) {
        const url = `${this.urlOld}/${payload.setupID}/items/write-in`;

        return this.http.patch(url, {
            phw_itemID: payload.itemID,
            phw_quantity: payload.count,
            phw_orig_quantity: payload.prev_count,
            phw_input_num: payload.input_num,
        }).pipe(map((response: any) => response.response)).toPromise();
    }

    barcodeUpdateWriteInItemCount(payload) {
        const url = `${this.urlOld}/${payload.setupID}/items/write-in/barcode`;

        return this.http.patch(url, {
            barcode: payload.barcode,
            phw_quantity: payload.count,
            phw_orig_quantity: payload.prev_count,
            phw_input_num: payload.input_num,
        }).pipe(map((response: any) => response.response)).toPromise();
    }

    clearPhysicalInventorySetupItemsCache(setupID, input_number) {
        const url = `${this.urlOld}/${setupID}/items/clear`;

        return this.http.post(url, {
            input_number
        }).pipe(map((response: any) => response.response)).toPromise();
    }

    clearPhysicalInventorySetupWriteInItemsCache(setupID, input_number) {
        const url = `${this.urlOld}/${setupID}/items/write-in/clear`;

        return this.http.post(url, {
            input_number
        }).pipe(map((response: any) => response.response)).toPromise();
    }

}
