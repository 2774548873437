import {catchError, map, switchMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {createEffect, Actions, ofType} from '@ngrx/effects';
import {Action} from '@ngrx/store';

import {Observable, EMPTY as empty, of, from as fromPromise} from 'rxjs';


import * as jobs from '../actions/job.actions';
import {JobService} from '../../core/services/job.service';
import * as ggResults from '../actions/grooveglove.actions';

@Injectable()
export class JobEffects {


    loadJobs: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(jobs.LOAD_JOBS),
        switchMap(() =>
            fromPromise(this.jobService.getJobs()).pipe(
                map((jobsInfo) => new jobs.LoadJobsSuccessfulAction(jobsInfo)),
                catchError((error) => of(new jobs.LoadJobsSubcategoryFailedAction(error))))
        )));


    loadJobSubcategories: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(jobs.LOAD_JOB_SUBCATEGORY),
        map((action: jobs.LoadJobSubcategoryAction) => action.payload),
        switchMap((jobId) =>
            fromPromise(this.jobService.getJobSubCategories(jobId)).pipe(
                map(jobSubcategories => new jobs.LoadJobSubcategorySuccessfulAction(jobSubcategories)),
                catchError(error => of(new jobs.LoadJobsSubcategoryFailedAction(error))))
        )));


    loadSubcategories: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(jobs.LOAD_SUBCATEGORIES),
        map((action: jobs.LoadSubcategoriesAction) => action.payload),
        switchMap((ids) =>
            fromPromise(this.jobService.getSubcategories(ids)).pipe(
                map(jobSubcategories => new jobs.LoadJobSubcategorySuccessfulAction(jobSubcategories)),
                catchError(error => of(new jobs.LoadJobsSubcategoryFailedAction(error))))
        )));


    loadJobForms: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(jobs.LOAD_JOB_FORMS),
        map((action: jobs.LoadJobFormsAction) => action.payload),
        switchMap(() =>
            fromPromise(this.jobService.getSubcatForms()).pipe(
                map(forms => new jobs.LoadJobFormsSuccessfulAction(forms)),
                catchError(error => of(new jobs.LoadJobFormsFailedAction(error))))
        )));

    constructor(private $actions: Actions, private jobService: JobService) {
    }
}
