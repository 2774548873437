<svg class="button-icon" *ngIf="name == 'inspection'" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 55 55" style="enable-background:new 0 0 55 55;" xml:space="preserve">
  <style type="text/css">
    .st0 {
      /* fill: #333333; */
    }

    .st1 {
      /* fill: #231F20; */
    }

    .st2 {
      /* fill: #5E5E5E; */
    }
  </style>
  <g id="MBnscw.tif">
    <g>
      <path [attr.fill]="fill" class="st0" d="M49.6,17.8c0-0.9-0.6-1.5-1.5-1.5c-0.5,0-0.9,0-1.4,0c-1.8,0-1.9,0-2.4,1.7c-0.1,0.3-0.3,0.6-0.5,1
			c0,0-0.1,0-0.1-0.1c0-0.1-0.1-0.2-0.1-0.4c-0.7-3.2-1.9-6.1-3.8-8.7c-0.7-1-1.7-1.8-2.9-2.2c-0.8-0.2-1.6-0.4-2.3-0.5
			c-2.8-0.4-5.6-0.5-8.4-0.5c-3.5,0-6.9,0-10.4,0.6c-2.1,0.4-3.8,1.3-4.9,3.2c-1.5,2.5-2.6,5-3.3,7.8c-0.1,0.2-0.1,0.5-0.2,0.7
			c-0.1,0-0.1,0-0.2,0c-0.2-0.3-0.3-0.6-0.5-0.9c-0.1-0.2-0.1-0.5-0.2-0.8c-0.2-0.7-0.6-1-1.4-1c-0.8,0-1.5,0-2.3,0
			c-0.9,0-1.4,0.6-1.5,1.5c0,0.6,0,1.1,0,1.7c0,1.1,0.6,1.7,1.6,1.7c1.2,0,2.4,0,3.7,0c0,0.1,0,0.1,0,0.1c-0.1,0.1-0.2,0.2-0.2,0.2
			c-2.2,2.2-3.5,4.8-3.5,7.9c0,1.7,0.1,3.5,0.3,5.2c0.2,1.7,0.5,3.3,1.3,4.9c0.3,0.6,0.6,1,1.3,1.1c0.3,0,0.3,0.2,0.3,0.5
			c0,0.8,0,1.6,0,2.5c0,1,0.7,1.7,1.7,1.7c1,0,2,0,3,0c1.1,0,1.7-0.6,1.7-1.8c0-0.6,0-1.3,0-1.9c8.6,0.6,17.2,0.6,25.8,0
			c0,0.7,0,1.4,0,2c0,1,0.7,1.7,1.6,1.7c1,0,2.1,0,3.1,0c1,0,1.6-0.7,1.6-1.7c0-0.8,0-1.6,0-2.5c0-0.3,0.1-0.5,0.4-0.5
			c0.6-0.1,1-0.5,1.2-1c0-0.1,0-0.1,0.1-0.2l-0.2-0.3l0.1-0.5l-0.4-0.4c-1.3,1-2.8,1.5-4.7,1.5c-3.5,0-6.5-2.2-7.6-5.3
			c0,0.1,0,0.1,0,0.2c0-0.4-0.2-1-0.4-1.6c-0.1-0.4-0.1-0.9-0.1-1.4c0-4.5,3.6-8.1,8.1-8.1c2.7,0,5.1,1.3,6.6,3.3
			c-0.5-2.1-1.6-3.9-3.3-5.5c-0.1-0.1-0.1-0.2-0.2-0.3c1.3,0,2.6,0,3.9,0c0.9,0,1.5-0.6,1.5-1.5C49.6,19,49.6,18.4,49.6,17.8z
			 M14.4,30.9c-1.1,0-2.2,0-3.3,0v0c-1.1,0-2.2,0-3.3,0c-1,0-1.7-0.7-1.7-1.7c0-0.5,0-1,0-1.6c0-0.9,0.7-1.6,1.6-1.6
			c1.5,0,3,0,4.5,0c1.9,0,3.4,1.5,3.6,3.4C15.9,30.3,15.4,30.9,14.4,30.9z M41.1,20.2c-10.4-0.9-20.8-0.9-31.3,0
			c0.1-0.4,0.2-0.8,0.3-1.2c0.7-2.6,1.7-5,3.1-7.4c0.2-0.4,0.5-0.5,0.9-0.7c2.3-0.6,4.7-0.9,7.1-1c2.9-0.1,5.8-0.1,8.7,0
			c2.4,0.1,4.8,0.3,7.1,1c0.3,0.1,0.6,0.3,0.7,0.5c1.7,2.7,2.8,5.5,3.4,8.6C41.1,20.1,41.1,20.2,41.1,20.2z" />
    </g>
  </g>
  <g>
    <path [attr.fill]="fill" class="st0" d="M48.7,37.8l-0.8,0l-0.7-0.6c0,0.2-0.1,0.4-0.1,0.5c0,0.1-0.1,0.2-0.1,0.4l0.4,0.4L47.3,39l5.4,5.7l1.3-1.3
		L48.7,37.8z" />
    <path [attr.fill]="fill" class="st0" d="M47.7,34.7c0.4-0.9,0.6-1.8,0.6-3c0-0.7-0.1-1.3-0.3-1.9c-0.8-3-3.6-5.2-6.9-5.2c-3.9,0-7.1,3.2-7.1,7.1
		c0,3.9,3.2,7.1,7.1,7.1c1.9,0,3.2-0.5,4.4-1.5l0.3-0.3l1.1,1c0-0.1,0.1-0.2,0.1-0.4c0-0.2,0.1-0.4,0.1-0.5l-0.8-0.7l0.3-0.4
		C47.2,35.7,47.5,35.2,47.7,34.7z M41.1,37.5c-3.1,0-5.7-2.6-5.7-5.7c0-0.4,0-0.7,0.1-1.1c0.5-2.6,2.8-4.6,5.6-4.6
		c1.4,0,2.7,0.5,3.6,1.3c1.3,1,2.1,2.6,2.1,4.4C46.9,34.9,44.3,37.5,41.1,37.5z" />
    <path [attr.fill]="fill" class="st0" d="M33.5,34.6c-0.1-0.2-0.1-0.4-0.2-0.6C33.4,34.2,33.5,34.4,33.5,34.6z" />
  </g>
</svg>

<svg class="button-icon" *ngIf="name == 'tire'" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 55 55" style="enable-background:new 0 0 55 55;" xml:space="preserve">
  <style type="text/css">
    .st0 {
      /* fill: #333333; */
    }
  </style>
  <path [attr.fill]="fill" class="st0" d="M36.2,4c-0.6,0-6.9-0.2-17.4-0.1c-2.9,0-5.6,2.6-7.8,6.3h4.9c1.9,0,1.9,2.4,0,2.4s-6.2,0-6.2,0
	c-0.7,1.5-1.3,3.2-1.8,4.9h4.6c1.9,0,1.9,2.4,0,2.4H7.3c-0.3,1.5-0.6,3-0.6,4.5h4.1c2,0,1.9,2.4,0,2.4H6.6c0,1.6,0.1,3.2,0.3,4.7
	h3.4c2,0,1.9,2.4,0,2.4H7.2c0.2,1.5,0.5,2.9,0.9,4.2h3.8c2,0,1.9,2.4,0,2.4H8.8c0.5,1.3,1,2.5,1.6,3.5h4.8c1.9,0,1.9,2.4,0,2.4h-3.3
	c1.8,2.5,4.1,4.1,6.5,4.3c3.8,0.4,17.1,0,17.5,0C43,50.9,49,40.4,49,27.4S43,4.2,36.2,4z M23.4,9.7h4c-0.5,0.8-0.9,1.6-1.2,2.4h-2.7
	C21.5,12.1,21.4,9.7,23.4,9.7z M20,17h4.4c-0.2,0.8-0.4,1.6-0.6,2.4H20C18.1,19.5,18,17.1,20,17z M18.3,24H23
	c-0.1,1-0.2,1.8-0.2,2.4h-4.5C16.4,26.4,16.3,24,18.3,24z M17.8,31.1h5c0.1,0.8,0.1,1.6,0.2,2.4h-5.2C16,33.5,15.9,31.1,17.8,31.1z
	 M19.5,37.7h4.3c0.2,0.8,0.4,1.6,0.7,2.4h-5C17.7,40.1,17.5,37.7,19.5,37.7z M22.2,46.5c-1.8,0-2-2.4,0-2.4h4.2
	c0.1,0.1,0.2,0.3,0.2,0.4c0.5,0.7,1,1.4,1.5,2C27,46.5,23.8,46.5,22.2,46.5z M36.8,39.3c-3.1,0-5.7-5.2-5.7-11.5s2.6-11.5,5.7-11.5
	c3.2,0,5.7,5.2,5.7,11.5S40,39.3,36.8,39.3z" />
</svg>

<svg class="button-icon" *ngIf="name == 'service'" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 55 55" style="enable-background:new 0 0 55 55;" xml:space="preserve">
  <style type="text/css">
    .st0 {
      /* fill: #333333; */
    }

    .st1 {
      /* fill: #231F20; */
    }

    .st2 {
      /* fill: #5E5E5E; */
    }
  </style>
  <g id="OuEYnD.tif">
    <g>
      <path [attr.fill]="fill" class="st0" d="M47.3,21.8c0-0.3-0.1-0.5-0.4-0.7c-1.4-0.6-2.9-1.2-4.3-1.7c-0.6-0.2-1.2-0.4-1.4-1.1
			c-0.1-0.2-0.2-0.3-0.2-0.5c-0.2-0.6-0.6-1.2-0.3-2c0.6-1.3,1.2-2.6,1.7-4c0.2-0.4,0.6-0.9,0.1-1.4c-1-0.9-1.9-1.8-2.8-2.7
			c-0.1-0.1-0.4-0.2-0.6-0.1c-1.6,0.7-3.3,1.3-4.9,2c-0.4,0.2-0.7,0.2-1.1,0c-0.4-0.2-0.9-0.5-1.3-0.6c-0.6-0.1-0.8-0.5-1-1
			c-0.7-1.5-1.4-3.1-2.1-4.6C28.5,3.2,28.3,3,28.1,3c-1.3,0-2.7,0-4,0c-0.2,0-0.5,0.2-0.6,0.4c-0.7,1.6-1.3,3.2-2,4.8
			c-0.1,0.3-0.3,0.5-0.6,0.6c-0.5,0.3-1.1,0.5-1.7,0.7c-0.3,0.1-0.6,0.1-0.8,0c-1.6-0.6-3.2-1.3-4.8-1.9c-0.4-0.2-0.8-0.1-1.1,0.2
			c-0.8,0.9-1.7,1.7-2.6,2.5c-0.3,0.3-0.3,0.5-0.2,0.9c0.5,1.1,1,2.2,1.4,3.4c0.3,0.6,0.7,1.3,0.6,2c-0.1,0.8-0.5,1.5-0.9,2.3
			c0,0.1-0.1,0.1-0.2,0.2c-1.5,0.6-3,1.2-4.5,1.8c-0.4,0.2-1.1,0.3-1.1,1c0,1.3,0,2.6,0,3.9c0,0.2,0.2,0.5,0.4,0.6
			c1.4,0.6,2.8,1.2,4.3,1.7c0.6,0.2,1.3,0.3,1.4,1.1c0.1,0.2,0.2,0.5,0.3,0.7c0.2,0.6,0.5,1.2,0.2,1.8c-0.6,1.5-1.3,3.1-1.9,4.6
			c-0.1,0.2,0,0.6,0.1,0.8c0.9,1,1.9,1.9,2.9,2.8c0.1,0.1,0.5,0.2,0.6,0.2c1.7-0.7,3.3-1.4,5-2.1c0.2-0.1,0.5-0.1,0.8,0
			c0.4,0.1,0.8,0.3,1.1,0.4c0.5,0.3,1.1,0.4,1.4,1.1c0.6,1.4,1.2,2.8,1.8,4.3c0.2,0.4,0.4,0.5,0.8,0.5c1.3,0,2.5,0,3.8,0
			c0.6,0,0.9-0.2,1-0.7c0.6-1.4,3.7-5.7,4.3-7.1c-2.1-2.3-4-3.6-4-3.6c-1,0.4-2.1,0.6-3.2,0.6c-5.3,0-9.5-4.3-9.5-9.5
			s4.3-9.5,9.5-9.5s9.5,4.3,9.5,9.5c0,1-0.2,2-0.4,2.9c0,0,2.2,2.7,4.7,4.6c1-0.8,1.3-2.9,2.3-3c0.2,0,0.4-0.1,0.7-0.2
			c1.1-0.5,2.3-1,3.4-1.4c0.9-0.4,1-0.5,1-1.5C47.3,23.9,47.3,22.8,47.3,21.8z" />
    </g>
  </g>
  <g id="uUFCIj.tif">
    <g>
      <path [attr.fill]="fill" class="st0" d="M44.9,37.9c-1.5-0.5-2.8-1.5-3.9-2.6c-1.5-1.5-3-3-4.4-4.5c-0.8-0.8-1.5-1.6-2.2-2.5
			c-0.6-0.8-0.9-1.7-0.7-2.8c0.4-2.6-0.4-4.8-2.5-6.4c-2.2-1.7-4.6-1.9-7.1-0.7c-0.1,0.1-0.2,0.1-0.4,0.2c0.2,0.1,0.2,0.1,0.3,0.2
			c1.4,0.7,2.8,1.4,4.2,2.2c1.8,0.9,2.5,3.1,1.6,4.8c-0.9,1.8-3,2.5-4.8,1.6c-0.5-0.3-1-0.5-1.5-0.8c-1-0.5-2-1-3-1.6
			c0,0.2,0,0.3,0.1,0.5c0.6,3.7,4.2,6.1,7.8,5.4c0.6-0.1,1.2-0.1,1.7,0.3c0.7,0.5,1.4,0.9,2,1.5c1.8,1.8,3.7,3.7,5.5,5.5
			c0.6,0.6,1.2,1.3,1.7,2c0.7,1,1.2,2.2,1.3,3.5c0.3,3.9,4.5,6.1,7.9,4.2c0.2-0.1,0.4-0.3,0.7-0.4l1-0.9c0-0.1,0-0.1,0.1-0.2
			c1.2-1.7,1.4-3.6,0.5-5.4c-0.9-1.9-2.5-2.9-4.6-3C45.6,38.1,45.2,38,44.9,37.9z M48.2,41.7c0.1,0.1,0.2,0.2,0.2,0.4
			c0,0.4,0.1,0.8,0.3,1.1c0.1,0.1,0.1,0.3,0,0.4c-0.2,0.3-0.3,0.7-0.3,1.1c0,0.1-0.1,0.3-0.2,0.4c-0.4,0.2-0.7,0.5-0.9,1
			c-0.1,0-0.2,0-0.3,0c-0.4,0-0.8,0-1.1,0.3c-0.2,0.1-0.3,0.1-0.5,0c-0.3-0.2-0.6-0.3-1-0.3c-0.2,0-0.4,0-0.5-0.3
			c-0.1-0.4-0.4-0.6-0.8-0.8c-0.2-0.1-0.3-0.2-0.2-0.4c0.1-0.4-0.1-0.8-0.3-1.1c-0.1-0.1-0.1-0.3,0-0.4c0.2-0.3,0.3-0.7,0.3-1.1
			c0-0.1,0.1-0.3,0.2-0.4c0.4-0.2,0.6-0.5,0.8-0.8c0.1-0.1,0.2-0.2,0.4-0.2c0.4,0,0.8-0.1,1.1-0.3c0.1-0.1,0.3-0.1,0.4,0
			c0.3,0.2,0.7,0.3,1.1,0.3c0.1,0,0.3,0.1,0.4,0.2C47.6,41.3,47.9,41.5,48.2,41.7z" />
    </g>
  </g>
</svg>

<svg class="button-icon" *ngIf="name == 'edit'" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 55 55" style="enable-background:new 0 0 55 55;" xml:space="preserve">
  <style type="text/css">
    .st0 {
      /* fill: #333333; */
    }

    .st1 {
      /* fill: #231F20; */
    }

    .st2 {
      /* fill: #5E5E5E; */
    }
  </style>
  <g>
    <path [attr.fill]="fill" class="st0" d="M26.7,17.8l4.4-12.5c0,0,1.8-5.5,6.2-3.9c2.3,0.8,3.6,3.1,2.2,6.9C38,12,34.8,20.9,34.8,20.9L26.7,17.8z"
    />
    <path [attr.fill]="fill" class="st0" d="M18.1,49.8c0,0-1.2-4.7,0-8.4c1.2-3.7,7.4-20.5,7.4-20.5l8.2,3.1l-7.6,20.8c0,0-2.6,5.1-5.8,6.1
		c-0.4,0.3-1.1,2.2-1.8,2.1c-0.5-0.3-0.5-0.4-0.3-1.7C18.4,50.4,18.1,49.8,18.1,49.8z" />
    <path [attr.fill]="fill" class="st0" d="M22.2,20.8L22,20.7c-0.9-0.3-1.3-1.3-1-2.2l5.9-15.4c0.3-0.9,1.3-1.3,2.2-1l0.2,0.1c0.9,0.3,1.3,1.3,1,2.2
		l-5.9,15.4C24.1,20.7,23.1,21.1,22.2,20.8z" />
  </g>
</svg>

<svg class="button-icon" *ngIf="name == 'speedometer'" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 55 55" style="enable-background:new 0 0 55 55;" xml:space="preserve">
  <style type="text/css">
    .st0 {
      /* fill: #333333; */
    }

    .st1 {
      /* fill: #231F20; */
    }

    .st2 {
      /* fill: #5E5E5E; */
    }
  </style>
  <path [attr.fill]="fill" class="st1" d="M46.5,17.7l-3.6,5c1.6,2.1,2.8,4.4,3.5,7c0,0,0,0,0,0c0.1,0.2,1.3,4.3,1.1,8.3h5.4l0.1-2.9c0-0.2,0-0.3,0-0.5
	C53,28.1,50.5,22.2,46.5,17.7z M27.2,9.2C13.1,9.2,1.5,20.6,1.5,34.6c0,0.2,0,0.3,0,0.5L1.6,38h6c0,0-0.7-6.4,1-9.9
	c2.7-7.6,10.1-13.1,18.7-13.1c4.2,0,8.1,1.3,11.3,3.5l4.6-4C38.8,11.2,33.3,9.2,27.2,9.2z" />
  <path [attr.fill]="fill" class="st1" d="M25.5,31.4l21-16.5L29.7,36.3c0,0-1.9,2.6-4.9,0.5C23.6,35.5,22.9,33.4,25.5,31.4z" />
</svg>

<svg class="button-icon" *ngIf="name == 'technician'" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 55 55" style="enable-background:new 0 0 55 55;" xml:space="preserve">
  <style type="text/css">
    .st0 {
      /* fill: #333333; */
    }

    .st1 {
      /* fill: #231F20; */
    }

    .st2 {
      /* fill: #5E5E5E; */
    }
  </style>
  <g id="uUFCIj.tif_1_">
    <g>
      <path [attr.fill]="fill" d="M23.5,24.9c0.1-0.1,0.3-0.2,0.5-0.4c0.2-0.2,0.3-0.5,0.4-0.8C24,24.1,23.7,24.5,23.5,24.9z M24.7,26.1
			c0-0.2-0.1-0.4-0.1-0.6c-0.3,0.1-0.5,0-0.5,0c0.1,0.9,0.3,1.3-0.5,2.6c-0.8,1.3-2.1,0.7-2.1,0.7s0.9,1.3-0.3,2.6
			c-1.2,1.3-2,0.7-2,0.7s0.8,1.3-0.3,2.5c-0.9,0.9-1.3,1.2-3.2,0.2c-1.1,1-2.5,1.8-4,2.2c-5,1.2-7.1,7.2-3.9,11.3
			c0.2,0.3,0.4,0.5,0.7,0.8l1.4,1.1c0.1,0,0.2,0,0.2,0.1c2.5,1.2,5,1.1,7.3-0.5c2.3-1.6,3.3-3.9,3-6.7c0-0.4,0-0.9,0-1.3
			c0.3-2.1,1.3-4,2.6-5.7c1.6-2.3,3.3-4.5,5-6.8c0.1-0.1,0.2-0.2,0.3-0.4C26.6,28.4,24.9,27.7,24.7,26.1z M17.4,43.5
			c-0.2,0.5-0.3,1-0.2,1.6c0,0.2-0.1,0.4-0.2,0.5c-0.4,0.3-0.7,0.7-0.9,1.3c-0.1,0.2-0.3,0.3-0.4,0.4c-0.5,0.1-1,0.3-1.4,0.6
			C14,48,13.7,48,13.6,47.9c-0.5-0.2-1-0.3-1.5-0.2c-0.2,0-0.5-0.1-0.6-0.2c-0.4-0.5-0.9-0.8-1.5-1c0-0.1-0.1-0.2-0.1-0.4
			c0-0.5-0.2-1-0.6-1.4c-0.2-0.2-0.2-0.4-0.1-0.7c0.2-0.4,0.3-0.9,0.2-1.4c-0.1-0.3,0-0.5,0.3-0.7c0.5-0.3,0.7-0.7,0.9-1.2
			c0.1-0.3,0.2-0.4,0.5-0.4c0.5,0,1-0.3,1.4-0.6c0.1-0.1,0.4-0.1,0.6-0.1c0.5,0.2,1,0.3,1.5,0.2c0.2,0,0.5,0.1,0.6,0.2
			c0.3,0.4,0.7,0.7,1.3,0.9c0.2,0.1,0.3,0.3,0.4,0.4c0.1,0.6,0.3,1,0.6,1.4C17.4,43.1,17.4,43.4,17.4,43.5z M45.3,5.5
			c-0.1-0.1-0.2-0.3-0.3-0.5c-0.1,0.2-0.1,0.3-0.2,0.5c-0.7,2-1.3,4-2,6c-0.8,2.5-3.5,3.9-6,3.1c-2.6-0.8-4-3.5-3.2-6.1
			c0.2-0.7,0.5-1.5,0.7-2.2c0.5-1.4,0.9-2.8,1.4-4.3c-0.3,0.1-0.4,0.1-0.6,0.2c-4.7,1.6-7.2,6.8-5.5,11.5c0.3,0.8,0.3,1.6,0,2.3
			c-0.3,0.7-2.5,3.8-3.9,5.8L26,22c0,0,0.2,0.4,0.4,0.9c0.4-0.1,0.9-0.1,1.6,0c1.5,0.2,3,0.5,4.4,0.8c0.9-1.2,1.7-2.2,2.1-2.5
			c0.9-1,2-1.5,3.5-1.5c3.5,0,6.2-1.6,7.9-4.6C47.6,11.8,47.3,8.5,45.3,5.5z" />
    </g>
  </g>
  <path [attr.fill]="fill" class="st2" d="M24.3,23.7c0,0.3-0.2,0.5-0.4,0.8c-0.2,0.2-0.3,0.3-0.5,0.4C23.7,24.5,24,24.1,24.3,23.7z" />
  <path [attr.fill]="fill" d="M24.3,23.7c-0.3,0.4-0.6,0.8-0.8,1.2c-0.7,0.5-1.1,0.3-1.1,0.3c0.1,0.5,0.2,0.8,0,1.2c-0.1,0.2-0.2,0.5-0.5,0.9
	c-0.2,0.3-0.4,0.5-0.6,0.6c-0.5,0.3-1.1,0-1.1,0c0,0,0.3,0.5,0.2,1.1c0,0.3-0.2,0.7-0.5,1c-0.2,0.3-0.5,0.4-0.7,0.5
	c-0.6,0.3-1,0.1-1,0c0,0,0.3,0.5,0.2,1c0,0.3-0.1,0.7-0.5,1.1c-0.4,0.3-0.6,0.6-0.9,0.7c-0.4,0.1-0.9,0-1.8-0.5
	c-1.7-0.9-3.6-2.6-3.6-2.6s-0.8-0.6-0.7-1.5c0-0.3,0.1-0.5,0.3-0.8c0.5-0.6,1.2-0.9,1.7-0.8c-0.1-0.3-0.1-0.6,0-0.9
	c0-0.3,0.1-0.7,0.4-1c0.6-0.7,1.2-1,1.9-0.7c-0.2-0.4-0.7-1.1,0.2-2.2c0.3-0.4,0.6-0.5,0.9-0.6c0.3-0.2,0.5-0.3,0.7-0.3
	c-0.2-0.4-0.3-1.1,0.3-1.8c0.2-0.2,0.3-0.3,0.5-0.4c0.7-0.3,1.5-0.2,1.5-0.2l4.5,3.3C24,22.2,24.4,22.9,24.3,23.7z" />
  <path [attr.fill]="fill" d="M24.3,23.7c0,0.3-0.2,0.5-0.4,0.8c-0.2,0.2-0.3,0.3-0.5,0.4C23.7,24.5,24,24.1,24.3,23.7z" />
  <path [attr.fill]="fill" d="M36.9,34.9l-6.2,8.1c0,0-0.5,0.9-2.4-0.6c-1.2-0.9-3-2.2-4.1-3.1c-0.7-0.5-1.1-0.8-1.1-0.8l7-9.3l0.7-0.9c0,0-0.8,0-1.8-0.2
	c-1.3-0.2-2.9-0.8-3.1-2c-0.1-0.4-0.1-0.8,0-1.1c0.1-0.4,0.2-0.8,0.6-1c0.4-0.2,0.9-0.3,1.7-0.2c1.2,0.1,2.6,0.4,3.6,0.7
	c1.1,0.2,1.8,0.5,1.8,0.5s1.9,0.7,2.3,2.6C36.2,29.3,37.3,34.3,36.9,34.9z" />
</svg>
