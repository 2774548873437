import { Action as NgrxAction } from '@ngrx/store';

export const LOAD_PROFILE = '[Profile] Load Profile';
export const SET_PROFILE = '[Profile] Set Profile';
export const LOAD_PROFILE_SUCCESSFUL = '[Profile] Load Profile successful';
export const LOADING_PROFILE_FAILED = '[Profile] Load Profile failed';
export const SET_REASON_FOR_VISIT = '[Profile] Set reaosn for visit';
export const SET_REASON_FOR_VISIT_BY_TYPE = '[Profile] Set reaosn for visit by type';


export class LoadProfileAction implements NgrxAction {
    readonly type = LOAD_PROFILE;

    constructor(public payload: any) {
    }

}

export class LoadProfileSuccessfulAction implements NgrxAction {
    readonly type = LOAD_PROFILE_SUCCESSFUL;

    constructor(public payload: any) {
    }

}

export class LoadProfileFailedAction implements NgrxAction {
    readonly type = LOADING_PROFILE_FAILED;

    constructor(public payload: any) {
    }
}

export class SetProfileAction implements NgrxAction {
    readonly type = SET_PROFILE;

    constructor(public payload: any) {
    }
}

export class SetReasonForVisitAction implements NgrxAction {
    readonly type = SET_REASON_FOR_VISIT;

    constructor(public payload: any) {
    }
}

export class SetReastForVisitByTypeAction implements NgrxAction {
    readonly type = SET_REASON_FOR_VISIT_BY_TYPE;

    constructor(public payload: string) {
    }
}

export type Action = SetProfileAction
| LoadProfileAction
| LoadProfileSuccessfulAction
| LoadProfileFailedAction
| SetReasonForVisitAction;
