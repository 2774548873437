import * as session from '../actions/session.actions';

export interface State {
    loading: boolean;
    loadingSuccessful: boolean;
    loadingError: string;

    session: any;
};

const initialState: State = {

    loading: false,
    loadingSuccessful: null,
    loadingError: null,

    session: null
};

export function reducer(state = initialState, action): State {

    switch (action.type) {
        case session.LOAD_SESSION:
            return {...state, loading: true};
        case session.LOAD_SESSION_SUCCESSFUL:
            return {...state, loading: false, session: action.payload, loadingSuccessful: true};
        case session.LOAD_SESSION_FAILED:
            return {...state, loading: false, loadingSuccessful: false, loadingError: action.payload};

        default:
            return state;
    }

}
