import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

@Injectable()
export class AppointmentService {

    public url: string;

    constructor(private http: HttpClient) {
        this.url = environment.tireguruBaseApi + '/appointments';
    }

    getAppointments() {
        const url = `${this.url}`;
        return this.http.get(url, {
            params: {
                per_page: '10'
            }
        }).toPromise();
    }

    searchAppointments() {
        return new Promise((resolve, reject) => {
            const data = [
                {
                    name: 'Pete',
                    body: 'Lorem ipsum Facilis, perferendis. dolor sit amet consectetur adipisicing elit. Facilis, perferendis.'
                },
                {
                    name: 'Larry',
                    body: 'lorem ipsum text goes here'
                },
                {
                    name: 'Bob',
                    body: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis, perferendis.'
                },
            ];
            resolve(data);
        });
    }

    searchAppointmentsAdvanced() {
        return new Promise((resolve, reject) => {
            const data = [
                {
                    name: 'Pete',
                    body: 'Lorem ipsum Facilis, perferendis. dolor sit amet consectetur adipisicing elit. Facilis, perferendis.'
                },
                {
                    name: 'Larry',
                    body: 'lorem ipsum text goes here'
                },
                {
                    name: 'Bob',
                    body: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis, perferendis.'
                },
            ];
            resolve(data);
        });
    }

}
