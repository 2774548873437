<svg class="icon" *ngIf="name == 'user'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path [attr.fill]="fill" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"
  />
</svg>

<svg class="icon" *ngIf="name == 'nav'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path [attr.fill]="fill" d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
</svg>

<svg class="icon" *ngIf="name == 'mail'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path [attr.fill]="fill" d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z"
  />
</svg>

<svg class="icon" *ngIf="name == 'note'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path [attr.fill]="fill" d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm2 14h-3v3h-2v-3H8v-2h3v-3h2v3h3v2zm-3-7V3.5L18.5 9H13z"
  />
</svg>

<svg class="icon" *ngIf="name == 'checkmark'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="hiddenForPrinting">
  <path [attr.fill]="fill" d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
</svg>

<svg class="icon" *ngIf="name == 'image'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="hiddenForPrinting">
  <path [attr.fill]="fill" d="M21 3H3C2 3 1 4 1 5v14c0 1.1.9 2 2 2h18c1 0 2-1 2-2V5c0-1-1-2-2-2zM5 17l3.5-4.5 2.5 3.01L14.5 11l4.5 6H5z" />
</svg>

<svg class="icon" *ngIf="name == 'search'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path [attr.fill]="fill" d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
  />
</svg>

<svg class="icon" *ngIf="name == 'close'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path [attr.fill]="fill" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
</svg>

<svg class="icon" *ngIf="name == 'hourglass'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
  <path [attr.fill]="fill" d="M368 32h4c6.627 0 12-5.373 12-12v-8c0-6.627-5.373-12-12-12H12C5.373 0 0 5.373 0 12v8c0 6.627 5.373 12 12 12h4c0 91.821 44.108 193.657 129.646 224C59.832 286.441 16 388.477 16 480h-4c-6.627 0-12 5.373-12 12v8c0 6.627 5.373 12 12 12h360c6.627 0 12-5.373 12-12v-8c0-6.627-5.373-12-12-12h-4c0-91.821-44.108-193.657-129.646-224C324.168 225.559 368 123.523 368 32zM48 32h288c0 110.457-64.471 200-144 200S48 142.457 48 32zm288 448H48c0-110.457 64.471-200 144-200s144 89.543 144 200z"/>
</svg>

<svg class="icon" *ngIf="name == 'plus-fill'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="hiddenForPrinting">
  <path [attr.fill]="fill" d="M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-2 10h-4v4h-2v-4H7v-2h4V7h2v4h4v2z"
  />
</svg>

<svg class="icon" *ngIf="name == 'thumbs-up'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path [attr.fill]="fill" d="M1 21h4V9H1v12zm22-11c0-1.1-.9-2-2-2h-6.31l.95-4.57.03-.32c0-.41-.17-.79-.44-1.06L14.17 1 7.59 7.59C7.22 7.95 7 8.45 7 9v10c0 1.1.9 2 2 2h9c.83 0 1.54-.5 1.84-1.22l3.02-7.05c.09-.23.14-.47.14-.73v-1.91l-.01-.01L23 10z" />
</svg>

<svg class="icon" *ngIf="name == 'ban'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
  <path [attr.fill]="fill" d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm141.421 106.579c73.176 73.175 77.05 187.301 15.964 264.865L132.556 98.615c77.588-61.105 191.709-57.193 264.865 15.964zM114.579 397.421c-73.176-73.175-77.05-187.301-15.964-264.865l280.829 280.829c-77.588 61.105-191.709 57.193-264.865-15.964z" />
</svg>

<svg class="icon" *ngIf="name == 'req'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
  <path fill="#ff005c" d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z" />
</svg>

<svg class="icon" *ngIf="name == 'sugg'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
  <path fill="#febc58" d="M10,0 C15.52,0 20,4.48 20,10 C20,15.52 15.52,20 10,20 C4.48,20 0,15.52 0,10 C0,4.48 4.48,0 10,0 Z M10,4 C8.07,4 6.5,5.57 6.5,7.5 L8.25,7.5 C8.25,6.53 9.03,5.75 10,5.75 C10.97,5.75 11.75,6.53 11.75,7.5 C11.75,7.98 11.55,8.42 11.24,8.74 L10.15,9.84 C9.52,10.47 9.13,11.35 9.13,12.31 L9.13,12.75 L10.88,12.75 C10.88,11.44 11.27,10.91 11.91,10.28 L12.69,9.48 C13.19,8.98 13.51,8.28 13.51,7.51 C13.5,5.57 11.93,4 10,4 Z M9.05,15.95 L10.95,15.95 L10.95,14.05 L9.05,14.05 L9.05,15.95 Z" />
</svg>

<svg class="icon" *ngIf="name == 'ok'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
  <path fill="#3cb371" d="M10,0 C15.52,0 20,4.48 20,10 C20,15.52 15.52,20 10,20 C4.48,20 -5.24580379e-15,15.52 -5.24580379e-15,10 C-5.24580379e-15,4.48 4.48,0 10,0 Z M14.5,6.5 L9.01,12 L6,9 L4.5,10.5 L9.01,15 L16,8 L14.5,6.5 Z" />
</svg>

<svg  class="icon" *ngIf="name == 'link'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
  <path fill="#111111" d="M326.612 185.391c59.747 59.809 58.927 155.698.36 214.59-.11.12-.24.25-.36.37l-67.2 67.2c-59.27 59.27-155.699 59.262-214.96 0-59.27-59.26-59.27-155.7 0-214.96l37.106-37.106c9.84-9.84 26.786-3.3 27.294 10.606.648 17.722 3.826 35.527 9.69 52.721 1.986 5.822.567 12.262-3.783 16.612l-13.087 13.087c-28.026 28.026-28.905 73.66-1.155 101.96 28.024 28.579 74.086 28.749 102.325.51l67.2-67.19c28.191-28.191 28.073-73.757 0-101.83-3.701-3.694-7.429-6.564-10.341-8.569a16.037 16.037 0 0 1-6.947-12.606c-.396-10.567 3.348-21.456 11.698-29.806l21.054-21.055c5.521-5.521 14.182-6.199 20.584-1.731a152.482 152.482 0 0 1 20.522 17.197zM467.547 44.449c-59.261-59.262-155.69-59.27-214.96 0l-67.2 67.2c-.12.12-.25.25-.36.37-58.566 58.892-59.387 154.781.36 214.59a152.454 152.454 0 0 0 20.521 17.196c6.402 4.468 15.064 3.789 20.584-1.731l21.054-21.055c8.35-8.35 12.094-19.239 11.698-29.806a16.037 16.037 0 0 0-6.947-12.606c-2.912-2.005-6.64-4.875-10.341-8.569-28.073-28.073-28.191-73.639 0-101.83l67.2-67.19c28.239-28.239 74.3-28.069 102.325.51 27.75 28.3 26.872 73.934-1.155 101.96l-13.087 13.087c-4.35 4.35-5.769 10.79-3.783 16.612 5.864 17.194 9.042 34.999 9.69 52.721.509 13.906 17.454 20.446 27.294 10.606l37.106-37.106c59.271-59.259 59.271-155.699.001-214.959z">
  </path>
</svg>

<svg xmlns="http://www.w3.org/2000/svg" *ngIf="name == 'licensePlate'" width="35" height="18" viewBox="0 0 35 18">
  <path fill="#000000" d="M32,12 L31,12 L31,14 L29,14 L29,15 L32,15 L32,12 Z M32,6 L31,6 L31,4 L29,4 L29,3 L32,3 L32,6 Z M4,4 L6,4 L6,3 L3,3 L3,6 L4,6 L4,4 Z M4,14 L6,14 L6,15 L3,15 L3,12 L4,12 L4,14 Z M33,0 L2,0 C0.9,0 0,0.9 0,2 L0,16 C0,17.1 0.9,18 2,18 L33,18 C34.1,18 35,17.1 35,16 L35,2 C35,0.9 34.1,0 33,0 Z M2,16.01 L2,1.99 L33,1.99 L33,16.01 L2,16.01 Z"/>
</svg>

<svg xmlns="http://www.w3.org/2000/svg" *ngIf="name == 'message'" width="45" height="30" viewBox="0 0 512 512">
  <path [attr.fill]="fill" d="M256 288c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zm28.7-160h-57.4c-10 0-17.6 9.1-15.7 18.9l18 96c1.4 7.6 8 13.1 15.7 13.1h21.4c7.7 0 14.3-5.5 15.7-13.1l18-96c1.9-9.8-5.7-18.9-15.7-18.9zM256 32C114.6 32 0 125.1 0 240c0 47.6 19.9 91.2 52.9 126.3C38 405.7 7 439.1 6.5 439.5c-6.6 7-8.4 17.2-4.6 26S14.4 480 24 480c61.5 0 110-25.7 139.1-46.3C192 442.8 223.2 448 256 448c141.4 0 256-93.1 256-208S397.4 32 256 32zm0 368c-26.7 0-53.1-4.1-78.4-12.1l-22.7-7.2-19.5 13.8c-14.3 10.1-33.9 21.4-57.5 29 7.3-12.1 14.4-25.7 19.9-40.2l10.6-28.1-20.6-21.8C69.7 314.1 48 282.2 48 240c0-88.2 93.3-160 208-160s208 71.8 208 160-93.3 160-208 160z"></path></svg>

<svg xmlns="http://www.w3.org/2000/svg" *ngIf="name == 'angle-up'" width="35" height="18" viewBox="0 0 512 512">
  <path fill="#ffffff" d="M177 255.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 351.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 425.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1zm-34-192L7 199.7c-9.4 9.4-9.4 24.6 0 33.9l22.6 22.6c9.4 9.4 24.6 9.4 33.9 0l96.4-96.4 96.4 96.4c9.4 9.4 24.6 9.4 33.9 0l22.6-22.6c9.4-9.4 9.4-24.6 0-33.9l-136-136c-9.2-9.4-24.4-9.4-33.8 0z"></path>
</svg>

<svg xmlns="http://www.w3.org/2000/svg" *ngIf="name == 'angle-down'" width="35" height="18" viewBox="0 0 512 512">
  <path fill="#ffffff" d="M143 256.3L7 120.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0L313 86.3c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.4 9.5-24.6 9.5-34 .1zm34 192l136-136c9.4-9.4 9.4-24.6 0-33.9l-22.6-22.6c-9.4-9.4-24.6-9.4-33.9 0L160 352.1l-96.4-96.4c-9.4-9.4-24.6-9.4-33.9 0L7 278.3c-9.4 9.4-9.4 24.6 0 33.9l136 136c9.4 9.5 24.6 9.5 34 .1z"></path>
</svg>

<svg xmlns="http://www.w3.org/2000/svg" *ngIf="name === 'single-chevron-down'" width="17" height="17" viewBox="0 0 448 512">
  <path fill="currentColor" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
</svg>

<svg xmlns="http://www.w3.org/2000/svg" *ngIf="name == 'cell-phone'" width="10" height="20" viewBox="0 0 320 512">
  <path fill="currentColor" d="M272 0H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h224c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zm-64 452c0 6.6-5.4 12-12 12h-72c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h72c6.6 0 12 5.4 12 12v8zm64-80c0 6.6-5.4 12-12 12H60c-6.6 0-12-5.4-12-12V60c0-6.6 5.4-12 12-12h200c6.6 0 12 5.4 12 12v312z"></path>
</svg>

<svg xmlns="http://www.w3.org/2000/svg" *ngIf="name == 'trash-can'" width="17" height="30" viewBox="0 0 448 512">
  <path fill="currentColor" d="M296 432h16a8 8 0 0 0 8-8V152a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v272a8 8 0 0 0 8 8zm-160 0h16a8 8 0 0 0 8-8V152a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v272a8 8 0 0 0 8 8zM440 64H336l-33.6-44.8A48 48 0 0 0 264 0h-80a48 48 0 0 0-38.4 19.2L112 64H8a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h24v368a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V96h24a8 8 0 0 0 8-8V72a8 8 0 0 0-8-8zM171.2 38.4A16.1 16.1 0 0 1 184 32h80a16.1 16.1 0 0 1 12.8 6.4L296 64H152zM384 464a16 16 0 0 1-16 16H80a16 16 0 0 1-16-16V96h320zm-168-32h16a8 8 0 0 0 8-8V152a8 8 0 0 0-8-8h-16a8 8 0 0 0-8 8v272a8 8 0 0 0 8 8z"></path>
</svg>

<svg xmlns="http://www.w3.org/2000/svg" *ngIf="name == 'add-item'" width="12" height="12" viewBox="0 0 448 512">
  <path fill="currentColor" d="M416 208H272V64c0-17.67-14.33-32-32-32h-32c-17.67 0-32 14.33-32 32v144H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h144v144c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32V304h144c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path>
</svg>


<svg class="icon" xmlns="http://www.w3.org/2000/svg" *ngIf="name == 'subtract'" width="12" height="12" viewBox="0 0 448 512">
  <path fill="currentColor" d="M416 208H32c-17.67 0-32 14.33-32 32v32c0 17.67 14.33 32 32 32h384c17.67 0 32-14.33 32-32v-32c0-17.67-14.33-32-32-32z"></path>
</svg>

<svg xmlns="http://www.w3.org/2000/svg" *ngIf="name == 'circle'" width="12" height="12" viewBox="0 0 512 512">
  <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
</svg>

<svg xmlns="http://www.w3.org/2000/svg" *ngIf="name == 'new-message'" width="17" height="17" viewBox="0 0 576 512">
  <path fill="currentColor" d="M417.8 315.5l20-20c3.8-3.8 10.2-1.1 10.2 4.2V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h292.3c5.3 0 8 6.5 4.2 10.2l-20 20c-1.1 1.1-2.7 1.8-4.2 1.8H48c-8.8 0-16 7.2-16 16v352c0 8.8 7.2 16 16 16h352c8.8 0 16-7.2 16-16V319.7c0-1.6.6-3.1 1.8-4.2zm145.9-191.2L251.2 436.8l-99.9 11.1c-13.4 1.5-24.7-9.8-23.2-23.2l11.1-99.9L451.7 12.3c16.4-16.4 43-16.4 59.4 0l52.6 52.6c16.4 16.4 16.4 43 0 59.4zm-93.6 48.4L403.4 106 169.8 339.5l-8.3 75.1 75.1-8.3 233.5-233.6zm71-85.2l-52.6-52.6c-3.8-3.8-10.2-4-14.1 0L426 83.3l66.7 66.7 48.4-48.4c3.9-3.8 3.9-10.2 0-14.1z"></path>
</svg>

<svg class="icon" xmlns="http://www.w3.org/2000/svg" *ngIf="name == 'exclamation-point'" width="17" height="17" viewBox="0 0 192 512">
  <path [attr.fill]="fill" d="M176 432c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80zM25.26 25.199l13.6 272C39.499 309.972 50.041 320 62.83 320h66.34c12.789 0 23.331-10.028 23.97-22.801l13.6-272C167.425 11.49 156.496 0 142.77 0H49.23C35.504 0 24.575 11.49 25.26 25.199z"></path>
</svg>

<svg class="icon" xmlns="http://www.w3.org/2000/svg" *ngIf="name == 'scanner'" width="17" height="17" viewBox="0 0 640 512">
  <path [attr.fill]="fill" d="M368 64H96c-53 0-96 43-96 96 0 52.4 42.1 94.9 94.3 95.8L6.4 408C-6.9 431 1 460.3 24 473.6l55.4 32c23 13.3 52.3 5.4 65.6-17.6l133.9-232H368c8.8 0 16-7.2 16-16V80c0-8.8-7.2-16-16-16zM256 448c17.7 0 32-14.3 32-32V304.3L205 448h51zm376 0H456c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h176c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zm0-96H456c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h176c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8zm0-288H456c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h176c4.4 0 8-3.6 8-8V72c0-4.4-3.6-8-8-8zm0-64H456c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h176c4.4 0 8-3.6 8-8V8c0-4.4-3.6-8-8-8zm0 288H456c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h176c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8zm0-128H456c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h176c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z"></path>
</svg>

<svg class="icon" xmlns="http://www.w3.org/2000/svg" *ngIf="name == 'file-invoice-dollar'" width="17" height="17" viewBox="0 0 384 512">
  <path fill="currentColor" d="M377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9zm-153 31V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zM64 72c0-4.42 3.58-8 8-8h80c4.42 0 8 3.58 8 8v16c0 4.42-3.58 8-8 8H72c-4.42 0-8-3.58-8-8V72zm0 80v-16c0-4.42 3.58-8 8-8h80c4.42 0 8 3.58 8 8v16c0 4.42-3.58 8-8 8H72c-4.42 0-8-3.58-8-8zm144 263.88V440c0 4.42-3.58 8-8 8h-16c-4.42 0-8-3.58-8-8v-24.29c-11.29-.58-22.27-4.52-31.37-11.35-3.9-2.93-4.1-8.77-.57-12.14l11.75-11.21c2.77-2.64 6.89-2.76 10.13-.73 3.87 2.42 8.26 3.72 12.82 3.72h28.11c6.5 0 11.8-5.92 11.8-13.19 0-5.95-3.61-11.19-8.77-12.73l-45-13.5c-18.59-5.58-31.58-23.42-31.58-43.39 0-24.52 19.05-44.44 42.67-45.07V232c0-4.42 3.58-8 8-8h16c4.42 0 8 3.58 8 8v24.29c11.29.58 22.27 4.51 31.37 11.35 3.9 2.93 4.1 8.77.57 12.14l-11.75 11.21c-2.77 2.64-6.89 2.76-10.13.73-3.87-2.43-8.26-3.72-12.82-3.72h-28.11c-6.5 0-11.8 5.92-11.8 13.19 0 5.95 3.61 11.19 8.77 12.73l45 13.5c18.59 5.58 31.58 23.42 31.58 43.39 0 24.53-19.05 44.44-42.67 45.07z"></path>
</svg>

<svg class="icon" xmlns="http://www.w3.org/2000/svg" *ngIf="name == 'history'" width="17" height="17" viewBox="0 0 512 512">
  <path fill="currentColor" d="M504 255.531c.253 136.64-111.18 248.372-247.82 248.468-59.015.042-113.223-20.53-155.822-54.911-11.077-8.94-11.905-25.541-1.839-35.607l11.267-11.267c8.609-8.609 22.353-9.551 31.891-1.984C173.062 425.135 212.781 440 256 440c101.705 0 184-82.311 184-184 0-101.705-82.311-184-184-184-48.814 0-93.149 18.969-126.068 49.932l50.754 50.754c10.08 10.08 2.941 27.314-11.313 27.314H24c-8.837 0-16-7.163-16-16V38.627c0-14.254 17.234-21.393 27.314-11.314l49.372 49.372C129.209 34.136 189.552 8 256 8c136.81 0 247.747 110.78 248 247.531zm-180.912 78.784l9.823-12.63c8.138-10.463 6.253-25.542-4.21-33.679L288 256.349V152c0-13.255-10.745-24-24-24h-16c-13.255 0-24 10.745-24 24v135.651l65.409 50.874c10.463 8.137 25.541 6.253 33.679-4.21z"></path>
</svg>

