import {catchError, switchMap, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {createEffect, Actions, ofType} from '@ngrx/effects';

import {Observable, of, from as fromPromise} from 'rxjs';


import * as CC_Processing_Actions from '../actions/credit-card-processing.actions';
import {CreditCardProcessingService} from '../../core/services/credit-card-processing.service';

@Injectable()
export class CreditCardProcessingEffects {


    Get360Terminals: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(CC_Processing_Actions.GET_360_TERMINALS),
        map((action: CC_Processing_Actions.Get360Terminals) => action.payload),
        switchMap((payload) =>
            fromPromise(this.creditCardProcessingService.get360Terminals()).pipe(
                map((response) => new CC_Processing_Actions.Get360TerminalsSuccess(response)),
                catchError((error) => of(new CC_Processing_Actions.Get360TerminalsFailed(error))))
        )));


    Get360TerminalByID: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(CC_Processing_Actions.GET_360_TERMINAL_BY_ID),
        map((action: CC_Processing_Actions.Get360Terminals) => action.payload),
        switchMap((payload) =>
            fromPromise(this.creditCardProcessingService.get360TerminalByID(payload)).pipe(
                map((response) => new CC_Processing_Actions.get360TerminalByIDActionSuccess(response)),
                catchError((error) => of(new CC_Processing_Actions.get360TerminalByIDActionFailed(error))))
        )));


    Pair360Terminal: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(CC_Processing_Actions.PAIR_360_TERMINAL_ACTION),
        map((action: CC_Processing_Actions.Pair360TerminalAction) => action.payload),
        switchMap((payload) =>
            fromPromise(this.creditCardProcessingService.pairTerminal(payload)).pipe(
                map((response) => new CC_Processing_Actions.Pair360TerminalSuccess(response)),
                catchError((error) => of(new CC_Processing_Actions.Pair360TerminalFailed(error))))
        )));


    UnPair360Terminal: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(CC_Processing_Actions.UN_PAIR_360_TERMINAL_ACTION),
        map((action: CC_Processing_Actions.UnPair360TerminalAction) => action.payload),
        switchMap((payload) =>
            fromPromise(this.creditCardProcessingService.unPairTerminal(payload)).pipe(
                map((response) => new CC_Processing_Actions.UnPair360TerminalSuccess(response)),
                catchError((error) => of(new CC_Processing_Actions.UnPair360TerminalFailed(error))))
        )));

    constructor(private $actions: Actions, private creditCardProcessingService: CreditCardProcessingService) {
    }
}
