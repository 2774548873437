import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-messages-center-message',
    templateUrl: './messages-center-message.component.html',
    styleUrls: ['./messages-center-message.component.scss']
})
export class MessagesCenterMessageComponent implements OnInit {

    @Input() message;
    @Input() type;

    @Output() markAsRead: EventEmitter<FormData> = new EventEmitter<FormData>();
    @Output() toWorkorder: EventEmitter<FormData> = new EventEmitter<FormData>();
    @Output() toMessages: EventEmitter<FormData> = new EventEmitter<FormData>();

    constructor() {

    }

    ngOnInit() {
    }

    goToWorkOrder(woID) {
        this.toWorkorder.emit(woID);
    }

    goToMessages(ID) {
        this.toMessages.emit(ID);
    }

    goMarkAsRead(msgID) {
        this.markAsRead.emit(msgID);
        this.message.read = 1;
    }

}
