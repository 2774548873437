import * as vehicleMotorData from '../actions/vehiclemotordata.actions';

export interface State {
    loading: boolean;

    loadVehicleMotorDataSuccessful: boolean;
    loadVehicleMotorDataFailed: string;

    loadVehicleMotorDataSpecsSuccessful: boolean;
    loadVehicleMotorDataSpecsFailed: string;

    loadVehicleMotorDataItemsAvailableSuccessful: boolean;
    loadVehicleMotorDataItemsAvailableFailed: string;

    loadVehicleMotorDataEngineTypesSuccessful: boolean;
    loadVehicleMotorDataEngineTypesFailed: boolean;

    motor_data: any;
    motor_data_specs: any;
    motor_data_items_available: any;
    engine_types: any;
}

const initialState: State = {
    loading: false,

    loadVehicleMotorDataSuccessful: null,
    loadVehicleMotorDataFailed: null,

    loadVehicleMotorDataSpecsSuccessful: null,
    loadVehicleMotorDataSpecsFailed: null,

    loadVehicleMotorDataItemsAvailableSuccessful: null,
    loadVehicleMotorDataItemsAvailableFailed: null,

    loadVehicleMotorDataEngineTypesSuccessful: null,
    loadVehicleMotorDataEngineTypesFailed: null,

    motor_data: null,
    motor_data_specs: null,
    motor_data_items_available: null,
    engine_types: null
};

export function reducer(state = initialState, action): State {
    switch (action.type) {
    case vehicleMotorData.CLEAR_VEHICLE_MOTOR_DATA:
        return {...state, motor_data: null};
    case vehicleMotorData.LOAD_VEHICLE_MOTOR_DATA:
        return {...state, loading: true};
    case vehicleMotorData.LOAD_VEHICLE_MOTOR_DATA_SUCCESSFUL:
        return {...state, loading: false, loadVehicleMotorDataSuccessful: true, motor_data: action.payload};
    case vehicleMotorData.LOAD_VEHICLE_MOTOR_DATA_FAILED:
        return {...state, loadVehicleMotorDataSuccessful: false, loadVehicleMotorDataFailed: action.payload};

    case vehicleMotorData.CLEAR_VEHICLE_MOTOR_DATA_SPECS:
        return {...state, motor_data_specs: null};
    case vehicleMotorData.LOAD_VEHICLE_MOTOR_DATA_SPECS:
        return {...state, loading: true};
    case vehicleMotorData.LOAD_VEHICLE_MOTOR_DATA_SPECS_SUCCESSFUL:
        return {...state, loading: false, loadVehicleMotorDataSpecsSuccessful: true, motor_data_specs: action.payload};
    case vehicleMotorData.LOAD_VEHICLE_MOTOR_DATA_SPECS_FAILED:
        return {...state, loadVehicleMotorDataSpecsSuccessful: false, loadVehicleMotorDataSpecsFailed: action.payload};

    case vehicleMotorData.CLEAR_VEHICLE_MOTOR_DATA_ITEMS_AVAILABLE:
        return {...state, motor_data_items_available: null};
    case vehicleMotorData.LOAD_VEHICLE_MOTOR_DATA_ITEMS_AVAILABLE:
        return {...state, loading: true};
    case vehicleMotorData.LOAD_VEHICLE_MOTOR_DATA_ITEMS_AVAILABLE_SUCCESSFUL:
        return {...state, loading: false, loadVehicleMotorDataItemsAvailableSuccessful: true, motor_data_items_available: action.payload};
    case vehicleMotorData.LOAD_VEHICLE_MOTOR_DATA_ITEMS_AVAILABLE_FAILED:
        return {...state, loadVehicleMotorDataItemsAvailableSuccessful: false, loadVehicleMotorDataItemsAvailableFailed: action.payload};

    case vehicleMotorData.CLEAR_VEHICLE_MOTOR_DATA_ENGINE_TYPES:
        return {...state, engine_types: null};
    case vehicleMotorData.LOAD_VEHICLE_MOTOR_DATA_ENGINE_TYPES:
        return {...state, loading: true};
    case vehicleMotorData.LOAD_VEHICLE_MOTOR_DATA_ENGINE_TYPES_SUCCESSFUL:
        return {...state, loading: false, loadVehicleMotorDataEngineTypesSuccessful: true, engine_types: action.payload};
    case vehicleMotorData.LOAD_VEHICLE_MOTOR_DATA_ENGINE_TYPES_FAILED:
        return {...state, loading: false, loadVehicleMotorDataEngineTypesSuccessful: false, loadVehicleMotorDataEngineTypesFailed: action.payload};

    default:
        return state;
    }
}

export const getCurrentVehicleMotorData = (state: State) => state.motor_data;

export const getCurrentVehicleMotorDataSpecs = (state: State) => state.motor_data_specs;

export const getCurrentVehicleMotorDataItemsAvailable = (state: State) => state.motor_data_items_available;

export const getCurrentVehicleMotorDataEngineTypes = (state: State) => state.engine_types;

