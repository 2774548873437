import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';
import {map, tap} from 'rxjs/operators';

const PIC_SOURCE_WORKORDER = 2;

@Injectable()
export class FormService {

    public url: string;
    public submissionurl: string;
    private customerUrl: string;
    private companyUrl: string;
    private tireguruBaseUrl: string;
    private inspectionUrl: string;
    private workOrderuRL = `${environment.tireguruBaseApi}/work-orders`;
    private inspectionInfoUrl: string;

    constructor(private http: HttpClient) {
        this.url = environment.tireguruBaseApi + '/form';
        this.submissionurl = environment.tireguruBaseApi + '/submission';
        this.customerUrl = environment.tireguruBaseApi + '/customer';
        this.companyUrl = environment.tireguruBaseApi + '/company';
        this.tireguruBaseUrl = environment.tireguruBaseUrl;
        this.inspectionUrl = environment.tireguruBaseApi + '/inspection-form';
        this.inspectionInfoUrl = environment.tireguruBaseUrl + '/app/public/get-inspection-data/';
    }

    getForm(id) {
        const url = `${this.url}/${id}`;


        return this.http.get(url)
            .pipe(
                map(response =>  response['data'])
            );
    }

    getForms() {
        const url = `${this.url}`;
        return this.http.get(url).toPromise();
    }

    saveForm(id, form) {
        const url = `${this.url}/${id}`;
        return this.http.get(url).toPromise();
    }

    saveFormValue(data: FormData, formID: number, workorderID: number) {
        const url = `${this.url}/${formID}/work-order/${workorderID}`;
        const body = data;
        return this.http.post(url, body);
    }

    saveInspectionForm(data) {
        return this.http.post(this.inspectionUrl, data);
    }

    getInspectionInfo(key) {
        const url = `${this.inspectionInfoUrl}` + key;

        return this.http.get(url).toPromise();
    }

    saveTechnicianImage(customerId: number, description: string, image: any, workorderId: number, source = PIC_SOURCE_WORKORDER) {

        const url = `${this.tireguruBaseUrl}/forms/formUpdate.php`;

        const formData = new FormData();
        // Default Params
        formData.append('act', 'picture_add');
        formData.append('questionID', 'vp');
        formData.append('woID', workorderId.toString());
        formData.append('custID', customerId.toString());
        formData.append('source', source.toString());
        formData.append('formID', '66');
        formData.append('return_to', 'portal');
        formData.append('picture', image, image.name);
        formData.append('description', description);

        return this.http.post(url, formData).toPromise();
    }

    getFormSubmission(formId, workOrderId) {
        const url = `${this.submissionurl}/${formId}/work-order/${workOrderId}`;
        return this.http.get(url)
            .pipe(
                map((response: any) => {
                    if (!response.success) {
                        throw new Error('No form submissions found yet');
                    }
                    return response['data'];
                })
            );
    }

    getFormSubmissionsForCustomerAndVehicle(customerId, vehicleId, page = 1) {

        const url = `${this.customerUrl}/${customerId}/submissions/${vehicleId}?page=${page}`;

        return this.http.get(url)
            .pipe(
                map((response: any) => response.data)
            );
    }

    getSubmission(submissionId: number) {
        const url = `${this.submissionurl}s/${submissionId}`;

        return this.http.get(url)
            .pipe(
                map((response: any) => response.data)
            );
    }

    getSumbissionsForWorkOrder(workOrderId: number) {
        const url = `${this.workOrderuRL}/${workOrderId}/forms/submissions`;

        return this.http.get(url)
            .pipe(
                map((response: any) => response.data.length ? response.data : {})
            );
    }

    getSlugJobCategoryRelations(companyId: number) {

        const url = `${this.companyUrl}/${companyId}/slug-job-category-relations`;

        return this.http.get(url)
            .pipe(
                map((response: any) => response.data.length ? response.data : [])
            );
    }

    deleteImageAttachment(comment, slug) {
        const url = `${this.submissionurl}/form-section-question-attachment/delete`;

        return this.http.post(url, {
            comment: comment,
            slug: slug
        })
            .pipe(
                map((response: any) => response.data.length ? response.data : [])
            );
    }
}
