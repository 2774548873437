
import {map} from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../store/reducers/index';

@Injectable()
export class VehicleService {

    private urlApi: string;
    private urlOld: string;

    constructor(private http: HttpClient, private router: Router, private store: Store<any>) {
        this.urlApi = environment.tireguruBaseApi;
        this.urlOld = environment.tireguruBaseUrl;
    }

    _updateVehicle({
        action, location, server, vehicleId = '', customerId, year, make, model, type = '', _package,
        air_pressure_front = '', air_pressure_rear = '', license = '',
        state = '', vin = '', rawSize = '', torque = ''
    }) {

        const url = `${this.urlOld}/customers/vehicleUpdate.php`;

        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

        const body = new URLSearchParams();
        // Default Params
        body.append('year', year);
        body.append('make', make);
        body.append('model', model);
        body.append('type', type);

        if (_package && _package.package) {
            body.append('aces_vehicleID', _package.aces_vehicleID);
            body.append('base_vehicleID', _package.base_vehicleID);
            body.append('vehicle_pkgID', _package.packageID);
            body.append('package', _package.package);
        } else {
            body.append('package', _package);
            body.append('aces_vehicleID', '');
            body.append('base_vehicleID', '');
            body.append('vehicle_pkgID', '');
        }

        body.append('air_pressure_front', air_pressure_front);
        body.append('air_pressure_rear', air_pressure_rear);
        body.append('license', license);
        body.append('state', state);
        body.append('vin', vin);
        body.append('act', action);
        body.append('_L', location);
        body.append('_S', server);
        body.append('custID', customerId);
        body.append('vehicleID', vehicleId);
        body.append('merge', '0');
        body.append('merge_toID', '0');
        body.append('merge_fromID', '0');
        body.append('in_div', '');
        body.append('from', '');
        body.append('from-cust-select', '0');
        body.append('invoice-type', '0');
        body.append('rawsize', rawSize);
        body.append('torque', torque);
        body.append('unit', '');
        body.append('color', '');
        body.append('notes', '');
        body.append('active', '1');

        // "_L" => "2"
        // "_S" => "TG1"

        // "ymmt_hash" => "cc1951c6dc717e66bbf4fc68fbd41358"

        const options = { headers };
        return this.http.post(url.toString(), body.toString(), options).toPromise();
    }

    createVehicle(params) {
        params.action = 'add';
        return this._updateVehicle(params);
    }

    editVehicle(params) {
        params.action = 'edit';
        return this._updateVehicle(params);
    }

    getVehicle(vehicleId: number): Promise<any> {
        const url = `${this.urlApi}/vehicles/${vehicleId}`;
        return this.http.get(url).pipe(map((response: any) => response.data)).toPromise();
    }

    getVehicleForCustomer(customerId, vehicleId) {
        const url = `${this.urlApi}/customer/${customerId}/vehicle/${vehicleId}`;
        return this.http.get(url, {}).toPromise();
    }

    getVehiclesForCustomer(customerId) {
        const url = `${this.urlApi}/customer/${customerId}/vehicles`;
        return this.http.get(url, {}).toPromise();
    }

    getCarFax(license, state, vin) {
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

        const url = `${this.urlApi}/vehicle/carfax`;
        return this.http.get(url, {
            params: {
                license, state, vin
            },
            headers
        }).toPromise();
    }

    getVehicleSelectOptions(year, make, model, type, _package) {
        const url = `${this.urlOld}/vehicle.php`;
        const paramsString = new URLSearchParams();
        // Default Params
        paramsString.append('show_package', '1');
        paramsString.append('show_engine', '1');
        paramsString.append('type', 't');
        // paramsString.append('packageID', '41817');

        paramsString.append('typeID', type);
        paramsString.append('makeID', make);
        paramsString.append('modelID', model);
        paramsString.append('yearID', year);
        paramsString.append('packageID', _package);

        const fullUrl = `${url}?${paramsString}`;

        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        const options = { headers };
        return this.http.get(fullUrl, options).toPromise();
    }

    setVehicleProperty(prop, value) {
        const url = `${this.urlOld}/customers/vehicleUpdate.php`;

        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

        const body = new URLSearchParams();
        body.append(prop, value);
        const options = { headers };

        // return this.http.post(url.toString(), body.toString(), options).toPromise();
    }

    findVehicleSelectOptions(year, make, model, type, _package) {
        const url = `${this.urlOld}/vehicle.php`;
        const paramsString = new URLSearchParams();
        // Default Params
        paramsString.append('show_package', '1');
        paramsString.append('show_engine', '1');
        paramsString.append('type', 't');
        // paramsString.append('packageID', '41817');

        paramsString.append('find_type', type);
        paramsString.append('find_make', make);
        paramsString.append('find_model', model);
        paramsString.append('find_year', year);
        paramsString.append('find_package', _package);

        const fullUrl = `${url}?${paramsString}`;

        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        const options = { headers };
        return this.http.get(fullUrl, options).toPromise();
    }

    saveVehicleImage(formData: FormData, vehicleId: Number) {
        const url = `${this.urlApi}/vehicles/${vehicleId}/image`;

        return this.http.post(url, formData).toPromise();
    }

    updateVehicle(vehInfo) {

        const url = `${this.urlApi}/customer/${vehInfo.veh_custID}/vehicle/${vehInfo.veh_vehicleID}/update`;
        return this.http.post(url, vehInfo).toPromise();
    }

  duplicateVinLookup(vin) {
    const url = `${this.urlOld}/app/vehicles/vins/${vin}/intercompany-duplicate`;
    return this.http.get(url).toPromise();
  }
}
