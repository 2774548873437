
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Injectable()
export class GlobalSearchService {

    private readonly urlOld: string;

    constructor(private http: HttpClient) {
        this.urlOld = environment.tireguruBaseUrl;
    }

    search(search) {
        const url = `${this.urlOld}/app/global-search`;
        return this.http.get(url, {
            params: {
                search: search
            }
        }).pipe(map((response: any) => response)).toPromise();
    }
}
