
import {map, take, skipWhile} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {environment} from '../../../environments/environment';
import * as fromRoot from '../../store/reducers';

@Injectable()
export class VehicleMotorDataService {
    private urlApi: string;
    private urlOld: string;

    constructor(private http: HttpClient, private router: Router, private store: Store<any>) {
        this.urlApi = environment.tireguruBaseApi;
        this.urlOld = environment.tireguruBaseUrl;
    }

    canActivate() {
        this.store.select(fromRoot.getVehicle).pipe(skipWhile(vehicle => !vehicle), take(1), ).subscribe(vehicle => {
            if (vehicle && vehicle.veh_vehicleID) {

                if ((vehicle.veh_engine_aces_vehicleID || vehicle.veh_aces_vehicleID) && vehicle.veh_engine_configID) {
                    return true;
                }
                this.router.navigate(['/vehicles/select-engine-type']);
            }
        });
        return true;
    }

    getMotorData(veh_id) {
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        const url = `${this.urlApi}/vehicle/motor_data`;

        return this.http.get(url, {
            params: {
                veh_id
            },
            headers
        }).toPromise();
    }

    getVehicleSpecs(veh_id) {
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        const url = `${this.urlApi}/vehicle/vehicle_specs`;

        return this.http.get(url, {
            params: {
                veh_id
            },
            headers
        }).pipe(map((response: any) => response)).toPromise();
    }

    getItemsAvailable(type, vehicle, work_order) {
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        const url = `${this.urlApi}/vehicle/items-available`;

        return this.http.get(url, {
            params: {
                type, vehicle, work_order
            },
            headers
        }).toPromise();
    }

    getEngineTypes(veh_id) {
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        const url = `${this.urlApi}/vehicle/get-engine-types`;

        return this.http.get(url, {
            params: {
                veh_id
            },
            headers
        }).toPromise();
    }

}
