import { Action as NgrxAction } from '@ngrx/store';


export const GET_WORK_ORDER_MESSAGES = "[WorkOrderMessages] get work order messages";
export const GET_MESSAGES_SUCCESSFUL = "[WorkOrderMessages] successful";
export const GET_MESSAGES_UNSUCCESSFUL = "[WorkOrderMessages] unsuccessful";
export const MARK_WORK_ORDER_MESSAGE_AS_READ = "[WorkOrderMessages] mark message as read";
export const MESSAGE_RECEIVED = "[WorkOrderMessage] message received";
export const GET_USERS = "[WorkOrderMessage] get employees";
export const GET_USERS_SUCCESSFUL = "[WorkOrderMessage] get employees successful";
export const GET_USERS_UNSUCCESSFUL = "[WorkOrderMessage] get employees unsuccessful";
export const SEND_MESSAGE = "[WorkOrderMessage] send message";

export class GetWorkOrderMessages implements NgrxAction {
    readonly type = GET_WORK_ORDER_MESSAGES;
    constructor(public payload: any) {

    }
}

export class GetMessagesSuccessful implements NgrxAction {
    readonly type = GET_MESSAGES_SUCCESSFUL;
    constructor(public payload: any) {

    }
}

export class GetMessagesUnsuccessful implements NgrxAction {
    readonly type = GET_MESSAGES_UNSUCCESSFUL;
    constructor() {

    }
}

export class MarkWorkOrderMessageAsRead implements NgrxAction {
    readonly type = MARK_WORK_ORDER_MESSAGE_AS_READ;
    constructor(public payload: any) {
    }
}

export class MessageReceived implements NgrxAction {
    readonly type = MESSAGE_RECEIVED;
    constructor(public payload: any) {

    }
}

export class GetUsersForMessage implements NgrxAction {
    readonly type = GET_USERS;
    constructor(public payload: any) {

    }
}

export class GetUsersSuccessful implements NgrxAction {
    readonly type = GET_USERS_SUCCESSFUL;
    constructor(public payload: any) {

    }
}

export class GetUsersUnsuccessful implements NgrxAction {
    readonly type = GET_USERS_UNSUCCESSFUL;
    constructor(public payload: any) {

    }
}

export class SendMessage implements NgrxAction {
    readonly type = SEND_MESSAGE;
    constructor(public payload: any) {

    }
}

export type Action = GetWorkOrderMessages
| GetMessagesSuccessful
| GetMessagesUnsuccessful
| MarkWorkOrderMessageAsRead
| MessageReceived
| GetUsersForMessage
| GetUsersSuccessful
| GetUsersUnsuccessful
| SendMessage;
