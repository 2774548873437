
import {map} from 'rxjs/operators';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

@Injectable()
export class JobService {

    private urlApi: string;
    private urlOld: string;

    constructor(private http: HttpClient) {
        this.urlApi = environment.tireguruBaseApi;
        this.urlOld = environment.tireguruBaseUrl;
    }

    getPreviousJobs() {
        return new Promise((resolve, reject) => {
            const data = [
                {
                    name: 'High Mileage Oil Change'
                },
                {
                    name: 'Standard Alignment'
                }
            ];
            resolve(data);
        });
        // const url = `${this.url}`;
        // return new Promise((resolve, reject) => {
        //     this.http.get(url, {
        //         params: {
        //         }
        //     }).subscribe(response => {
        //         resolve(response);
        //     }, error => {
        //         reject(error);
        //     });
        // });
    }

    addGenericJobToWorkOrder({ action = 'add_to_wo', workOrderId, subcategoryId, quantity = 1, loadOrder = 0, ajax = 1 }) {
        const url = `${this.urlOld}/mobile/jobUpdate.php`;

        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        const options = { headers };

        const body = new URLSearchParams();
        // Default Params
        body.append('act', action);
        body.append('woID', workOrderId.toString());
        body.append('subcategoryID', subcategoryId.toString());
        body.append('qty', quantity.toString());
        body.append('load_order', loadOrder.toString());
        body.append('ajax', ajax.toString());

        return this.http.post(url, body.toString(), options).toPromise();
    }

    addOilJobToWorkOrder({ loadOrder = 0, mobile = 1, workOrderId, volume, category, oilId, addonId, filterId }) {
        const url = `${this.urlOld}/oil_change/add_to_wo.php`;

        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

        const body = new URLSearchParams();

        return this.http.get(url, {
            params: {
                'load_order': loadOrder.toString(),
                'mobile': mobile.toString(),
                'woID': workOrderId.toString(),
                'volume': volume.toString(),
                'category': category.toString(),
                'oilID': oilId.toString(),
                'addonID': addonId.toString(),
                'filterID': filterId.toString(),
            },
            headers
        }).toPromise();
    }

    getJobs() {
        const url = `${this.urlApi}/job-category`;

        return this.http.get(url).toPromise();
    }

    getJobSubCategories(jobId: number) {
        const url = `${this.urlApi}/${jobId}/subcategories`;

        return this.http.get(url).toPromise();
    }

    getSubcategories(ids: Array<number>) {
        const url = `${this.urlApi}/job-sub-categories`;
        let params = new HttpParams();

        ids.forEach((id) => {
            params = params.append('subcategoryIds[]', `${id}`);
        });

        return this.http.get(url, {
            params
        }).pipe(map((response: any) => response.data.data)).toPromise();
    }

    getSubcategoriesPricing(ids: Array<number>) {
        const url = `${this.urlApi}/job-sub-categories/pricing`;


        let params = new HttpParams();

        ids.forEach((id) => {
            params = params.append('subcategoryIds[]', `${id}`);
        });

        return this.http.get(url, {
            params
        }).pipe(map((response: any) => response.prices)).toPromise();

    }

    getSubcatForms() {
        const url = `${this.urlApi}/form-sub-categories`;

        return this.http.get(url).pipe(map((response) => response)).toPromise();
    }

}
