import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CarfaxRoutingModule } from './carfax-routing.module';
import {CarfaxVehicleInfoComponent} from './carfax-vehicle-info/carfax-vehicle-info.component';
import {CarfaxVehicleInfoOptionalComponent} from './carfax-vehicle-info/carfax-vehicle-info-optional/carfax-vehicle-info-optional.component';
import {CarfaxVehicleInfoEngineSpecsComponent} from './carfax-vehicle-info/carfax-vehicle-info-engine-specs/carfax-vehicle-info-engine-specs.component';
import {CarfaxVehicleInfoGeneralComponent} from './carfax-vehicle-info/carfax-vehicle-info-general/carfax-vehicle-info-general.component';
import {CarfaxVehicleInfoInformationComponent} from './carfax-vehicle-info/carfax-vehicle-info-information/carfax-vehicle-info-information.component';
import {CommonComponentsModule} from '../common-components/common-components.module';

@NgModule({
    imports: [
        CommonModule,
        CarfaxRoutingModule,
    ],
    exports: [
        CarfaxVehicleInfoComponent,
        CarfaxVehicleInfoOptionalComponent,
        CarfaxVehicleInfoEngineSpecsComponent,
        CarfaxVehicleInfoGeneralComponent,
        CarfaxVehicleInfoInformationComponent
    ],
    declarations: [
        CarfaxVehicleInfoComponent,
        CarfaxVehicleInfoOptionalComponent,
        CarfaxVehicleInfoEngineSpecsComponent,
        CarfaxVehicleInfoGeneralComponent,
        CarfaxVehicleInfoInformationComponent
    ]
})
export class CarfaxModule { }
