import * as grooveGloveResponses from '../actions/grooveglove.actions';

export interface State {
    grooveglove: any;
    loading: boolean;
    loadingSuccessful: boolean;
    loadingError: string;
    link: any;

    formLinkedScans: any;
    getFormLinkedScansSuccessful: boolean;
    getFormLinkedScansFailed: any;
};

const initialState: State = {
    grooveglove: null,
    loading: false,
    loadingSuccessful: null,
    loadingError: null,
    link: null,

    formLinkedScans: null,
    getFormLinkedScansSuccessful: null,
    getFormLinkedScansFailed: null
};

export function reducer(state = initialState, action): State {

    switch (action.type) {
    case grooveGloveResponses.GET_RESPONSES:
        return {...state, grooveglove: action.payload};
    case grooveGloveResponses.GET_RESPONSES_SUCCESSFUL:
        return {...state, loading: false, grooveglove: action.payload, loadingSuccessful: true};
    case grooveGloveResponses.GET_RESPONSES_FAILED:
        return {...state, loading: false, loadingSuccessful: false, loadingError: action.payload};
    case grooveGloveResponses.LINK_DATA_TO_FORM:
        return {...state, link: action.payload};
    case grooveGloveResponses.LINK_SUCCESSFUL:
        return {...state, loading: false, link: action.payload, loadingSuccessful: true};
    case grooveGloveResponses.LINK_FAILED:
        return {...state, loading: false, loadingSuccessful: false, loadingError: action.payload};
    case grooveGloveResponses.DELETE_SCAN:
        return {...state, link: action.payload};
    case grooveGloveResponses.DELETE_SUCCESSFUL:
        return {...state, loading: false, link: action.payload, loadingSuccessful: true};
    case grooveGloveResponses.DELETE_FAILED:
        return {...state, loading: false, loadingSuccessful: false, loadingError: action.payload};

    case grooveGloveResponses.GET_FORM_LINKED_SCANS:
        return {...state, loading: true, formLinkedScans: null, getFormLinkedScansSuccessful: null, getFormLinkedScansFailed: null};
    case grooveGloveResponses.GET_FORM_LINKED_SCANS_SUCCESSFUL:
        return {...state, formLinkedScans: action.payload, getFormLinkedScansSuccessful: true, loading: false};
    case grooveGloveResponses.GET_FORM_LINKED_SCANS_FAILED:
        return {...state, getFormLinkedScansSuccessful: false, getFormLinkedScansFailed: action.payload, loading: false};

    default:
        return state;
    }
}

export const getResponses = (state: State) => state.grooveglove;

export const linkGloveToForm = (state: State) => state.link;

export const getFormLinkedScans = (state: State) => ({
    loading: state.loading,
    response: state.formLinkedScans,
    success: state.getFormLinkedScansSuccessful,
    error: state.getFormLinkedScansFailed
});
