import { Action as NgrxAction } from '@ngrx/store';

export const GET_ITEM_BARCODE = '[Item Barcodes] Get Item Barcode';
export const GET_ITEM_BARCODE_SUCCESSFUL = '[Item Barcodes] Get Item Barcode successful';
export const GET_ITEM_BARCODE_FAILED = '[Item Barcodes] Get Item Barcode failed';

export const CREATE_ITEM_BARCODE = '[Item Barcodes] Create Item Barcode';
export const CREATE_ITEM_BARCODE_SUCCESSFUL = '[Item Barcodes] Create Item Barcode successful';
export const CREATE_ITEM_BARCODE_FAILED = '[Item Barcodes] Create Item Barcode failed';

export const SEARCH_ITEM_BY_BARCODE = '[Item Barcode] Search for item by barcode';
export const SEARCH_ITEM_BY_BARCODE_SUCCESSFUL = '[Item Barcode] Search for item by barcode successful';
export const SEARCH_ITEM_BY_BARCODE_FAILED = '[Item Barcode] Search for item by barcode failed';

export const SET_BARCODES_SCANNED_HISTORY = '[Item Barcode] Set barcodes scanned history';
export const ADD_TO_BARCODES_SCANNED_HISTORY = '[Item Barcode] Add to barcodes scanned history';


export class SetBarcodesScannedHistory implements NgrxAction {
    readonly type = SET_BARCODES_SCANNED_HISTORY;

    constructor(public payload: any) {
    }
}

export class AddToBarcodesScannedHistory implements NgrxAction {
    readonly type = ADD_TO_BARCODES_SCANNED_HISTORY;

    constructor(public payload: any) {
    }
}

export class GetItemBarcode implements NgrxAction {
    readonly type = GET_ITEM_BARCODE;

    constructor(public payload: { itemID: number; qty: number }) {
    }
}

export class GetItemBarcodeSuccessful implements NgrxAction {
    readonly type = GET_ITEM_BARCODE_SUCCESSFUL;

    constructor(public payload: any) {
    }
}

export class GetItemBarcodeFailed implements NgrxAction {
    readonly type = GET_ITEM_BARCODE_FAILED;

    constructor(public err: any) {
    }
}

export class SearchForItemByBarcode implements NgrxAction {
    readonly type = SEARCH_ITEM_BY_BARCODE;

    constructor(public payload: {code: any}) {
    }
}

export class SearchForItemByBarcodeSuccessful implements NgrxAction {
    readonly type = SEARCH_ITEM_BY_BARCODE_SUCCESSFUL;

    constructor(public payload: any) {
    }
}

export class SearchForItemByBarcodeFailed implements NgrxAction {
    readonly type = SEARCH_ITEM_BY_BARCODE_FAILED;

    constructor(public err: any) {
    }
}

export class CreateItemBarcode implements NgrxAction {
    readonly type = CREATE_ITEM_BARCODE;

    constructor(public payload: any) {
    }
}

export class CreateItemBarcodeSuccessful implements NgrxAction {
    readonly type = CREATE_ITEM_BARCODE_SUCCESSFUL;

    constructor(public payload: any) {
    }
}

export class CreateItemBarcodeFailed implements NgrxAction {
    readonly type = CREATE_ITEM_BARCODE_FAILED;

    constructor(public err: any) {
    }
}
