import * as customerCommunications from '../actions/customerCommunication.actions';

export interface State {
    unreadMessagesCount: any;
    unreadMessagesLoading: boolean;
    unreadMessagesSuccessful: boolean;
    unreadMessagesError: string;

    textMessages: any;
    textMessagesLoading: boolean;
    textMessagesSuccessful: boolean;
    textMessagesError: string;
    numberOfTextMessagePages: number;

    customerCommunications: any;
    customerCommunicationsLoading: boolean;
    customerCommunicationsSuccessful: boolean;
    customerCommunicationsError: string;

    textSending: boolean;
    textSentSuccessful: boolean;
    textSendingError: string;

    markAsReadLoading: boolean;
    markAsReadSuccessful: boolean;
    markAsReadError: string;
}

const initialState: State = {
    unreadMessagesCount: false,
    unreadMessagesLoading: false,
    unreadMessagesSuccessful: null,
    unreadMessagesError: null,

    textMessages: false,
    textMessagesLoading: false,
    textMessagesSuccessful: null,
    textMessagesError: null,
    numberOfTextMessagePages: 0,

    customerCommunications: false,
    customerCommunicationsLoading: false,
    customerCommunicationsSuccessful: null,
    customerCommunicationsError: null,

    textSending: false,
    textSentSuccessful: null,
    textSendingError: null,

    markAsReadLoading: false,
    markAsReadSuccessful: null,
    markAsReadError: null,
};

export function reducer(state = initialState, action): State {

    switch (action.type) {
    case customerCommunications.GET_UNREAD_TEXT_COUNT:
        return {...state, unreadMessagesLoading: true};
    case customerCommunications.GET_UNREAD_TEXT_COUNT_SUCCESSFUL:
        return {...state, unreadMessagesLoading: false, unreadMessagesSuccessful: true, unreadMessagesCount: action.payload.data};
    case customerCommunications.GET_UNREAD_TEXT_COUNT_FAILED:
        return {...state, unreadMessagesSuccessful: false, unreadMessagesLoading: false, unreadMessagesError: action.payload};
    case customerCommunications.GET_CUSTOMER_COMMUNICATIONS:
        return {...state, customerCommunications: false, customerCommunicationsLoading: true};
    case customerCommunications.GET_CUSTOMER_COMMUNICATIONS_SUCCESSFUL:
        return {...state, customerCommunicationsLoading: false, customerCommunicationsSuccessful: true, customerCommunications: action.payload};
    case customerCommunications.GET_CUSTOMER_COMMUNICATIONS_FAILED:
        return {...state, customerCommunicationsSuccessful: false, customerCommunicationsLoading: false, customerCommunicationsError: action.payload};
    case customerCommunications.SEND_TEXT_MESSAGE:
        return {...state, textSending: true};
    case customerCommunications.SEND_TEXT_MESSAGE_SUCCESSFUL:
        return {...state, textSending: false, textSentSuccessful: true, customerCommunications: action.payload};
    case customerCommunications.SEND_TEXT_MESSAGE_FAILED:
        return {...state, textSending: false, textSentSuccessful: false, textSendingError: action.payload};
    case customerCommunications.GET_TEXT_MESSAGES:
        return {...state, textMessagesLoading: true};
    case customerCommunications.GET_TEXT_MESSAGES_SUCCESSFUL:
        return {...state, textMessagesLoading: false, textMessagesSuccessful: true, textMessages: action.payload.data.data, numberOfTextMessagePages: action.payload.textsPaginated.last_page};
    case customerCommunications.GET_TEXT_MESSAGES_FAILED:
        return {...state, textMessagesLoading: false, textMessagesSuccessful: false, textMessagesError: action.payload};
    case customerCommunications.MARK_TEXTS_AS_READ:
        return {...state, markAsReadLoading: true};
    case customerCommunications.MARK_TEXTS_AS_READ_SUCCESSFUL:
        return {...state, markAsReadLoading: false, markAsReadSuccessful: true, customerCommunications: action.payload};
    case customerCommunications.MARK_TEXTS_AS_READ_FAILED:
        return {...state, markAsReadLoading: false, markAsReadSuccessful: false, markAsReadError: action.payload};
    case customerCommunications.MARK_INDIVIDUAL_TEXT_AS_READ:
        return {...state, markAsReadLoading: true};
    case customerCommunications.MARK_INDIVIDUAL_TEXT_AS_READ_SUCCESSFUL:
        return {...state, markAsReadLoading: false, markAsReadSuccessful: true, unreadMessagesCount: action.payload.data};
    case customerCommunications.MARK_INDIVIDUAL_TEXT_AS_READ_FAILED:
        return {...state, markAsReadLoading: false, markAsReadSuccessful: true, markAsReadError: action.payload};
    case customerCommunications.CLEAR_MARK_TEXT_STATE:
        return {...state, markAsReadLoading: false, markAsReadSuccessful: null, markAsReadError: null};
    default:
        return state;
    }
}

export const getCustomerCommunications = (state: State) => state.customerCommunications;
export const getUnreadTextsCount = (state: State) => state.unreadMessagesCount;
export const getTextMessages = (state: State) => state.textMessages;
export const getNumberOfTextPages = (state: State) => state.numberOfTextMessagePages;
