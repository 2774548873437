import * as forms from '../actions/form.actions';

export interface State {
    form: any;
    responses: any;
    attachments: any;
    addingItems: boolean;
    addingItemsFrom: string;
    itemAdded: boolean;
    addedItemId: number;
    itemSeverity: string;
    customerSubmissions: {
        [id: number]: any;
    };
    loadingSlugCategoryRelations: boolean;
    slugCategoryRelations: any;
    formInfo: any;
    formInfoLoading: boolean;
    formInfoSuccessful: boolean;
    formInfoError: string;

    deleteImageAttachmentLoading: boolean;
    deleteImageAttachmentSuccessful: boolean;
    deleteImageAttachmentFailed: any;
}

const initialState: State = {
    form: null,
    responses: {},
    attachments: {},
    addingItems: false,
    addingItemsFrom: null,
    itemAdded: null,
    addedItemId: null,
    itemSeverity: null,
    customerSubmissions: null,
    loadingSlugCategoryRelations: false,
    slugCategoryRelations: null,
    formInfo: false,
    formInfoLoading: false,
    formInfoSuccessful: null,
    formInfoError: null,

    deleteImageAttachmentLoading: false,
    deleteImageAttachmentSuccessful: false,
    deleteImageAttachmentFailed: null
};

export function reducer(state: State = initialState, action) {

    switch (action.type) {
        case forms.SET_RESPONSES:
            return {...state, responses: {...action.payload}};

        case forms.SET_RESPONSE:
            return {...state, responses: {...state.responses, [action.payload.key]: action.payload.value}};

        case forms.DELETE_RESPONSE:
            const responses = {...state.responses};
            if (responses[action.payload]) {
                delete responses[action.payload];
            }
            return {...state, responses};

        case forms.SET_FORM:
            return {...state, form: {...action.payload}};

        case forms.SET_ATTACHMENTS:
            return {...state, attachments: {...action.payload}};

        case forms.SET_ATTACHMENT:
            return {...state, attachments: {...state.attachments, [action.payload.key]: action.payload.value}};

    case forms.DELETE_IMAGE_ATTACHMENT:
        const formAttachments = {...state.attachments};

        if (formAttachments[action.payload.slug][action.payload.key]) {
            delete formAttachments[action.payload.slug][action.payload.key];
        }

        return {...state, formAttachments};

    case forms.DELETE_IMAGE_ATTACHMENT_SUCCESSFUL:
        return {...state,
            deleteImageAttachmentSuccessful: true,
            deleteImageAttachmentLoading: false
        };

    case forms.DELETE_IMAGE_ATTACHMENT_FAILED:
        return {...state, deleteImageAttachmentFailed: action.payload, deleteImageAttachmentLoading: false};

        case forms.SET_ADDING_ITEMS:
            return {...state, addingItems: true, itemAdded: false, addedItemId: null, itemSeverity: action.payload.severity, addingItemsFrom: action.payload.slug};

        case forms.FINISHED_ADDING_ITEMS:
            return {...state, addingItems: false, itemSeverity: null, itemAdded: true, addedItemId: action.payload.itemID};

        case forms.CLEAR_ADDING_ITEMS:
            return {...state, addingItems: false, addingItemsFrom: null, itemAdded: null, addedItemId: null, itemSeverity: null};

        case forms.LOAD_CUSTOMER_FORM_SUBMISSIONS_SUCCESSFUL:
            return {...state, customerSubmissions: action.payload};

        case forms.LOAD_SLUG_JOB_CATEGORY_RELATIONS:
            return {...state, loadingSlugCategoryRelations: true};
        case forms.LOAD_SLUG_JOB_CATEGORY_RELATIONS_SUCCESSFUL:
            const slugCategoryRelations = {};

            action.payload.forEach((slugCategoryRelation) => {
                slugCategoryRelations[slugCategoryRelation.slug] = slugCategoryRelation.job_category;
            });
            return {...state, loadingSlugCategoryRelations: false, slugCategoryRelations};
        case forms.LOAD_SLUG_JOB_CATEGORY_RELATIONS_FAILED:
            return {...state, loadingSlugCategoryRelations: false, slugCategoryRelations: null};
        case forms.GET_INSPECTION_INFO:
        return {...state, formInfoLoading: true};
        case forms.GET_INSPECTION_INFO_SUCCESSFUL:
        return {...state, formInfo: action.payload, formInfoLoading: false, formInfoSuccessful: true};
        case forms.GET_INSPECTION_INFO_FAILED:
        return {...state, formInfoLoading: false, formInfoSuccessful: false, formInfoError: action.payload};
    case forms.CLEAR_STATE:
        return {...state,
            form: null,
            responses: {},
            attachments: {},
            addingItems: false,
            addingItemsFrom: null,
            itemAdded: null,
            addedItemId: null,
            itemSeverity: null,
            customerSubmissions: null,
            loadingSlugCategoryRelations: false,
            slugCategoryRelations: null,
            formInfo: false,
            formInfoLoading: false,
            formInfoSuccessful: null,
            formInfoError: null,
        };
        default:
            return state;
    }

}

export const getForm = (state: State) => state.form;
export const getResponses = (state: State) => state.responses;
export const getResponse = (state: State, key) => {
    if (state.responses && state.responses[key]) {
        return state.responses[key];
    }
    return null;
};

export const getAttachments = (state: State) => state.attachments;

export const getAttachment = (state: State, key: any) => {
    if (state.attachments && state.attachments[key]) {
        return state.attachments[key];
    }

    return false;
};

export const getAddingItemsState = (state: State) => {
    const {addingItems, itemAdded, addedItemId, itemSeverity, addingItemsFrom} = state;
    return {addingItems, itemAdded, addedItemId, itemSeverity, addingItemsFrom};
};

export const getSlugCatgegoryRelationsLoading = (state: State) => state.loadingSlugCategoryRelations;
export const getSlugJobCategoryRelations = (state: State) => state.slugCategoryRelations;
export const getFormInfo = (state: State) => state.formInfo;
