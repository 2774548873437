<div class="panel__body dark" (click)="closeCheckBoxes()">
  <div class="panelHeaderDiv">
    <app-panel-header>New Message</app-panel-header>
  </div>

  <div class="panel__row has-margin-y-2">
    <div class="columns">
      <div class="column">
        <button (click)="showCheckBoxes = !showCheckBoxes; $event.stopPropagation()"
                class="add-message-select is-grey btn">
          <span class="blackColor">Recipients:</span>
          <app-icon class="blackColor" name="single-chevron-down" fill="#000000"></app-icon>
        </button>
      </div>

      <div class="column">
        <span *ngIf="!recipientList.includes('All')">
          <span *ngFor="let recipient of recipientList">
            <span>
              <a (click)="addEmployeeToSendList(employees[recipient]?.use_userID, recipient)"
                 class="clickable">X
              </a>
              {{this.employees[recipient].use_fname}} {{this.employees[recipient].use_lname}},</span>
          </span>
        </span>

        <span *ngIf="recipientList.includes('All') || recipientList.length === 0">All</span>
      </div>
    </div>

    <div *ngIf="showCheckBoxes" class="panel__body checkboxes" #employeeList (click)="$event.stopPropagation()">
      <input type="checkbox" class="checkbox" [checked]="isEmployeeSelected(allEmployees)"
             (click)="addEmployeeToSendList(allEmployees, '')">All
      <br>

      <span *ngFor="let employee of employeesKeys">
        <input type="checkbox" class="checkbox"
               [checked]="isEmployeeSelected(employees[employee]?.use_userID)"
               (click)="addEmployeeToSendList(employees[employee]?.use_userID, employee)">
        {{employees[employee]?.use_fname}} {{employees[employee]?.use_lname}}
        <br>
        </span>
    </div>

  </div>

  <div class="textarea-container">
    <label>
      <textarea placeholder="New Message" [(ngModel)]="messageText" class="textarea"></textarea>
    </label>
  </div>

  <div>
    <button class="btn is-primary centerItem" (click)="sendMessage()">Send</button>
  </div>
</div>
