import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-panel',
    templateUrl: './panel.component.html',
    styleUrls: ['./panel.component.scss']
})
export class PanelComponent implements OnInit {
    @Input() y_padding: any = false;

    constructor() { }

    ngOnInit() {
        this.y_padding = this.y_padding ? this.y_padding : 'has-padding-y-1';
    }

}
