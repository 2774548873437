import {Action, ActionReducer, ActionReducerMap, combineReducers, MetaReducer} from '@ngrx/store';
import {createSelector} from 'reselect';

import * as fromCustomer from './customers.reducer';
import * as fromProfile from './profile.reducer';
import * as fromVehicles from './vehicles.reducer';
import * as fromVehicleMotorData from './vehiclemotordata.reducer';
import * as fromWorkorders from './workorders.reducer';
import * as fromForm from './form.reducer';
import * as fromInvoiceItemCategories from './invoice-item-categories.reducer';
import * as fromJob from './job.reducer';
import * as fromSearch from './search.reducer';
import * as fromSession from './session.reducer';
import * as fromGrooveGlove from './grooveglove.reducer';
import * as fromWorkOrderMessage from './workordermessages.reducer';
import * as fromWorkOrderNote from './workordernote.reducer';
import * as fromCustomerCommunication from './customerCommunication.reducer';
import * as fromCreditCardProcessing from './credit-card-processing.reducer'
import * as fromArc from './arc.reducer';
import * as fromFinance from './finance.reducer';
import * as fromGlobalSearch from './global-search.reducer';
import * as fromPurchaseOrders from './purchase-orders.reducer';
import * as fromBarcodes from './barcodes.reducer';
import * as fromPhysicalInventory from './physical-inventory.reducer';


export interface State {
    customers: fromCustomer.State;
    profile: fromProfile.State;
    vehicles: fromVehicles.State;
    vehiclemotordata: fromVehicleMotorData.State;
    workOrders: fromWorkorders.State;
    invoiceItemCategories: fromInvoiceItemCategories.State;
    form: fromForm.State;
    jobs: fromJob.State;
    search: fromSearch.State;
    session: fromSession.State;
    grooveGlove: fromGrooveGlove.State;
    workOrderMessages: fromWorkOrderMessage.State;
    workOrderNotes: fromWorkOrderNote.State;
    customerCommunications: fromCustomerCommunication.State;
    arc: fromArc.State;
    finance: fromFinance.State;
    credit_card_processing: fromCreditCardProcessing.State;
    globalSearch: fromGlobalSearch.State;
    purchaseOrders: fromPurchaseOrders.State;
    barcodes: fromBarcodes.State;
    physicalInventory: fromPhysicalInventory.State;
}

const reducers = {
    customers: fromCustomer.reducer,
    profile: fromProfile.reducer,
    vehicles: fromVehicles.reducer,
    vehiclemotordata: fromVehicleMotorData.reducer,
    workOrders: fromWorkorders.reducer,
    invoiceItemCategories: fromInvoiceItemCategories.reducer,
    form: fromForm.reducer,
    jobs: fromJob.reducer,
    search: fromSearch.reducer,
    session: fromSession.reducer,
    grooveGlove: fromGrooveGlove.reducer,
    workOrderMessages: fromWorkOrderMessage.reducer,
    workOrderNotes: fromWorkOrderNote.reducer,
    arc: fromArc.reducer,
    finance: fromFinance.reducer,
    credit_card_processing: fromCreditCardProcessing.reducer,
    customerCommunications: fromCustomerCommunication.reducer,
    globalSearch: fromGlobalSearch.reducer,
    purchaseOrders: fromPurchaseOrders.reducer,
    barcodes: fromBarcodes.reducer,
    physicalInventory: fromPhysicalInventory.reducer
};

export const reducer: ActionReducerMap<State> = reducers;

export function clearState(reducer: ActionReducer<State>): ActionReducer<State> {
    return function(state: State, action: Action): State {
        if (action.type === 'CLEAR_STATE') {
            state = undefined;
        }
        return reducer(state, action);
    };
}

export const metaReducers: MetaReducer<State>[] = [clearState];

export const getCustomerState = (state: State) => state.customers;
export const getCurrentCustomer = createSelector(getCustomerState, fromCustomer.getCurrentCustomer);
export const getCustomerLoading = createSelector(getCustomerState, fromCustomer.getCustomerLoading);
export const getCustomerUpdating = createSelector(getCustomerState, fromCustomer.getCustomerUpdating);
export const getCustomerCreating = createSelector(getCustomerState, fromCustomer.getCustomerUpdating);
export const getCustomers = createSelector(getCustomerState, fromCustomer.getCustomers);

export const getProfileState = (state: State) => state.profile;
export const getProfile = createSelector(getProfileState, fromProfile.getCurrentProfile);
export const getProfileLoading = createSelector(getProfileState, fromProfile.getProfileLoading);
export const getQuickLinks = createSelector(getProfileState, fromProfile.getQuickLinks);
export const getServer = createSelector(getProfileState, fromProfile.getServer);
export const getReasonsForVisit = createSelector(getProfileState, fromProfile.getReasonsForVisit);
export const getCurrentReasonForVisit = createSelector(getProfileState, fromProfile.getCurrentReasonForVisit);

export const getVehicleState = (state: State) => state.vehicles;
export const getVehicle = createSelector(getVehicleState, fromVehicles.getCurrentVehicle);
export const getVehicles = createSelector(getVehicleState, fromVehicles.getVehicles);
export const getVehicleLoading = createSelector(getVehicleState, fromVehicles.getVehicleLoading);
export const getVehicleUpdating = createSelector(getVehicleState, fromVehicles.getVehicleUpdating);
export const getVehicleCreateing = createSelector(getVehicleState, fromVehicles.getVehicleCreating);
export const getVehicleDeleting = createSelector(getVehicleState, fromVehicles.getVehicleDeleting);
export const getVehicleImageUploadState = createSelector(getVehicleState, fromVehicles.getVehicleImageUploadState);
export const getCurrentVehicleCarfax = createSelector(getVehicleState, fromVehicles.getCurrentVehicleCarfax);
export const getCurrentVehicleCarfaxState = createSelector(getVehicleState, fromVehicles.getCurrentVehicleCarfaxState);
export const getDuplicateVinLookupResults = createSelector(getVehicleState, fromVehicles.getDuplicateVinLookupResults);

export const getVehicleMotorDataState = (state: State) => state.vehiclemotordata;
export const getCurrentVehicleMotorData = createSelector(getVehicleMotorDataState, fromVehicleMotorData.getCurrentVehicleMotorData);
export const getCurrentVehicleMotorDataSpecs = createSelector(getVehicleMotorDataState, fromVehicleMotorData.getCurrentVehicleMotorDataSpecs);
export const getCurrentVehicleMotorDataItemsAvailable = createSelector(getVehicleMotorDataState, fromVehicleMotorData.getCurrentVehicleMotorDataItemsAvailable);
export const getCurrentVehicleMotorDataEngineTypes = createSelector(getVehicleMotorDataState, fromVehicleMotorData.getCurrentVehicleMotorDataEngineTypes);

export const getNoteState = (state: State) => state.workOrderNotes;
export const getNotes = createSelector(getNoteState, fromWorkOrderNote.getNotes);
export const getCurrentNote = createSelector(getNoteState, fromWorkOrderNote.getCurrentNote);
export const getNoteLoading = createSelector(getNoteState, fromWorkOrderNote.getNoteLoading);
export const getNoteUpdating = createSelector(getNoteState, fromWorkOrderNote.getNoteUpdating);
export const getNoteDeleting = createSelector(getNoteState, fromWorkOrderNote.getNoteDeleting);
export const getNoteCreating = createSelector(getNoteState, fromWorkOrderNote.getNoteCreating);

export const getFinanceState = (state: State) => state.finance;
export const getShowSendTextToPayModal = createSelector(getFinanceState, fromFinance.getShowSendTextToPayModal);

export const getWorkorderState = (state: State) => state.workOrders;
export const getWorkorder = createSelector(getWorkorderState, fromWorkorders.getCurrentWorkorder);
export const getWorkorderLoading = createSelector(getWorkorderState, fromWorkorders.getWorkorderLoading);
export const getWorkorderUpdating = createSelector(getWorkorderState, fromWorkorders.getWorkorderLoading);
export const getWorkorderDeleting = createSelector(getWorkorderState, fromWorkorders.getWorkorderLoading);
export const getWorkorderAddingItems = createSelector(getWorkorderState, fromWorkorders.getAddingItems);
export const getWorkorderAddingItemSuccess = createSelector(getWorkorderState, fromWorkorders.getAddingItemSuccess);
export const getShowWorkOrderBackButton = createSelector(getWorkorderState, fromWorkorders.getShowBackButton);
export const loadWorkOrderSuccess = createSelector(getWorkorderState, fromWorkorders.loadWorkOrderSuccess);
export const getWorkOrderStatuses = createSelector(getWorkorderState, fromWorkorders.getWorkOrderStatuses);
export const getSetWorkOrderStatus = createSelector(getWorkorderState, fromWorkorders.getSetWorkOrderStatus);
export const getEmployeesForAssignment = createSelector(getWorkorderState, fromWorkorders.getEmployeesForAssignment);
export const getTicketType = createSelector(getWorkorderState, fromWorkorders.getTicketType);
export const getSetWorkOrderEmployees = createSelector(getWorkorderState, fromWorkorders.getSetWorkOrderEmployees);
export const getWorkOrderItemsVerifications = createSelector(getWorkorderState, fromWorkorders.getWorkOrderItemVerifications);
export const getUpdateWorkOrderItemVerifications = createSelector(getWorkorderState, fromWorkorders.updateWorkOrderItemVerifications);
export const getDeclinedServices = createSelector(getWorkorderState, (state: fromWorkorders.State) => {
    if (
        state.currentWorkorder
        && state.currentWorkorder.declined_service_relationships
        && state.currentWorkorder.declined_service_relationships.length
        && state.currentWorkorder.declined_service_relationships[0].declined_services_work_order
        && state.currentWorkorder.declined_service_relationships[0].declined_services_work_order.item_relations.length
    ) {

        return state.currentWorkorder.declined_service_relationships[0].declined_services_work_order.item_relations;
    }

    return [];
});

export const getInvoiceItemCategoriesState = (state: State) => state.invoiceItemCategories;
export const getInvoiceItemCategories = createSelector(getInvoiceItemCategoriesState, fromInvoiceItemCategories.getInvoiceItemCategories);

export const getWorkOrderItemsByInvoiceItemCategory = createSelector(getWorkorder, getInvoiceItemCategories, (workorder, invoiceItemCategories) => {

    if (!workorder) {
        return {};
    }

    const workOrderItems = workorder.item_relations;

    if (!workOrderItems.length) {
        return {};

    }

    const invoiceItemCategoryEntities = invoiceItemCategories.reduce((aggregator, category) => {
        aggregator[category.iic_categoryID] = {...category};
        return aggregator;
    }, {});

    const sortedWorkOrderItems = workOrderItems.sort((a, b) => {
        if (a.woi_order < b.woi_order) {
            return -1;
        }

        if (a.woi_order > b.woi_order) {
            return 1;
        }

        return 0;
    });

    let parentWorkOrderItems = sortedWorkOrderItems.reduce((aggregator, workOrderItem) => {
        if (!workOrderItem.woi_parent_woitemID || workOrderItem.woi_parent_woitemID === 0) {
            aggregator[workOrderItem.woi_workitemID] = workOrderItem;
        }
        return aggregator;
    }, {});

    sortedWorkOrderItems.forEach((workOrderItem) => {
        if (workOrderItem.woi_parent_woitemID && parentWorkOrderItems[workOrderItem.woi_parent_woitemID]) {
            if (!parentWorkOrderItems[workOrderItem.woi_parent_woitemID].items) {
                parentWorkOrderItems[workOrderItem.woi_parent_woitemID].items = [];
            }

            parentWorkOrderItems[workOrderItem.woi_parent_woitemID].items.push(workOrderItem);
        } else {
            parentWorkOrderItems[workOrderItem.woi_workitemID] = workOrderItem;
        }
    });

    parentWorkOrderItems = Object.keys(parentWorkOrderItems).map(key => parentWorkOrderItems[key]);

    const uncategorizedItems = [];

    parentWorkOrderItems.forEach((parentWorkOrderItem) => {
        if (invoiceItemCategoryEntities[parentWorkOrderItem.woi_categoryID]) {
            if (!invoiceItemCategoryEntities[parentWorkOrderItem.woi_categoryID].items) {
                invoiceItemCategoryEntities[parentWorkOrderItem.woi_categoryID].items = [];
            }
            invoiceItemCategoryEntities[parentWorkOrderItem.woi_categoryID].items.push(parentWorkOrderItem);
        } else {
            uncategorizedItems.push(parentWorkOrderItem);
        }
    });

    invoiceItemCategories = Object.keys(invoiceItemCategoryEntities).map(key => invoiceItemCategoryEntities[key]);

    let sortedInvoiceItemCategories = invoiceItemCategories.sort((a, b) => {
        if (a.iic_categoryID < 1000000 && b.iic_categoryID > 1000000) {
            return -1;
        }


        if (a.iic_categoryID > 1000000 && b.iic_categoryID < 1000000) {
            return 1;
        }

        if (a.iic_order < b.iic_order) {
            return -1;
        }

        if (a.iic_order > b.iic_order) {
            return 1;
        }

        return 0;
    });

    sortedInvoiceItemCategories = sortedInvoiceItemCategories.filter(category => category.items && category.items.length);

    return {
        categorized: sortedInvoiceItemCategories,
        uncategorized: uncategorizedItems
    };

});

export const getFormState = (state: State) => state.form;
export const getForm = createSelector(getFormState, fromForm.getForm);
export const getResponses = createSelector(getFormState, fromForm.getResponses);
export const getResponse = (key) => createSelector(getFormState, (state: fromForm.State) => fromForm.getResponse(state, key));
export const getAttachments = createSelector(getFormState, fromForm.getAttachments);
export const getAttachment = (key) => createSelector(getFormState, (state: fromForm.State) => fromForm.getAttachment(state, key));
export const getAddingItemsState = createSelector(getFormState, fromForm.getAddingItemsState);
export const getSlugJobCategoryRelationsLoading = createSelector(getFormState, fromForm.getSlugCatgegoryRelationsLoading);
export const getSlugJobCategoryRelations = createSelector(getFormState, fromForm.getSlugJobCategoryRelations);
export const getFormInfo = createSelector(getFormState, fromForm.getFormInfo);

export const getJobsState = (state: State) => state.jobs;
export const getJobs = createSelector(getJobsState, fromJob.getJobs);
export const getJob = (id) => createSelector(getJobsState, (state: fromJob.State) => fromJob.getJob(id, state));
export const getJobSubcategories = (jobID) => createSelector(getJobsState, (state: fromJob.State) => fromJob.getJobSubcategories(jobID, state));
export const getJobSubcategoriesByIds = (ids) => createSelector(getJobsState, (state: fromJob.State) => fromJob.getJobSubcategoriesByIds(ids, state));
export const getJobForms = (subCatID) => createSelector(getJobsState, (state: fromJob.State) => fromJob.getJobForms(subCatID, state));


export const getSearchState = (state: State) => state.search;
export const getSearchTerm = createSelector(getSearchState, fromSearch.getSearchTerm);
export const getSearchInfo = createSelector(getSearchState, fromSearch.getSearchInfo);
export const getSearchresults = createSelector(getSearchState, fromSearch.getSearchResults);
export const getSearchFailed = createSelector(getSearchState, fromSearch.getSearchFailed);

export const getSessionState = (state: State) => state.session;

export const getGrooveGloveState = (state: State) => state.grooveGlove;
export const getGrooveGlove = createSelector(getGrooveGloveState, fromGrooveGlove.getResponses);
export const getFormLinkedScans = createSelector(getGrooveGloveState, fromGrooveGlove.getFormLinkedScans);

export const getWorkOrderMessagesState = (state: State) => state.workOrderMessages;

export const getWorkOrderMessageState = (state: State) => state.workOrderMessages;
export const getUnreadWorkOrderMessages = (userID) => createSelector(getWorkOrderMessageState, (state: fromWorkOrderMessage.State) => fromWorkOrderMessage.getUnreadWorkOrderMessages(userID, state));
export const getAllWorkOrderMessages = createSelector(getWorkOrderMessageState, fromWorkOrderMessage.getAllWorkOrderMessages);
export const getUnreadWorkOrderMessagesCount = (userID) =>  createSelector(getWorkOrderMessageState, (state: fromWorkOrderMessage.State) => fromWorkOrderMessage.getUnreadWorkOrderMessagesCount(userID, state));
export const getMessagesForWorkOrder = (workOrderID) => createSelector(getWorkOrderMessageState, (state: fromWorkOrderMessage.State) => fromWorkOrderMessage.getMessagesForWorkOrder(workOrderID, state));
export const getUsersForMessage = createSelector(getWorkOrderMessageState, fromWorkOrderMessage.getUsersToMessage);

export const getArcState = (state: State) => state.arc;
export const getArcCart = createSelector(getArcState, fromArc.getCart);
export const getUnlinkedPartsLaborCore = createSelector(getArcState, fromArc.getUnlinkedPartsLaborCore);
export const getUnlinkedVendor = createSelector(getArcState, fromArc.getUnlinkedVendor);

export const getCreditCardProcessingState = (state: State) => state.credit_card_processing;
export const get360Terminals = createSelector(getCreditCardProcessingState, fromCreditCardProcessing.get360Terminals);
export const get360TerminalByID = createSelector(getCreditCardProcessingState, fromCreditCardProcessing.get360TerminalByID);

export const getCustomerCommunicationsState = (state: State) => state.customerCommunications;
export const getCustomerCommunications = createSelector(getCustomerCommunicationsState, fromCustomerCommunication.getCustomerCommunications);
export const getTextMessages = createSelector(getCustomerCommunicationsState, fromCustomerCommunication.getTextMessages);
export const getUnreadTextsCount = createSelector(getCustomerCommunicationsState, fromCustomerCommunication.getUnreadTextsCount);
export const getNumberOfTextPages = createSelector(getCustomerCommunicationsState, fromCustomerCommunication.getNumberOfTextPages);

export const getGlobalSearchState = (state: State) => state.globalSearch;
export const getGlobalSearchResponse = createSelector(getGlobalSearchState, fromGlobalSearch.getGlobalSearchResponse);
export const getGlobalSearchCurrentSearch = createSelector(getGlobalSearchState, fromGlobalSearch.getGlobalSearchCurrentSearch);
export const getGlobalSearchSearching = createSelector(getGlobalSearchState, fromGlobalSearch.getGlobalSearchSearching);

export const getPurchaseOrdersState = (state: State) => state.purchaseOrders;
export const getLocationPurchaseOrders = createSelector(getPurchaseOrdersState, fromPurchaseOrders.getLocationPurchaseOrders);
export const getSetPurchaseOrder = createSelector(getPurchaseOrdersState, fromPurchaseOrders.getPurchaseOrder);
export const getVerificationForPurchaseOrder = createSelector(getPurchaseOrdersState, fromPurchaseOrders.getPurchaseOrderVerifications);
export const getUpdatePurchaseOrderItemVerification = createSelector(getPurchaseOrdersState, fromPurchaseOrders.getUpdatePurchaseOrderItemVerification);

export const getBarcodesState = (state: State) => state.barcodes;
export const getItemBarcode = createSelector(getBarcodesState, fromBarcodes.getItemBarcode);
export const getItemSearchFromCode = createSelector(getBarcodesState, fromBarcodes.getItemSearchFromCode);
export const getCreateItemBarcode = createSelector(getBarcodesState, fromBarcodes.getCreateItemBarcode);
export const getBarcodesScannedHistory = createSelector(getBarcodesState, fromBarcodes.getBarcodesScannedHistory);

export const getPhysicalInventoryState = (state: State) => state.physicalInventory;
export const getLocationPhysicalInventoryMenuSetups = createSelector(getPhysicalInventoryState, fromPhysicalInventory.getLocationPhysicalInventoryMenuSetups);
export const getCurrentLocationPhysicalInventorySetup = createSelector(getPhysicalInventoryState, fromPhysicalInventory.getCurrentLocationPhysicalInventorySetup);
export const getPhysicalInventoryInputNumber = createSelector(getPhysicalInventoryState, fromPhysicalInventory.getPhysicalInventoryInputNumber);
export const getUpdateLocationPhysicalInventorySetup = createSelector(getPhysicalInventoryState, fromPhysicalInventory.getUpdateLocationPhysicalInventorySetup);
export const getPhysicalInventorySetupItems = createSelector(getPhysicalInventoryState, fromPhysicalInventory.getPhysicalInventorySetupItems);
export const getPhysicalInventorySetupWriteInItems = createSelector(getPhysicalInventoryState, fromPhysicalInventory.getPhysicalInventorySetupWriteInItems);
export const getPhysicalInventoryUpdateItem = createSelector(getPhysicalInventoryState, fromPhysicalInventory.getPhysicalInventoryUpdateItem);
export const getPhysicalInventoryUpdateWriteInItem = createSelector(getPhysicalInventoryState, fromPhysicalInventory.getPhysicalInventoryUpdateWriteInItem);
export const getPhysicalInventoryCreateWriteInItem = createSelector(getPhysicalInventoryState, fromPhysicalInventory.getPhysicalInventoryCreateWriteInItem);
export const getDeletePhysicalInventorySetupWriteInItem = createSelector(getPhysicalInventoryState, fromPhysicalInventory.getDeletePhysicalInventorySetupWriteInItem);
export const getPhysicalInventoryClearSetupItemsCache = createSelector(getPhysicalInventoryState, fromPhysicalInventory.getPhysicalInventoryClearSetupItemsCache);
export const getPhysicalInventoryClearSetupWriteInItemsCache = createSelector(getPhysicalInventoryState, fromPhysicalInventory.getPhysicalInventoryClearSetupWriteInItemsCache);
