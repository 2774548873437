<table *ngIf="veh_carfax">
  <tr>
    <td class="headerVerticalAlign textAlignLeft">
      <span class="title is-size-3 is-bold">
        Engine Specifications*
      </span><br>

      <span *ngIf="veh_carfax['data']['oem-engine-information']"><strong class="subtitle">Engine Information:</strong>
            <span>{{veh_carfax['data']['oem-engine-information']}}<br></span>
          </span>

      <span *ngIf="veh_carfax['data']['nonoem-liter']"><strong class="subtitle">Liter:</strong>
            <span>{{veh_carfax['data']['nonoem-liter']}}<br></span>
          </span>

      <span *ngIf="veh_carfax['data']['nonoem-type']"><strong class="subtitle">Type:</strong>
            <span>{{veh_carfax['data']['nonoem-type']}}<br></span>
          </span>

      <span *ngIf="veh_carfax['data']['nonoem-head']"><strong class="subtitle">Head:</strong>
            <span>{{veh_carfax['data']['nonoem-head']}}<br></span>
          </span>

      <span *ngIf="veh_carfax['data']['nonoem-valves']"><strong class="subtitle">Valves:</strong>
            <span>{{veh_carfax['data']['nonoem-valves']}}<br></span>
          </span>

      <span *ngIf="veh_carfax['data']['nonoem-fuel']"><strong class="subtitle">Fuel:</strong>
            <span>{{veh_carfax['data']['nonoem-fuel']}}<br></span>
          </span>

      <span *ngIf="veh_carfax['data']['nonoem-induction']"><strong class="subtitle">Induction:</strong>
            <span>{{veh_carfax['data']['nonoem-induction']}}<br></span>
          </span>

      <span *ngIf="veh_carfax['data']['nonoem-induction-type']"><strong class="subtitle">Induction Type:</strong>
            <span>{{veh_carfax['data']['nonoem-induction-type']}}<br></span>
          </span>

      <span *ngIf="veh_carfax['data']['nonoem-aspiration']"><strong class="subtitle">Aspiration:</strong>
            <span>{{veh_carfax['data']['nonoem-aspiration']}}<br></span>
          </span>

      <span *ngIf="veh_carfax['data']['oem-carburetion']"><strong class="subtitle">Carburetion:</strong>
            <span>{{veh_carfax['data']['oem-carburetion']}}<br></span>
          </span>

      <span *ngIf="veh_carfax['data']['nonoem-cylinders']"><strong class="subtitle">Cylinders:</strong>
            <span>{{veh_carfax['data']['nonoem-cylinders']}}<br></span>
          </span>

      <span *ngIf="veh_carfax['data']['nonoem-cc']"><strong class="subtitle">Cc:</strong>
            <span>{{veh_carfax['data']['nonoem-cc']}}<br></span>
          </span>

      <span *ngIf="veh_carfax['data']['oem-displacement']"><strong class="subtitle">Displacement:</strong>
            <span>{{veh_carfax['data']['oem-displacement']}}<br></span>
          </span>

      <span *ngIf="veh_carfax['data']['oemtruck-truck-engine-model']"><strong class="subtitle">Truck Engine Model:</strong>
            <span>{{veh_carfax['data']['oemtruck-truck-engine-model']}}<br></span>
          </span>

      <span *ngIf="veh_carfax['data']['oemtruck-truck-engine-mfg']"><strong class="subtitle">Truck Engine Mfg:</strong>
            <span>{{veh_carfax['data']['oemtruck-truck-engine-mfg']}}<br></span>
          </span>

      <span *ngIf="veh_carfax['data']['oemtruck-truck-engine-type-code']"><strong class="subtitle">Truck Engine Type:</strong>
            <span>{{veh_carfax['data']['oemtruck-truck-engine-type-code']}}<br></span>
          </span>

      <span *ngIf="veh_carfax['data']['oem-transmission']"><strong class="subtitle">Transmission:</strong>
            <span>{{veh_carfax['data']['oem-transmission']}}<br></span>
          </span>

    </td>
  </tr>
</table>
