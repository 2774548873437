import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {environment} from 'environments/environment';

@Injectable()
export class InvoiceItemCategoryService {

    private url: string;

    constructor(private http: HttpClient) {
        this.url = `${environment.tireguruBaseApi}/invoice-item-categories`;
    }

    getInvoiceItemCategories() {
        return this.http.get(this.url).toPromise();
    }

    getInvoiceItemCategoryById(id: number) {
        const url = `${this.url}/${id}`;
        return this.http.get(url).toPromise();
    }
}
