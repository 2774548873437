import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

@Injectable()
export class TireService {

    public url: string;

    constructor(private http: HttpClient) {
        this.url = environment.tireguruBaseApi + '/appointments';
    }

    getPreviousTireServices() {
        return new Promise((resolve, reject) => {
            const data = [
                {
                    name: 'Sumic - Sumic GTA'
                }
            ];
            resolve(data);
        });
        // const url = `${this.url}`;
        // return new Promise((resolve, reject) => {
        //     this.http.get(url, {
        //         params: {
        //         }
        //     }).subscribe(response => {
        //         resolve(response);
        //     }, error => {
        //         reject(error);
        //     });
        // });
    }

}
