import { Action as NgrxAction } from '@ngrx/store';

export const GET_RESPONSES = '[Grooveglove] Get responses';
export const GET_RESPONSES_SUCCESSFUL = '[Grooveglove] Get responses successful';
export const GET_RESPONSES_FAILED = '[Grooveglove] Get responses failed';
export const LINK_DATA_TO_FORM = '[Grooveglove] Link data to form';
export const LINK_SUCCESSFUL = '[Grooveglove] Link successful';
export const LINK_FAILED = '[Grooveglove] Link failed';
export const DELETE_SCAN = '[Grooveglove] Delete scan';
export const DELETE_FAILED = '[Grooveglove] Delete failed';
export const DELETE_SUCCESSFUL = '[Grooveglove] Delete successful';
export const GET_FORM_LINKED_SCANS = '[Grooveglove] Get form linked scan';
export const GET_FORM_LINKED_SCANS_SUCCESSFUL = '[Grooveglove] Get form linked scan successful';
export const GET_FORM_LINKED_SCANS_FAILED = '[Grooveglove] Get form linked scan failed';

export class GetResponsesDataAction implements NgrxAction {
    readonly type = GET_RESPONSES;

    constructor() {
    }
}
export class GetResponsesFailedAction implements NgrxAction {
    readonly type = GET_RESPONSES_FAILED;

    constructor(public payload: any) {
    }
}
export class GetResponsesSuccessfulAction implements NgrxAction {
    readonly type = GET_RESPONSES_SUCCESSFUL;

    constructor(public payload: any) {
    }
}

export class LinkGloveDataToForm implements NgrxAction {
    readonly type = LINK_DATA_TO_FORM;

    constructor(public payload: any) {
    }
}

export class LinkFailedAction implements NgrxAction {
    readonly type = LINK_FAILED;

    constructor(public payload: any) {
    }
}
export class LinkSuccessfulAction implements NgrxAction {
    readonly type = LINK_SUCCESSFUL;

    constructor(public payload: any) {
    }
}

export class DeleteGrooveGloveScan implements NgrxAction {
    readonly type = DELETE_SCAN;

    constructor(public payload: any) {
    }
}

export class DeleteScanFailed implements NgrxAction {
    readonly type = DELETE_FAILED;

    constructor(public payload: any) {
    }
}
export class DeleteScanSuccessful implements NgrxAction {
    readonly type = DELETE_SUCCESSFUL;

    constructor(public payload: any) {
    }
}

export class GetFormLinkedGrooveGloveScans implements NgrxAction {
    readonly type = GET_FORM_LINKED_SCANS;

    constructor(public payload: {workOrderId: any; formID: any; locID: any}) {
    }
}

export class GetFormLinkedGrooveGloveScansSuccessful implements NgrxAction {
    readonly type = GET_FORM_LINKED_SCANS_SUCCESSFUL;

    constructor(public payload: any) {
    }
}

export class GetFormLinkedGrooveGloveScansFailed implements NgrxAction {
    readonly type = GET_FORM_LINKED_SCANS_FAILED;

    constructor(public payload: any) {
    }
}

export type Action = GetResponsesDataAction
| GetResponsesFailedAction
| GetResponsesSuccessfulAction
| LinkGloveDataToForm
| LinkFailedAction
| LinkSuccessfulAction
| DeleteGrooveGloveScan
| DeleteScanFailed
| DeleteScanSuccessful;
