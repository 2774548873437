import * as physical_inventory from '../actions/physical-inventory.actions';
import {
    BARCODE_UPDATE_WRITE_IN_ITEM_COUNT, BARCODE_UPDATE_WRITE_IN_ITEM_COUNT_FAILED,
    BARCODE_UPDATE_WRITE_IN_ITEM_COUNT_SUCCESSFUL
} from "../actions/physical-inventory.actions";

export interface State {
    loading: boolean;
    updating: boolean;
    deleting: boolean;

    inputNumber: number;

    physicalInventorySetup: any;
    physicalInventorySetupItems: any;
    physicalInventorySetupWriteInItems: any;

    physicalInventoryMenuSetups: any;
    getPhysicalInventoryMenuSetupsSuccessful: boolean;
    getPhysicalInventoryMenuSetupsFailed: any;

    updatePhysicalInventorySetupSuccessful: boolean;
    updatePhysicalInventorySetupFailed: any;

    getPhysicalInventoryItemsSuccessful: boolean;
    getPhysicalInventoryItemsFailed: any;

    lastItemUpdated: any;
    updatedItemSuccessfully: boolean;
    updatedItemFailed: any;

    lastWriteInItemUpdated: any;
    updateWriteInItemSuccessfully: boolean;
    updateWriteInItemFailed: any;

    getPhysicalInventoryWriteInItemsSuccessful: boolean;
    getPhysicalInventoryWriteInItemsFailed: any;

    createPhysicalInventoryWriteInItem: any;
    createPhysicalInventoryWriteInItemSuccessful: boolean;
    createPhysicalInventoryWriteInItemFailed: any;

    deletePhysicalInventoryItem: any;
    deletePhysicalInventoryWriteInItemSuccessful: boolean;
    deletePhysicalInventoryWriteInItemFailed: any;

    clearSetupItemsCacheSuccessful: boolean;
    clearSetupItemsCacheFailed: any;
    clearingItems: boolean;

    clearSetupWriteInItemsCacheSuccessful: boolean;
    clearSetupWriteInItemsCacheFailed: any;
    clearingWriteInItems: boolean;
}

const initialState: State = {
    loading: false,
    updating: false,
    deleting: false,

    inputNumber: null,

    physicalInventorySetup: null,
    physicalInventorySetupItems: null,
    physicalInventorySetupWriteInItems: null,

    physicalInventoryMenuSetups: null,
    getPhysicalInventoryMenuSetupsSuccessful: null,
    getPhysicalInventoryMenuSetupsFailed: null,

    updatePhysicalInventorySetupSuccessful: null,
    updatePhysicalInventorySetupFailed: null,

    getPhysicalInventoryItemsSuccessful: null,
    getPhysicalInventoryItemsFailed: null,

    lastItemUpdated: null,
    updatedItemSuccessfully: null,
    updatedItemFailed: null,

    lastWriteInItemUpdated: null,
    updateWriteInItemSuccessfully: null,
    updateWriteInItemFailed: null,

    getPhysicalInventoryWriteInItemsSuccessful: null,
    getPhysicalInventoryWriteInItemsFailed: null,

    createPhysicalInventoryWriteInItem: null,
    createPhysicalInventoryWriteInItemSuccessful: null,
    createPhysicalInventoryWriteInItemFailed: null,

    deletePhysicalInventoryItem: null,
    deletePhysicalInventoryWriteInItemSuccessful: null,
    deletePhysicalInventoryWriteInItemFailed: null,

    clearSetupItemsCacheSuccessful: null,
    clearSetupItemsCacheFailed: null,
    clearingItems: false,

    clearSetupWriteInItemsCacheSuccessful: null,
    clearSetupWriteInItemsCacheFailed: null,
    clearingWriteInItems: false
};

export function reducer(state = initialState, action): State {
    switch (action.type) {
    case physical_inventory.CLEAR_ENTIRE_PHYSICAL_INVENTORY_STATE:
        return {...state = initialState};
    case physical_inventory.SET_PHYSICAL_INVENTORY_INPUT_SETUP:
        return {...state, physicalInventoryMenuSetups: null, physicalInventorySetup: action.payload};
    case physical_inventory.SET_PHYSICAL_INVENTORY_INPUT_NUMBER:
        return {...state, inputNumber: action.inputNumber};

    case physical_inventory.GET_LOCATION_PHYSICAL_INVENTORY_MENU_SETUPS:
        return {...state, loading: true, physicalInventoryMenuSetups: null,  getPhysicalInventoryMenuSetupsSuccessful: null, getPhysicalInventoryMenuSetupsFailed: null};
    case physical_inventory.GET_LOCATION_PHYSICAL_INVENTORY_MENU_SETUPS_SUCCESSFUL:
        return {...state, physicalInventoryMenuSetups: action.payload, getPhysicalInventoryMenuSetupsSuccessful: true, loading: false};
    case physical_inventory.GET_LOCATION_PHYSICAL_INVENTORY_MENU_SETUPS_FAILED:
        return {...state, getPhysicalInventoryMenuSetupsFailed: action.err, getPhysicalInventoryMenuSetupsSuccessful: false, loading: false};

    case physical_inventory.UPDATE_LOCATION_PHYSICAL_INVENTORY_SETUP:
        return {...state, updating: true, updatePhysicalInventorySetupSuccessful: null, updatePhysicalInventorySetupFailed: null};
    case physical_inventory.UPDATE_LOCATION_PHYSICAL_INVENTORY_SETUP_SUCCESSFUL:
        return {...state, updatePhysicalInventorySetupSuccessful: true, updating: false};
    case physical_inventory.UPDATE_LOCATION_PHYSICAL_INVENTORY_SETUP_FAILED:
        return {...state, updatePhysicalInventorySetupSuccessful: false, updatePhysicalInventorySetupFailed: action.err, updating: false};

    case physical_inventory.GET_PHYSICAL_INVENTORY_SETUP_ITEMS:
        return {...state, loading: true, physicalInventorySetupItems: null, getPhysicalInventoryItemsSuccessful: null, getPhysicalInventoryItemsFailed: null};
    case physical_inventory.GET_PHYSICAL_INVENTORY_SETUP_ITEMS_SUCCESSFUL:
        return {...state, physicalInventorySetupItems: action.payload, getPhysicalInventoryItemsSuccessful: true, loading: false};
    case physical_inventory.GET_PHYSICAL_INVENTORY_SETUP_ITEMS_FAILED:
        return {...state, getPhysicalInventoryItemsSuccessful: false, getPhysicalInventoryItemsFailed: action.err, loading: false};

    case physical_inventory.MANUAL_UPDATE_ITEM_COUNT:
        return {...state, updating: true, lastItemUpdated: null, updatedItemSuccessfully: null, updatedItemFailed: null};
    case physical_inventory.MANUAL_UPDATE_ITEM_COUNT_SUCCESSFUL:
        return {...state, lastItemUpdated: action.payload, updatedItemSuccessfully: true, updating: false};
    case physical_inventory.MANUAL_UPDATE_ITEM_COUNT_FAILED:
        return {...state, updatedItemSuccessfully: false, updatedItemFailed: action.err, updating: false};

    case physical_inventory.MANUAL_UPDATE_WRITE_IN_ITEM_COUNT:
        return {...state, updating: true, lastWriteInItemUpdated: null, updateWriteInItemSuccessfully: null, updateWriteInItemFailed: null};
    case physical_inventory.MANUAL_UPDATE_WRITE_IN_ITEM_COUNT_SUCCESSFUL:
        return {...state, lastWriteInItemUpdated: action.payload, updateWriteInItemSuccessfully: true, updating: false};
    case physical_inventory.MANUAL_UPDATE_WRITE_IN_ITEM_COUNT_FAILED:
        return {...state, updateWriteInItemSuccessfully: false, updateWriteInItemFailed: action.err, updating: false};

    case physical_inventory.BARCODE_UPDATE_ITEM_COUNT:
        return {...state, updating: true, lastItemUpdated: null, updatedItemSuccessfully: null, updatedItemFailed: null};
    case physical_inventory.BARCODE_UPDATE_ITEM_COUNT_SUCCESSFUL:
        return {...state, lastItemUpdated: action.payload, updatedItemSuccessfully: true, updating: false};
    case physical_inventory.BARCODE_UPDATE_ITEM_COUNT_FAILED:
        return {...state, updatedItemSuccessfully: false, updatedItemFailed: action.err, updating: false};

    case physical_inventory.BARCODE_UPDATE_WRITE_IN_ITEM_COUNT:
        return {...state, updating: true, lastWriteInItemUpdated: null, updateWriteInItemSuccessfully: null, updateWriteInItemFailed: null};
    case physical_inventory.BARCODE_UPDATE_WRITE_IN_ITEM_COUNT_SUCCESSFUL:
        return {...state, lastWriteInItemUpdated: action.payload, updateWriteInItemSuccessfully: true, updating: false};
    case physical_inventory.BARCODE_UPDATE_WRITE_IN_ITEM_COUNT_FAILED:
        return {...state, updateWriteInItemSuccessfully: false, updateWriteInItemFailed: action.err, updating: false};

    case physical_inventory.GET_PHYSICAL_INVENTORY_SETUP_WRITE_IN_ITEMS:
        return {...state, loading: true, physicalInventorySetupWriteInItems: null, getPhysicalInventoryWriteInItemsSuccessful: null, getPhysicalInventoryWriteInItemsFailed: null};
    case physical_inventory.GET_PHYSICAL_INVENTORY_SETUP_WRITE_IN_ITEMS_SUCCESSFUL:
        return {...state, physicalInventorySetupWriteInItems: action.payload, getPhysicalInventoryWriteInItemsSuccessful: true, loading: false};
    case physical_inventory.GET_PHYSICAL_INVENTORY_SETUP_WRITE_IN_ITEMS_FAILED:
        return {...state, getPhysicalInventoryWriteInItemsSuccessful: false, getPhysicalInventoryWriteInItemsFailed: action.err, loading: false};

    case physical_inventory.CREATE_PHYSICAL_INVENTORY_SETUP_WRITE_IN_ITEM:
        return {...state, loading: true, createPhysicalInventoryWriteInItem: null, createPhysicalInventoryWriteInItemSuccessful: null, createPhysicalInventoryWriteInItemFailed: null};
    case physical_inventory.CREATE_PHYSICAL_INVENTORY_SETUP_WRITE_IN_ITEM_SUCCESSFUL:
        return {...state, createPhysicalInventoryWriteInItem: action.payload, createPhysicalInventoryWriteInItemSuccessful: true, loading: false};
    case physical_inventory.CREATE_PHYSICAL_INVENTORY_SETUP_WRITE_IN_ITEM_FAILED:
        return {...state, createPhysicalInventoryWriteInItemSuccessful: false, createPhysicalInventoryWriteInItemFailed: action.err, loading: false};

    case physical_inventory.DELETE_PHYSICAL_INVENTORY_SETUP_WRITE_IN_ITEM:
        return {...state, deleting: true, deletePhysicalInventoryItem: null, deletePhysicalInventoryWriteInItemSuccessful: null, deletePhysicalInventoryWriteInItemFailed: null};
    case physical_inventory.DELETE_PHYSICAL_INVENTORY_SETUP_WRITE_IN_ITEM_SUCCESSFUL:
        return {...state, deletePhysicalInventoryItem: action.payload, deletePhysicalInventoryWriteInItemSuccessful: true, deleting: false};
    case physical_inventory.DELETE_PHYSICAL_INVENTORY_SETUP_WRITE_IN_ITEM_FAILED:
        return {...state, deletePhysicalInventoryWriteInItemSuccessful: false, deletePhysicalInventoryWriteInItemFailed: action.err, deleting: false};

    case physical_inventory.CLEAR_PHYSICAL_INVENTORY_SETUP_ITEMS_CACHE:
        return {...state, loading: true, clearSetupItemsCacheSuccessful: null, clearSetupItemsCacheFailed: null};
    case physical_inventory.CLEAR_PHYSICAL_INVENTORY_SETUP_ITEMS_CACHE_SUCCESSFUL:
        return {...state, clearSetupItemsCacheSuccessful: true, loading: false};
    case physical_inventory.CLEAR_PHYSICAL_INVENTORY_SETUP_ITEMS_CACHE_FAILED:
        return {...state, clearSetupItemsCacheSuccessful: false, clearSetupItemsCacheFailed: action.err, loading: false};

    case physical_inventory.CLEAR_PHYSICAL_INVENTORY_SETUP_WRITE_IN_ITEMS_CACHE:
        return {...state, loading: true, clearSetupWriteInItemsCacheSuccessful: null, clearSetupWriteInItemsCacheFailed: null};
    case physical_inventory.CLEAR_PHYSICAL_INVENTORY_SETUP_WRITE_IN_ITEMS_CACHE_SUCCESSFUL:
        return {...state, clearSetupWriteInItemsCacheSuccessful: true, loading: false};
    case physical_inventory.CLEAR_PHYSICAL_INVENTORY_SETUP_WRITE_IN_ITEMS_CACHE_FAILED:
        return {...state, clearSetupWriteInItemsCacheSuccessful: false, clearSetupWriteInItemsCacheFailed: action.err, loading: false};


    default:
        return state;
    }
}

export const getLocationPhysicalInventoryMenuSetups = (state: State) => ({
    response: state.physicalInventoryMenuSetups,
    success: state.getPhysicalInventoryMenuSetupsSuccessful,
    failed: state.getPhysicalInventoryMenuSetupsFailed,
    loading: state.loading
});

export const getDeletePhysicalInventorySetupWriteInItem = (state: State) => ({
    response: state.deletePhysicalInventoryItem,
    success: state.deletePhysicalInventoryWriteInItemSuccessful,
    failed: state.deletePhysicalInventoryWriteInItemFailed,
    deleting: state.deleting
});

export const getCurrentLocationPhysicalInventorySetup = (state: State) => state.physicalInventorySetup;

export const getPhysicalInventoryInputNumber = (state: State) => state.inputNumber;

export const getPhysicalInventoryCreateWriteInItem = (state: State) => ({
    loading: state.loading,
    response: state.createPhysicalInventoryWriteInItem,
    success: state.createPhysicalInventoryWriteInItemSuccessful,
    failed: state.createPhysicalInventoryWriteInItemFailed
});

export const getUpdateLocationPhysicalInventorySetup = (state: State) => ({
    success: state.updatePhysicalInventorySetupSuccessful,
    failed: state.updatePhysicalInventorySetupFailed,
    updating: state.updating
});

export const getPhysicalInventorySetupItems = (state: State) => ({
    response: state.physicalInventorySetupItems,
    success: state.getPhysicalInventoryItemsSuccessful,
    failed: state.getPhysicalInventoryItemsFailed,
    loading: state.loading,
});

export const getPhysicalInventorySetupWriteInItems = (state: State) => ({
    response: state.physicalInventorySetupWriteInItems,
    success: state.getPhysicalInventoryWriteInItemsSuccessful,
    failed: state.getPhysicalInventoryWriteInItemsFailed,
    loading: state.loading,
});

export const getPhysicalInventoryUpdateItem = (state: State) => ({
    response: state.lastItemUpdated,
    success: state.updatedItemSuccessfully,
    failed: state.updatedItemFailed,
    updating: state.updating
});

export const getPhysicalInventoryUpdateWriteInItem = (state: State) => ({
    response: state.lastWriteInItemUpdated,
    success: state.updateWriteInItemSuccessfully,
    failed: state.updateWriteInItemFailed,
    updating: state.updating
});

export const getPhysicalInventoryClearSetupItemsCache = (state: State) => ({
    loading: state.clearingItems,
    success: state.clearSetupItemsCacheSuccessful,
    failed: state.clearSetupItemsCacheFailed
});

export const getPhysicalInventoryClearSetupWriteInItemsCache = (state: State) => ({
    loading: state.clearingWriteInItems,
    success: state.clearSetupWriteInItemsCacheSuccessful,
    failed: state.clearSetupWriteInItemsCacheFailed
});
