import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-carfax-vehicle-info-engine-specs',
    templateUrl: './carfax-vehicle-info-engine-specs.component.html',
    styleUrls: ['./carfax-vehicle-info-engine-specs.component.scss']
})
export class CarfaxVehicleInfoEngineSpecsComponent implements OnInit {

    @Input() veh_carfax;

    constructor() { }

    ngOnInit() {
    }

}
