import * as barcodes from '../actions/barcodes.actions';

export interface State {
    loading: boolean;
    updating: boolean;
    searching: boolean;

    message: any;
    itemBarcodeInfo: any;
    item: any;

    getItemBarcodeSuccessful: boolean;
    getItemBarcodeFailed: any;

    updateItemBarcodeSuccessful: boolean;
    updateItemBarcodeFailed: any;

    searchForItemSuccessful: boolean;
    searchForItemFailed: any;

    history: any;
}

const initialState: State = {
    loading: false,
    updating: false,
    searching: false,

    message: null,
    itemBarcodeInfo: null,
    item: null,

    getItemBarcodeSuccessful: null,
    getItemBarcodeFailed: null,

    updateItemBarcodeSuccessful: null,
    updateItemBarcodeFailed: null,

    searchForItemSuccessful: null,
    searchForItemFailed: null,

    history: []
};

export function reducer(state = initialState, action): State {
    switch (action.type) {
    case barcodes.GET_ITEM_BARCODE:
        return {...state, loading: true, itemBarcodeInfo: null, getItemBarcodeSuccessful: null, getItemBarcodeFailed: null};
    case barcodes.GET_ITEM_BARCODE_SUCCESSFUL:
        return {...state, itemBarcodeInfo: action.payload, getItemBarcodeSuccessful: true, loading: false};
    case barcodes.GET_ITEM_BARCODE_FAILED:
        return {...state, getItemBarcodeSuccessful: false, getItemBarcodeFailed: action.err, loading: false};

    case barcodes.SEARCH_ITEM_BY_BARCODE:
        return {...state, searching: true, item: null, searchForItemSuccessful: null, searchForItemFailed: null};
    case barcodes.SEARCH_ITEM_BY_BARCODE_SUCCESSFUL:
        return {...state, item: action.payload, searchForItemSuccessful: true, searching: false};
    case barcodes.SEARCH_ITEM_BY_BARCODE_FAILED:
        return {...state, searchForItemSuccessful: false, searchForItemFailed: action.err, searching: false};

    case barcodes.CREATE_ITEM_BARCODE:
        return {...state, loading: true, itemBarcodeInfo: null, updateItemBarcodeSuccessful: null, updateItemBarcodeFailed: null};
    case barcodes.CREATE_ITEM_BARCODE_SUCCESSFUL:
        return {...state, itemBarcodeInfo: action.payload, updateItemBarcodeSuccessful: true, loading: false};
    case barcodes.CREATE_ITEM_BARCODE_FAILED:
        return {...state, updateItemBarcodeSuccessful: false, updateItemBarcodeFailed: action.err, loading: false};

    case barcodes.SET_BARCODES_SCANNED_HISTORY:
        return {...state, history: action.payload};
    case barcodes.ADD_TO_BARCODES_SCANNED_HISTORY:
        let scanned_codes = [...state.history.slice(0, 5), action.payload];
        return {...state, history: scanned_codes};

    default:
        return state;
    }
}

export const getBarcodesScannedHistory = (state: State) => state.history;

export const getItemBarcode = (state: State) => ({
    response: state.itemBarcodeInfo,
    success: state.getItemBarcodeSuccessful,
    failed: state.getItemBarcodeFailed,
    loading: state.loading
});

export const getItemSearchFromCode = (state: State) => ({
    response: state.item,
    success: state.searchForItemSuccessful,
    failed: state.searchForItemFailed,
    searching: state.searching
});

export const getCreateItemBarcode = (state: State) => ({
    response: state.itemBarcodeInfo,
    success: state.getItemBarcodeSuccessful,
    failed: state.getItemBarcodeFailed,
    loading: state.loading
});
