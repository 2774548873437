
import {take, skipWhile} from 'rxjs/operators';
import {Component, OnInit, ViewChild, OnDestroy, ElementRef} from '@angular/core';
import * as fromRoot from '../../../../store/reducers';
import {Store} from '@ngrx/store';
import {State} from '../../../../store/reducers';
import * as fromGlobalSearch from '../../../../store/actions/global-search.actions';

@Component({
    selector: 'app-global-search',
    templateUrl: './global-search.component.html',
    styleUrls: ['./global-search.component.scss']
})
export class GlobalSearchComponent implements OnInit, OnDestroy {
    public search_value: any = '';
    public search_result: any = false;
    public loading = false;
    public profile: any = false;
    public show_search = false;

    private time_out;

    @ViewChild('global_search_input') global_search_input: ElementRef;
    @ViewChild('global_search_logo') global_search_logo: ElementRef;
    @ViewChild('global_search_results_container') global_search_results_container: ElementRef;


    constructor(
        private store: Store<State>
    ) {
    }

    ngOnInit() {
        this.getProfile();
        this.watchMouseClick();
    }

    ngOnDestroy() {
        document.removeEventListener('click', this.offClickHandler.bind(this));
    }

    private getProfile() {
        this.store.select(fromRoot.getProfile).pipe(
            skipWhile((profile) => !profile),
            take(1), )
            .subscribe((profile) => {
                this.profile = profile;
            });
    }

    public onSearchValueChange(event) {
        if (this.time_out) {
            clearTimeout(this.time_out);
        }

        if (this.canSearch()) {
            this.time_out = setTimeout(() => {
                this.search();
            }, 400);
        }
    }

    private canSearch() {
        return this.search_value.length > 0;
    }

    private search() {
        this.loading = true;
        this.store.dispatch(new fromGlobalSearch.GlobalSearchAction(this.search_value));

        this.store.select(fromRoot.getGlobalSearchResponse).pipe(
            skipWhile((result) => !result.searching === true),
            skipWhile((result) => !result.response),
            take(1), )
            .subscribe((result) => {
                this.search_result = result;
                this.loading = false;
            });
    }

    public toggleSearch() {
        return this.show_search = !this.show_search;
    }

    public clearResults() {
        this.search_result = false;
        this.search_value = '';
    }

    get showResults() {
        if (this.search_value && this.loading) {
            return true;
        }

        return !!this.search_result;
    }

    private watchMouseClick() {
        document.addEventListener('click', this.offClickHandler.bind(this));
    }

    offClickHandler(event: any) {
        if (this.global_search_results_container && !this.global_search_results_container.nativeElement.contains(event.target)) {
            this.search_result = false;
            this.search_value = '';
        }
    }
}
