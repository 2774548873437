<table *ngIf="veh_carfax">
  <tr>
    <td class="headerVerticalAlign textAlignLeft">
      <span class="title is-size-3 is-bold">
        General*
      </span><br>

      <span *ngIf="getYear()"><strong class="subtitle">Year Model:</strong>
            <span>{{getYear()}}<br></span>
          </span>

      <span *ngIf="getMake()"><strong class="subtitle">Make Name:</strong>
            <span>{{getMake()}}<br></span>
          </span>

      <span *ngIf="getModel()"><strong class="subtitle">Series Name:</strong>
            <span>{{getModel()}}<br></span>
          </span>

      <span *ngIf="getType()"><strong class="subtitle">Submodel:</strong>
            <span>{{getType()}}<br></span>
          </span>

      <span *ngIf="veh_carfax['data']['nonoem-body']"><strong class="subtitle">Body:</strong>
            <span>{{veh_carfax['data']['nonoem-body']}}<br></span>
          </span>

      <span *ngIf="veh_carfax['data']['nonoem-drive']"><strong class="subtitle">Drive:</strong>
            <span>{{veh_carfax['data']['nonoem-drive']}}<br></span>
          </span>

      <span *ngIf="veh_carfax['data']['oem-base-shipping-weight']"><strong class="subtitle">Shipping Weight:</strong>
            <span>{{veh_carfax['data']['oem-base-shipping-weight']}}<br></span>
          </span>

      <span *ngIf="veh_carfax['data']['oem-body-type']"><strong class="subtitle">Body Type:</strong>
            <span>{{veh_carfax['data']['oem-body-type']}}<br></span>
          </span>

      <span *ngIf="veh_carfax['data']['oem-front-axle-code']"><strong class="subtitle">Front Axle:</strong>
            <span>{{veh_carfax['data']['oem-front-axle-code']}}<br></span>
          </span>

      <span *ngIf="veh_carfax['data']['oem-rear-axle-code']"><strong class="subtitle">Rear Axle:</strong>
            <span>{{veh_carfax['data']['oem-rear-axle-code']}}<br></span>
          </span>

      <span *ngIf="veh_carfax['data']['oem-driving-wheels']"><strong class="subtitle">Driving Wheels:</strong>
            <span>{{veh_carfax['data']['oem-driving-wheels']}}<br></span>
          </span>

      <span *ngIf="veh_carfax['data']['oemtruck-truck-driving-wheels']"><strong class="subtitle">Truck Driving Wheels:</strong>
            <span>{{veh_carfax['data']['oemtruck-truck-driving-wheels']}}<br></span>
          </span>

      <span *ngIf="veh_carfax['data']['nonoem-wheel-base']"><strong class="subtitle">Wheel Base:</strong>
            <span>{{veh_carfax['data']['nonoem-wheel-base']}}<br></span>
          </span>

      <span *ngIf="veh_carfax['data']['oem-tire-size']"><strong class="subtitle">Tire Size:</strong>
            <span>{{veh_carfax['data']['oem-tire-size']}}<br></span>
          </span>

    </td>
  </tr>
</table>
