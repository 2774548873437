
import {catchError, map, switchMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';

import { Observable ,  of , from as fromPromise} from 'rxjs';





import * as ggResults from '../actions/grooveglove.actions';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {getForm} from '../reducers';
import {GrooveGloveService} from '../../core/services/grooveGlove.service';
import {DELETE_SCAN} from '../actions/grooveglove.actions';

@Injectable()
export class GroovegloveEffects {

    loadGrooveGloveResponses: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(ggResults.GET_RESPONSES),
        switchMap(() => this.store.select(getForm)),
        switchMap((form) => fromPromise(this.grooveGloveService.getGrooveGlove(form.frm_locID))),
        map((state: any) => new ggResults.GetResponsesSuccessfulAction(state)),
        catchError((error) => of(new ggResults.GetResponsesFailedAction({error: {message: error}})))));


    linkGloveDataToForm: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(ggResults.LINK_DATA_TO_FORM),
        map((action: ggResults.LinkGloveDataToForm) => action.payload),
        map(payload => {
            this.grooveGloveService.linkGrooveGlove(payload.scanId, payload.workOrderId, payload.customerId, payload.formId).subscribe(response => {
            });
            return false;
        })), {dispatch: false});


    deleteGrooveGloveScan: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(ggResults.DELETE_SCAN),
        map((action: ggResults.LinkGloveDataToForm) => action.payload),
        map(payload => {
            this.grooveGloveService.deleteGrooveGlove(payload.scanId).subscribe(response => {
            });
            return false;
        })), {dispatch: false});


    getFormLinkedGrooveGloveScans$: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(ggResults.GET_FORM_LINKED_SCANS),
        map((action: ggResults.GetFormLinkedGrooveGloveScans) => action.payload),
        switchMap((payload) => fromPromise(this.grooveGloveService.getFormLinkedGrooveGloveScans(payload.workOrderId, payload.formID, payload.locID)).pipe(
            map((response) => new ggResults.GetFormLinkedGrooveGloveScansSuccessful(response)),
            catchError((err) => of(new ggResults.GetFormLinkedGrooveGloveScansFailed(err))))
        )));

    constructor(private $actions: Actions, private router: Router, private grooveGloveService: GrooveGloveService, private store: Store<any>) {}

}
