import { Action as NgrxAction } from '@ngrx/store';

export const SET_WORKORDER = '[Workorder] Set workorder';
export const LOAD_WORKORDER = '[WorkOrder] Load workorder';
export const LOAD_WORKORDER_SUCCESSFUL = '[WorkOrder] Load workorder successful';
export const LOADING_WORKORDER_FAILED = '[WorkOrder] Load workorder failed';
export const RESET_LOAD_WORKORDER_SUCCESSFUL_STATUS = '[WorkOrder] Reset load workorder successful status';
export const DELETE_WORKORDER = '[WorkOrder] Delete work order';
export const DELETE_WORKORDER_SUCCESSFUL = '[WorkOrder] Delete workorder successful';
export const DELETE_WORKORDER_FAILED = '[WorkOrder] Delete workorder failed';
export const UPDATE_WORKORDER = '[WorkOrder] Update workorder';
export const UPDATE_WORKORDER_SUCCESSFUL = '[WorkOrder] Update workorder successful';
export const UPDATE_WORKORDER_FAILED = '[WorkOrder] Update workorder failed';
export const CREATE_WORKORDER = '[WorkOrder] Create workorder';
export const CREATE_WORKORDER_SUCCESSFUL = '[WorkOrder] create workorder successful';
export const CREATE_WORKORDER_FAILED = '[WorkOrder] Create workorder failed';
export const SET_ADDING_ITEMS = '[WorkOrder] Set adding items';
export const CLEAR_ADDING_ITEMS = '[WorkOrder] clear adding items';
export const ADD_ITEM_TO_WORKORDER = '[WorkOrder] Add item to workorder';
export const ADD_ITEM_TO_WORKORDER_SUCCESSFUL = '[WorkOrder] Add item to workorder successful';
export const ADD_ITEM_TO_WORKORDER_FAILED = '[WorkOrder] Add item to workorder failed';
export const GET_WORKORDER_ITEMS_VERIFICATION = '[WorkOrder] Get work order items verification';
export const GET_WORKORDER_ITEMS_VERIFICATION_SUCCESSFUL = '[WorkOrder] Get work order items verification successful';
export const GET_WORKORDER_ITEMS_VERIFICATION_FAILED = '[WorkOrder] Get work order items verification failed';
export const UPDATE_WORKORDER_ITEM_VERIFICATION = '[WorkOrder] Update work order item verification';
export const UPDATE_WORKORDER_ITEM_VERIFICATION_SUCCESSFUL = '[WorkOrder] Update work order item verification successful';
export const UPDATE_WORKORDER_ITEM_VERIFICATION_FAILED = '[WorkOrder] Update work order item verification failed';
export const SHOW_WORK_ORDER_BACK_BUTTON = "[WorkOrderr] show work order back button";
export const HIDE_WORK_ORDER_BACK_BUTTON = "[WorkOrder} hide work order back button";
export const GET_WORK_ORDER_STATUSES = '[WorkOrder] get work order statuses';
export const GET_WORK_ORDER_STATUSES_SUCCESSFUL = '[WorkOrder] get work order statuses successful';
export const GET_WORK_ORDER_STATUSES_FAILED = '[WorkOrder] get work order statuses failed';
export const SET_WORK_ORDER_STATUSES = '[WorkOrder] set work order status';
export const SET_WORK_ORDER_STATUSES_SUCCESSFUL = '[WorkOrder] set work order status successful';
export const SET_WORK_ORDER_STATUSES_FAILED = '[WorkOrder] set work order status failed';
export const GET_EMPLOYEES_FOR_ASSIGNMENT = '[WorkOrder] get employees for assignment';
export const GET_EMPLOYEES_FOR_ASSIGNMENT_SUCCESSFUL = '[WorkOrder] get employees for assignment successful';
export const GET_EMPLOYEES_FOR_ASSIGNMENT_FAILED = '[WorkOrder] get employees for assignment failed';
export const SET_WORK_ORDER_EMPLOYEES = '[WorkOrder] set work order employees action';
export const SET_WORK_ORDER_EMPLOYEES_SUCCESSFUL = '[WorkOrder] set work order employees action successful';
export const SET_WORK_ORDER_EMPLOYEES_FAILED = '[WorkOrder] set work order employees action failed';
export const CLEAR_SALES_TECH_LOADING_VARIABLES_ACTION = '[WorkOrder] clear sales tech loading variables action';
export const SET_TICKET_TYPE = '[WorkOrder] set ticket type action';
export const CLEAR_TICKET_TYPE = '[WorkOrder] clear ticket type action';

export class SetWorkorderAction implements NgrxAction {
    readonly type = SET_WORKORDER;

    constructor(public payload: any) {
    }
}

export class LoadWorkOrderAction implements NgrxAction {
    readonly type = LOAD_WORKORDER;

    constructor(public payload: any) {
    }

}

export class SetTicketTypeAction implements NgrxAction {
    readonly type = SET_TICKET_TYPE;

    constructor(public payload: any) {
    }
}

export class ClearTicketTypeAction implements NgrxAction {
    readonly type = CLEAR_TICKET_TYPE;

    constructor(public payload: any) {
    }

}

export class LoadWorkOrderSuccessfulAction implements NgrxAction {
    readonly type = LOAD_WORKORDER_SUCCESSFUL;

    constructor(public payload: any) {
    }

}

export class LoadWorkOrderFailedAction implements NgrxAction {
    readonly type = LOADING_WORKORDER_FAILED;

    constructor(public payload: any) {
    }
}

export class ResetLoadWorkOrderSuccessfulStatusAction implements NgrxAction {
    readonly type = RESET_LOAD_WORKORDER_SUCCESSFUL_STATUS;

    constructor(public payload: any) {
    }
}

export class DeleteWorkOrderAction implements NgrxAction {
    readonly type = DELETE_WORKORDER;

    constructor(public payload: any) {
    }

}

export class DeleteWorkOrderSuccessfulAction implements NgrxAction {
    readonly type = DELETE_WORKORDER_SUCCESSFUL;

    constructor(public payload: any) {
    }

}

export class DeleteWorkOrderFailedAction implements NgrxAction {
    readonly type = DELETE_WORKORDER_FAILED;

    constructor(public payload: any) {
    }
}

export class UpdateWorkOrderAction implements NgrxAction {
    readonly type = UPDATE_WORKORDER;

    constructor(public payload: any) {
    }

}

export class UpdateWorkOrderSuccessfulAction implements NgrxAction {
    readonly type = UPDATE_WORKORDER_SUCCESSFUL;

    constructor(public payload: any) {
    }

}

export class UpdateWorkOrderFailedAction implements NgrxAction {
    readonly type = UPDATE_WORKORDER_FAILED;

    constructor(public payload: any) {
    }
}

export class CreateWorkOrderAction implements NgrxAction {
    readonly type = CREATE_WORKORDER;

    constructor(public payload: any) {
    }

}

export class CreateWorkOrderSuccessfulAction implements NgrxAction {
    readonly type = CREATE_WORKORDER_SUCCESSFUL;

    constructor(public payload: any) {
    }

}

export class CreateWorkOrderFailedAction implements NgrxAction {
    readonly type = CREATE_WORKORDER_FAILED;

    constructor(public payload: any) {
    }
}

export class SetAddingItemsAction implements NgrxAction {
    readonly type = SET_ADDING_ITEMS;

    constructor() {
    }
}

export class ClearAddingItemsAction implements NgrxAction {
    readonly type = CLEAR_ADDING_ITEMS;

    constructor() {
    }
}

export class AddItemToWorkOrderAction implements NgrxAction {
    readonly type = ADD_ITEM_TO_WORKORDER;

    constructor(public payload: any) {
    }

}

export class AddItemToWorkOrderSuccessfulAction implements NgrxAction {
    readonly type = ADD_ITEM_TO_WORKORDER_SUCCESSFUL;

    constructor(public payload: any) {
    }

}

export class AddItemToWorkOrderFailedAction implements NgrxAction {
    readonly type = ADD_ITEM_TO_WORKORDER_FAILED;

    constructor(public payload: any) {
    }
}

export class ShowWorkOrderBackButtonAction implements NgrxAction {
    readonly type = SHOW_WORK_ORDER_BACK_BUTTON;
}

export class HideWorkOrderBackButtonAction implements NgrxAction {
    readonly type = HIDE_WORK_ORDER_BACK_BUTTON;
}

export class GetWorkOrderStatusesAction implements NgrxAction {
    readonly type = GET_WORK_ORDER_STATUSES;
    constructor(public payload: any) {
    }
}

export class GetWorkOrderStatusesSuccessfulAction implements NgrxAction {
    readonly type = GET_WORK_ORDER_STATUSES_SUCCESSFUL;
    constructor(public payload: any) {
    }
}

export class GetWorkOrderStatusesFailedAction implements NgrxAction {
    readonly type = GET_WORK_ORDER_STATUSES_FAILED;
    constructor(public payload: any) {
    }
}

export class SetWorkOrderStatusAction implements NgrxAction {
    readonly type = SET_WORK_ORDER_STATUSES;
    constructor(public payload: any) {
    }
}

export class SetWorkOrderStatusSuccessfulAction implements NgrxAction {
    readonly type = SET_WORK_ORDER_STATUSES_SUCCESSFUL;
    constructor(public payload: any) {
    }
}

export class SetWorkOrderStatusFailedAction implements NgrxAction {
    readonly type = SET_WORK_ORDER_STATUSES_FAILED;
    constructor(public payload: any) {
    }
}

export class GetEmployeesForAssignmentAction implements NgrxAction {
    readonly type = GET_EMPLOYEES_FOR_ASSIGNMENT;
    constructor(public payload: any){
    }
}

export class GetEmployeesForAssignmentSuccessfulAction implements NgrxAction {
    readonly type = GET_EMPLOYEES_FOR_ASSIGNMENT_SUCCESSFUL;
    constructor(public payload: any){
    }
}

export class GetEmployeesForAssignmentFailedAction implements NgrxAction {
    readonly type = GET_EMPLOYEES_FOR_ASSIGNMENT_FAILED;
    constructor(public payload: any){
    }
}

export class SetWorkOrderEmployeesAction implements NgrxAction {
    readonly type = SET_WORK_ORDER_EMPLOYEES;
    constructor(public payload: any) {
    }
}

export class SetWorkOrderEmployeesSuccessfulAction implements NgrxAction {
    readonly type = SET_WORK_ORDER_EMPLOYEES_SUCCESSFUL;
    constructor(public payload: any) {
    }
}

export class SetWorkOrderEmployeesFailedAction implements NgrxAction {
    readonly type = SET_WORK_ORDER_EMPLOYEES_FAILED;
    constructor(public payload: any) {
    }
}

export class ClearSalesTechVariablesAction implements NgrxAction {
    readonly type = CLEAR_SALES_TECH_LOADING_VARIABLES_ACTION;
    constructor() {
    }
}

export class GetWorkOrderItemsVerificationAction implements NgrxAction {
    readonly type = GET_WORKORDER_ITEMS_VERIFICATION;
    constructor(public payload: any) {
    }
}

export class GetWorkOrderItemsVerificationActionSuccessful implements NgrxAction {
    readonly type = GET_WORKORDER_ITEMS_VERIFICATION_SUCCESSFUL;
    constructor(public payload: any) {
    }
}

export class GetWorkOrderItemsVerificationActionFailed implements NgrxAction {
    readonly type = GET_WORKORDER_ITEMS_VERIFICATION_FAILED;
    constructor(public payload: any) {
    }
}

export class UpdateWorkOrderItemVerificationAction implements NgrxAction {
    readonly type = UPDATE_WORKORDER_ITEM_VERIFICATION;
    constructor(public payload: any) {
    }
}

export class UpdateWorkOrderItemVerificationActionSuccessful implements NgrxAction {
    readonly type = UPDATE_WORKORDER_ITEM_VERIFICATION_SUCCESSFUL;
    constructor(public payload: any) {
    }
}

export class UpdateWorkOrderItemVerificationActionFailed implements NgrxAction {
    readonly type = UPDATE_WORKORDER_ITEM_VERIFICATION_FAILED;
    constructor(public payload: any) {
    }
}


export type Action = SetWorkorderAction
| LoadWorkOrderAction
| LoadWorkOrderSuccessfulAction
| LoadWorkOrderFailedAction
| ResetLoadWorkOrderSuccessfulStatusAction
| DeleteWorkOrderAction
| DeleteWorkOrderSuccessfulAction
| DeleteWorkOrderFailedAction
| UpdateWorkOrderAction
| UpdateWorkOrderSuccessfulAction
| UpdateWorkOrderFailedAction
| CreateWorkOrderAction
| CreateWorkOrderFailedAction
| CreateWorkOrderSuccessfulAction
| ShowWorkOrderBackButtonAction
| HideWorkOrderBackButtonAction
| GetWorkOrderStatusesAction
| GetWorkOrderStatusesSuccessfulAction
| GetWorkOrderStatusesFailedAction
| SetWorkOrderStatusAction
| SetWorkOrderStatusSuccessfulAction
| SetWorkOrderStatusFailedAction
| GetEmployeesForAssignmentAction
| GetEmployeesForAssignmentSuccessfulAction
| GetEmployeesForAssignmentFailedAction
| SetWorkOrderEmployeesAction
| SetWorkOrderEmployeesSuccessfulAction
| SetWorkOrderEmployeesFailedAction
| ClearSalesTechVariablesAction
| SetTicketTypeAction
| ClearTicketTypeAction
| AddItemToWorkOrderAction
| AddItemToWorkOrderSuccessfulAction
| AddItemToWorkOrderFailedAction
| GetWorkOrderItemsVerificationAction
| GetWorkOrderItemsVerificationActionSuccessful
| GetWorkOrderItemsVerificationActionFailed
| UpdateWorkOrderItemVerificationAction
| UpdateWorkOrderItemVerificationActionSuccessful
| UpdateWorkOrderItemVerificationActionFailed;
