import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {Store} from "@ngrx/store";
import * as fromRoot from "../../../../store/reducers";

@Component({
    selector: 'app-assign-sales-and-tech-per-line-item',
    templateUrl: './assign-sales-and-tech-per-line-item.component.html',
    styleUrls: ['./assign-sales-and-tech-per-line-item.component.scss']
})
export class AssignSalesAndTechPerLineItemComponent implements OnInit {

    @Input() workOrderItem;
    @Input() employees;
    @Input() events: Observable<any>;
    @Input() profile: any;
    @Output() updateAssignment = new EventEmitter();

    @ViewChild('sales1') sales1: ElementRef;
    @ViewChild('sales2') sales2: ElementRef;
    @ViewChild('tech1') tech1: ElementRef;
    @ViewChild('tech2') tech2: ElementRef;

    public salesmen: any;
    public techs: any;
    public eventSubscription: Subscription;


    constructor() { }

    ngOnInit() {
        this.salesmen = this.getSalesman();
        this.techs = this.getTechnicians();
        if (this.events) {
            this.eventSubscription = this.events.subscribe((event) => {
                this.assignEmployee(event.assignment, event.employeeID);
            });
        }
    }

    getSalesman() {
        const salesmen = [];
        this.employees.forEach((employee) => {
            const type = employee.emp_type.split(',');
            if (type.includes('24')) {
                salesmen.push(employee);
            }
        });
        return salesmen;
    }

    getTechnicians() {
        const techs = [];
        this.employees.forEach((employee) => {
            const type = employee.emp_type.split(',');
            if (type.includes('25') || type.includes('30') || type.includes('31')) {
                techs.push(employee);
            }
        });
        return techs;
    }

    assignEmployee(assignment, employeeID) {
        if (assignment == 'sales1') {
            this.sales1.nativeElement.value = employeeID;
            this.updateAssignment.emit({'itemID': this.workOrderItem.woi_workitemID, 'employeeID': employeeID, 'assignment': 'sales1'});
        } else if (assignment == 'sales2') {
            this.sales2.nativeElement.value = employeeID;
            this.updateAssignment.emit({'itemID': this.workOrderItem.woi_workitemID, 'employeeID': employeeID, 'assignment': 'sales2'});
        } else if (assignment == 'tech1') {
            this.tech1.nativeElement.value = employeeID;
            this.updateAssignment.emit({'itemID': this.workOrderItem.woi_workitemID, 'employeeID': employeeID, 'assignment': 'tech1'});
        } else if (assignment == 'tech2') {
            this.tech2.nativeElement.value = employeeID;
            this.updateAssignment.emit({'itemID': this.workOrderItem.woi_workitemID, 'employeeID': employeeID, 'assignment': 'tech2'});
        }
    }

}
