import { Action as NgrxAction } from '@ngrx/store';

export const SET_NOTE = "[Note] Set Notes";
export const CREATE_NOTE = "[Note] Create Note";
export const CREATE_NOTE_SUCCESSFUL = "[Note] Create Note successful";
export const CREATE_NOTE_FAILED = "[Note] Create Note failed";
export const LOAD_NOTES = "[Note] Load Notes";
export const LOAD_NOTES_SUCCESSFUL = "[Note] Load Notes successful";
export const LOAD_NOTES_FAILED = "[Note] Load Notes failed";
export const UPDATE_NOTE = "[Note] Update Note";
export const UPDATE_NOTE_SUCCESSFUL = "[Note] Update Note successful";
export const UPDATE_NOTE_FAILED = "[Note] Update Note failed";
export const DELETE_NOTE = "[Note] Delete Note";
export const DELETE_NOTE_SUCCESSFUL = "[Note] Delete Note successful";
export const DELETE_NOTE_FAILED = "[Note] Delete Note failed";


export class SetNotesAction implements NgrxAction {
    readonly type = SET_NOTE;

    constructor(public payload: any) {
    }
}

export class CreateNoteAction implements NgrxAction {
    readonly type = CREATE_NOTE;

    constructor(public payload: any) {
    }
}

export class CreateNoteSuccessfulAction implements NgrxAction {
    readonly type = CREATE_NOTE_SUCCESSFUL;

    constructor(public payload: any) {
    }
}

export class CreateNoteFailedAction implements NgrxAction {
    readonly type = CREATE_NOTE_FAILED;

    constructor(public payload: any) {
    }
}

export class LoadNotesAction implements NgrxAction {
    readonly type = LOAD_NOTES;

    constructor(public payload: any) {
    }
}

export class LoadNotesSuccessfulAction implements NgrxAction {
    readonly type = LOAD_NOTES_SUCCESSFUL;

    constructor(public payload: any) {
    }
}

export class LoadNotesFailedAction implements NgrxAction {
    readonly type = LOAD_NOTES_FAILED;

    constructor(public payload: any) {
    }
}

export class UpdateNoteAction implements NgrxAction {
    readonly type = UPDATE_NOTE;

    constructor(public payload: any) {
    }
}

export class UpdateNoteSuccessfulAction implements NgrxAction {
    readonly type = UPDATE_NOTE_SUCCESSFUL;

    constructor(public payload: any) {
    }
}

export class UpdateNoteFailedAction implements NgrxAction {
    readonly type = UPDATE_NOTE_FAILED;

    constructor(public payload: any) {
    }
}

export class DeleteNoteAction implements NgrxAction {
    readonly type = DELETE_NOTE;

    constructor(public payload: any) {
    }
}

export class DeleteNoteSuccessfulAction implements NgrxAction {
    readonly type = DELETE_NOTE_SUCCESSFUL;

    constructor(public payload: any) {
    }
}

export class DeleteNoteFailedAction implements NgrxAction {
    readonly type = DELETE_NOTE_FAILED;

    constructor(public payload: any) {
    }
}

export type Action = SetNotesAction
| CreateNoteAction
| CreateNoteSuccessfulAction
| CreateNoteFailedAction
| LoadNotesAction
| LoadNotesSuccessfulAction
| LoadNotesFailedAction
| UpdateNoteAction
| UpdateNoteSuccessfulAction
| UpdateNoteFailedAction
| DeleteNoteAction
| DeleteNoteSuccessfulAction
| DeleteNoteFailedAction;

