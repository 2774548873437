<div class="messaging-center__container">

  <div class="messaging-center__heading">
    <app-panel-header><b>Messaging Center</b></app-panel-header>
  </div>

  <div class="message-center-options-container">
    <div class="message-center-options">
      <div (click)="showCustomer = true" class="message-center-customer" *ngIf="settingAllowingTexting && permissionToViewTexting">
        <button class="btn {{showCustomer ? 'is-primary' : 'is-unselected'}} disabled" [ngClass]="{'disabled': showCustomer === true}"><b>CUSTOMER</b></button>
      </div>
      <div (click)="showCustomer = false" class="message-center-internal" *ngIf="settingAllowingTexting && permissionToViewTexting">
        <button class="btn {{showCustomer ? 'is-unselected' : 'is-primary'}}" [ngClass]="{'disabled': showCustomer === false}"><b>INTERNAL</b></button>
      </div>
    </div>
  </div>

  <div *ngIf="showCustomer === false">
    <app-internal-messages-center-container></app-internal-messages-center-container>
  </div>

  <div *ngIf="showCustomer === true">
    <app-customer-messages-center-container></app-customer-messages-center-container>
  </div>

</div>
