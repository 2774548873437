<div class="messaging-center__messages-container" *ngIf="message">
  <div>
    <div class="message-center__body-info">
      <span class="message-center__body-info-from">
        <span *ngIf="!message.read && type === 'internal'">
          <app-icon class="unread-message-icon is-primary" name="circle"></app-icon>
        </span>

        <span *ngIf="!message.read && type === 'customer-text' && message.direction === 'outgoing'">
          <app-icon class="unread-message-icon is-primary" name="circle"></app-icon>
        </span>
        <b> {{message.user}}<span *ngIf="type === 'internal' || type === 'workorder'" class="workorder-messages-heading"><span
          *ngIf="message.whoTo"> | To {{message.whoTo}}</span> <span
          *ngIf="message.woNumber"> | WO# {{message.woNumber}}</span>
          <span *ngIf="message.vehicle"> | {{message.vehicle}}</span></span>
        </b>
      </span>

      <span *ngIf="type === 'customer-text'">
        <b>{{message.whoTo}}</b>
      </span>
    </div>

    <div class="messaging-center__message">
      <span>{{message.message}}</span>
    </div>
  </div>

  <div>
    <div class="message-center__actions">
      <div class="message-center-actions-icon" *ngIf="type === 'internal'">
        <app-navigation-icon [name]="'service'" (click)="goToWorkOrder(message.woID)"></app-navigation-icon>
      </div>

      <div *ngIf="type === 'internal'">
        <app-icon [name]="'mail'" (click)="goToMessages(message.woID)"></app-icon>
      </div>

      <div *ngIf="type === 'customer-text' && message.custID">
        <app-icon [name]="'mail'" (click)="goToMessages(message.custID)"></app-icon>
      </div>

      <div *ngIf="!message.read" (click)="goMarkAsRead(message.msgID)">
        <app-icon [name]="'checkmark'"
                  *ngIf="type === 'internal' && !message.read"></app-icon>

        <app-icon [name]="'checkmark'"
                  *ngIf="type === 'customer-text' && !message.read && message.direction === 'outgoing'"></app-icon>
      </div>
    </div>
  </div>
</div>
