import * as CC_Processing_Actions from '../actions/credit-card-processing.actions';

export interface State {
    terminals: any;
    selectedTerminal: any;
    loading: boolean;
    error: any;
    pairSuccess: any;
    unPairSuccess: any;
    getByIDError: any;
}

const initialState: State = {
    terminals: null,
    loading: false,
    error: null,
    pairSuccess: null,
    unPairSuccess: null,
    getByIDError: null,
    selectedTerminal: null

};

export function reducer(state = initialState, action): State {
    switch (action.type) {
    case CC_Processing_Actions.GET_360_TERMINALS:
        return {...state, loading: true, terminals: null};
    case CC_Processing_Actions.GET_360_TERMINALS_SUCCESSFUL:
        return {...state, loading: false, terminals: action.payload['value']};
    case CC_Processing_Actions.GET_360_TERMINALS_FAILED:
        return {...state, loading: false, error: action.payload, terminals: null};

    case CC_Processing_Actions.PAIR_360_TERMINAL_ACTION:
        return {...state, loading: true, pairSuccess: null, unPairSuccess: null};
    case CC_Processing_Actions.PAIR_360_TERMINAL_SUCCESSFUL:
        return {...state, loading: false, terminals: action.payload['value'], pairSuccess: true};
    case CC_Processing_Actions.PAIR_360_TERMINAL_FAILED:
        return {...state, loading: false, error: action.payload, pairSuccess: false};

    case CC_Processing_Actions.UN_PAIR_360_TERMINAL_ACTION:
        return {...state, loading: true, pairSuccess: null, unPairSuccess: null};
    case CC_Processing_Actions.UN_PAIR_360_TERMINAL_SUCCESSFUL:
        return {...state, loading: false, terminals: action.payload['value'], unPairSuccess: true};
    case CC_Processing_Actions.UN_PAIR_360_TERMINAL_FAILED:
        return {...state, loading: false, error: action.payload, unPairSuccess: false};

    case CC_Processing_Actions.GET_360_TERMINAL_BY_ID:
        return {...state, loading: true, selectedTerminal: null};
    case CC_Processing_Actions.GET_360_TERMINAL_BY_ID_SUCCESSFUL:
        return {...state, loading: false, selectedTerminal: action.payload['value']};
    case CC_Processing_Actions.GET_360_TERMINAL_BY_ID_FAILED:
        return {...state, loading: false, getByIDError: action.payload, selectedTerminal: null};

    default:
        return state;
    }
}

export const get360Terminals = (state: State) => state;
export const get360TerminalByID = (state: State) => state;

