<app-panel y_padding="no-top-padding" class="has-margin-y-bottom-2">
  <app-panel-header>Change Work Order Status</app-panel-header>

  <app-panel-body class="has-margin-y-bottom-2">
    <div class="centered-text">
      <div class="justify-flex-center">
        <table class="table">
          <tr>
            <td class="is-semibold has-text-right">Status:</td>
            <td>{{selected_status?.wos_name || ''}}</td>
          </tr>

          <tr>
            <td class="has-text-right">
              <label class="is-semibold" for="send_text">Send Txt:</label>
            </td>
            <td>
              <input id="send_text" type="checkbox" class="checkbox"
                     [(ngModel)]="send_text"
                     [disabled]="!canSendText" [checked]="canSendText">
            </td>
          </tr>

          <tr>
            <td class="is-semibold has-text-right">Customer:</td>
            <td>{{customerName}}</td>
          </tr>

          <tr>
            <td class="is-semibold has-text-right">Cell:</td>
            <td>{{customer?.cus_cellphone}}</td>
          </tr>

          <tr>
            <td class="is-semibold has-text-right">Msg:</td>
            <td>{{selected_status?.wos_text}}</td>
          </tr>

        </table>
      </div>

      <div class="justify-flex-center has-margin-y-bottom-2">
        <button class="btn is-primary is-centered" (click)="submit()">Submit ></button>
      </div>
    </div>
  </app-panel-body>
</app-panel>
