
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable()
export class WorkorderNoteService {

    private urlApi: string;
    private urlOld: string;

    constructor(private http: HttpClient) {
        this.urlApi = environment.tireguruBaseApi;
        this.urlOld = environment.tireguruBaseUrl;
    }

    createNote(body, workOrderID) {
        const url = `${this.urlApi}/work-order/${workOrderID}/create-note`;
        return this.http.post(url, body).pipe(map((response: any) => response.data)).toPromise();
    }

    updateNote(body, woi_workitemID) {
        const url = `${this.urlApi}/work-order/${woi_workitemID}/update-note`;
        return this.http.post(url, body).pipe(map((response: any) => response.data)).toPromise();
    }

    deleteNote(woi_workitemID) {
        const url = `${this.urlApi}/work-order/${woi_workitemID}/delete-note`;
        return this.http.delete(url).pipe(map((response: any) => response.data)).toPromise();
    }

    getNotes(body, workOrderID) {
        const url = `${this.urlApi}/work-order/${workOrderID}/get-notes`;
        return this.http.get(url, body).pipe(map((response: any) => response.data)).toPromise();
    }
}
