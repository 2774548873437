import * as job from '../actions/job.actions';

export interface State {
    loadingJobs: boolean;
    loadingJobsSuccessful: boolean;
    loadingJobsFailed: any;

    loadingJobSubcategories: boolean;
    loadingJobSubcategoriesSuccessful: boolean;
    loadingJobSubcategoriesFailed: any;

    jobs: { [id: number]: any };
    jobSubcategories: {
        [id: number]: {
            [id: number]: any;
        };
    };

    jobForms: any;
    loadingJobsFormsSuccessful: boolean;
}

const initialState: State = {
    loadingJobs: false,
    loadingJobsSuccessful: null,
    loadingJobsFailed: null,

    loadingJobSubcategories: false,
    loadingJobSubcategoriesSuccessful: null,
    loadingJobSubcategoriesFailed: null,

    jobs: {},
    jobSubcategories: {},
    jobForms: false,
    loadingJobsFormsSuccessful: false
};

export function reducer(state = initialState, action): State {
    switch (action.type) {
        case job.LOAD_JOBS:
            return {...state, loadingJobs: true};

        case job.LOAD_JOBS_SUCCESSFUL:
            const jobs = action.payload.reduce((carry, job) => ({...carry, [job.jbc_jobID]: job}), {});
            return {...state, loadingJobs: false, loadingJobsSuccessful: true, jobs, loadingJobsFailed: false};

        case job.LOAD_JOBS_FAILED:
            return {...state, loadingJobs: false, loadingJobsSuccessful: false, loadingJobsFailed: action.payload};

        case job.LOAD_JOB_SUBCATEGORY:
        case job.LOAD_SUBCATEGORIES:
            return {...state, loadingJobSubcategories: true};

        case job.LOAD_JOB_SUBCATEGORY_SUCCESSFUL:
            const jobSubcategories = action.payload.reduce((carry, subcategory) => {
                carry[subcategory.jsc_categoryID] = carry[subcategory.jsc_categoryID] || {};
                carry[subcategory.jsc_categoryID] = {...carry[subcategory.jsc_categoryID], [subcategory.jsc_subcategoryID]: subcategory};
                return carry;
            }, {});
            return {...state, loadingJobSubcategories: false, jobSubcategories, loadingJobsFailed: false};

        case job.LOAD_JOBS_FAILED:
            return {...state, loadingJobSubcategories: false, loadingJobsFailed: action.payload};

        case job.LOAD_JOB_FORMS:
            return {...state, loadingJobs: true};
        case job.LOAD_JOB_FORMS_SUCCESSFUL:
        return {...state, jobForms: action.payload, loadingJobs: false};
        case job.LOAD_JOB_FORMS_FAILED:
        return {...state, loadingJobs: false};
        default:
            return state;

    }
}

export const getJobs = (state: State) => Object.keys(state.jobs).map(key => state.jobs[key]);
export const getJob = (id, state: State) => state.jobs[id] || false;
export const getJobSubcategories = (id, state: State) => state.jobSubcategories[id] || false;
export const getJobSubcategoriesByIds = (ids, state: State) => {
    let subCategories = {};
    Object.keys(state.jobSubcategories).forEach((key) => {
        subCategories = {...subCategories, ...state.jobSubcategories[key]};
    });

    const toReturn = [];

    ids.forEach(id => {
        if (subCategories[id]) {
            toReturn.push(subCategories[id]);
        }
    });

    return toReturn;
};

export const getJobForms = (id, state: State) => {
    let form = {};
    if (state.jobForms.data != null && typeof state.jobForms.data != 'undefined') {
        Object.keys(state.jobForms.data).forEach((key) => {
            if (state.jobForms.data[key]['jobSubcategoryID'] == id) {
                form = state.jobForms.data[key];
            }
        });
    }
    return form;
};
