import {throwError as observableThrowError, Observable, EMPTY as empty, of, from as fromPromise} from 'rxjs';

import {take, catchError, map, delay, switchMap, tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {createEffect, Actions, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';


import * as workorder from '../actions/workorders.actions';

import {WorkOrderService} from '../../core/services/work-order.service';
import {WorkOrderProvider} from '../../core/providers/work-order.provider';
import * as fromRoot from '../reducers';

@Injectable()
export class WorkOrderEffects {


    loadWorkorder: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(workorder.LOAD_WORKORDER),
        map((action: workorder.LoadWorkOrderAction) => action.payload),
        switchMap((workorderID) => {
            if (!workorderID) {
                return observableThrowError({payload: {error: {message: 'Could not load work order'}}});
            }

            return fromPromise(this.workorderService.getWorkOrder(workorderID)).pipe(
                switchMap((workorderInfo: any) => fromPromise(this.workOrderProvider.setWorkOrder(workorderInfo.data, true)).pipe(
                    map(() => new workorder.LoadWorkOrderSuccessfulAction(workorderInfo.data)))
                ),
                catchError((error) =>
                    of(new workorder.LoadWorkOrderFailedAction({error: {message: 'Work Order could not be loaded, please try again'}}))
                )
            );
        })
    ));


    createWorkorder: Observable<workorder.Action> = createEffect(() => this.$actions.pipe(
        ofType(workorder.CREATE_WORKORDER),
        map((action: workorder.CreateWorkOrderAction) => action.payload),
        switchMap((creationInfo) => {
            const {customerId, vehicleId = null, woType = null} = creationInfo;
            return fromPromise(this.workorderService.createWorkOrder(customerId, vehicleId, woType)).pipe(
                map((workorderInfo: any) => new workorder.CreateWorkOrderSuccessfulAction(workorderInfo)),
                catchError(() => of(new workorder.CreateWorkOrderFailedAction({error: {message: 'Could not creat work order, please try again later'}})))
            );
        })
    ));


    getWorkOrderStatuses: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(workorder.GET_WORK_ORDER_STATUSES),
        map((action: workorder.GetWorkOrderStatusesAction) => action.payload),
        switchMap((payload) => {
            return this.store.select(fromRoot.getWorkOrderStatuses).pipe(
                take(1),
                switchMap((statuses: any) => {
                    if (statuses) {
                        return of(statuses);
                    }
                    return fromPromise(this.workorderService.getWorkOrderStatuses(payload.locID));
                }),
                map((statuses: any) => new workorder.GetWorkOrderStatusesSuccessfulAction(statuses)),
                catchError((error) =>  of(new workorder.GetWorkOrderStatusesFailedAction(error)))
            );
        })
    ));


    setWorkOrderStatus: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(workorder.SET_WORK_ORDER_STATUSES),
        map((action: workorder.SetWorkOrderStatusAction) => action.payload),
        switchMap((payload) => fromPromise(
                this.workorderService.setWorkOrderStatus(payload.locID, payload.woID, payload.statusID, payload.sendText)).pipe(
                map((statuses) => new workorder.SetWorkOrderStatusSuccessfulAction(statuses)),
                catchError((error) => of(new workorder.SetWorkOrderStatusFailedAction(error)))
            )
        )
    ));


    getEmployeesForAssignment: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(workorder.GET_EMPLOYEES_FOR_ASSIGNMENT),
        map((action: workorder.GetEmployeesForAssignmentAction) => action.payload),
        switchMap((payload) =>
            this.store.select(fromRoot.getEmployeesForAssignment).pipe(
                take(1)
            ).pipe(
                switchMap((employees: any) => {
                    if (employees) {
                        return of(employees);
                    }
                    return fromPromise(this.workorderService.getEmployeesForAssignment(payload.locID));
                })
            ).pipe(
                map((employees: any) => new workorder.GetEmployeesForAssignmentSuccessfulAction(employees)),
                catchError((error) => of(new workorder.GetEmployeesForAssignmentFailedAction(error)))
            )
        )
    ));


    setWorkOrderEmployees: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(workorder.SET_WORK_ORDER_EMPLOYEES),
        map((action: workorder.SetWorkOrderEmployeesAction) => action.payload),
        switchMap((payload) =>
            fromPromise(this.workorderService.setWorkOrderEmployees(payload.locID, payload.woID, payload['assignments'])).pipe(
                map((results) => new workorder.SetWorkOrderEmployeesSuccessfulAction(results)),
                catchError((error) => of(new workorder.SetWorkOrderEmployeesFailedAction(error)))
            )
        )
    ));


    addItemToWorkOrder: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(workorder.ADD_ITEM_TO_WORKORDER),
        map((action: workorder.AddItemToWorkOrderAction) => action.payload),
        switchMap((payload) =>
            fromPromise(this.workorderService.addItemToWorkorder(payload.itemID, payload.qty)).pipe(
                map((itemAdded) => new workorder.AddItemToWorkOrderSuccessfulAction(itemAdded)),
                catchError((error) => of(new workorder.AddItemToWorkOrderSuccessfulAction(error)))
            )
        )
    ));


    getWorkOrderItemVerification: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(workorder.GET_WORKORDER_ITEMS_VERIFICATION),
        map((action: workorder.GetWorkOrderItemsVerificationAction) => action.payload),
        switchMap((payload) =>
            fromPromise(this.workorderService.getWorkOrderItemsVerification(payload.woID)).pipe(
                map((itemVerifications) => new workorder.GetWorkOrderItemsVerificationActionSuccessful(itemVerifications)),
                catchError((error) => of(new workorder.GetWorkOrderItemsVerificationActionFailed(error)))
            )
        )
    ));


    updateWorkOrderItemVerification: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(workorder.UPDATE_WORKORDER_ITEM_VERIFICATION),
        map((action: workorder.UpdateWorkOrderItemVerificationAction) => action.payload),
        switchMap((payload) =>
            fromPromise(this.workorderService.updateWorkOrderItemVerification(payload)).pipe(
                map((itemVerification) => new workorder.UpdateWorkOrderItemVerificationActionSuccessful(itemVerification)),
                catchError((error) => of(new workorder.UpdateWorkOrderItemVerificationAction(error)))
            )
        )
    ));

    constructor(private $actions: Actions, private workorderService: WorkOrderService, private workOrderProvider: WorkOrderProvider, private store: Store<fromRoot.State>) {
    }
}
