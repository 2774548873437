
import {take, skipWhile} from 'rxjs/operators';
import {Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, ViewChildren} from '@angular/core';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../store/reducers';
import * as fromWorkorders from '../../../store/actions/workorders.actions';
import {AlertProvider} from '../../../core/providers/alert.provider';
import {AssignSalesAndTechPerLineItemComponent} from './assign-sales-and-tech-per-line-item/assign-sales-and-tech-per-line-item.component';
import {Subject} from 'rxjs';

@Component({
    selector: 'app-salesman-technician-popup',
    templateUrl: './salesman-technician-popup.component.html',
    styleUrls: ['./salesman-technician-popup.component.scss']
})
export class SalesmanTechnicianPopupComponent implements OnInit {

    @Input() showPopup;
    @Input() locID;
    @Input() salesTech;
    @Input() workOrder;
    @Output() valueChange = new EventEmitter();

    public employees: any;
    public toUpdate: object = {};
    public workOrderItems;
    public profile: any = false;

    assignAllEvent: Subject<any> = new Subject<any>();

    constructor(private alertProvider: AlertProvider, private store: Store<fromRoot.State>) {
    }

    ngOnInit() {
        this.getProfile();
        this.getEmployeesForAssignment();
        this.subscribeToWorkOrderItems();
    }

    getEmployeesForAssignment() {
        this.store.dispatch(new fromWorkorders.GetEmployeesForAssignmentAction({'locID': this.locID}));
        this.store.select(fromRoot.getEmployeesForAssignment).pipe(skipWhile(employees => !employees), take(1), ).subscribe((employees) => {
            this.employees = employees;
        });
    }

    subscribeToWorkOrderItems() {
        this.store.select(fromRoot.getWorkorder).pipe(skipWhile(workorder => !workorder)).subscribe((workOrder) => {
            if (workOrder && workOrder.item_relations) {
                this.workOrderItems = workOrder.item_relations;
            }
        });
    }

    saveAssignedTechs() {
        const payload = {'locID': this.locID, 'woID': this.workOrderItems[0]['woi_workorderID'], 'assignments': this.toUpdate};
        this.store.dispatch(new fromWorkorders.SetWorkOrderEmployeesAction(payload));
        this.store.select(fromRoot.getSetWorkOrderEmployees).pipe(skipWhile(status => status[0]), take(1), ).subscribe((results) => {
            if (results[0] == false && results[1] == true) {
                this.alertProvider.addSuccess("Successfully assigned salesman and technicians.");
                this.showPopup = false;
            }
        });
        this.store.dispatch(new fromWorkorders.LoadWorkOrderAction(this.workOrderItems[0]['woi_workorderID']));

    }

    getProfile() {
        this.store.select(fromRoot.getProfile).pipe(
            skipWhile(profile => (profile && typeof profile === 'object' && !Object.keys(profile).length)),
            take(1), )
            .subscribe((profile) => {
                if (profile && profile.location) {
                    this.profile = profile;
                }
            });
    }

    createNewAssignments(event) {
        if (!this.toUpdate['' + event['itemID']]) {
            this.toUpdate['' + event['itemID']] = {};
        }

        if (!this.toUpdate['' + event['itemID']][event['assignment']]) {
            this.toUpdate['' + event['itemID']][event['assignment']] = {};
        }
        this.toUpdate['' + event['itemID']][event['assignment']] = event['employeeID'];
    }

    getWorkOrderItems() {
        const itemsToAssign = [];

        this.workOrderItems.forEach((item) => {
            if (item.woi_itemID) {
                itemsToAssign.push(item);
            } else if (item.fee && (item.fee.fee_force_salesmen || item.fee.fee_force_technician)) {
                itemsToAssign.push(item);
            }
        });
        return itemsToAssign;
    }

    createAllAssignments(event) {
        this.emitEventToChild(event.assignment, event.employeeID);
    }

    emitEventToChild(assignment, employeeID) {
        this.assignAllEvent.next({'assignment': assignment, 'employeeID': employeeID});
    }
}
