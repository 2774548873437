import {Injectable} from '@angular/core';

@Injectable()
export class VariableHelper {
    static isset(accessor: any): boolean {
        try {
            return accessor() !== undefined && accessor() !== null;
        } catch (e) {
            return false;
        }
    }
}
