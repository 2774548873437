import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

@Injectable()
export class CarfaxService {

    private urlApi: string;
    private urlOld: string;

    constructor(
        private http: HttpClient,
    ) {
        this.urlApi = environment.tireguruBaseApi + '/carfax';
        this.urlOld = environment.tireguruBaseUrl;
    }

    getCarFaxWithLookupCodes(license, state, vin) {

        const url = `${this.urlApi}/lookup_codes`;

        return this.http.get(url, {
            params: {
                license, state, vin
            },
        }).toPromise();
    }

}
