import {catchError, switchMap, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {createEffect, Actions, ofType} from '@ngrx/effects';

import {Observable, of, from as fromPromise} from 'rxjs';

import * as arc from '../actions/arc.actions';
import {ArcService} from '../../core/services/arc.service';
import {GET_ARC_CART} from '../actions/arc.actions';

@Injectable()
export class ArcEffects {


    loadCart: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(arc.GET_ARC_CART),
        map((action: arc.GetArcCartAction) => action.payload),
        switchMap((payload) => fromPromise(this.arcService.getArcCart(payload)).pipe(
            map((arcCart) => new arc.GetArcCartSuccessFulAction(arcCart)),
            catchError((error) => of(new arc.GetArcCartFailedAction(error)))
        ))
    ));


    updateItem: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(arc.UPDATE_ITEM_QTY),
        map((action: arc.UpdateItemQtyAction) => action.payload),
        switchMap((payload) => fromPromise(this.arcService.updateItemQty(payload.id, payload)).pipe(
            map((update) => new arc.UpdateItemQtySuccessFulAction(update)),
            catchError((error) => of(new arc.UpdateItemQtyFailedAction(error)))
        ))
    ));


    deleteItemFromCart: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(arc.DELETE_ITEM_FROM_ARC_CART),
        map((action: arc.DeleteItemFromArcCartAction) => action.payload),
        switchMap((itemToRemove) => fromPromise(this.arcService.deleteItemFromCart(itemToRemove)).pipe(
            map((deleted) => new arc.DeleteItemFromArcCartSuccessFulAction(deleted)),
            catchError((error) => of(new arc.DeleteItemFromArcCartFailedAction(error)))
        ))
    ));


    getUnlinkedPartsLaborCore: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(arc.GET_UNLINKED_ARC_PARTS_LABOR_CORE),
        map((action: arc.GetUnlinkedArcPartsLaborCoreAction) => action.payload),
        switchMap((payload) => fromPromise(this.arcService.getArcUnlinkedPartsLaborCore(payload)).pipe(
            map((unlinked) => new arc.GetUnlinkedArcPartsLaborCoreSuccessfulAction(unlinked)),
            catchError((error) => of(new arc.GetUnlinkedArcPartsLaborCoreFailedAction(error)))
        ))
    ));


    getUnlinkedVendors: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(arc.GET_UNLINKED_VENDORS),
        map((action: arc.GetUnlinkedVendorsAction) => action.payload),
        switchMap((payload) => fromPromise(this.arcService.getUnlinkedVendors(payload)).pipe(
            map((unlinked) => new arc.GetUnlinkedVendorsSuccessfulAction(unlinked)),
            catchError((error) => of(new arc.GetUnlinkedVendorsFailedAction(error)))
        ))
    ));


    finalizeArc: Observable<any> = createEffect(() => this.$actions.pipe(
        ofType(arc.FINALIZE_ARC),
        map((action: arc.FinalizeArcAction) => action.payload),
        switchMap((payload) => fromPromise(this.arcService.finalizeArc(payload)).pipe(
            map((cartID) => new arc.FinalizeArcSuccessfulAction(cartID)),
            catchError((error) => of(new arc.FinalizeArcFailedAction(error)))
        ))
    ));

    constructor(private $actions: Actions, private arcService: ArcService) {
    }
}
