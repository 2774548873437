import { Action as NgrxAction } from '@ngrx/store';

export const SET_SHOW_TEXT_TO_PAY_MODAL = '[Finance] Set show text to pay modal';

export class SetShowTextToPayModal implements NgrxAction {
    readonly type = SET_SHOW_TEXT_TO_PAY_MODAL;

    constructor(public payload: any) {
    }
}

export type Action = SetShowTextToPayModal;
