<div *ngIf="loaded">
  <div *ngIf="custID && vehicleID; else error">
    <a *ngIf="returnUrl" [routerLink]="['/' + returnUrl]" class="btn is-primary is-sm">Back</a>
    <app-iframe [src]="url" [minHeight]="500"></app-iframe>
  </div>
  <ng-template #error>
    Cannot load vehicle history
  </ng-template>
</div>


