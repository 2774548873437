import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';

@Injectable()
export class ServiceService {

    public urlApi: string;
    public urlOld: string;

    constructor(private http: HttpClient) {
        this.urlApi = environment.tireguruBaseApi;
        this.urlOld = environment.tireguruBaseUrl;
    }

    getServices(actions: Array<number>) {
        let params = '';
        actions.forEach(action => {
            params += `categoryIds[]=${action}&`;
        });
        params = params.slice(0, -1);
        const url = `${this.urlApi}/job-categories?${params}`;
        return this.http.get(url).toPromise();
        // return new Promise((resolve, reject) => {
        //     const data = [
        //         {
        //             title: 'Courtesy Inspection',
        //             cost: 'Free',
        //             action: 'Open Inspection',
        //             id: 550
        //         },
        //         {
        //             title: 'Complete Vehicle Inspection',
        //             cost: '$19.99',
        //             action: 'Open Inspection',
        //             id: 550
        //         },
        //         {
        //             title: 'No Inspection',
        //             cost: 'Free',
        //             action: 'Collect Signature',
        //             id: 550
        //         },
        //     ];
        //     resolve(data);
        // });
        // const url = `${this.url}`;
        // return new Promise((resolve, reject) => {
        //     this.http.get(url, {
        //         params: {
        //         }
        //     }).subscribe(response => {
        //         resolve(response);
        //     }, error => {
        //         reject(error);
        //     });
        // });
    }

}
