import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ProfileService } from '../../../core/services/profile.service';
import { ProfileProvider } from '../../../core/providers/profile.provider';
import { UserService } from '../../../core/services/user.service';

import {State} from '../../../store/reducers';
import {Store} from '@ngrx/store';
import * as invoiceItemCategoriesActions from '../../../store/actions/invoiceitemcategories.actions';
import {WorkOrderProvider} from '../../../core/providers/work-order.provider';
import { environment } from '../../../../environments/environment';
import * as fromRoot from '../../../store/reducers';
import * as fromCustComms from '../../../store/actions/customerCommunication.actions';
import * as fromWorkorders from '../../../store/actions/workorders.actions';
import {GetUnreadTextCountAction} from '../../../store/actions/customerCommunication.actions';

@Component({
    selector: 'app-header',
    templateUrl: './app-header.component.html',
    styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit {

    public headerDynamicRoute: string;

    public profile: any;

    public numberOfUnreadMessages: number;

    constructor(
        private router: Router,
        private profileProvider: ProfileProvider,
        private workorderProvider: WorkOrderProvider,
        private userService: UserService,
        private store: Store<State>,
    ) {

        this.profile = {};

        this.profileProvider.getProfile().subscribe(result => {
            this.profile = result;
        });

        // Listen for router event (Navigation Start)
        router.events.subscribe( (event) => {
            if (event instanceof NavigationStart) {
                this.updateHeader(event);
            }
        });

        this.store.dispatch(new fromCustComms.GetUnreadTextCountAction({'locID': this.profile.location.loc_locID}));

        this.store.select(fromRoot.getUnreadWorkOrderMessagesCount(this.profile.use_userID)).subscribe( (e) => {
            this.store.select(fromRoot.getUnreadTextsCount).subscribe((texts) => {
                if (e && texts) {
                    this.numberOfUnreadMessages = e + texts;
                } else if (e) {
                    this.numberOfUnreadMessages = e;
                } else if (texts) {
                    this.numberOfUnreadMessages = texts;
                } else {
                    this.numberOfUnreadMessages = 0;
                }
            });
        });
    }

    ngOnInit() {
        this.store.dispatch(new invoiceItemCategoriesActions.LoadInvoiceItemCategoriesAction());
    }

    updateHeader(navigationEvent) {
        const url = navigationEvent.url;
        this.headerDynamicRoute = url;
    }

    logout() {
        this.userService.logout().then(_ => {
            this.profileProvider.setProfile({});
            this.workorderProvider.clear();
            this.store.dispatch({type: 'CLEAR_STATE'});
            this.router.navigate(['/login']);
        }).catch(error => {
            console.error(error);
        });
    }

    get firstName(): string {
        return this.profile && this.profile.use_fname ? this.profile.use_fname : '';
    }

    get lastName(): string {
        return this.profile && this.profile.use_lname ? this.profile.use_lname : '';
    }

    get permissionToUseGlobalSearch() {
        return !!this.profile['location']['settings']['loc_global_search_mobile'];
    }

    get logoUrl(): string {
        const logo = this.profile.location.settings.loc_mobile_logo;
        const tireguruBaseUrl = environment.tireguruLegacyBaseURL;
        if (logo) {
            return `${tireguruBaseUrl}/images/${logo}`;
        }

        return `${tireguruBaseUrl}/images/mobile/logos/1_2_logo.png`;
    }

    get isProfileEmpty(): boolean {
        return Object.keys(this.profile).length === 0;
    }
}
