import { Action as NgrxAction } from '@ngrx/store';

export const GLOBAL_SEARCH = "[Global Search] Global Search"
export const GLOBAL_SEARCH_SUCCESSFUL = "[Global Search] Global Search successful"
export const GLOBAL_SEARCH_FAILED = "[Global Search] Global Search failed"

export class GlobalSearchAction implements NgrxAction {
    readonly type = GLOBAL_SEARCH;

    constructor(public payload: any) {
    }
}

export class GlobalSearchActionSuccessful implements NgrxAction {
    readonly type = GLOBAL_SEARCH_SUCCESSFUL;

    constructor(public payload: any) {
    }
}

export class GlobalSearchActionFailed implements NgrxAction {
    readonly type = GLOBAL_SEARCH_FAILED;

    constructor(public err: any) {
    }
}
