<div class="container">
    <div class="columns is-centered">
        <div class="title column is-half">
            <p class="logo-container">
                <img src="./assets/images/tireguru-logo.png" alt="Tire Guru Logo">
            </p>
            <p class="title is-size-2 is-inverted centered-text">
                Your location is not set up to use premium mobile. To set up premium mobile please contact Tire Guru
                support at <a href="tel:+18774702267">(877) 470-2267</a>
            </p>
        </div>
    </div>
</div>
